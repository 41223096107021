import React, { useState, memo } from "react";
import {
  Marker,
  InfoWindow,
} from "react-google-maps";
import styled from "styled-components";
import _ from "lodash";
import Map2 from "../../../../images/map2.png";
import {
  UserOutlined,
  FieldTimeOutlined,
  FlagOutlined,
  MobileOutlined,
} from "@ant-design/icons";
const customerMarker = memo(({ item, rowId }) => {
  const [isShow, setIsShow] = useState({
    image: "",
    click: false,
  });
  const onMarker = (e) => {
    setIsShow((preState) => {
      let nextState = { ...preState };
      nextState.click = !isShow.click;
      return nextState;
    });
  };
  return (
    <div>
      <Marker
        position={{ lat: item.latitude, lng: item.longitude }}
        onClick={onMarker}
        icon={{
          url: Map2,
          scaledSize: new window.google.maps.Size(50, 50),
        }}
      />
      {isShow.click && (
        <div>
          <InfoWindow
            className="test"
            onCloseClick={() => {
              setIsShow((preState) => {
                let nextState = { ...preState };
                nextState.click = false;
                return nextState;
              });
            }}
            position={{
              lat: item.latitude,
              lng: item.longitude,
            }}
            onClose={isShow.click}
            options={
              {
                // pixelOffset: new google.maps.Size(0, -23),
              }
            }
          >
            <div style={{ border: "1px solid 1px" }}>
              <span
                style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}
              >
                <UserOutlined /> {_.get(item, "staffName", "")}
              </span>
              <div
                style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}
              >
                <span style={{ marginRight: 10 }}>
                  <FieldTimeOutlined /> {_.get(item, "endTime", "")}
                </span>
                <span>
                  <MobileOutlined /> {_.get(item, "bateryPercent", "")}%
                </span>
              </div>
              <span
                style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}
              >
                <FlagOutlined style={{color:'blue'}}/> {_.get(item, "address", "")}
              </span>
            </div>
          </InfoWindow>
        </div>
      )}
    </div>
  );
});
export default styled(customerMarker)``;
