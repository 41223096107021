/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 20/09/2021.
 * @description Màn hình `Danh sách nhóm người dùng`. Role `/access-management/role`
 * */

import { useCallback, useState, useEffect } from "react";
import { Card, Button, Table, message, Modal, Row, Col, Input, Form, Checkbox, Tabs } from "antd";
import { PlusOutlined, SearchOutlined, DeleteFilled, EditOutlined} from "@ant-design/icons";
import services from "services";
import ConfirmModal from "components/modals/confirm";
import RoleModal from "components/modals/RoleModal";
import _ from "lodash";
//import Title from "antd/lib/skeleton/Title";
import Text from "antd/lib/typography/Text";

const GrantPermission = function({onCancel, grantPermission}){
  const { visible, dataSource } = grantPermission;
  const [permissionAccess, setPermissionAccess] = useState([]);
  const [userAccess, setUserAccess] = useState([]);
 // const [isLoading, setIsLoading] = useState(false);
  const [selectTab, setSelectTab] = useState(0);
  //const [form] = Form.useForm();
  const { TabPane } = Tabs;
  
  const columnsPermission = [
    {
      dataIndex: 'accessStatus',
      key: 'accessStatus',
      title: '#',
      align: 'center',
      width: 100,
      render: (value, record, rowIndex) => (
          <Checkbox
              checked={value}
              onChange={handleCheckboxChangeFactory(rowIndex,record, "accessStatus")}
          />
      )
    },
    {
      dataIndex: 'permissionName',
      key: 'permissionName',
      title: 'Quyền truy cập',
      align: 'left',
    },
  ];

  const columnsUserAccount =[
    {
      dataIndex: 'grantAccess',
      key: 'grantAccess',
      title: '#',
      align: 'center',
      width: 100,
      render: (value, record, rowIndex) => (
        <Checkbox
            checked={value}
            onChange={handleCheckboxChangeFactory(rowIndex,record, "grantAccess")}
        />
      )
    },
    {
      dataIndex: 'email',
      key: 'email',
      title: 'Tên tài khoản',
      //align: 'center',
      align: 'left',
      width: 150,
    },
    {
      dataIndex: 'fullName',
      key: 'fullName',
      title: 'Tên nhân viên',
      //align: 'center',
      align: 'left',
      width: 150,
    },
    {
      dataIndex: 'titleReferenceName',
      key: 'titleReferenceName',
      title: 'Chức danh',
      //align: 'center',
      align: 'left',
      width: 150,
    },
  ];

  const [selectItems, setSelectedItem] = useState({
    data: [],
    key: [],
  });
  const handleCheckboxChangeFactory = (rowIndex, record, columnKey) => event => {
    if(selectTab === "0" ){ //permission access
      const newPermissionState = [...permissionAccess];
      newPermissionState[rowIndex][columnKey] = event.target.checked;
      setPermissionAccess(newPermissionState);
      setSelectedItem((preState) => {
        let nextState = { ...preState };
        nextState.data= record;
        nextState.key = rowIndex;
        return nextState;
      });
    }else if(selectTab === "1" ){ //user access
      const newUserAccountState = [...userAccess];
      newUserAccountState[rowIndex][columnKey] = event.target.checked;
      setUserAccess(newUserAccountState);
      setSelectedItem((preState) => {
        let nextState = { ...preState };
        nextState.data= record;
        nextState.key = rowIndex;
        return nextState;
      });
    }
  };

  const handleGetAllRoleBaseAccess = useCallback(()=>{
    services.accessManagementService
    .roleBaseAccess_GetBsWithPagination()
    .then((res)=>{
      let item = res.items.find((item, index) => item.roleDefinitionId === dataSource.id);
      let array = _.map(item?.permissionDefinitions,(item,index)=>{
            item.key = index +1;
            return item;
          });
      setPermissionAccess(array);
    });
  },[dataSource.id]);

  const handleGetAllUserRole = useCallback(()=>{
    services.accessManagementService
    .userRole_GetUserListByRoleId(dataSource.id)
    .then((res)=>{
      setUserAccess(res.items);
    });
  },[dataSource.id]);

  useEffect(()=>{
    handleGetAllRoleBaseAccess();
  }, [handleGetAllRoleBaseAccess]);

  useEffect(()=>{
    handleGetAllUserRole();
  }, [handleGetAllUserRole]);


  const handleSubmit = useCallback((values) => {
    if(selectTab === "0" ){
      //setIsLoading(true);
      let listAccess = _.filter(permissionAccess, function(value){
        if(value.accessStatus){
          return value;
        }
      });
      //collect ids 
      let listPermissionIds = _.map(listAccess, function(value){
        if(value.accessStatus){
          return value?.id;
        }
      });
      let params = {
        roleDefinitionId: dataSource?.id,
        permissionDefinitionIds: listPermissionIds
      };
      services.accessManagementService.roleBaseAccess_Update(params)
      .then((res)=>{
        if(res.succeeded){
          setTimeout(() => {
            message.success("Chỉnh sửa thành công.");
          }, 1000);
          //setIsLoading(false);
          setSelectedItem([]);
        }
        else {
          message.error("Chỉnh sửa không thành công.Vui lòng thử lại");
        }
      }).catch((err)=>{
        message.error("Chỉnh sửa không thành công.Vui lòng thử lại");
      }).finally(()=>{
        onCancel();
      });
    }else if(selectTab === "1"){
      let listUserAccess = _.filter(userAccess, function(value){
        if(value.grantAccess){
          return value;
        }
      });
      let listUserAccessIds = listUserAccess.map((obj) =>obj.userId);
      let param = {
        id: dataSource?.id,
        roleName: dataSource.roleName,
        userIds: listUserAccessIds
      }
      services.accessManagementService.userRole_Update(param)
      .then((res)=>{
        if(res.succeeded){
          setTimeout(() => {
            message.success("Chỉnh sửa thành công.");
          }, 1000);
          //setIsLoading(false);
          setSelectedItem([]);
        }
        else {
          message.error("Chỉnh sửa không thành công.Vui lòng thử lại");
        }
      }).catch((err)=>{
        message.error("Chỉnh sửa không thành công.Vui lòng thử lại");
      }).finally(()=>{
        onCancel();
      });
    }
    
    
    
  },[dataSource?.id, dataSource.roleName, onCancel, permissionAccess, selectItems, selectTab, userAccess]);

  return (
    <Modal
      visible={visible}
      title= {<div>"THÔNG TIN CHI TIẾT CỦA NHÓM <Text type="danger" level={2}>{dataSource.roleName}</Text></div>}
      onCancel={onCancel}
      width="50%"
      footer={[
        <Button onClick={handleSubmit} type='info'>
          <span className='d-flex align-items-center'>
            <PlusOutlined /><span className='ml-1'>Cập nhật</span> 
          </span>
        </Button>
      ]}
    >
      <Tabs defaultActiveKey='0' type='card' onChange = {(value)=>setSelectTab(value)}>
        <TabPane tab='Quản lý truy cập' key='0'>
          <Table
            size="small"
            key='grantPermissionId'
            columns={columnsPermission}
            dataSource={permissionAccess}
          />
        </TabPane>
        <TabPane tab='Quản lý tài khoản' key='1'>
          <Table
            size="small"
            key='userAccountId'
            columns={columnsUserAccount}
            dataSource={userAccess}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};
const RolePage = function (){
    const [form] = Form.useForm();
    const [data, setData] = useState([]);

    const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [isReloadPage, setIsReloadPage] = useState(false);
    //const [selectedRow, setSelectedRow] = useState({});
    const [isLoading, setIsLoading]= useState(false);
   // const [isDisablePermission, setDisablePermission] = useState(true);
    const [grantPermission, setGrantPermission] = useState({
      dataSource: {},
      visible:false,
    })

    useEffect(() => {
      setLoading(true);
      services.accessManagementService.userRole_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res)=>{
        setLoading(false);
        setTotal(res?.totalCount ?? 0);
        setData(res.items);
      })
      .catch((error)=>{
        setTotal(0);
        setLoading(false);
      })
    }, [paging,isReloadPage]);

    const columns = [
        {
            title: "Nhóm tài khoản",
            dataIndex: "roleName",
            key:"roleName",
            width: 150,
            align: "left",
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            key:"description",
            width: 300,
            align: "left",
        },
        {
            title: "Chi tiết quyền truy cập",
            dataIndex: "permission",
            key:"permission",
            width: 100,
            align: "center",
            render: (value,row) =>(
              <div className="align-items-center justify-content-between">
                <Button
                  onClick={() =>
                    setGrantPermission({
                      dataSource: row,
                      visible: true,
                    })
                  }
                  type="link"
                  size="small"
                >
                  <span className="d-flex align-items-center">Chi tiết</span>
                </Button>
              </div>
            ),
        },
        {
          dataIndex: "action",
          key: "action",
          title: "#",
          align: "center",
          fixed: "right",
          width: 100,
          render: (_, row) => (
            <div className="warehouseInfo-card__button">
              <Button
                  type="primary"
                  size="small"
                  onClick={handleEditRole(row)}
              >
                <span className="d-flex align-items-center">
                  <EditOutlined /> <span className="ml-1"></span>
                </span>
              </Button>
              <Button
                  className="warehouseInfo-card__button--right"
                  type="danger"
                  size="small"
                  onClick={handleDeleteRole(row)}
              >
                <span className="d-flex align-items-center">
                  <DeleteFilled /> <span className="ml-1"></span>
                </span>
              </Button>
            </div>
          ),
        },
    ];
    
    const [selectedRole, setSelectedRole] = useState({});
    const [isOpenRoleModal, setIsOpenRoleModal] = useState(false);
    const [roleModalMode, setRoleModalMode] = useState("add");
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    const handleCreateRoleModal = useCallback(() => {
    setRoleModalMode("add");
    setIsOpenRoleModal(true);
    setSelectedRole([]);
  }, []);
  
  
  const handleEditRole = useCallback(
    (currencyUnit) => (e) => {
      setRoleModalMode("edit")
      setIsOpenRoleModal(true);
      setSelectedRole(currencyUnit);
    },
    [setSelectedRole,setRoleModalMode,setIsOpenRoleModal]
  );

  const handleCancelRoleModal = useCallback(() => {
    setIsOpenRoleModal(false);
    setSelectedRole({});
  }, [setIsOpenRoleModal, setSelectedRole]);

  const handleCancelGrantPermission = useCallback(() => {
    setGrantPermission({
      visible: false,
      dataSource: {}
    });
  }, [setGrantPermission]);

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedRole({});
  }, [setSelectedRole, setIsReloadPage, isReloadPage]);
  
  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ page: currentPage, pageSize });
    },
    [paging]
  );

  const handleDeleteRole = useCallback(
    (role) => () => {
      setSelectedRole(role);
      setIsShowConfirmModal(true);
    },
    [setSelectedRole, setIsShowConfirmModal]
  );

  const handleApproveToDeleteRole = useCallback(() => {
    services.accessManagementService.userRole_Delete([{
        id: selectedRole?.id,
        currentState: selectedRole?.currentState
      }])
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        handleReloadPage();
      })
      .catch((err) => {
        message.error(err?.message || "Xóa kho thất bại");
      });
  }, [handleReloadPage, selectedRole]);

  const handleRejectToDeleteRole = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedRole({});
  }, [setSelectedRole]);

  // const handleClickRow = useCallback(
  //   (record, index) => {
  //     setSelectedRow(record);
  //     setDisablePermission(false);
  //   },
  //   [setSelectedRow, setDisablePermission],
  // );

  const handleSubmit = (values) => {
    setIsLoading(true);
    let filter = {
      ...form.getFieldsValue(),
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize
    };
    services.accessManagementService.userRole_Filtering(filter)
    .then(res =>{
      setIsLoading(false);
      setData(res.items);
      setTotal(res?.totalCount ?? 0);
      if (res.items.length === 0) {
        message.error("Không tìm thấy thông tin");
      }
      else {
        message.success("Tìm kiếm thành công");
      }
    })
    .catch(err =>{
      setTotal(0);
      setIsLoading(false);
    })
  };

  return (
    <Card
      className="RolePage"
    >
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={10}>
            <Form.Item name='keyword' label='Tìm theo từ khóa'>
              <Input placeholder='Nhập từ khóa' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button
                type='primary'
                htmlType='submit'
                disabled={isLoading}
              >
                <span className='d-flex align-items-center'>
                  <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
                </span>
              </Button>
          </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height ' label=' '>
              <Button onClick={handleCreateRoleModal}>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Tạo mới</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Export excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Import excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        size="small"
        scroll={{ x: 1200 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.page ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        }}
        loading={loading}
        locale={{ emptyText: "Không có dữ liệu" }}
        // onRow={(record, rowIndex) => ({
        //     onClick: () =>handleClickRow(record, rowIndex) // click row
        // })}
      />
      <RoleModal
        visible={isOpenRoleModal}
        mode = {roleModalMode}
        onCancel = {handleCancelRoleModal}
        dataItem = {selectedRole}
        onReloadPage={handleReloadPage}
      />
      <ConfirmModal
        title="Xóa chức vụ"
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeleteRole}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa{" "}
          <strong>{selectedRole?.roleName}</strong> không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleRejectToDeleteRole}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeleteRole}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
      <GrantPermission
        onCancel = {handleCancelGrantPermission} 
        grantPermission={grantPermission}
      />
    </Card>
  );
};
export default RolePage;