import React, { memo } from "react";

import { Table } from "antd";

const List = memo(({ listCustomers, total, paging, setPaging }) => {

  const handleChangePaging = (pageNumber, pageSize) => {
    const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
    setPaging({ pageNumber: currentPage, pageSize });
  };
  
  const customerColumns = [
    {
      dataIndex: "no",
      key: "no",
      title: "STT",
      align: "center",
      width: 50,
      render: (value, row, index) => ((paging.pageNumber - 1) * paging.pageSize) + index + 1
    },
    {
      dataIndex: 'customerCode',
      key: 'customerCode',
      title: 'Mã KH',
      width: 135
    },
    {
      dataIndex: 'customerName',
      key: 'customerName',
      title: 'Tên KH',
      width: 125
    },
    {
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      title: "SĐT",
      align: "right",
      width: 120
    },
    {
      dataIndex: "address",
      key: "address",
      title: "Địa chỉ",
      width: 220
    },
    {
      dataIndex: "staffCode",
      key: "staffCode",
      title: "Mã NV",
      width: 90
    },
    {
      dataIndex: "staffName",
      key: "staffName",
      title: "Tên NV",
      width: 135
    },
    {
      dataIndex: 'saleRouteName',
      key: 'saleRouteName',
      title: 'Tên tuyến',
      width: 220,
    },
    {
      dataIndex: "turnoverFormatted",
      key: "turnoverFormatted",
      title: "Doanh số",
      width: 130
    },
    {
      dataIndex: 'promotionPercentage',
      key: "promotionPercentage",
      title: '% chiết khấu',
      width: 50
    },
    {
      dataIndex: "promotionAmountFormatted",
      key: "promotionAmountFormatted",
      title: "Tiền chiết khấu",
      width: 120
    }
  ];

  return (
    <>
      <Table
        columns={customerColumns}
        dataSource={listCustomers}
        bordered
        size="small"
        scroll={{ x: "calc(700px + 50%)", y: 400 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
      />
    </>
  );
});

export default List;
