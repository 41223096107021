import React, {
  memo,
} from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
} from "react-google-maps";
import _ from "lodash";
import CustomerMarker from "./CustomerMarker/index";

const Map = memo(({ dataMerker, dataUser,data,setData }) => {
  const centerUser = {
    lat: 15.818135592909831,
    lng: 108.2856855718962,
  };
  //const [selectedPark, setSelectedPark] = useState(null);
 
  // useEffect(() => {
  //   let mounted = true;
  //   const listener = (e) => {
  //     if (e.key === "Escape") {
  //       setSelectedPark(null);
  //     }
  //   };
  //   window.addEventListener("keydown", listener);

  //   return () => {
  //     window.removeEventListener("keydown", listener);
  //   };
  // }, [data]);
  // cái này dùng để center theo từng điểm
  // const constzoomToMarkers = (map) => {
  //   if (data.length > 1) {
  //     const bounds = new window.google.maps.LatLngBounds();
  //     data.forEach((child) => {
  //       bounds.extend(
  //         new window.google.maps.LatLng(child.latitude, child.longitude)
  //       );
  //     });
  //     map && map.fitBounds(bounds);
  //   }
  // };
  return (
    <div>
      <GoogleMap
        // ref={constzoomToMarkers}
        defaultZoom={6}
        // onClick={(e) =>
        //   // console.log('e',e.latLng)
        //   setData([
        //     {
        //       latitude: e.latLng.lat(),
        //       longitude: e.latLng.lng(),
        //     },
        //   ])
        // }
        center={centerUser}
        options={{
          gestureHandling: "greedy",
        
        }}
      >
        {/* //marker của từng điểm */}

        {_.map(data, (item, index) => {
          return <CustomerMarker item={item} key={item.keyRow} />;
        })}
      </GoogleMap>
    </div>
  );
});

const MapWrapped = withScriptjs(withGoogleMap(Map));
const App = memo(({ dataMap, dataUser, data, ggMapKey }) => {
  return (
    <div style={{ width: "100%", height: "95vh" }}>
      <MapWrapped
        dataMerker={dataMap}
        dataUser={dataUser}
        data={data}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places${ggMapKey ? ('&key=' + ggMapKey) : ''}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
});
export default App;
