/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { Form, Input, Select, Button, Row, Col, Card, message } from "antd";
import services from "services";

const SaleRouteInfo = ({ saleRouteInfo, setSaleRouteInfo, onCancel }) => {
  const { generalService, userManagementService } = services;
  const [form] = Form.useForm();

  const [listDistributors, setListDistributors] = useState([]);
  const [listSalers, setListSalers] = useState([]);
  const [saveCreate, setSave] = useState(false);

  const isNewSaleRoute = !saleRouteInfo;
  const saleRouteId = saleRouteInfo?.id || null;
  const getSaleRouteInfo = useCallback(async () => {
    if (isNewSaleRoute) {
      form.resetFields();
      return;
    }

    try {
      
      if(saleRouteId){
        const response = await generalService.saleRoute_GetSaleRouteById(
          saleRouteId
        );
        // setSaleRouteInfo(response);
        form.setFieldsValue({
          saleRouteCode: response?.saleRouteCode,
          saleRouteName: response?.saleRouteName,
          status: response?.status,
          salerName: response?.salerName,
          notes: response?.notes,
          distributorId: response?.distributorId,
          salerId: response?.salerId,
        });
        getSalersByDistributorId(response?.distributorId);
      }
      
    } catch {
      // setSaleRouteInfo(null);
    }
  }, [form, saleRouteId]);

  const getAllDistributor = useCallback(async () => {
    try {
      const res = await generalService.distributor_GetAllDistributorsWithPermission();
      setListDistributors(res);
    } catch {
      setListDistributors([]);
    }
  }, []);

  useEffect(() => {
    getAllDistributor();
    getSaleRouteInfo();
  }, [getSaleRouteInfo, getAllDistributor]);

  const getSalersByDistributorId = async (value) => {
    if (!value) return;

    try {
      const listSalers =
        await userManagementService.resource_GetByDistributorId(value);
      setListSalers(listSalers);
    } catch {
      setListSalers([]);
    }
  };

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, []);

  // const handleUpdateSaleRoute = useCallback(
  //   async () => {
     
  //   },
  //   [saleRouteInfo, form]
  // );

  // const handleCreateSaleRoute = useCallback(
  //   () => {
      
  //   },
  //   [saleRouteInfo, form]
  // );

  const onDistributorChange = (value) => {
    setListSalers([]);
    form.setFieldsValue({ salerId: null });
    setTimeout(() => getSalersByDistributorId(value), 300);
  };

  const handleConfirm = useCallback(
    (state) => {
      if (state) {
        setSave(true);
      } else {
        setSave(false);
      }
      form.submit();
    },
    [form]
  );

  const onSubmit = async() => {
    const values = form.getFieldsValue();

    if (saleRouteId) {
      const updateParams = {
        ...values,
        id: saleRouteInfo?.id,
        currentState: saleRouteInfo?.currentState,
      };

      try {
        await generalService.saleRoute_Update(updateParams);
        message.success("Lưu tuyến thành công.");
        if (saveCreate) {
        } else {
          onCancel();
        }
      } catch {
        message.error("Lưu tuyến không thành công. Vui lòng thử lại");
      }
    } else {
      form.validateFields().then(async () => {
        //const createParams = { ...values };

        try {
          //const res = await generalService.saleRoute_Create(createParams);
          message.success("Tạo tuyến thành công.");
          if (saveCreate) {
            form.resetFields();
          } else {
            form.resetFields();
            onCancel();
          }
          // setSaleRouteInfo(res.objectReturn);
        } catch {
          message.error("Tạo tuyến không thành công. Vui lòng thử lại");
        }
      });
    }
  };
  return (
    <Card size="small" style={{ marginBottom: "2em" }}>
      <Form
        form={form}
        initialValues={isNewSaleRoute ? { status: 1 } : {}}
        labelCol={{ span: 24 }}
        onFinish={onSubmit}
      >
        <Row gutter={[24]}>
          <Col lg={24} xl={12}>
            <Form.Item
              name="saleRouteCode"
              label="Mã tuyến"
              rules={[{ required: true, message: "Thiếu mã tuyến" }]}
            >
              <Input placeholder="Nhập mã tuyến" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={12}>
            <Form.Item
              name="distributorId"
              label="NPP"
              rules={[{ required: true, message: "Thiếu NPP" }]}
            >
              <Select onChange={onDistributorChange} placeholder="Chọn NPP">
                {listDistributors.map((distributor) => (
                  <Select.Option key={distributor.id} value={distributor.id}>
                    {distributor.distributorName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24]}>
          <Col lg={24} xl={12}>
            <Form.Item
              name="saleRouteName"
              label="Tên tuyến"
              rules={[{ required: true, message: "Thiếu tên tuyến" }]}
            >
              <Input placeholder="Nhập tên tuyến" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={12}>
            <Form.Item
              name="status"
              label="Trạng thái"
              rules={[{ required: true, message: "Thiếu trạng thái" }]}
            >
              <Select placeholder="Chọn trạng thái">
                <Select.Option value={1}>Hoạt động</Select.Option>
                <Select.Option value={0}>Không hoạt động</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24]}>
          <Col lg={24} xl={12}>
            <Form.Item
              name="salerId"
              label="Nhân viên"
              rules={[{ required: true, message: "Thiếu nhân viên" }]}
            >
              <Select placeholder="Chọn nhân viên">
                {listSalers.map((saler) => (
                  <Select.Option key={saler.userId} value={saler.userId}>
                    {saler.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={12}>
            <Form.Item
              name="notes"
              label="Mô tả"
              // rules={[{ required: true, message: "Thiếu mô tả" }]}
            >
              <Input.TextArea placeholder="Nhập mô tả" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" gutter={[8]}>
          <Col>
            <Button
              type="primary"
              // onClick={
              //   isNewSaleRoute ? handleCreateSaleRoute : handleUpdateSaleRoute
              // }
              onClick={() => handleConfirm(false)}
            >
              <span className="d-flex align-items-center">
                <span className="ml-1">{isNewSaleRoute ? "Lưu" : "Lưu"}</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              // onClick={
              //   isNewSaleRoute ? handleCreateSaleRoute(true) : handleUpdateSaleRoute
              // }
              onClick={() => handleConfirm(true)}
            >
              <span className="d-flex align-items-center">
                <span className="ml-1">Lưu và tiếp tục</span>
              </span>
            </Button>
          </Col>

          <Col>
            <Button onClick={handleCancel}>
              <span className="d-flex align-items-center">
                <span className="ml-1">Quay Lại</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SaleRouteInfo;
