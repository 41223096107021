import { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  Table,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import services from 'services';
import _ from 'lodash';

const AreaMonitoringPage = () => {
  const [form] = Form.useForm();
  const [businessRegion, setBusinessRegion] = useState([]);
  const [province, setProvince] = useState([]);
  const [listTable, setListTable] = useState([]);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    services.organization.businessRegion_GetAllRegionsWithPermission().then((res) => {
      setBusinessRegion(res);
    });
  }, []);
  const handleChangeRegion = useCallback(
    (obj) => {
      let regionItem = _.filter(businessRegion, function (item) {
        if (item.id === obj?.value) {
          return item?.id;
        }
      });
      services.organization
        .province_GetProvinceByRegion(regionItem[0]?.id)
        .then((res) => {
          setProvince(res);
        });
      form.getFieldValue().provinceObj = "";
    },
    [form, businessRegion]
  );

  const handleConfirmfilter = useCallback(() => {
    form.submit();
  }, [form]);

  const handleSubmit = (values) => {
    var params = {
      ...values,
      regionId: form.getFieldValue('businessRegionObj')?.value,
      provinceId: form.getFieldValue('provinceObj')?.value,
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize
    };
    setIsLoading(true);
    services.satatisticReportService.user_HumanResourceReport(params)
    .then((res)=> {
      setTotal(res?.totalCount ?? 0);
      setListTable(res.items);
      setIsLoading(false);

    })
    .catch((err)=> {
      setTotal(0);
      setListTable([]);
      setIsLoading(false);
    })
  };

  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ page: currentPage, pageSize });
    },[paging?.pageSize]
  );

  const columns = [
    {
      dataIndex: 'regionName',
      key: 'regionName',
      title: 'Vùng miền',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: 'provinceName',
      key: 'provinceName',
      title: 'Tỉnh thành',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: 'managerUser',
      key: 'managerUser',
      title: 'Người giám sát',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: 'salerName',
      key: 'salerName',
      title: 'Nhân viên bán hàng',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: 'distributorName',
      key: 'distributorName',
      title: 'Nhà phân phối',
      width: 150,
      ellipsis: true,
    },
  ];

  return (
    <Card className='areaMonitoringPage'>
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={6}>
            <Form.Item label='Vùng miền' name='businessRegionObj'>
              <Select placeholder='Chọn vùng miền' labelInValue allowClear onChange={handleChangeRegion}>
                {businessRegion.map((rg, id) => (
                  <Select.Option key={id} value={rg?.id}>
                    {rg?.regionName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item label='Tỉnh thành' name='provinceObj'>
              <Select
                placeholder="Chọn tỉnh"
                showSearch
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                labelInValue
                allowClear
              >
                {province?.map((pv, id) => (
                  <Select.Option key={id} value={pv?.id}>
                    {pv?.provinceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
              <Form.Item name="distributorName" label="Tên NPP">
                <Input placeholder="Nhập tên NPP" />
              </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
              <Form.Item name="salerName" label="Tên Nhân viên">
                <Input placeholder="Nhập tên nhân viên" />
              </Form.Item>
          </Col>
        </Row>
        
        <Row
          justify='end'
          style={{ marginBottom: '12px', display: 'flex', gap: '10px' }}
        >
          <Button
            type='primary'
            onClick={handleConfirmfilter}
            icon={<SearchOutlined />}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Tìm kiếm
          </Button>
          <Button type='dashed'>
            <span className='d-flex align-items-center'>
              <DownloadOutlined /> <span className='ml-1'>Xuất file Excel</span>
            </span>
          </Button>
        </Row>
      </Form>

      <Table
        bordered
        columns={columns}
        dataSource={listTable}
        scroll={{ x: 1200 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        }}
        loading={isLoading}
        locale={{ emptyText: 'Không có dữ liệu' }}
      />
    </Card>
  );
};

export default AreaMonitoringPage;
