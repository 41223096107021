import React, { memo, useState, useCallback, useEffect } from "react";
import { Row, Col, Card, Spin } from "antd";
import styled from "styled-components";
import _ from "lodash";
import List from "./list";
import services from "services";
import Filter from "./Filter";
import moment from "moment";

let time;
const Index = memo(({ className, profile }) => {
  const [listCustomers, setListCustomers] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    arrBusinessRegions: [],
    arrProvince: [],
  });
  const [selectedRegionId, setSelectedRegionId] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);

  const [params, setParams] = useState({
    regionId: undefined,
    provinceId: undefined,
    quarter: moment(),
    year: moment(),
  });

  useEffect(() => {
    // api khu vực
    services.organization
      .businessRegion_GetAllRegionsWithPermission()
      .then(async (res) => {
        let array = _.map(res, (item, index) => {
          item.name = item.regionName;
          return item;
        });
        setDataFilter((preState) => {
          let nextState = { ...preState };
          nextState.arrBusinessRegions = array;
          return nextState;
        });
      })
      .catch((error) => {});

    // api tỉnh
    services.organization
      .province_GetAllProvincesWithPermission()
      .then(async (res) => {
        let array = _.map(res, (item, index) => {
          item.name = item.provinceName;
          return item;
        });

        setDataFilter((preState) => {
          let nextState = { ...preState };
          nextState.arrProvince = array;
          return nextState;
        });
      })
      .catch((error) => {});
  }, [setDataFilter]);

  const getCustomers = useCallback(() => {
    setLoading(true);
    services.generalService
      .customerInformation_PromotionReport({
        regionId: params.regionId,
        provinceId: params.provinceId,
        quarter: params.quarter && moment(params.quarter).format("Q"),
        year: params.quarter && moment(params.quarter).format("YYYY"),
        pageNumber: paging.pageNumber,
        pageSize: paging.pageSize,
      })
      .then((res) => {
        setLoading(false);
        setListCustomers(res.items);
        setTotal(res.totalCount);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [paging]);

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(getCustomers, 800);
  }, [getCustomers]);

  return (
    <Card>
      <Spin spinning={loading} tip="Đang lấy dữ liệu...">
        <Row className={className} gutter={16}>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <Filter
              params={params}
              setParams={setParams}
              dataFilter={dataFilter}
              selectedRegionId={selectedRegionId}
              setSelectedRegionId={setSelectedRegionId}
              paging={paging}
              setPaging={setPaging}
              loading={loading}
            />
          </Col> 
            <List
              listCustomers={listCustomers}
              total={total}
              paging={paging}
              setPaging={setPaging}
            />
        </Row>
      </Spin>
    </Card>
  );
});

export default styled(Index)``;
