/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Màn hình `Quản lý chức danh`. Route `/organization/title-management`
 * */

import { useCallback, useState, useEffect } from "react";
import {
  Card,
  Button,
  Table
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";

import utils from "utils";
import services from "services";
import { get } from "lodash";
import UserRoleModal from "components/modals/UserRoleModal";

const TitleManagementPage = function () {
  const [roleModalConfig, setRoleModalConfig] = useState({
    mode: utils.$const.COMMON.MODAL_MODE.ADD,
    visible: false,
    dataSource: {},
  });
  const handleEditTitle = useCallback(
    (dataSource) => (e) => {
      setRoleModalConfig({
        mode: utils.$const.COMMON.MODAL_MODE.EDIT,
        visible: true,
        dataSource: dataSource,
      });
    },
    []
  );

  const [fetching, setfetching] = useState(false);
  const [roles, setRoles] = useState([]);
  const [rolesPagination, setRolesPagination] = useState({
    total: 0,
    pageSize: 20,
  });

  const handleBrowseRoles = useCallback((param) => {
    setfetching(true);
    const pageNumber = get(param, "pageNumber", 1);
    const pageSize = get(param, "pageSize", 20);
    services.organization
      .titleDefinition_GetBsWithPagination(pageNumber, pageSize)
      .then((res) => {
        const items = get(res, "items");
        const total = get(res, "totalCount");
        setRoles(items);
        setRolesPagination((prev) => ({
          ...prev,
          total,
          pageSize,
        }));
      })
      .finally(() => setfetching(false));
  }, []);

  const handleCanceRoleModal = useCallback(() => {
    setRoleModalConfig({
      visible: false,
      dataSource: {},
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    handleBrowseRoles({});
  }, [handleBrowseRoles]);

  const handleChangeTable = useCallback(
    (pagination, filters, sorter, extra) => () => {
      const pageNumber = get(pagination, "pageNumber", 1);
      const pageSize = get(pagination, "pageSize", 20);
      handleBrowseRoles({ pageNumber, pageSize });
    },
    [handleBrowseRoles]
  );
  useEffect(() => {
    handleBrowseRoles({});
  }, [handleBrowseRoles]);

  const columns = [
    {
      title: "Mã chức danh",
      dataIndex: "titleCode",
      key: "titleCode",
      // align: "center",
      width: 150,
    },
    {
      title: "Tên chức danh",
      dataIndex: "titleName",
      key: "titleName",
      // align: "center",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 175,
      render: (value) => {
        var findedStatus = utils.$const.ROLE.STATUS.find((x) => x.id === value);
        return (
          <span style={{ color: findedStatus?.color }}>
            {findedStatus?.name}
          </span>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      width: 50,
      render: (_, row) => (
        <EditOutlined
          className="text-primary"
          onClick={() => handleEditTitle(row)}
        />
      ),
    },
  ];

  return (
    <Card className="roleManagementPage" size="small">
      <div className="text-right mb-2">
        <Button
          onClick={() =>
            setRoleModalConfig({
              mode: utils.$const.COMMON.MODAL_MODE.ADD,
              visible: false,
              dataSource: {},
            })
          }
        >
          <span className="d-flex align-items-center">
            <PlusOutlined />
            <span className="ml-1">Tạo mới</span>
          </span>
        </Button>
      </div>

      <Table
        size="small"
        bordered
        columns={columns}
        dataSource={roles}
        rowKey="id"
        loading={fetching}
        pagination={rolesPagination}
        onChange={handleChangeTable}
        locale={{ emptyText: "Không có quyền" }}
      />
      <UserRoleModal config={roleModalConfig} onCancel={handleCanceRoleModal} />
    </Card>
  );
};
export default TitleManagementPage;
