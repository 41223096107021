import React, { useState, memo } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import styled from "styled-components";
import _ from "lodash";
import Map2 from "../../../../images/iconusser.png";
import {
  UserOutlined,
  FieldTimeOutlined,
  FlagOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import moment from "moment";

const customerMarker = memo(({ item, params }) => {
  const google = window.google;
  const [isShow, setIsShow] = useState({
    image: "",
    click: false,
  });
  const checkDate = _.get(params, "checkDate") && `${moment(_.get(params, "checkDate")).format('YYYY-MM-DD')}T00:00:00.000Z`;
  const onMarker = (e) => {
    setIsShow((preState) => {
      let nextState = { ...preState };
      nextState.click = !isShow.click;
      return nextState;
    });
  };
  
  return (
    <div>
      <Marker
        position={{ lat: item.latitude, lng: item.longitude }}
        onClick={onMarker}
        icon={{
          url: Map2,
          scaledSize: new window.google.maps.Size(50, 50),
        }}
      />
      {isShow.click && (
        <div>
          <InfoWindow
            className="test"
            onCloseClick={() => {
              setIsShow((preState) => {
                let nextState = { ...preState };
                nextState.click = false;
                return nextState;
              });
            }}
            position={{
              lat: item.latitude,
              lng: item.longitude,
            }}
            onClose={isShow.click}
            options={{pixelOffset: new google.maps.Size(0, -30)}}
          >
            <div style={{ border: "1px solid 1px" }}>
              <div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                <UserOutlined /> NV: {_.get(item, "staffName", "")}
              </div>
              <div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                <span>
                  <UserOutlined /> KH: {_.get(item, "customerName", "")}
                </span>
                <span style={{marginLeft: 5}}>
                  <MobileOutlined /> {_.get(item, "bateryPercent", "")}%
                </span>
              </div>
              <div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                  <FieldTimeOutlined /> Check in: {_.get(item, "startTime", "")}
              </div>
              {_.get(item, "endTime", "") !== "" &&
              <div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                  <FieldTimeOutlined /> Check out: {_.get(item, "endTime", "")}
              </div>}
              <span style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                <FlagOutlined style={{ color: "blue" }} />{" "}
                {_.get(item, "address", "")}
              </span>
              <div>
                <a
                  href={`/monitoring/location-checkin-staff?distributorId=${params.distributorId}&staffId=${item.staffId}&type=${params.type}&checkDate=${checkDate}`}
                  target="_bank"
                >
                  Chi tiết
                </a>
              </div>
            </div>
          </InfoWindow>
        </div>
      )}
    </div>
  );
});
export default styled(customerMarker)``;
