import { Fragment, useEffect, useState } from 'react';
import { Button, Form, Select, DatePicker, Input, Row, Col } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { map } from 'lodash';

import services from 'services';
import utils from 'utils';

function SearchForm({ onShowAddOutboundModal }) {
  const [listWarehouse, setListWarehouse] = useState([]);
  const [listCustomer, setListCustomer] = useState([]);

  useEffect(() => {
    const getListCustomer = () => {
      services.generalService
        .customerInformation_GetListCustomer()
        .then((res) => setListCustomer(res))
        .catch(() => setListCustomer([]));
    };
    getListCustomer();
  }, []);

  useEffect(() => {
    const getListWarehouse = () => {
      services.generalService
        .warehouses_GetAllWarehouse()
        .then((res) => setListWarehouse(res))
        .catch(() => setListWarehouse([]));
    };
    getListWarehouse();
  }, []);

  return (
    <Fragment>
      <Row gutter={[8]}>
        <Col span={6} lg={8} xl={6}>
          <Form.Item name='documentCode' label='Mã phiếu xuất'>
            <Input placeholder='Nhập mã' />
          </Form.Item>
        </Col>
        <Col span={6} lg={8} xl={6}>
          <Form.Item name='customerId' label='Mã khách hàng'>
            <Select placeholder='Chọn khách hàng' allowClear>
              {map(listCustomer, (customer) => (
                <Select.Option key={customer?.id} value={customer?.id}>
                  {customer?.customerName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} lg={8} xl={6}>
          <Form.Item name='documentType' label='Hình thức xuất'>
            <Select placeholder='Hình thức xuất' allowClear>
              {map(utils.$const.OUTBOUND.DOCUMENT_TYPE, (stat) => (
                <Select.Option key={stat.id} value={stat.value}>
                  {stat.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} lg={8} xl={6}>
          <Form.Item name='fromWarehouseId' label='Từ kho'>
            <Select placeholder='Chọn kho' allowClear>
              {map(listWarehouse, (warehouse) => (
                <Select.Option key={warehouse?.id} value={warehouse?.id}>
                  {warehouse?.warehouseName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} lg={8} xl={6}>
          <Form.Item name='fromDate' label='Từ ngày' style={{ width: '100%' }}>
            <DatePicker style={{ width: '100%' }} placeholder='Chọn ngày' />
          </Form.Item>
        </Col>
        <Col span={6} lg={8} xl={6}>
          <Form.Item name='toDate' label='Đến ngày' style={{ width: '100%' }}>
            <DatePicker style={{ width: '100%' }} placeholder='Chọn ngày' />
          </Form.Item>
        </Col>
        <Col span={6} lg={8} xl={6}>
          <Form.Item name='status' label='Trạng thái'>
            <Select placeholder='Chọn trạng thái' allowClear>
              {map(utils.$const.OUTBOUND.STATUS, (stat) => (
                <Select.Option key={stat.id} value={stat.id}>
                  {stat.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify='end' gutter={[8]} className='mb-4'>
        <Col>
          <Button onClick={onShowAddOutboundModal}>
            <span className='d-flex align-items-center'>
              <PlusOutlined /> <span className='ml-1'>Tạo phiếu</span>
            </span>
          </Button>
        </Col>
        <Col>
          <Button type='primary' htmlType='submit'>
            <span className='d-flex align-items-center'>
              <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
            </span>
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}

export default SearchForm;
