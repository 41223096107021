import { Col, Row } from "antd";
import Chart from "react-google-charts";

const CHART_DATA_FAKE = [
  ["day", "Doanh số", "khách hàng"],
  ["1", 135, 111],
  ["2", 157, 100],
  ["3", 139, 100],
  ["4", 136, 100],
  ["5", 136, 100],
  ["6", 136, 100],
  ["7", 165, 100],
  ["8", 135, 100],
  ["9", 157, 100],
  ["10", 165, 100],
  ["11", 135, 100],
  ["12", 157, 100],
  ["13", 139, 100],
  ["14", 136, 100],
  ["15", 136, 100],
  ["16", 136, 100],
  ["17", 165, 100],
  ["18", 135, 100],
  ["19", 157, 100],
  ["20", 165, 100],
  ["21", 135, 100],
  ["22", 157, 100],
  ["23", 139, 100],
  ["24", 136, 100],
  ["25", 136, 100],
  ["26", 136, 100],
  ["27", 165, 100],
  ["28", 135, 100],
  ["29", 157, 100],
  ["30", 139, 100],
  ["31", 136, 100],
];

const PIE_CHART_DATA = [
  ["Task", "Hours per Day"],
  [" 1", 11],
  [" 2", 5],
  [" 3", 3],
  [" 4", 3],
  [" 5", 7],
  [" 6", 5],
  [" 7", 7],
  [" 8", 7],
  [" 1", 11],
  [" 2", 5],
  [" 3", 3],
  [" 4", 3],
  [" 5", 7],
  [" 6", 5],
  [" 7", 7],
  [" 8", 7],
];

function ChartCustomer(props) {
  const { selectedMonth } = props;

  return (
    <div>
      <Row gutter={16} className="space_div">
        <Col xs={24}>
          <Chart
            width="100%"
            height={500}
            chartType="ComboChart"
            loader={<div>Loading Chart</div>}
            data={
              selectedMonth
                ? CHART_DATA_FAKE
                : [
                    ["day", "Doanh số", "khách hàng"],
                    ["No Data", 0, 0],
                  ]
            }
            options={{
              seriesType: "bars",
              chartArea: { width: "85%", marginLeft: "0px" },
              hAxis: {
                title: "Doanh thu + doanh số theo ngày",
                minValue: 0,
              },
              colors: ["#5656ff", "#f70000"],
            }}
          />
        </Col>
        {selectedMonth ? (
          <Col xs={8}>
            <Chart
              width="100%"
              chartType="PieChart"
              loader={<div>Loading Chart</div>}
              data={PIE_CHART_DATA}
              options={{
                // title: "Doanh thu + doanh số của 8 tuần gần nhất",
                hAxis: {
                  title: "Doanh thu + doanh số của 8 tuần gần nhất",
                  minValue: 0,
                },
              }}
              rootProps={{ "data-testid": "1" }}
            />
            <i
              style={{
                fontSize: 16,
                display: "flex",
                justifyContent: "center",
              }}
            >
              Doanh thu theo ngày{" "}
            </i>
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default ChartCustomer;
