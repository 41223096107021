/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 22/06/2021.
 * @description Modal Thêm/Sửa nhà phân phối
 * */

import { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Select } from "antd";
import PropTypes from "prop-types";
import services from "services";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";

const DistributorModal = function ({
  visible,
  mode,
  onCancel,
  dataItem = {},
  onReloadPage,
  listProvince,
}) {
  const [form] = Form.useForm();
  const [district, setDistrict] = useState([]);
  const [isContinue, setIsContinue] = useState(false);

  const handleConfirm = useCallback(() => {
    setIsContinue(false);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleConfirmAndContinue = useCallback(() => {
    setIsContinue(true);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleSubmit = useCallback(
    (items) => {
      items.districtId = items.districtObj?.key;
      items.provinceId = items.provinceObj?.key;
      if (mode === "edit") {
        services.generalService
          .distributor_Update(items)
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onReloadPage();
            onCancel();
            form.resetFields();
          })
          .catch((err) => {
            message.error("Lưu không thành công, hãy thử lại.");
          });
      } else if (mode === "add") {
        services.generalService
          .distributor_Create(items)
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onReloadPage();
            if (isContinue) {
              form.resetFields();
            } else {
              onCancel();
              form.resetFields();
            }
          })
          .catch((err) => {
            message.error("Lưu không thành công, hãy thử lại.");
          });
      }
    },
    [form, isContinue, mode, onCancel, onReloadPage]
  );

  const handleChangeProvince = async (obj) => {
    let provinceItem = _.filter(listProvince, function (item) {
      if (item.id === obj.value) {
        return item?.id;
      }
    });
    await services.organization
      .district_GetDistrictByProvince(provinceItem[0]?.id)
      .then((res) => {
        setDistrict(res);
      });
    form.getFieldValue().districtObj = "";
    form.setFieldsValue({ provinceObj: obj });
  };

  const renderTitle = () =>
    mode === "add" ? `Thêm mới nhà phân phối` : ` Chỉnh sửa nhà phân phối`;

  useEffect(() => {
    if (visible && mode === 'edit') {

      const initValues = {
        id: dataItem.id,
        currentState: dataItem.currentState,
        distributorCode: dataItem.distributorCode,
        distributorName: dataItem.distributorName,
        activeStatus: dataItem.activeStatus,
        representative: dataItem.representative,
        taxCode: dataItem.taxCode,
        phoneNumber: dataItem.phoneNumber,
        address: dataItem.address,
        notes: dataItem.notes,
        districtObj: {
          value: dataItem.districtId,
          label: dataItem.districtName,
        },
        provinceObj: {
          value: dataItem.provinceId,
          label: dataItem.provinceName,
        },
      };
      form.setFieldsValue(initValues);
    }
  }, [dataItem, form]);

  const handleCloseModal = useCallback(() => {
    onCancel && onCancel();
    form.resetFields();
  }, [form, onCancel]);
  return (
    <Modal
      width="40%"
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      onCancel={onCancel}
      footer={[
        <Form.Item>
          <Button onClick={handleConfirm} type="primary">
            {dataItem?.id ? "Sửa" : "Thêm"}
          </Button>
          {mode === "add" ? (
            <Button
              title="Thêm và tiếp tục"
              key="submitAndContinute"
              type="primary"
              onClick={handleConfirmAndContinue}
            >
              Thêm và tiếp tục
            </Button>
          ) : (
            ""
          )}
          <Button type="default" onClick={handleCloseModal}>
            Quay lại
          </Button>
        </Form.Item>,
      ]}
    >
      <Form initialValues={dataItem} onFinish={handleSubmit} form={form}>
        <Form.Item
          label="Mã nhà phân phối"
          required
          name="distributorCode"
          rules={[{ required: true, message: "Mã sản phẩm không được trống" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập mã sản phẩm" />
        </Form.Item>
        <Form.Item
          label="Tên nhà phân phối"
          name="distributorName"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập tên nhà phân phối" />
        </Form.Item>
        <Form.Item label="Tỉnh thành" name="provinceObj" labelCol={{ span: 7 }}>
          <Select
            placeholder="Chọn tỉnh thành"
            showSearch
            showArrow={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            labelInValue
            allowClear
            onChange={handleChangeProvince}
          >
            {_.map(listProvince, (item, id) => (
              <Select.Option key={id} value={item?.id}>
                {item?.provinceName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Quận huyện" name="districtObj" labelCol={{ span: 7 }}>
          <Select
            placeholder="Chọn quận huyện"
            showSearch
            showArrow={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            labelInValue
            allowClear
          >
            {_.map(district, (item, id) => (
              <Select.Option key={id} value={item?.id}>
                {item?.districtName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Người đại diện"
          name="representative"
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Vui lòng nhập" />
        </Form.Item>
        <Form.Item label="Mã số thuế" name="taxCode" labelCol={{ span: 7 }}>
          <Input placeholder="Vui lòng nhập" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Số điện thoại"
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>
        <Form.Item name="address" label="Địa chỉ" labelCol={{ span: 7 }}>
          <Input placeholder="Nhập địa chỉ" />
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="activeStatus"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 7 }}
        >
          <Select defaultValue={1} placeholder="Chọn trạng thái">
            <Select.Option value={1}>Hoạt động</Select.Option>
            <Select.Option value={0}>Không hoạt động</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Ghi chú" name="notes" labelCol={{ span: 7 }}>
          <TextArea placeholder="Nhập ghi chú" rows={5} />
        </Form.Item>
        <Form.Item hidden name="id">
          <Input disabled></Input>
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
DistributorModal.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
};

export default DistributorModal;
