import React, { memo, useState, useCallback } from "react";
import {
  Button,
  Form,
  message,
  Modal,
  Checkbox,
  Select,
  Input,
} from "antd";
import _ from "lodash";
import services from "services";
// import AuthSlice from "redux/auth/authSlice";
// import { useSelector } from "react-redux";
const { Option } = Select;

const Create = memo(
  ({
    visible,
    setVisible,
    selectedCustomerInfor,
    dataFilter,
    id,
    setParams,
  }) => {
    const [saveCreate, setSave] = useState(false);

    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 24 },
    };
    // const tailLayout = {
    //   wrapperCol: { offset: 14},
    // };
    const [form] = Form.useForm();
    // const type = _.get(visible, "type");
    // const isShowCreate = _.get(visible, "isShowCreate");
    // const user = useSelector(AuthSlice.seletors.makeSelectUser);

    const onClose = () => {
      setVisible((preState) => {
        let nextState = { ...preState };
        nextState.isShowCreate = false;
        return nextState;
      });
      // setParams((preState) => {
      //   let nextState = { ...preState };
      //   nextState = nextState;
      //   return nextState;
      // });
      form.resetFields();
    };
    const onSubmit = useCallback(
      (state) => {
        if (state) {
          setSave(true);
        } else {
          setSave(false);
        }
        form.submit();
      },
      [form]
    );
    const onFinish = () => {
      let values = form.getFieldValue();
      Object.assign(values, {
        customerId: id,
        problemStatus: values?.problemStatus === undefined ? 1 : 4,
      });
      services.customerService
        .visitComment_Create(values)
        .then((res) => {
          message.success("Tạo vấn đề thành công");
          if (saveCreate) {
            form.resetFields();
          } else {
            onClose();
          }
        })
        .catch((error) => {
          message.error("Có lỗi trong quá trình xử lí !");
        });

      // form.resetFields();
    };
    return (
      <Modal
        closable={false}
        onCancel={onClose}
        destroyOnClose
        visible={visible.isShowCreate}
        placement="right"
        width="40%"
        footer={null}
        title="Thêm mới vấn đề khách hàng"
      >
        <Form
          {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          labelAlign="left"
        >
          <Form.Item
            name="problemTypeId"
            label="Nhóm vấn đề"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu!" }]}
          >
            <Select placeholder="Nhóm vấn đề" allowClear>
              {_.map(_.get(dataFilter, "arrProblem"), (item, index) => {
                return <Option value={item.id}>{item.problemTypeName}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="comments"
            label="Vấn đề"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu!" }]}
          >
            <Input.TextArea placeholder="Vấn đề" />
          </Form.Item>
          <Form.Item
            name="staffId"
            label="Người nhận"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu!" }]}
          >
            <Select placeholder="Người nhận" allowClear>
              {_.map(_.get(dataFilter, "arrUser"), (item, index) => {
                return <Option value={item.id}>{item.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="problemStatus"
            label="Đã xử lý"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          {/* <Form.Item {...tailLayout}> */}
          <div style={{display:'flex',justifyContent:'end'}}>
            <Button
              type="primary"
              style={{ marginRight: 5 }}
              onClick={() => onSubmit(false)}
            >
              Lưu
            </Button>
            <Button
              type="primary"
              onClick={() => onSubmit(true)}
              style={{ marginRight: 5 }}
            >
              Lưu và tiếp tục
            </Button>
            <Button onClick={onClose}>Quay lại</Button>
            </div>
          {/* </Form.Item> */}
        </Form>
      </Modal>
    );
  }
);
export default Create;
