/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Modal Thêm/Sửa nhân viên
 * */

import { Modal } from "antd";
import PropTypes from "prop-types";
import AddUpdateOrderPage from "pages/shippingOrder/AddUpdateOrder";

const AddEditShippingOrderModal = function ({ config, onCancel }) {
  const { visible, dataSource } = config;

  return (
    <Modal
      visible={visible}
      title="Chỉnh sửa đơn hàng"
      footer={null}
      bodyStyle={{ padding: 0 }}
      onCancel={onCancel}
      style={{ top: 20 }}
      width="90%"
    >
      <AddUpdateOrderPage dataSource={dataSource} onCancel={onCancel} />
    </Modal>
  );
};
AddEditShippingOrderModal.propTypes = {
  config: PropTypes.any,
};
export default AddEditShippingOrderModal;
