import React, { useState, useCallback, useEffect } from "react";
import { Select } from "antd";
import { isArray, get } from "lodash";
let searchTimer = null;

const BaseSelect = function ({
  extraInfo = false,
  searchName,
  itemValue,
  itemLabel,
  placeholder = "Chọn",
  searchOnMount = false,
  labelInValue = false,
  filterOption = false,
  showSearch = true,
  onSearch,
  onChange,
  onFilter,
  ...restProps
}) {
  const [dataSource, setDataSource] = useState([]);

  const handleChange = useCallback(
    (value) => {
      if (onChange) {
        if (!extraInfo) {
          onChange(value);
          return;
        }
        onChange(
          value ? dataSource.find((x) => x[itemValue] === value.value) : value
        );
      }
    },
    [onChange, dataSource, itemValue, extraInfo]
  );
  const handleSearch = useCallback(
    (value, immediately) => {
      const search = () => {
        onSearch(value)
          .then((res) => {
            const resIsArray = isArray(res);
            let data = resIsArray ? res || [] : get(res, "items", []);
            setDataSource(data);
          })
          .catch((error) => setDataSource([]));
      };
      if (immediately) {
        search();
        return;
      }
      if (filterOption) return;
      clearTimeout(searchTimer);
      searchTimer = setTimeout(search, 300);
    },
    [onSearch, filterOption]
  );

  useEffect(() => {
    if (searchOnMount) {
      handleSearch("", searchOnMount);
    }
  }, [searchOnMount, handleSearch]);

  const options = (onFilter ? onFilter(dataSource) : dataSource).map((item) => (
    <Select.Option key={item[itemValue]}>{item[itemLabel]}</Select.Option>
  ));
  return (
    <Select
      {...restProps}
      placeholder={placeholder}
      className={searchName}
      showSearch={showSearch}
      labelInValue={labelInValue}
      defaultActiveFirstOption={false}
      showArrow={true}
      filterOption={filterOption}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      allowClear
    >
      {options}
    </Select>
  );
};

export default BaseSelect;
