import React, {
  memo,
} from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
} from "react-google-maps";
import _ from "lodash";
import CustomerMarker from "./CustomerMarker/index";

const Map = memo(({ dataMarker, dataUser, data, params }) => {
  const centerUser = {
    lat: (data && data.length > 0)  ? data[0].latitude : 15.818135592909831,
    lng: (data && data.length > 0)  ? data[0].longitude : 108.2856855718962
  };

  return (
    <div>
      <GoogleMap
        defaultZoom={6}
        center={centerUser}
        options={{
          gestureHandling: "greedy"
        }}
      >
        {/* //marker của từng điểm */}

        {_.map(data, (item, index) => {
          return <CustomerMarker item={item} key={item.keyRow} params={params}/>;
        })}
      </GoogleMap>
    </div>
  );
});

const MapWrapped = withScriptjs(withGoogleMap(Map));
const MapCheckinAllDetail = memo(({ dataMap, dataUser, data, params, ggMapKey}) => {
  return (
    <div style={{ width: "100%", height: "95vh" }}>
      <MapWrapped
        dataMerker={dataMap}
        dataUser={dataUser}
        data={data}
        params={params}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places${ggMapKey ? ('&key=' + ggMapKey) : ''}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
});
export default MapCheckinAllDetail;
