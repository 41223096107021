/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 01/06/2021.
 * @description Modal Thêm/Sửa đơn vị tiền tệ
 * */

import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import PropTypes from 'prop-types';
import services from 'services';


const CurrencyUnitModal = function ({ visible, mode, onCancel, dataItem, onReloadPage}){
    const[form] = Form.useForm();
    const [isContinue, setIsContinue] = useState(false);

    const handleConfirm = useCallback(() => {
      setIsContinue(false);
      setTimeout(() => form.submit(), 400);
    },[form]);

    const handleConfirmAndContinue = useCallback(() => {
      setIsContinue(true);
      setTimeout(() => form.submit(), 400);
    }, [form]);

    const handleSubmit = useCallback((items) => {
      if(mode === "edit"){
        services.generalService
          .currencyUnit_Update(items)
          .then((res)=>{
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onReloadPage();
            onCancel();
            form.resetFields();
          }).catch((err)=>{
            message.error("Lưu không thành công, hãy thử lại.");
          })
      }else if (mode === "add"){
        services.generalService
          .currencyUnit_Create(items)
          .then((res)=>{
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onReloadPage();
            if(isContinue){
              form.resetFields();
            }else{
              onCancel();
              form.resetFields();
            }
          }).catch((err)=>{
            message.error("Lưu không thành công, hãy thử lại.");
          })
      }
    },[mode, onReloadPage, onCancel, form, isContinue]);

    const renderTitle = () => 
        mode === "add" ? `Thêm mới đơn vị tiền tệ`: ` Chỉnh sửa đơn vị tiền tệ`;
    
    useEffect(() => {
      const initValues = {
        id: dataItem.id,
        currentState: dataItem.currentState,
        code: dataItem.code,
        name: dataItem.name,
        status: dataItem.status,
        representative: dataItem.representative,
        taxCode: dataItem.taxCode,
        phonenumber: dataItem.phonenumber,
        address: dataItem.address,
        notes: dataItem.notes,
      };
      form.setFieldsValue(initValues);
    }, [dataItem, form]);
    
    const handleCloseModal = useCallback(() => {
      onCancel && onCancel();
      form.resetFields();
    }, [form, onCancel]);

    return (
        <Modal
        visible={visible}
        title={renderTitle()}
        onOk={handleConfirm}
        onCancel={onCancel}
        footer={[
          <Form.Item>
            <Button onClick={handleConfirm} type="primary">
              {dataItem?.id ? "Sửa" : "Thêm"}
            </Button>
            {mode === "add" ?
              <Button
                title='Thêm và tiếp tục'
                key='submitAndContinute'
                type="primary"
                onClick={handleConfirmAndContinue}
              >
                Thêm và tiếp tục
              </Button> : ''
            }
            <Button type='default' onClick={handleCloseModal}>
              Quay lại
            </Button>
          </Form.Item>
        ]}>
            <Form initialValues={dataItem} onFinish={handleSubmit} form={form} labelAlign="left">
              <Form.Item
                label="Mã đơn vị tiền tệ"
                required
                name="code"
                rules={[{ required: true, message: "Mã đơn vị tiền tệ không được trống" }]}
                labelCol={{ span: 7 }}
              >
                <Input placeholder="Nhập mã đơn vị tiền tệ" />
              </Form.Item>
              <Form.Item
                label="Đơn vị tiền tệ"
                name="name"
                rules={[{ required: true, message: "Vui lòng nhập" }]}
                labelCol={{ span: 7 }}
              >
                <Input placeholder="Nhập đơn vị tiền tệ" />
              </Form.Item>
              <Form.Item
                label="Trạng thái"
                name="status"
                rules={[{ required: true, message: "Vui lòng nhập" }]}
                labelCol={{ span: 7 }}
              >
                <Select defaultValue={1} placeholder="Chọn trạng thái">
                  <Select.Option value={1}>Hoạt động</Select.Option>
                  <Select.Option value={0}>Không hoạt động</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item hidden name="id"> 
                <Input disabled></Input>
              </Form.Item>
              <Form.Item hidden name="currentState"> 
                <Input disabled></Input>
              </Form.Item>
            </Form>
        </Modal>
        
    );
};
CurrencyUnitModal.propTypes = {
    visible: PropTypes.bool,
    mode: PropTypes.string
};

export default CurrencyUnitModal;