import { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Form,
  Table,
  Row,
  Col,
  DatePicker,
  Select,
  Button,
  message,
  Divider
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { filter, map } from 'lodash';
import utils from 'utils';
import services from 'services';
import moment from 'moment';

const StatisticShippingOrderPage = () => {
  const [form] = Form.useForm();
  const [listOrderByProduct, setListOrderByProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [productItems, setProductItems] = useState([]);
  const [fromToDate, setFromToDate] = useState({
    fromDate: utils.$datetime.currentFirstDate(),
    toDate: utils.$datetime.currentLastDate(),
  });
  const [listSaler, setListSaler] = useState([]);
  const [listProvince, setListProvince] = useState([]);  
  const { RangePicker } = DatePicker;
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: null,
    toDate: null,
    provinceId: null,
    salerId: null,
    productId: null,
    status: null});
  const [filteredStaffs, setFilteredStaffs] = useState([]);

  const getStatistics = useCallback(() => {
    setIsLoading(true);

    services.shippingOrderService
      .purchaseOrders_GetPreOrderByProductQuery({
        fromDate: filterCriteria?.fromDate,
        toDate: filterCriteria?.toDate,
        provinceId: filterCriteria?.provinceId,
        salerId: filterCriteria?.salerId,
        productId: filterCriteria?.productId,
        status: filterCriteria?.status,
        pageNumber: paging?.pageNumber ?? 1,
        pageSize: paging?.pageSize ?? 10
      })
      .then((res) => {
        setListOrderByProduct(res.items);
        setIsLoading(false);
        setTotal(res.totalCount);
        if (res.items.length === 0) {
          message.error('Không tìm thấy sản phẩm');
        }
      })
      .catch((err) => {
        setTotal(0);
        setListOrderByProduct([]);
        setIsLoading(false);
      });
  }, [paging]);

  useEffect(() => {
    getStatistics({});
  }, [getStatistics]);
    
  const handleSubmit = (values) => {    
    let fromDate = values.date[0];
    let toDate = values.date[1];
    let saler = values.salerObj;
    let provinceId = values.provinceId;
    let product = values.productObj;
    let status = values.status;
    setFilterCriteria({
      fromDate: fromDate,
      toDate: toDate,
      provinceId: provinceId,
      salerId: saler?.key,
      productId: product?.key,
      status: status
    });
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
  };

  const handleChangeRangePicker = useCallback((_, dateString) => {
    setFromToDate({
      fromDate: dateString?.[0],
      toDate: dateString?.[1],
    });
  }, []);

  const handleChangePaging = (pageNumber, pageSize) => {
    const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
    setPaging({ pageNumber: currentPage, pageSize });
  };

  const productColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'STT',
      align: 'center',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      title: "Mã ĐH",
      dataIndex: "purchaseId",
      key: "purchaseId",
      width: 150
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
      render: (value) => {
        var findedStatus = utils.$const.STATISTICS.STATUS.find(
          (x) => x.id === value
        );
        return (
          <span style={{ color: findedStatus?.color }}>
            {findedStatus?.name}
          </span>
        );
      },
    },
    {
      dataIndex: 'customerName',
      key: 'customerName',
      title: 'Tên khách hàng',
      align: 'center',
      width: 150,
    },
    {
      title: "Ngày",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 100,
      render: (value) => utils.$datetime.formatDatetime(value),
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
      width: 150,
    },
    {
      dataIndex: 'orderQuantity',
      key: 'orderQuantity',
      title: 'Số lượng',
      align: 'center',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'promotionQuantity',
      key: 'promotionQuantity',
      title: 'Số lượng tặng',
      align: 'center',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'salePrice',
      key: 'salePrice',
      title: 'Đơn giá',
      align: 'center',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: 'Thành tiền',
      align: 'center',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    }
  ];

  useEffect(() => {
    services.generalService
      .products_GetAllProducts()
      .then((res) => {
        setProductItems(res);
      })
      .catch((err) => {
        message.error('Không tìm thấy thông tin sản phẩm');
      });
    setTimeout(() => {}, 300);
  }, [setProductItems]);
  
  useEffect(() => {       
    services.userManagementService.resource_GetAllSalersWithPermission().then((res) => {
      setListSaler(res);
      setFilteredStaffs([...res]);
    })
    .catch((err) => {
      message.error('Không tìm thấy thông tin nhân viên');
    });
    setTimeout(() => {}, 300);
  }, [setListSaler]);
  
  useEffect(() => {       
    services.organization
    .province_GetAllProvincesWithPermission()
    .then(async (res) => {
      let array = map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });

      setListProvince(array);
    })
    .catch((error) => {});
    setTimeout(() => {}, 300);
  }, [setListProvince]);

  const filterStaffs = (value) => {
    if (value) {        
      let arrStaffs = filter(listSaler,
        (item, index) => {
          return item?.provinceId === value;
        },
      )
      form.setFieldsValue({salerObj: null});
      setFilteredStaffs(arrStaffs);
    } else {
      setFilteredStaffs([...listSaler]);
    }
  }

  return (
    <Card className='orderManagementPage'>
      <Form
        initialValues={{
          date: [moment(fromToDate.fromDate), moment(fromToDate.toDate)],
        }}
        form={form}
        labelCol={{ span: 24 }}
        onFinish={handleSubmit}
      >
        <Row gutter={[8]}>
          <Col lg={8} xl={4}>
            <Form.Item name='provinceId' label='Tỉnh'>
              <Select
                style={{ width: "100%" }}
                placeholder="Tỉnh"
                allowClear
                onChange={(value) => {
                  filterStaffs(value);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
              >
                {map(listProvince, (item, index) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='salerObj' label='Nhân viên'>
              <Select
                placeholder="Chọn"
                labelInValue
                allowClear
                showArrow={true}
                filterOption={false}
              >
                {filteredStaffs.map((item, id) => (
                  <Select.Option key={id} value={item.userId}>
                    {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item label='Sản phẩm' name='productObj'>
              <Select
                placeholder="Chọn"
                labelInValue
                allowClear
                showArrow={true}
                filterOption={false}
              >
                {productItems.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.productName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name="status" label="Trạng thái">
              <Select allowClear placeholder="Chọn">
                {map(utils.$const.STATISTICS.STATUS, (stat) => (
                  <Select.Option key={stat.id} value={stat.id}>
                    {stat.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='date' label='Từ ngày - đến ngày'>
              <RangePicker
                format={utils.$const.COMMON.DATE_TIME_FORMAT['YYYY-MM-DD']}
                onChange={handleChangeRangePicker}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' gutter={[8]}>
          <Col>
            <Button
              type='primary'
              onClick={() => form.submit()}
              disabled={isLoading}
            >
              <span className='d-flex align-items-center'>
                <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button type='dashed'>
              <span className='d-flex align-items-center'>
                <DownloadOutlined />{' '}
                <span className='ml-1'>Xuất file Excel</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Table
        key='statisticByProducts'
        className='shippingOrderList'
        columns={productColumns}
        dataSource={listOrderByProduct}
        total={listOrderByProduct?.length ?? 0}
        loading={isLoading}
        pagination={{
          total: total ?? 0,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
        scroll={{ x: 1400, y: 680 }}
      />
    </Card>
  );
};

export default StatisticShippingOrderPage;
