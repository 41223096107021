import { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  Divider,
  Select,
  message
} from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import services from "services";
import GeneralSummary from "components/attendanceSummary/GeneralSummary";
import _ from "lodash";
import moment from 'moment';
import {saveFileAs} from 'utils/excel';
const { Option } = Select;

const AttendanceSummary = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [paging, setPaging] = useState({pageNumber: 1, pageSize: 10});
  const [dataFilter, setDataFilter] = useState({
    arrStaffs: [],
    arrProvinces: [],
  });
  const [filteredStaffs, setFilteredStaffs] = useState([]);

  const handleSubmit = (values) => {
    setParams(values);
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize });
  };

  useEffect(() => {
    // api nhân viên        
    services.userManagementService.resource_GetAllSalersWithPermission().then((res) => {
      let arrData = _.map(res, (item, index) => {
        item.index = index + 1;
        item.id = _.get(item, "userId");
        item.name = _.get(item, "fullName");
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrStaffs = arrData;
        return nextState;
      });
    });

    // api tỉnh
    services.organization
    .province_GetAllProvincesWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });

      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrProvinces = array;
        return nextState;
      });
    })
    .catch((error) => {});

  }, [setDataFilter]);

  useEffect(() => {
    setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
  }, [dataFilter]);

  const filterStaffs = (value) => {
    if (value) {        
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.provinceId === value;
        },
      )
      form.setFieldsValue({staffId: undefined});
      setFilteredStaffs(arrStaffs);
    } else {
      setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
    }
  }

  const exportExcel = () => {
    setLoading(true);
    services.customerService
      .visitAttendance_ExportReportVisitAttendanceCheckin({
        customerName: params.customerName,
        staffId: params.staffId,
        provinceId: params.provinceId,
        pageNumber: paging.pageNumber,
        pageSize: paging.pageSize,
        fromDate: params.fromDate && params.fromDate.format(),
        toDate: params.toDate && params.toDate.format(),
      })
      .then((response) => {
        saveFileAs(response.data, 'BC viếng thăm KH '+ moment().format("DD-MM-YYYY") + '.xlsx');
      })
      .catch((err) => {
        message.error('Có lỗi khi xuất excel');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card className="orderManagementPage">
      <Divider style={{ margin: 10 }} />
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={4} xl={4}>
            <Form.Item name="provinceId" label="Tỉnh">
              <Select
                style={{ width: "100%" }}
                placeholder="Tỉnh"
                allowClear
                onChange={(e) => {
                  filterStaffs(e);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {_.map(_.get(dataFilter, "arrProvinces"), (item, index) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="staffId" label="Nhân viên">
              <Select
                style={{ width: "100%" }}
                placeholder="Nhân viên"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {_.map(filteredStaffs, (item, index) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="customerName" label="Khách hàng">
              <Input placeholder="Tên khách hàng"/>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="fromDate" label="Từ ngày">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Chọn ngày"
                disabledDate={(current) => {
                  const toDate = form.getFieldValue("toDate");
                  return toDate && current > toDate;
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="toDate" label="Đến ngày">
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Chọn ngày"
                disabledDate={(current) => {
                  const fromDate = form.getFieldValue("fromDate");
                  return fromDate && current < fromDate;
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 5,marginBottom:10 }}>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
              >
                <span className="d-flex align-items-center">
                  <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col className='ml-2'>
            <Button
              type='dashed'
              disabled={loading}
              onClick={exportExcel}
            >
              <span className='d-flex align-items-center'>
                <DownloadOutlined /> <span className='ml-1'>Xuất file excel</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <GeneralSummary
        params={params}
        paging={paging}
        setPaging={setPaging}
        loading={loading}
        setLoading={setLoading}
      />
    </Card>
  );
};

export default AttendanceSummary;
