import React, { useCallback, useState, useEffect } from "react";
import {
  Card,
  Form,
  Table,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Button,
  message,
  Divider,
  Typography,
  InputNumber,
  Space,
  Modal,
} from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  map,
  trim,
  size,
  find,
  set,
  round,
  split,
  filter,
  toLower,
  includes,
} from "lodash";
import utils from "utils";
import services from "services";
import BaseSelect from "components/selects/baseSelect";

const { Text, Title } = Typography;

const AMOUNT_STYLE = {
  paddingRight: 11,
};

let searchProductTimer = null;
const AddUpdateOrderPage = ({ dataSource = {}, onCancel }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [listProduct, setListProduct] = useState([]);
  const [selectedListProduct, setSelectedListProduct] = useState([]);
  const [selectedListPromotionProduct, setSelectedListPromotionProduct] =
    useState([]);

  const [summary, setSummary] = useState({
    totalQuantity: 0,
    totalAmount: 0,
    discount: 0,
    discountAmount: 0,
    totalAfterDiscount: 0,
    vat: 0,
    vatAmount: 0,
    totalAmountAfterVat: 0,
    paidAmount: 0,
    finalAmount: 0,
  });
  const history = useHistory();

  const isAdd = location.pathname === "/sale-management/purchase-order/new";

  const handleSubmit = (values) => {
    if (size(selectedListProduct) === 0) {
      message.error("Vui lòng chọn sản phẩm");
      return;
    }
    let data = {
      customerId: values.customerIdObj?.value,
      warehouseId: values.warehouseIdObj?.value,
      distributorId: values.distributorIdObj?.value,
      orderDate: values.orderDate,
      shippingEstDate: values.shippingEstDate,
      saleId: values.salerObj.value,
      notes: values.notes,
      purchaseOrderDetails: [],
      promotionOrderDetails: [],
      finalVAT: summary.vat,
      finalDiscount: summary.discount,
      moneyPaid: summary.paidAmount,
    };

    data.purchaseOrderDetails = map(selectedListProduct, (item) => {
      return {
        productId: item.id,
        productName: item.productName,
        productCode: item.productCode,
        isUnitDefault: item.isUnitDefault,
        unitTypeId: item.unitTypeId,
        salePrice: item.unitPrice,
        orderQuantity: item.quantity,
        discount: item.discount,
        amount: item.amount,
      };
    });
    data.promotionOrderDetails = map(selectedListPromotionProduct, (item) => {
      return {
        productId: item.id,
        productName: item.productName,
        productCode: item.productCode,
        isUnitDefault: item.isUnitDefault,
        unitTypeId: item.unitTypeId,
        orderQuantity: item.quantity,
      };
    });
    if (isAdd) {
      services.shippingOrderService
        .purchaseOrders_Create(data)
        .then((result) => {
          setTimeout(() => {
            message.success("Tạo đơn hàng thành công");
            history.push("/sale-management/purchase-order");
          }, 1500);
        })
        .catch((err) => {
          message.error("Tạo đơn hàng không thành công");
        })
        .finally(() => {});
    } else {
      data.id = values.id;
      data.status = values.status;
      data.currentState = values.currentState;
      if (values.action === "New") {
        services.shippingOrderService
          .purchaseOrders_Update(data)
          .then((result) => {
            setTimeout(() => {
              message.success("Sửa đơn hàng thành công");
            }, 1500);
            
            onCancel();
          })
          .catch((err) => {
            message.error("Sửa đơn hàng không thành công");
          })
          .finally(() => {});
      } else {
        // Duyệt đơn hoặc từ chối
        services.shippingOrderService
          .purchaseOrders_ChangePurchaseOrderStatus({
            id: values.id,
            status: values.status,
            currentState: values.currentState,
          })
          .then((result) => {
            setTimeout(() => {
              let msg =
                values.status === 2
                  ? "Duyệt đơn hàng thành công"
                  : "Hủy đơn đơn hàng thành công";
              message.success(msg);
            }, 1500);
            onCancel();
          })
          .catch((err) => {
            let msg =
              values.status === 2
                ? "Duyệt đơn hàng không thành công"
                : "Hủy đơn đơn hàng không thành công";
            message.error(msg);
          })
          .finally(() => {});
      }
    }
  };

  const handleBrowseProduct = useCallback((search) => {
    if (size(trim(search)) < 2) {
      return;
    }
    if (searchProductTimer) {
      clearTimeout(searchProductTimer);
      searchProductTimer = null;
    }
    searchProductTimer = setTimeout(async () => {
      const products =
        await services.generalService.products_GetProductIncludeUnit();
      let filtered = filter(products, (x) => {
        return (
          includes(
            utils.$common.converVietNamese(toLower(x.productName)),
            utils.$common.converVietNamese(toLower(search))
          ) ||
          includes(
            utils.$common.converVietNamese(toLower(x.productCode)),
            utils.$common.converVietNamese(toLower(search))
          )
        );
      });
      setListProduct(filtered);
    }, 300);
  }, []);

  const handleBrowseCustomerInformation = useCallback((saleId) => {
    return services.generalService.customerInformation_GetCustomerBySaleId(
      saleId
    );
  }, []);
  const handleBrowseDistributor = useCallback((search) => {
    return services.generalService.distributor_GetAllDistributorsWithPermission();
  }, []);
  const handleBrowseWarehouse = useCallback((search) => {
    return services.generalService.warehouses_GetAllWarehouse();
  }, []);

  const handleBrowseUsers = useCallback((param) => {
    const pageNumber = 1;
    const pageSize = 1000;
    return services.organization.humanResource_GetAll(pageNumber, pageSize);
  }, []);
  const handleCalculateSummary = useCallback((products) => {
    let totalQuantity = 0;
    let totalAmount = 0;
    products.forEach((product) => {
      if (product.isDefault) {
        // Bán lẻ
        totalQuantity += product.quantity;
      } else {
        // Bán sỉ
        totalQuantity += product.quantity * product.qtyPerPackage;
      }
      totalAmount += product.amount;
    });
    setSummary((prev) => ({
      ...prev,
      totalQuantity,
      totalAmount,
    }));
    handleChangeSummary({});
  }, []);
  const handleAddProductToList = (productId) => {
    let keys = split(productId, "unitTypeId");
    let id = "",
      unitTypeId = "";
    if (size(keys) > 1) {
      id = keys[0];
      unitTypeId = keys[1];
    }
    if (!id || !unitTypeId) return;
    if (
      find(
        selectedListProduct,
        (x) => x.id === id && x.unitTypeId === unitTypeId
      )
    ) {
      message.warning("Đã có sản phẩm này trong danh sách.");
      return;
    }
    let findedProduct = find(
      listProduct,
      (x) => x.id === id && x.unitTypeId === unitTypeId
    );
    if (findedProduct) {
      message.info("Thêm sản phẩm vào danh sách");
      setSelectedListProduct((prev) => {
        let next = [...prev];
        next.push({
          ...findedProduct,
          id: findedProduct.id,
          isPromotion: false,
          stt: size(next) + 1,
          unitPrice: findedProduct.salePrice || 0,
          quantity: 1,
          discount: 0,
          isUnitDefault: findedProduct.isDefault,
          amount: findedProduct.salePrice || 0,
        });
        handleCalculateSummary(next);
        return next;
      });
    }
  };
  const handleAddPromotionProductToList = (productId) => {
    let keys = split(productId, "unitTypeId");
    let id = "",
      unitTypeId = "";
    if (size(keys) > 1) {
      id = keys[0];
      unitTypeId = keys[1];
    }
    if (!id || !unitTypeId) return;
    if (
      find(
        selectedListPromotionProduct,
        (x) => x.id === id && x.unitTypeId === unitTypeId
      )
    ) {
      message.warning("Đã có sản phẩm này trong danh sách.");
      return;
    }
    let findedProduct = find(
      listProduct,
      (x) => x.id === id && x.unitTypeId === unitTypeId
    );
    if (findedProduct) {
      message.info("Thêm sản phẩm vào danh sách khuyến mại");
      setSelectedListPromotionProduct((prev) => {
        let next = [...prev];
        let unitPrice = 0;
        if (findedProduct.isDefault) {
          unitPrice = findedProduct.oddSalePrice || 0;
        } else {
          unitPrice = findedProduct.evenSalePrice;
        }
        next.push({
          ...findedProduct,
          isPromotion: true,
          stt: size(next) + 1,
          unitPrice: unitPrice,
          quantity: 1,
          discount: 0,
          isUnitDefault: findedProduct.isDefault,
          amount: unitPrice,
        });
        return next;
      });
    }
  };
  const handleUpdateProductInList = ({ index, item, name, value }) => {
    if (item.isPromotion) {
      setSelectedListPromotionProduct((prev) => {
        let next = [...prev];
        set(next, [index, name], value);
        if (name === "quantity" || name === "discount") {
          // Tính lại thành tiền = đơn giá * số lượng - discount(%)
          let order = next[index];
          let amountBeforeDiscount = order.quantity * order.unitPrice;
          let discountAmount = (amountBeforeDiscount * order.discount) / 100;
          order.amount = round(amountBeforeDiscount - discountAmount, 0);
        }
        return next;
      });
    } else {
      setSelectedListProduct((prev) => {
        let next = [...prev];
        set(next, [index, name], value);
        if (name === "quantity" || name === "discount") {
          // Tính lại thành tiền = đơn giá * số lượng - discount(%)
          let order = next[index];
          let amountBeforeDiscount = order.quantity * order.unitPrice;
          let discountAmount = (amountBeforeDiscount * order.discount) / 100;
          order.amount = round(amountBeforeDiscount - discountAmount, 0);
        }
        handleCalculateSummary(next);
        return next;
      });
    }
  };
  const handleRemoveProduct = ({ index, item }) => {
    if (item.isPromotion) {
      setSelectedListPromotionProduct((prev) => {
        let next = [...prev];
        next.splice(index, 1);
        return next;
      });
    } else {
      setSelectedListProduct((prev) => {
        let next = [...prev];
        next.splice(index, 1);
        handleCalculateSummary(next);
        return next;
      });
    }
  };

  const handleChangeSummary = ({ value, name }) => {
    setSummary((prev) => {
      let next = { ...prev };
      if (name) {
        next[name] = value || 0;
      }

      next.discountAmount = round((next.discount * next.totalAmount) / 100, 0);
      next.totalAfterDiscount = next.totalAmount - next.discountAmount;
      next.vatAmount = round((next.totalAfterDiscount * next.vat) / 100, 0);
      next.totalAmountAfterVat = next.totalAfterDiscount + next.vatAmount;
      next.finalAmount = next.totalAmountAfterVat - next.paidAmount;
      return next;
    });
  };

  const handleGoTOrderPage = () => {
    history.push("/sale-management/purchase-order");
  };

  useEffect(() => {
    if (dataSource && dataSource.id) {
      // set dữ liệu trong dataSource vào form
      form.setFieldsValue({
        id: dataSource.id,
        status: dataSource.status,
        currentState: dataSource.currentState,
        purchaseId: dataSource.purchaseId,
        customerCode: dataSource.customer?.customerCode,
        customerPhone: dataSource.customer?.phonenumber,
        customerAddress: dataSource.customer?.address,
        customerIdObj: {
          label: dataSource.customer?.customerName,
          value: dataSource.customer?.id,
        },
        distributorIdObj: {
          label: dataSource.distributor.distributorName,
          value: dataSource.distributor.id,
        },
        warehouseIdObj: {
          label: dataSource.warehouse.warehouseName,
          value: dataSource.warehouse.id,
        },
        salerObj: {
          label: dataSource.saler?.fullName,
          value: dataSource.saler?.userId,
        },
        notes: dataSource.notes,
        orderDate: utils.$datetime.stringToDateTime(dataSource.orderDate),
        shippingEstDate: utils.$datetime.stringToDateTime(
          dataSource.shippingEstDate
        ),
      });
      let products = dataSource.purchaseOrderDetails.map((x, index) => ({
        ...x,
        stt: index + 1,
        id: x.productId,
        isPromotion: false,
        isUnitDefault: x.isUnitDefault,
        qtyPerPackage: x.qtyPerPackage,
        unitTypeId: x.unitTypeId,
        unitTypeName: x.unitTypeName,
        isDefault: x.isUnitDefault,
        discount: x.discount,
        quantity: x.orderQuantity,
        amount: x.amount,
        unitPrice: x.salePrice,
      }));
      setSelectedListProduct(products);
      setSummary((prev) => ({
        ...prev,
        vat: dataSource.finalVAT,
        vatAmount: dataSource.finalVATAmount,
        discount: dataSource.finalDiscount,
        discountAmount: dataSource.finalDiscountAmount,
        paidAmount: dataSource.moneyPaid,
        finalAmount: dataSource.finalTotalAmount,
        totalAmount: dataSource.totalAmount,
        totalAfterDiscount: dataSource.totalAmountExcludedVAT,
      }));
      handleCalculateSummary(products);
      setSelectedListPromotionProduct(
        dataSource.promotionOrderDetails.map((x, index) => ({
          ...x,
          id: x.productId,
          isPromotion: true,
          stt: index + 1,
          isUnitDefault: x.isUnitDefault,
          qtyPerPackage: x.qtyPerPackage,
          unitTypeId: x.unitTypeId,
          unitTypeName: x.unitTypeName,
          isDefault: x.isUnitDefault,
          discount: x.discount,
          quantity: x.orderQuantity,
          amount: x.amount,
          unitPrice: x.salePrice || "-",
        }))
      );
    }
  }, [dataSource, form, handleCalculateSummary]);

  const productOptions = map(listProduct, (product) => {
    const productKey = `${product.id}unitTypeId${product.unitTypeId}`;
    const title = `${product.productCode} - ${product.productName} - ${product.unitTypeName}`;
    return (
      <Select.Option key={productKey} value={productKey} title={title}>
        <span>{product.productCode}</span>
        {product.productCode && product.productName ? (
          <span className="mx-1">-</span>
        ) : null}
        <span>{product.productName}</span>
        {product.productName && product.unitTypeName ? (
          <span className="mx-1">-</span>
        ) : null}
        <span>{product.unitTypeName}</span>
      </Select.Option>
    );
  });

  const columns = [
    {
      key: "stt",
      dataIndex: "stt",
      title: "STT",
      align: "center",
      width: 50,
    },
    {
      key: "productCode",
      dataIndex: "productCode",
      title: "Mã SP",
      width: 100,
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "Tên SP",
    },
    {
      key: "unitTypeName",
      dataIndex: "unitTypeName",
      title: "ĐVT",
      align: "center",
      width: 100,
    },
    {
      key: "packageTypeUnit",
      dataIndex: "packageTypeUnit",
      title: "Tồn kho",
      align: "center",
      width: 100,
      render: (value) => value || "-",
    },
    {
      key: "unitPrice",
      dataIndex: "unitPrice",
      title: "Đơn giá",
      width: 100,
      align: "center",
      render: (value) => (
        <div className="text-right">{utils.$number.numberFormatter(value)}</div>
      ),
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: "Số lượng",
      align: "center",
      width: 75,
      render: (value, item, index) => (
        <InputNumber
          placeholder="Nhập"
          min={0}
          value={value}
          style={{ width: 50 }}
          controls={false}
          className="text-center"
          onChange={(quantity) =>
            handleUpdateProductInList({
              index,
              name: "quantity",
              value: quantity,
              item,
            })
          }
        />
      ),
    },
    {
      key: "discount",
      dataIndex: "discount",
      title: "Chiết khấu(%)",
      align: "center",
      width: 120,
      render: (value, item, index) =>
        item.isPromotion ? (
          "-"
        ) : (
          <InputNumber
            placeholder="Nhập"
            min={0}
            value={value}
            style={{ width: 50 }}
            controls={false}
            className="text-center"
            onChange={(discount) =>
              handleUpdateProductInList({
                index,
                name: "discount",
                value: discount,
                item,
              })
            }
          />
        ),
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "Thành tiền",
      width: 125,
      align: "center",
      render: (value, item) => (
        <div className="text-right">
          {item.isPromotion ? "-" : utils.$number.numberFormatter(value)}
        </div>
      ),
    },
    {
      key: "packageTypeUnit",
      dataIndex: "packageTypeUnit",
      title: "Kho xuất",
      align: "center",
      width: 150,
    },
    {
      key: "packageTypeUnit",
      dataIndex: "packageTypeUnit",
      title: "Mã CTKM",
      align: "center",
      width: 100,
    },
    {
      key: "action",
      dataIndex: "action",
      title: "",
      width: 50,
      fixed: "right",
      render: (value, item, index) => {
        return (
          <span className="d-flex align-items-center justify-content-center text-danger">
            <DeleteOutlined
              onClick={() => handleRemoveProduct({ item, index })}
            />
          </span>
        );
      },
    },
  ];

  const renderFormActionAdd = (
    <div className="d-flex align-items-center justify-content-end bg-white">
      <Button htmlType="submit" size="middle">
        Lưu
      </Button>
      <Button className="ml-2 " danger onClick={handleGoTOrderPage}>
        Quay lại
      </Button>
    </div>
  );
  const renderFormActionUpdate = (
    <div className="d-flex align-items-center justify-content-end">
      <Space>
        {dataSource.status === 1 && (
          <React.Fragment>
            <Button
              onClick={() => {
                form.setFieldsValue({
                  action: "New",
                });
                form.submit();
              }}
            >
              Lưu đơn
            </Button>
            <Button
              onClick={() => {
                form.setFieldsValue({
                  status: 2,
                  action: "Approved",
                });
                Modal.confirm({
                  title: (
                    <span>
                      Xác nhận duyệt đơn hàng
                      <b className="text-danger ml-1">
                        {dataSource.purchaseId}
                      </b>
                    </span>
                  ),
                  icon: <ExclamationCircleOutlined />,
                  okText: "Xác nhận",
                  okType: "danger",
                  cancelText: "Hủy",
                  content: "",
                  onOk() {
                    form.submit();
                  },
                  onCancel() {},
                });
              }}
            >
              Duyệt đơn
            </Button>
            <Button
              danger
              size="middle"
              onClick={() => {
                form.setFieldsValue({
                  status: 3,
                  action: "Cancel",
                });
                Modal.confirm({
                  title: (
                    <span>
                      Xác nhận hủy đơn hàng
                      <b className="text-danger">{dataSource.purchaseId}</b>
                    </span>
                  ),
                  icon: <ExclamationCircleOutlined />,
                  okText: "Xác nhận",
                  okType: "danger",
                  cancelText: "Hủy",
                  content: "",
                  onOk() {
                    form.submit();
                  },
                  onCancel() {},
                });
              }}
            >
              Từ chối
            </Button>
          </React.Fragment>
        )}
        <Button danger onClick={onCancel}>
          Quay lại
        </Button>
      </Space>
    </div>
  );

  return (
    <div className="addUpdateOrderPage">
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={(errorInfo) => {
        }}
        labelAlign="left"
        labelCol={{ span: 8 }}
      >
        <Row gutter={[0, 0]}>
          <Col span={14}>
            <Card size="small" className="h-100">
              <Row gutter={[64]}>
                <Col span={12}>
                  <Form.Item label="Mã đơn" className="mb-3">
                    <Typography.Text italic type="success">
                      {dataSource?.purchaseId
                        ? dataSource.purchaseId
                        : "Mã đơn hàng được tạo tự động"}
                    </Typography.Text>
                  </Form.Item>
                  <Form.Item label="Trạng thái" className="mb-3">
                    {dataSource.status
                      ? utils.$const.SHIPPING_ORDER.STATUS.find(
                          (x) => x.id === dataSource.status
                        )?.name
                      : "Tạo mới"}
                  </Form.Item>
                  <Form.Item
                    label="NPP"
                    name="distributorIdObj"
                    rules={[{ required: true, message: "Vui lòng chọn" }]}
                    className="mb-3"
                  >
                    <BaseSelect
                      itemValue="id"
                      itemLabel="distributorName"
                      placeholder="Chọn"
                      searchOnMount
                      labelInValue
                      onChange={(item) => {
                        form.setFieldsValue({
                          distributorIdObj: item,
                          salerObj: undefined,
                        });
                      }}
                      onSearch={handleBrowseDistributor}
                    />
                  </Form.Item>
                  <Form.Item
                    dependencies={["distributorIdObj"]}
                    label="Kho"
                    rules={[{ required: true, message: "Vui lòng chọn" }]}
                    className="mb-3"
                  >
                    {({ getFieldValue }) => {
                      let selectedDistributorIdObj =
                        getFieldValue("distributorIdObj");
                      return (
                        <Form.Item
                          name="warehouseIdObj"
                          rules={[{ required: true, message: "Vui lòng chọn" }]}
                          className="mb-0"
                        >
                          <BaseSelect
                            searchName="WarehouseIdObj"
                            itemValue="id"
                            itemLabel="warehouseName"
                            placeholder="Chọn kho"
                            searchOnMount
                            labelInValue
                            onFilter={(data) =>
                              selectedDistributorIdObj
                                ? data.filter(
                                    (x) =>
                                      x.distributorId ===
                                      selectedDistributorIdObj.value
                                  )
                                : data
                            }
                            onSearch={handleBrowseWarehouse}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Form.Item
                    name="orderDate"
                    label="Ngày đặt"
                    rules={[{ required: true, message: "Chọn ngày" }]}
                    className="mb-3"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Chọn ngày"
                    />
                  </Form.Item>
                  <Form.Item
                    name="shippingEstDate"
                    label="Ngày giao"
                    rules={[{ required: true, message: "Chọn ngày" }]}
                    className="mb-3"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Chọn ngày"
                    />
                  </Form.Item>

                  <Form.Item
                    dependencies={["distributorIdObj"]}
                    label="Người đặt"
                    rules={[{ required: true, message: "Vui lòng chọn" }]}
                    className="mb-3"
                  >
                    {({ getFieldValue }) => {
                      let selectedDistributorIdObj =
                        getFieldValue("distributorIdObj");
                      return (
                        <Form.Item
                          name="salerObj"
                          rules={[{ required: true, message: "Vui lòng chọn" }]}
                          className="mb-0"
                        >
                          <BaseSelect
                            searchName="saler"
                            itemValue="userId"
                            itemLabel="fullName"
                            placeholder="Chọn"
                            searchOnMount
                            labelInValue
                            onFilter={(data) =>
                              selectedDistributorIdObj
                                ? data.filter(
                                    (x) =>
                                      x.distributorId ===
                                      selectedDistributorIdObj.value
                                  )
                                : data
                            }
                            onSearch={handleBrowseUsers}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Mã KH" name="customerCode" className="mb-3">
                    <Input placeholder="Chưa chọn" readOnly />
                  </Form.Item>
                  <Form.Item
                    label="Tên KH"
                    dependencies={["salerObj"]}
                    rules={[{ required: true, message: "Chọn khách hàng" }]}
                    className="mb-3"
                  >
                    {({ getFieldValue }) => {
                      let selectedsalerObj = getFieldValue("salerObj");
                      return (
                        <Form.Item
                          name="customerIdObj"
                          rules={[{ required: true, message: "Vui lòng chọn" }]}
                          className="mb-0"
                        >
                          <BaseSelect
                            itemValue="id"
                            itemLabel="customerName"
                            placeholder="Chọn khách hàng"
                            searchOnMount
                            labelInValue
                            filterOption={(inputValue, option) => {
                              return includes(
                                utils.$common.converVietNamese(
                                  toLower(option.children)
                                ),
                                utils.$common.converVietNamese(
                                  toLower(inputValue)
                                )
                              );
                            }}
                            extraInfo
                            onChange={(item) => {
                              form.setFieldsValue({
                                customerCode: item?.customerCode,
                                customerPhone: item?.phonenumber,
                                customerAddress: item?.address,
                                customerIdObj: item
                                  ? {
                                      label: item.customerName,
                                      value: item.id,
                                    }
                                  : undefined,
                              });
                            }}
                            onSearch={() =>
                              handleBrowseCustomerInformation(
                                selectedsalerObj?.value
                              )
                            }
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Form.Item
                    label="Điện thoại"
                    name="customerPhone"
                    className="mb-3"
                  >
                    <Input placeholder="Chưa chọn" readOnly />
                  </Form.Item>
                  <Form.Item
                    label="Địa chỉ"
                    name="customerAddress"
                    className="mb-3"
                  >
                    <Input placeholder="Chưa chọn" readOnly />
                  </Form.Item>
                  <Form.Item name="notes" label="Ghi chú" className="mb-3">
                    <Input.TextArea rows={3} />
                  </Form.Item>
                  <Row gutter={[8, 0]}>
                    <Col span={12}>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        label="Hạn mức nợ"
                        className="mb-0"
                      >
                        0
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        label="Dư nợ hiện tại"
                        className="mb-0"
                      >
                        <Title level={5} className="mb-0 text-primary">
                          {utils.$number.numberFormatter(summary.finalAmount)}
                        </Title>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={10}>
            <Card size="small" className="h-100">
              <Row gutter={[16, 0]} align="middle">
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 16 }}
                    label="Tổng sản lượng"
                    className="mb-0"
                  >
                    <div className="text-right pr-3">
                      <Text>
                        {utils.$number.numberFormatter(summary.totalQuantity)}
                      </Text>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 16 }}
                    label="Tổng tiền"
                    className="mb-0"
                  >
                    <div className="text-right pr-3">
                      <Text>
                        {utils.$number.numberFormatter(summary.totalAmount)}
                      </Text>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="input-content-align-right mb-0"
                    label="Chiết khấu (%)"
                    labelCol={{ span: 16 }}
                  >
                    <InputNumber
                      className="w-100 borderLess text-center"
                      placeholder="Nhập"
                      min={0}
                      controls={false}
                      max={100}
                      value={summary.discount}
                      onChange={(value) =>
                        handleChangeSummary({ name: "discount", value })
                      }
                      style={{ maxWidth: 100 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    labelCol={{ span: 16 }}
                    label="Tiền chiết khấu"
                    className="mb-0"
                  >
                    <div className="text-right" style={AMOUNT_STYLE}>
                      <Title level={5} type="danger">
                        <span className="mr-1">-</span>
                        {utils.$number.numberFormatter(summary.discountAmount)}
                      </Title>
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Divider className="my-2" />

              <Row gutter={[16, 16]} align="middle">
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 16 }}
                    className="mb-0"
                    label="Tổng tiền sau chiết khấu"
                  >
                    <div className="text-right" style={AMOUNT_STYLE}>
                      <Text>
                        {utils.$number.numberFormatter(
                          summary.totalAfterDiscount
                        )}
                      </Text>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="mb-0"
                    label="VAT (%)"
                    labelCol={{ span: 16 }}
                  >
                    <InputNumber
                      controls={false}
                      className="w-100 borderLess text-center"
                      placeholder="Nhập"
                      value={summary.vat}
                      onChange={(value) =>
                        handleChangeSummary({ name: "vat", value })
                      }
                      min={0}
                      max={100}
                      style={{ maxWidth: 100 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="mb-0"
                    labelCol={{ span: 16 }}
                    label="Tiền VAT"
                  >
                    <div className="text-right" style={AMOUNT_STYLE}>
                      <Title level={5} type="success" className="mb-0">
                        <span className="mr-1">+</span>
                        {utils.$number.numberFormatter(summary.vatAmount)}
                      </Title>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 16 }}
                    label="Tổng tiền sau thuế"
                    className="mb-0"
                  >
                    <div className="text-right" style={AMOUNT_STYLE}>
                      {utils.$number.numberFormatter(
                        summary.totalAmountAfterVat
                      )}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="input-content-align-right mb-0"
                    labelCol={{ span: 16 }}
                    label="Đã thanh toán"
                  >
                    <InputNumber
                      controls={false}
                      placeholder="Nhập"
                      value={summary.paidAmount}
                      onChange={(value) =>
                        handleChangeSummary({ name: "paidAmount", value })
                      }
                      className="w-100 borderLess text-right"
                      formatter={utils.$number.numberFormatter}
                      parser={utils.$number.numberParser}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 16 }}
                    label="Tổng tiền phải thu"
                    className="mb-0"
                  >
                    <div
                      className="text-right text-primary"
                      style={AMOUNT_STYLE}
                    >
                      <Title level={3} className="mb-0 text-primary">
                        {utils.$number.numberFormatter(summary.finalAmount)}
                      </Title>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <Card size="small">
              <div className="d-flex align-items-center">
                <Select
                  showSearch
                  style={{ width: 300 }}
                  placeholder="Nhập từ khóa (2 ký tự trở lên)"
                  optionFilterProp="children"
                  notFoundContent={null}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  value={null}
                  onSelect={handleAddProductToList}
                  onSearch={handleBrowseProduct}
                >
                  {productOptions}
                </Select>
              </div>
              <Table
                className="mt-3"
                size="small"
                rowKey="stt"
                bordered
                scroll={{ x: size(selectedListProduct) > 0 ? 1366 : 1024 }}
                dataSource={selectedListProduct}
                columns={columns}
                pagination={false}
                locale={{ emptyText: "Chưa có sản phẩm" }}
              />
              <div className="d-flex align-items-center mt-3">
                <Select
                  showSearch
                  style={{ width: 300 }}
                  placeholder="Nhập từ khóa (2 ký tự trở lên)"
                  optionFilterProp="children"
                  notFoundContent={null}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  value={null}
                  onSelect={handleAddPromotionProductToList}
                  onSearch={handleBrowseProduct}
                >
                  {productOptions}
                </Select>
                <span className="ml-2 text-secondary">Sản phẩm khuyến mại</span>
              </div>
              <Table
                className="mt-3"
                size="small"
                rowKey="stt"
                bordered
                scroll={{
                  x: size(selectedListPromotionProduct) > 0 ? 1366 : 1024,
                }}
                columns={columns}
                pagination={false}
                dataSource={selectedListPromotionProduct}
                locale={{ emptyText: "Chưa có sản phẩm" }}
              />
              <div className="mt-2">
                {isAdd ? renderFormActionAdd : renderFormActionUpdate}
              </div>
            </Card>
          </Col>
        </Row>
        <Form.Item noStyle hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item noStyle hidden name="status">
          <Input />
        </Form.Item>
        <Form.Item noStyle hidden name="currentState">
          <Input />
        </Form.Item>
        <Form.Item noStyle hidden name="action">
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddUpdateOrderPage;
