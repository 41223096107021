import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import Chart from "react-google-charts";

const Dashboard = ({ className, profile,dataOrder,dataRevenue }) => {
  return (
    <div style={{ background: "white" }}>
      <Row gutter={15}>
        <Col xxl={12} xl={12} lg={12}>
          <Chart
            width="100%"
            height={500}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={dataRevenue}
            options={{
              // title: "Doanh thu + doanh số của 8 tuần gần nhất",
              hAxis: {
                title: "Doanh thu + doanh số của 8 tuần gần nhất",
                minValue: 0,
              },
              legend: {
                position: "top",
                alignment: "center",
                textStyle: {
                  color: "233238",
                  fontSize: 14,
                },
              },
            }}
            rootProps={{ "data-testid": "4" }}
          />
          <i
            style={{
              fontSize: 16,
              display: "flex",
              justifyContent: "center",
              // marginTop: -10,
            }}
          >
            Báo Cáo Doanh Thu Trong 08 tuần
          </i>
        </Col>
        <Col xxl={12} xl={12} lg={12}>
          <Chart
            width="100%"
            height={500}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={dataOrder}
            options={{
              // title: "Doanh thu + doanh số của 8 tuần gần nhất",
              hAxis: {
                title: "Doanh thu + doanh số của 8 tuần gần nhất",
                minValue: 0,
              },
              legend: {
                position: "top",
                alignment: "center",
              },
              chartArea: {
                // top: 0,
                width: "70%",
                height: "70%"
              },
            }}
            rootProps={{ "data-testid": "4" }}
          />
          <i
            style={{
              fontSize: 16,
              display: "flex",
              justifyContent: "center",
              // marginTop: -10,
            }}
          >
            Báo Cáo Doanh số Trong 08 tuần
          </i>
        </Col>
      </Row>
    </div>
  );
};

export default styled(Dashboard)``;
