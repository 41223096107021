/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 01/06/2021.
 * @description Màn hình `Thông tin kênh bán hàng`. Route `/generalManagement/saleChannel`
 * */

import { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  message,
  Row,
  Col,
  Input,
  Form,
  Space,
} from "antd";
import {
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ChannelModal from "components/modals/ChannelModal";
import services from "services";
import ConfirmModal from "components/modals/confirm";
import utils from "utils";

const SaleChannelPage = function () {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [isReloadPage, setIsReloadPage] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    services.generalService
      .saleChannel_GetBsWithPagination(paging?.pageNumber, paging?.pageSize)
      .then((res) => {
        setIsLoading(false);
        setTotal(res?.totalCount ?? 0);
        setData(res.items);
      })
      .catch((error) => {
        setTotal(0);
        setIsLoading(false);
      });
  }, [paging, isReloadPage]);

  const columns = [
    {
      title: "Mã kênh",
      dataIndex: "saleChannelCode",
      key: "saleChannelCode",
      // align: "center",
      width: 200,
    },
    {
      title: "Tên kênh",
      dataIndex: "saleChannelName",
      key: "saleChannelName",
      // align: "center",
      width: 400,
    },
    {
      title: "Mô tả",
      dataIndex: "notes",
      key: "notes",
      width: 500,
      // align: "center",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      fixed: "right",
      width: 150,
      ellipsis: true,
      render: (value) => {
        var findedStatus = utils.$const.CHANNEL_INFO.STATUS.find(
          (x) => x.id === value
        );
        return (
          <span style={{ color: findedStatus?.color }}>
            {findedStatus?.name}
          </span>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      title: "",
      align: "center",
      fixed: "right",
      width: 50,
      render: (_, row) => (
        <Space className="d-flex align-items-center justify-content-center">
          <EditOutlined className="text-primary" onClick={handleEditSaleChannel(row)} />
          <DeleteOutlined
            className="text-danger"
            onClick={handleDeleteSaleChannel(row)}
          />
        </Space>
      ),
    },
  ];

  const [selectedChannel, setSelectedChannel] = useState({});
  const [isOpenChannelModal, setIsOpenChannelModal] = useState(false);
  const [channelModalMode, setChannelModalMode] = useState("add");
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const handleCreateSaleChannel = useCallback(() => {
    setChannelModalMode("add");
    setIsOpenChannelModal(true);
    setSelectedChannel([]);
  }, []);

  const handleEditSaleChannel = useCallback(
    (channel) => (e) => {
      setChannelModalMode("edit");
      setIsOpenChannelModal(true);
      setSelectedChannel(channel);
    },
    [setSelectedChannel, setChannelModalMode, setIsOpenChannelModal]
  );

  const handleCancelSaleChannel = useCallback(() => {
    setIsOpenChannelModal(false);
    setChannelModalMode("");
  }, [setIsOpenChannelModal, setChannelModalMode]);

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedChannel({});
  }, [setSelectedChannel, setIsReloadPage, isReloadPage]);

  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    },
    [paging]
  );

  const handleDeleteSaleChannel = useCallback(
    (channel) => () => {
      setSelectedChannel(channel);
      setIsShowConfirmModal(true);
    },
    [setSelectedChannel, setIsShowConfirmModal]
  );

  const handleApproveToDeleteChannel = useCallback(() => {
    services.generalService
      .saleChannel_Delete([
        {
          id: selectedChannel?.id,
          currentState: selectedChannel?.currentState,
        },
      ])
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedChannel({});
        handleReloadPage();
      })
      .catch((err) => {
        message.error(err?.message || "Xóa kho thất bại");
      });
  }, [handleReloadPage, selectedChannel]);

  const handleRejectToDeleteChannel = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedChannel({});
  }, []);

  const handleSubmit = (values) => {
    setIsLoading(true);
    let filter = {
      ...form.getFieldsValue(),
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
    };
    services.generalService.saleChannel_FilterSaleChannel(filter)
      .then(res => {
        setIsLoading(false);
        setData(res.items);
        setTotal(res?.totalCount ?? 0);
        if (res.items.length === 0) {
          message.error("Không tìm thấy thông tin");
        } else {
          message.success("Tìm kiếm thành công");
        }
      })
      .catch((err) => {
        setTotal(0);
        setIsLoading(false);
      });
  };

  const handleConfirmFilter = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <Card size="small" className="orderManagementPage">
      {/* <Typography.Title level={5}>KÊNH BÁN HÀNG</Typography.Title>
        <Divider /> */}

      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]} align="bottom">
          <Col lg={6} xl={6}>
            <Form.Item name="saleChannelCode" label="Mã kênh" className="mb-0">
              <Input placeholder="Nhập mã kênh" />
            </Form.Item>
          </Col>
          <Col lg={6} xl={6}>
            <Form.Item name="saleChannelName" label="Tên kênh" className="mb-0">
              <Input placeholder="Nhập tên kênh" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8]} justify="end" className="my-2">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading}
              onClick={handleConfirmFilter}
            >
              <span className="d-flex align-items-center">
                <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button onClick={handleCreateSaleChannel}>
              <span className="d-flex align-items-center">
                <PlusOutlined /> <span className="ml-1">Tạo mới</span>
              </span>
            </Button>
          </Col>
          {/* <Col>
            <Button className="ml-2 btn-success">
              <span className="d-flex align-items-center">
                <PlusOutlined /> <span className="ml-1">Export excel</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button className="ml-2 btn-success">
              <span className="d-flex align-items-center">
                <PlusOutlined /> <span className="ml-1">Import excel</span>
              </span>
            </Button>
          </Col> */}
        </Row>
      </Form>

      {/* <Divider/> */}
      <Table
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 1200 }}
        size="small"
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
        loading={isLoading}
        locale={{ emptyText: "Không có kênh bán hàng" }}
      />
      <ChannelModal
        visible={isOpenChannelModal}
        mode={channelModalMode}
        onCancel={handleCancelSaleChannel}
        dataItem={selectedChannel}
        onReloadPage={handleReloadPage}
      />
      <ConfirmModal
        title="Xóa nhà kênh bán hàng"
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeleteChannel}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa{" "}
          <strong>{selectedChannel?.saleChannelName}</strong> không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleRejectToDeleteChannel}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeleteChannel}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Card>
  );
};
export default SaleChannelPage;
