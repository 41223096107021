import React, { useState, useCallback, useEffect } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Row, Col, Card, Spin, message, Tabs, Table } from "antd";
import styled from "styled-components";
import _ from "lodash";
import Detail from "./detail";
import services from "services";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {saveFileAs} from 'utils/excel';
const { TabPane } = Tabs;
let time;

const Dashboard = ({ className, profile }) => {
  //tab 1 declare
  //const google = window.google;
  const ggMapKey = process?.env?.REACT_APP_GG_MAP_KEY;
  const [data, setData] = useState();
  const [dataPolyline, setDataPolyline] = useState();
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  let query = new URLSearchParams(location.search);

  //tab 2 declare  
  const [tableLoading, setTableLoading] = useState(false);
  const [listShippingOrder, setListShippingOrder] = useState([]);
  const [total, setTotal] = useState(0);
  
  const getStaffVisits = useCallback(() => {
    //load tab 1 - xem theo ban do
    setLoading(true);
    services.customerService
      .visitAttendance_GetVisitAttendanceCheckinOrCheckOut({
        type: query.get("type"),
        checkDate: query.get("checkDate") && `${moment(query.get("checkDate")).format("YYYY-MM-DD")}T00:00:00.000Z`,
        staffId: query.get("staffId"),
      })
      .then((res) => {
        let arrData = _.map(res, (item, index) => {
          item.startTime = moment(item.startTime).format("DD-MM-YYYY HH:mm");
          item.endTime = item.endTime && moment(item.endTime).format("DD-MM-YYYY HH:mm");
          item.lat = item.latitude;
          item.lng = item.longitude;
          item.distance = 0;
          item.prevCustomerName = '';
          return item;
        });

        let arrDataPolyline = _.map(res, (item, index) => {
          let obj = {
            lat: item.latitude,
            lng: item.longitude
          };
          return obj;
        });

        let path = [];
        for (var i = 0; i < arrDataPolyline.length; i++) {
          let lat = parseFloat(arrDataPolyline[i].lat);
          let lng = parseFloat(arrDataPolyline[i].lng);
          let pointPath = new window.google.maps.LatLng(lat,lng);
          path.push(pointPath);
          let polylineLength = 0;
          if (i > 0) {
            polylineLength = window.google.maps.geometry.spherical.computeDistanceBetween(path[i], path[i-1]);
            arrData[i].prevCustomerName = arrData[i-1].customerName;
          }
          arrData[i].distance = parseInt(polylineLength);
        }

        setData(arrData);
        setDataPolyline(arrDataPolyline);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error("Không tìm thấy kết quả");
      });

      //load tab 2 - xem theo danh sach
      setTableLoading(true);
      services.customerService
        .visitAttendance_ReportVisitAttendanceCheckin({
          customerName: undefined,
          staffId: query.get("staffId"),
          provinceId: undefined,
          pageNumber: 1,
          pageSize: 200,
          fromDate: query.get("checkDate") && `${moment(query.get("checkDate")).format("YYYY-MM-DD")}T00:00:00.000Z`,
          toDate: query.get("checkDate") && `${moment(query.get("checkDate")).format("YYYY-MM-DD")}T00:00:00.000Z`,
        })
        .then((res) => {
          setListShippingOrder(res?.items || []);
          setTotal(res?.totalCount);
        })
        .catch((err) => {
          message.error("Lấy thông tin đơn viếng thăm thất bại");
        })
        .finally(() => {
          setTableLoading(false);
        });
  }, []);

  const exportExcel = () => {
    setLoading(true);

    services.customerService
    .visitAttendance_ExportReportVisitAttendanceCheckin({
      customerName: undefined,
      staffId: query.get("staffId"),
      provinceId: undefined,
      fromDate: query.get("checkDate") && `${moment(query.get("checkDate")).format("YYYY-MM-DD")}T00:00:00.000Z`,
      toDate: query.get("checkDate") && `${moment(query.get("checkDate")).format("YYYY-MM-DD")}T00:00:00.000Z`,
    })
    .then((response) => {
      saveFileAs(response.data, 'BC vị trí viếng thăm của nhân viên '+ moment().format("DD-MM-YYYY") + '.xlsx');
    })
    .catch((err) => {
      message.error('Có lỗi khi xuất excel');
    })
    .finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(getStaffVisits, 800);
  }, [getStaffVisits]);


  const summaryColumns = [
    {
      dataIndex: "no",
      key: "no",
      title: "STT",
      align: "center",
      fixed: "left",
      width: 18,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: "staff",
      key: "StaffCode",
      title: "Mã NV",
      width: 30,
      render: (value) => (value ? value.staffCode : ""),
    },
    {
      dataIndex: "staff",
      key: "staffName",
      title: "Nhân viên",
      width: 60,
      render: (value) => (value ? value.staffName : ""),
    },
    {
      dataIndex: "startTime",
      key: "startTime",
      title: "Ngày",
      align: "right",
      width: 35,
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
    },
    {
      dataIndex: "startTime",
      key: "dayOfWeek",
      title: "Thứ",
      align: "right",
      width: 25,
      render: (value) => {
        var currentDate = value.getDay();
        switch (currentDate) {
          case 0:
            return "Chủ nhật";
          case 1:
            return "Thứ hai";
          case 2:
            return "Thứ ba";
          case 3:
            return "Thứ tư";
          case 4:
            return "Thứ năm";
          case 5:
            return "Thứ sáu";
          case 6:
            return "Thứ bảy";
          default:
            return "Chủ Nhật";
        }
      },
    },
    {
      dataIndex: "startTime",
      key: "CheckinTime",
      title: "Check in",
      align: "right",
      width: 30,
      render: (value) => (value ? moment(value).format("HH:mm:ss") : ""),
    },
    {
      dataIndex: "endTime",
      key: "CheckoutTime",
      title: "Check out",
      align: "right",
      width: 30,
      render:(value) => (value ? moment(value).format("HH:mm:ss") : ""),
    },
    {
      dataIndex: "customer",
      key: "customerCode",
      title: "Mã KH",
      width: 50,
      render: (value) => (value ? value.customerCode : ""),
    },
    {
      dataIndex: "customer",
      key: "customerName",
      title: "Tên KH",
      width: 60,
      render: (value) => (value ? value.customerName : ""),
    },
    {
      dataIndex: "phonenumber",
      key: "phonenumber",
      title: "SĐT",
      align: "right",
      width: 40,
    },
    {
      dataIndex: "address",
      key: "address",
      title: "Địa chỉ",
      width: 100,
    },
    {
      dataIndex: "checkinAddress",
      key: "checkinAddress",
      title: "Địa chỉ checkin",
      width: 130,
    },
    {
      dataIndex: "customer",
      key: "createdDate",
      title: "Ngày tạo KH",
      align: "right",
      width: 35,
      render: (value) => (value ? moment(value.createdDate).format("DD-MM-YYYY") : "")
    },
    {
      dataIndex: "countMinute",
      key: "countMinute",
      title: "Số phút",
      align: "right",
      width: 20,
    },
    {
      dataIndex: "isPhoto",
      key: "isPhoto",
      title: "Chụp ảnh",
      align: "center",
      width: 25,
      render: (value) => {
        if (value) {
          return <i className="fa fa-check" aria-hidden="true"></i>;
        } else {
          return <i className="fa fa-times" aria-hidden="true"></i>;
        }
      },
    },
    {
      dataIndex: "isPO",
      key: "isPO",
      title: "Đơn hàng",
      align: "center",
      width: 25,
      render: (value) => {
        if (value) {
          return <i className="fa fa-check" aria-hidden="true"></i>;
        } else {
          return <i className="fa fa-times" aria-hidden="true"></i>;
        }
      },
    },
    {
      dataIndex: "isInventory",
      key: "isInventory",
      title: "Ghi tồn",
      align: "center",
      width: 25,
      render: (value) => {
        if (value) {
          return <i className="fa fa-check" aria-hidden="true"></i>;
        } else {
          return <i className="fa fa-times" aria-hidden="true"></i>;
        }
      },
    },
    {
      dataIndex: "notes",
      key: "notes",
      title: "Ghi chú",
      width: 150,
    },
  ];

  return (
    <Card>
      <Row className={className} gutter={16}>
        <Col xs={24}>
          <Spin spinning={loading} tip="Đang lấy dữ liệu...">
            <Tabs type="card">
              <TabPane tab="Xem dạng bảng đồ" key="1">
                <Detail
                  data={data}
                  dataPolyline={dataPolyline}
                  ggMapKey={ggMapKey} />
              </TabPane>
              <TabPane tab="Xem dạng danh sách" key="2">
                <Row justify="end" style={{ marginTop: 5,marginBottom:10 }}>
                  <Col className='ml-2'>
                    <Button
                      type='dashed'
                      disabled={loading}
                      onClick={exportExcel}
                    >
                      <span className='d-flex align-items-center'>
                        <DownloadOutlined /> <span className='ml-1'>Xuất file excel</span>
                      </span>
                    </Button>
                  </Col>
                </Row>
                <Table
                  columns={summaryColumns}
                  dataSource={listShippingOrder}
                  loading={tableLoading}
                  pagination={{
                    total: total ?? 0,
                    //pageSizeOptions: [10, 20, 50],
                    //current: paging?.pageNumber ?? 1,
                    //pageSize: paging?.pageSize ?? 10,
                    //onChange: handleChangePaging,
                    //showSizeChanger: true,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} trong ${total} dòng`,
                  }}
                  locale={{ emptyText: "Không có viếng thăm nào" }}
                  bordered
                  size="small"
                  scroll={{ x: 3000, y: 680 }}
                />
              </TabPane>
            </Tabs>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default styled(Dashboard)``;
