import {
  DeleteFilled,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Drawer,
  message,
  Modal,
  Table,
  Tag
} from "antd";
import ConfirmModal from "components/modals/confirm";
import CustomermanagementModal from "components/modals/CustomermanagementModal";
//import RouteManagement from "components/modals/RouteManagement";
import React, { useCallback } from "react";
import services from "services";
import utils from "utils";
import { toUpper } from "lodash";
import SaleRouteModal from "components/modals/SaleRouteModal";
import CustomerInformation from "pages/customermanagement/CustomerInformation";
import SaleRoutePage from "./SaleRoute";

const NestTableRender = ({ dataSource, saleRouteId, onShowModal }) => {
  const handleEdit = (record) => {
    onShowModal({
      visible: true,
      mode: utils.$const.COMMON.MODAL_MODE.EDIT,
      dataSource: { ...record, saleRouteId },
    });
  };

  const columns = [
    {
      title: "Mã KH",
      dataIndex: "customerCode",
      key: "customerCode",
      width: 100,
    },
    {
      title: "Tên Khách Hàng",
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
    },
    {
      title: "Tên Cửa hàng",
      dataIndex: "storeName",
      key: "storeName",
      width: 150,
    },
    { title: "Địa chỉ", dataIndex: "address", key: "address", width: 300 },
    {
      title: "Thứ tự viếng thăm",
      dataIndex: "visitAttendanceIndex",
      key: "visitAttendanceIndex",
      width: 120,
      align: "center",
    },
    {
      title: "Ngày viếng thăm",
      dataIndex: "dateOfVisitings",
      key: "dateOfVisitings",
      render: (dateOfVisitings) => (
        <div className="d-flex align-items-center">
          {dateOfVisitings.map((item) => {
            return (
              <Tag>{utils.$const.COMMON.DAY_OF_WEEK[toUpper(item?.date)]}</Tag>
            );
          })}
        </div>
      ),
    },
    {
      title: "Actions",
      align: "center",
      width: 180,
      key: "x",
      render: (_, row) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button type="primary" size="small" onClick={() => handleEdit(row)}>
            <span className="d-flex align-items-center">
              <EditOutlined /> <span className="ml-1">Sửa</span>
            </span>
          </Button>
          <Button
            danger
            className="mx-2"
            size="small"
            onClick={() => handleShowConfirmDelete(row)}
          >
            <span className="d-flex align-items-center">
              <DeleteFilled /> <span className="ml-1">Xóa</span>
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa Khu vực <b className="text-danger">{row.areaName}</b>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.organization
          .businessArea_Delete([
            {
              id: row.id,
              currentState: row.currentState,
              status: row.status,
            },
          ])
          .then(() => message.success("Xóa Khu vực thành công."))
          .catch(() =>
            message.error("Xóa Khu vực không thành công.Vui lòng thử lại")
          );
      },
      onCancel() {},
    });
  }, []);
  return (
    <Table
      size="small"
      columns={columns}
      dataSource={dataSource}
      bordered
      rowKey="id"
      pagination={false}
    />
  );
};

function RouteCustomer() {
  const [data, setData] = React.useState([]);

  //const [paging, setPaging] = React.useState({ page: 1, pageSize: 10 });
  const [loading, setLoading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [isReloadPage, setIsReloadPage] = React.useState(false);
  const [showCustomerInfomation, setShowCustomerInfomation] =
    React.useState(false);
  const [showSaleRoute, setShowSaleRoute] = React.useState(false);
  //const [params, setParams] = React.useState({});
  const [selectedRoute, setSelectedRoute] = React.useState({});
  //const [isOpenRouteModal, setIsOpenRouteModal] = React.useState(false);
  //const [routeModalMode, setRouteModalMode] = React.useState("add");
  const [isShowConfirmModal, setIsShowConfirmModal] = React.useState(false);
  //const [SaleRouteManagement, setSaleRouteManagement] = React.useState([]);
  //const [humanResource, sethumanResource] = React.useState([]);

  const [customerManagementModalConfig, setCustomerManagementModalConfig] =
    React.useState({
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
      visible: false,
      dataSource: {},
    });

  const [saleRouteModalConfig, setSaleRouteModalConfig] = React.useState({
    mode: utils.$const.COMMON.MODAL_MODE.ADD,
    visible: false,
    dataSource: {},
  });

  // const [customerModalConfig, setCustomerModalConfig] = React.useState({
  //   mode: utils.$const.COMMON.MODAL_MODE.ADD,
  //   visible: false,
  //   dataSource: {},
  // });

  const handleBrowseSaleRouteManagement = () => {
    services.generalService
      .saleRoute_GetSaleRouteManagement()
      .then((res) => {
        setLoading(false);
        setTotal(res?.totalCount ?? 0);
        setData(res);
        // setSaleRouteManagement(
        //   res?.map((item) => {
        //     return item.listCustomer;
        //   })
        // );
      })
      .catch((error) => {
        setTotal(0);
        setLoading(false);
      });
  };

  // const handleCancelSaleRoute = useCallback(() => {
  //   setIsOpenRouteModal(false);
  //   setRouteModalMode("");
  //   setSelectedRoute({});
  // }, [setIsOpenRouteModal, setSelectedRoute]);

  // const handleEditSaleRoute = useCallback(
  //   (provider) => (e) => {
  //     setRouteModalMode("edit");
  //     setIsOpenRouteModal(true);
  //     setSelectedRoute(provider);
  //   },
  //   []
  // );

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedRoute({});
  }, [setSelectedRoute, setIsReloadPage, isReloadPage]);

  const handleDeleteRoute = useCallback(
    (route) => () => {
      setSelectedRoute(route);
      setIsShowConfirmModal(true);
    },
    [setSelectedRoute, setIsShowConfirmModal]
  );

  const handleApproveToDeleteRoute = useCallback(() => {
    services.generalService
      .saleRoute_Delete([
        {
          id: selectedRoute?.id,
          currentState: selectedRoute?.currentState,
        },
      ])
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedRoute({});
        handleReloadPage();
      })
      .catch((err) => {
        message.error(err?.message || "Xóa tuyến thất bại");
      });
  }, [handleReloadPage, selectedRoute]);

  const handleCancelConfirmModal = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedRoute({});
  }, [setSelectedRoute, setIsShowConfirmModal]);

  const handleCancelCustomerManagementModal = () => {
    setCustomerManagementModalConfig({
      visible: false,
      dataSource: {},
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    handleBrowseSaleRouteManagement();
  };

  const handleCancelSaleRouteModal = () => {
    setSaleRouteModalConfig({
      visible: false,
      dataSource: {},
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    handleBrowseSaleRouteManagement();
  };

  // React.useEffect(() => {
  //   services.organization
  //     .humanResource_GetAll(1, 100)
  //     .then((res) => sethumanResource(res?.items))
  //     .catch(() => sethumanResource([]));
  //   handleBrowseSaleRouteManagement();
  // }, []);

  const columns = [
    {
      title: "No.",
      key: "no",
      render: (value, row, index) => index + 1,
      width: 55,
    },
    {
      title: "Mã tuyến",
      dataIndex: "saleRouteCode",
      key: "saleRouteCode",
      width: 250,
    },
    {
      title: "Tên Tuyến",
      dataIndex: "saleRouteName",
      key: "saleRouteName",
    },
    {
      title: "Nhân viên Sale",
      dataIndex: "salerName",
      key: "salerName",
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Hành động",
      dataIndex: "",
      key: "action",
      width: 150,
      render: (_, row) => {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <Button
              onClick={() =>
                setSaleRouteModalConfig({
                  visible: true,
                  mode: utils.$const.COMMON.MODAL_MODE.EDIT,
                  dataSource: row,
                })
              }
              type="primary"
              size="small"
            >
              <span className="d-flex align-items-center">
                <EditOutlined /> <span className="ml-1">Sửa</span>
              </span>
            </Button>
            <Button danger size="small" onClick={handleDeleteRoute(row)}>
              <span className="d-flex align-items-center">
                <DeleteFilled /> <span className="ml-1">Xóa</span>
              </span>
            </Button>
          </div>
        );
      },
    },
  ];

  const renderTitle = () => {
    return (
      <div className="definition__search d-inline-flex">Quản lý Tuyến</div>
    );
  };
  const renderActions = () => {
    return (
      <div className="definition__actions">
        <Button.Group>
          <Button onClick={() => setShowSaleRoute(true)}>
            Thêm tuyến bán hàng
          </Button>
          <Button onClick={() => setShowCustomerInfomation(true)}>
            Thêm khách hàng
          </Button>
        </Button.Group>
      </div>
    );
  };

  return (
    <div>
      <Card title={renderTitle()} extra={renderActions()}>
        <Table
          rowKey="id"
          columns={columns}
          bordered
          dataSource={data}
          expandable={{
            expandedRowRender: (record) => (
              <NestTableRender
                dataSource={record.listCustomer}
                saleRouteId={record.id}
                onShowModal={(config) => {
                  setCustomerManagementModalConfig(config);
                }}
              />
            ),
          }}
          locale={{ emptyText: "Không có tuyến bán hàng" }}
          scroll={{ x: 1200 }}
          pagination={{
            total,
            defaultPageSize: 20,
            pageSizeOptions: [10, 20, 50],
            showSizeChanger: true,
          }}
          loading={loading}
        />
      </Card>
      <SaleRouteModal
        config={saleRouteModalConfig}
        onCancel={handleCancelSaleRouteModal}
      />
      <CustomermanagementModal
        config={customerManagementModalConfig}
        onCancel={handleCancelCustomerManagementModal}
      />
      <ConfirmModal
        title="Xóa"
        visible={isShowConfirmModal}
        onCancel={handleCancelConfirmModal}
        footer={false}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa <strong>{selectedRoute?.saleRouteName}</strong>{" "}
          không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleCancelConfirmModal}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeleteRoute}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
      <Drawer
        visible={showCustomerInfomation}
        width="80%"
        title="Thông tin khách hàng"
        onClose={() => setShowCustomerInfomation(false)}
      >
        <CustomerInformation />
      </Drawer>
      <Drawer
        visible={showSaleRoute}
        width="80%"
        title="Thông tin tuyến bán hàng"
        onClose={() => setShowSaleRoute(false)}
      >
        <SaleRoutePage />
      </Drawer>
    </div>
  );
}

export default RouteCustomer;
