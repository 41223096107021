/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 20/09/2021.
 * @description Màn hình `Danh sách chức năng - thao tác`. Permission `/access-management/action-control`
 * */

import { useCallback, useState, useEffect } from "react";
import { Card, Button, Table, message, Typography,Form, Row, Col, Input } from "antd";
import { PlusOutlined, SearchOutlined, EditOutlined, DeleteFilled } from "@ant-design/icons";
import services from "services";
import ConfirmModal from "components/modals/confirm";
import ActionControlModal from "components/modals/ActionControlModal";

const ActionControlPage = function (){
    const [form] = Form.useForm();
    const [data, setData] = useState([]);

    const [paging, setPaging] = useState({ page: 1, pageSize: 20 });
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [isReloadPage, setIsReloadPage] = useState(false);

    useEffect(() => {
      setLoading(true);
      services.accessManagementService.actionDefinition_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res)=>{
        setLoading(false);
        setTotal(res?.totalCount ?? 0);
        setData(res.items);
      })
      .catch((error)=>{
        setTotal(0);
        setLoading(false);
      })
    }, [paging,isReloadPage]);

    const columns = [
        {
            title: "#",
            dataIndex: "actionName",
            key:"actionName",
            width: 100,
            align: "center",
        },
        {
            title: "Mô tả",
            dataIndex: "notes",
            key:"notes",
            width: 400,
            align: "center"
        },
        {
            dataIndex: "action",
            key: "action",
            title: "#",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_, row) => (
              <div className="warehouseInfo-card__button">
                <Button
                    type="primary"
                    size="small"
                    onClick={handleEditActionControl(row)}
                >
                  <span className="d-flex align-items-center">
                    <EditOutlined /> <span className="ml-1"></span>
                  </span>
                </Button>
                <Button
                    className="warehouseInfo-card__button--right"
                    type="danger"
                    size="small"
                    onClick={handleDeleteActionControl(row)}
                >
                  <span className="d-flex align-items-center">
                    <DeleteFilled /> <span className="ml-1"></span>
                  </span>
                </Button>
                </div>
            ),
        },
    ];
    
    const [selectedActionControl, setSelectedActionControl] = useState({});
    const [isOpenActionControlModal, setIsOpenActionControlModal] = useState(false);
    const [actionControlModalMode, setActionControlModalMode] = useState("add");
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    const handleCreateActionControlModal = useCallback(() => {
    setActionControlModalMode("add");
    setIsOpenActionControlModal(true);
    setSelectedActionControl([]);
  }, []);
  
  const handleEditActionControl = useCallback(
    (currencyUnit) => (e) => {
      setActionControlModalMode("edit")
      setIsOpenActionControlModal(true);
      setSelectedActionControl(currencyUnit);
    },
    [setSelectedActionControl,setActionControlModalMode,setIsOpenActionControlModal]
  );

  const handleCancelActionControlModal = useCallback(() => {
    setIsOpenActionControlModal(false);
    setSelectedActionControl({});
  }, [setIsOpenActionControlModal, setSelectedActionControl]);

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedActionControl({});
  }, [setSelectedActionControl, setIsReloadPage, isReloadPage]);
  
  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ page: currentPage, pageSize });
    },
    [paging]
  );

  const handleDeleteActionControl = useCallback(
    (currencyUnit) => () => {
      setSelectedActionControl(currencyUnit);
      setIsShowConfirmModal(true);
    },
    [setSelectedActionControl, setIsShowConfirmModal]
  );

  const handleApproveToDeleteActionControl = useCallback(() => {
    services.accessManagementService
      .actionDefinition_Delete([{
        id: selectedActionControl?.id,
        currentState: selectedActionControl?.currentState
      }])
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedActionControl({});
        handleReloadPage();
      })
      .catch((err) => {
        message.error(err?.message || "Xóa kho thất bại");
      });
  }, [selectedActionControl,handleReloadPage]);

  const handleRejectToDeleteActionControl = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedActionControl({});
  }, [setSelectedActionControl]);

  const renderTitle = () => {
    return (
      <Typography.Text>CHỨC NĂNG - THAO TÁC</Typography.Text>
    );
  };
  const handleSubmit = (values) => {
    setIsLoading(true);
    let filter = {
      ...form.getFieldsValue(),
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize
    };
    services.accessManagementService.actionDefinition_Filtering(filter)
    .then(res =>{
      setIsLoading(false);
      setData(res.items);
      setTotal(res?.totalCount ?? 0);
      if (res.items.length === 0) {
        message.error("Không tìm thấy thông tin");
      }
      else {
        message.success("Tìm kiếm thành công");
      }
    })
    .catch(err =>{
      setTotal(0);
      setIsLoading(false);
    })
  };
  
  return (
    <Card className="ActionControlPage">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={10}>
            <Form.Item name='keyword' label='Tìm theo từ khóa'>
              <Input placeholder='Nhập từ khóa' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                >
                  <span className='d-flex align-items-center'>
                    <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
                  </span>
                </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height ' label=' '>
              <Button onClick={handleCreateActionControlModal}>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Tạo mới</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Export excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Import excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        size="small"
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 1200 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.page ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        }}
        loading={loading}
        locale={{ emptyText: "Không có nhà cung cấp" }}
      />
      <ActionControlModal
        visible={isOpenActionControlModal}
        mode = {actionControlModalMode}
        onCancel = {handleCancelActionControlModal}
        dataItem = {selectedActionControl}
        onReloadPage={handleReloadPage}
      />
      <ConfirmModal
        title="Xóa chức năng, quyền"
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeleteActionControl}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa {" "}
          <strong>{selectedActionControl?.actionName}</strong> không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleRejectToDeleteActionControl}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeleteActionControl}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Card>
  );
};
export default ActionControlPage;