/**
 * Copyright 2021-present, Hoàng An.
 * All rights reserved.
 * @author tangan2215@gmail.com on 07/06/2021.
 * @description Màn hình Nhóm nhãn hiệu. Route `/productManagement/productBrand`
 **/
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  message,
  Divider,
  Col,
  Row,
  Input,
  Form,
  Select
} from "antd";
import {
  DeleteOutlined,
  EditFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ProductBrandModal from "components/modals/ProductBrandModal";
import services from "services";
import Pagination from "components/Paginate/index";

export default function ProductBrand() {
  const [listProductBand, setlistProductBand] = useState([]);
  const [showProductBrandModal, setShowProductBrandModal] = useState(false);
  const [selectedProductBrand, setSelectedProductBrand] = useState({});

  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  //const [isReloadPage, setIsReloadPage] = useState(false);

  const handleGetlistProductBand = useCallback(() => {
    setLoading(true);
    services.generalService
      .productBrand_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res) => {
        setlistProductBand(res.items);
        setLoading(false);
        setTotal(res?.totalCount ?? 0);
      })
      .catch((error) => {
        setTotal(0);
        setLoading(false);
      });
  }, [paging]);

  const [form] = Form.useForm();

  const listStatus = [
    {
      id: 1,
      status: 1,
      statusName: "Hoạt động",
    },
    {
      id: 2,
      status: 0,
      statusName: "Không hoạt động",
    },
  ];

  useEffect(() => {
    handleGetlistProductBand();
  }, [handleGetlistProductBand]);

  // const handleChangePaging = useCallback(
  //   (page, pageSize) => {
  //     const currentPage = pageSize !== paging?.pageSize ? 1 : page;
  //     setPaging({ page: currentPage, pageSize });
  //   },
  //   [paging]
  // );

  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa nhãn hiệu <b className="text-danger">{row.brandCode}</b>{" "}
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.generalService
          .productBrand_Delete({
            id: row.id,
            currentState: row.currentState,
          })
          .then((res) => {
            message.success("Xóa nhãn hiệu thành công.");
            handleGetlistProductBand();
          })
          .catch((erro) => {
            message.error("Tồn tại sản phẩm có chứa nhãn hiệu.");
          });
      },
      onCancel() {},
    });
  }, []);

  const columns = [
    //  {
    //    dataIndex: "no",
    //    key: "no",
    //    title: "Số Thứ tự",
    //    align: "center",
    //  },
    {
      dataIndex: "brandCode",
      key: "brandCode",
      title: "Mã nhẫn hiệu",
      // align: "center",
      width: 150,
    },
    {
      dataIndex: "brandName",
      key: "brandName",
      title: "Tên nhãn hiệu",
      // align: "center",
      width: 200,
    },
    {
      dataIndex: "notes",
      key: "notes",
      title: "Ghi Chú",
      // align: "center",
    },
    {
      dataIndex: "activeStatus",
      key: "activeStatus",
      title: "Trạng Thái",
      align: "center",
      width: 180,
      fixed: "right",
      render: (value) => (
        <div className={`status--${value ? "active" : "inactive"}`}>
          {value === 1 ? "Hoạt Động" : "Không Hoạt Động"}
        </div>
      ),
    },
    {
      dataIndex: "#",
      key: "action",
      title: "#",
      width: 180,
      align: "center",
      fixed: "right",
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Button
            icon={<EditFilled className="warehouseInfo-card__icon" />}
            type="primary"
            onClick={handleEditProductBrand(row)}
          ></Button>
          <Button
            className="warehouseInfo-card__button--right"
            icon={<DeleteOutlined className="warehouseInfo-card__icon" />}
            type="danger"
            onClick={() => handleShowConfirmDelete(row)}
          ></Button>
        </div>
      ),
    },
  ];

  const handleCreateProductBrand = useCallback(() => {
    setShowProductBrandModal(true);
  }, []);

  const handleEditProductBrand = useCallback(
    (ProductBrandInfor) => (e) => {
      setShowProductBrandModal(true);
      setSelectedProductBrand(ProductBrandInfor);
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setShowProductBrandModal(false);
    setSelectedProductBrand({});
  }, []);

  const handleConfirmfilter = useCallback(() => {
    form.submit();
  }, [form]);

  const onSubmit = (values) => {
    setLoading(true);

    let dataSubmit = {
      ...form.getFieldValue(),
      pageNumber: 1,
      pageSize: 10,
    };

    services.generalService
      .productBrand_FilterProductBrand(dataSubmit)
      .then((res) => {
        setlistProductBand(res.items);
        setLoading(false);
        // if (res.items.length == 0) {
        //   message.error("Không tìm thấy nhãn hiệu");
        // } else {
        //   message.success("Tìm kiếm thành công");
        // }
      });
  };

  return (
    <>
      
      <Card className="ProductBrand-card">
        <Divider style={{margin:10}}/>
        <Form form={form} onFinish={onSubmit} labelCol={{ span: 24 }}>
          <Row gutter={[15]}>
            <Col lg={6} xl={6}>
              <Form.Item name="brandCode" label="Mã nhãn hiệu">
                <Input placeholder="Nhập mã nhãn hiệu" />
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              <Form.Item name="brandName" label="Tên nhãn hiệu">
                <Input placeholder="Nhập tên nhãn hiệu" />
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              <Form.Item name="activeStatus" label="Trạng thái">
                <Select allowClear placeholder="Chọn trạng thái">
                  {listStatus.map((item, i) => (
                    <Select.Option key={i} value={item?.status}>
                      {item?.statusName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleConfirmfilter}
                  size="middle"
                  disabled={loading}
                >
                  <span className="d-flex align-items-center">
                    <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                  </span>
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={[8]}>
            <Col>
              <Button size="middle" onClick={handleCreateProductBrand}>
                <span className="d-flex align-items-center">
                  <PlusOutlined />
                  <span className="ml-1">Thêm mới</span>
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
        <Divider style={{margin:10}}/>
        {/* <div className="CustomerInfor-card__header">
        <Typography.Text>DANH SÁCH NHÃN HIỆU</Typography.Text>
      </div> */}
        <Table
          rowKey="id"
          className="ProductBrand-card__table"
          columns={columns}
          dataSource={listProductBand}
          loading={loading}
          bordered
          size="small"
          scroll={{ x: 1200 }}
          // pagination={{
          //   total,
          //   pageSizeOptions: [10, 20, 50],
          //   current: paging?.page ?? 1,
          //   pageSize: paging?.pageSize ?? 10,
          //   onChange: handleChangePaging,
          //   showSizeChanger: true,
          // }}
          pagination={false}
          locale={{ emptyText: "Không có nhãn hiệu nào" }}
        />
        <Pagination total={total} setPaging={setPaging} paging={paging} />

        <ProductBrandModal
          visible={showProductBrandModal}
          onCancel={handleCloseModal}
          selectedProductBrand={selectedProductBrand}
          onReload={handleGetlistProductBand}
        />
      </Card>
    </>
  );
}
