/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 1/07/2021.
 * @description Màn hình `Thông tin nhà phân phối`. Route `/product-management/distributorInformation`
 * */

import { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  message,
  Form,
  Row,
  Col,
  Input,
  Select,
  Space,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import DistributorModal from "components/modals/DistributorModal";
import services from "services";
import utils from "utils";
import ConfirmModal from "components/modals/confirm";
import _ from "lodash";

const DistributorInformationPage = function () {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [filterCriteria, setFilterCriteria] = useState({
    distributorCode: null,
    distributorName: null,
    representative: null,
    provinceId: null,
    districtId: null});
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);

  const loadDistributors = useCallback(() => {
      setLoading(true);
      services.generalService
        .distributor_FilterDistributor({
          distributorCode: filterCriteria?.distributorCode,
          distributorName: filterCriteria?.distributorName,
          representative: filterCriteria?.representative,
          provinceId: filterCriteria?.provinceObj?.value,
          districtId: filterCriteria?.districtObj?.value,
          pageNumber: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
        })
        .then((res) => {
          setLoading(false);
          setData(res.items);
          setTotal(res?.totalCount ?? 0);
        })
        .catch((err) => {
          setTotal(0);
          setLoading(false);
        });
    },
    [paging]
  );

  useEffect(() => {
    loadDistributors();
  }, [loadDistributors]);

  useEffect(() => {
    services.organization.province_GetAllProvincesWithPermission().then((res) => {
      setProvince(res);
    });
  }, [setProvince]);

  const columns = [
    {
      dataIndex: "no",
      key: "no",
      title: "No.",
      width: 75,
      render: (value, row, index) => index + 1
    },
    {
      title: "Mã nhà phân phối",
      dataIndex: "distributorCode",
      key: "distributorCode",
      width: 200
    },
    {
      title: "Tên nhà phân phối",
      dataIndex: "distributorName",
      key: "distributorName",
      width: 400
    },
    {
      title: "Người đại diện",
      dataIndex: "representative",
      key: "representative",
      width: 150
    },
    {
      title: "Mã số thuế",
      dataIndex: "taxCode",
      key: "taxCode",
      width: 150
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 150,
      align: "right",
    },
    {
      title: "Tỉnh thành",
      dataIndex: "provinceName",
      key: "provinceName",
      width: 150,
    },
    {
      title: "Quận",
      dataIndex: "districtName",
      key: "districtName",
      width: 150
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      width: 400
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
      width: 500
    },
    {
      title: "Trạng thái",
      dataIndex: "activeStatus",
      key: "activeStatus",
      width: 150,
      align: "center",
      render: (value) => {
        var findedStatus = utils.$const.DISTRIBUTOR_INFO.STATUS.find(
          (x) => x.id === value
        );
        return (
          <span style={{ color: findedStatus?.color }}>
            {findedStatus?.name}
          </span>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      title: "",
      align: "center",
      fixed: "right",
      width: 75,
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Space className="d-flex align-items-center justify-content-center">
            <EditOutlined
              className="text-primary"
              onClick={handleEditDistributor(row)}
            />
            <DeleteOutlined
              title="Xóa"
              className="text-danger"
              onClick={handleDeleteDistributor(row)}
            />
          </Space>
        </div>
      ),
    },
  ];

  const [selectedDistributor, setSelectedDistributor] = useState({});
  const [isOpenDistributorModal, setIsOpenDistributorModal] = useState(false);
  const [distributorModalMode, setDistributorModalMode] = useState("add");
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const handleCreateDistributorModal = useCallback(() => {
    setDistributorModalMode("add");
    setIsOpenDistributorModal(true);
    setSelectedDistributor([]);
  }, []);

  const handleEditDistributor = useCallback(
    (distributor) => (e) => {
      setDistributorModalMode("edit");
      setIsOpenDistributorModal(true);
      setSelectedDistributor(distributor);
    },
    [setSelectedDistributor, setDistributorModalMode, setIsOpenDistributorModal]
  );

  const handleCancelDistributorModal = useCallback(() => {
    setIsOpenDistributorModal(false);
    setSelectedDistributor({});
  }, [setIsOpenDistributorModal, setSelectedDistributor]);

  const handleReloadPage = useCallback(() => {
    setSelectedDistributor({});
  }, [setSelectedDistributor]);

  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    }
  );
  const handleSubmit = (values) => {
    setFilterCriteria({ ...values });
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
  };

  const handleConfirmFilter = useCallback(() => {
    form.submit();
  }, [form]);

  const handleDeleteDistributor = useCallback(
    (distributor) => () => {
      setSelectedDistributor(distributor);
      setIsShowConfirmModal(true);
    },
    [setSelectedDistributor, setIsShowConfirmModal]
  );

  const handleChangeProvince = useCallback(
    (obj) => {
      let provinceItem = _.filter(province, function (item) {
        if (item.id === obj?.value) {
          return item?.id;
        }
      });
      services.organization
        .district_GetDistrictByProvince(provinceItem[0]?.id)
        .then((res) => {
          setDistrict(res);
        });
      form.getFieldValue().districtObj = "";
    },
    [form, province]
  );

  const handleApproveToDeleteDistributor = useCallback(() => {
    services.generalService
      .distributor_Delete({
        id: selectedDistributor?.id,
        currentState: selectedDistributor?.currentState,
      })
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedDistributor({});
        handleReloadPage();
      })
      .catch(() => {
        message.error("Xóa nhà phân phối thất bại");
      });
  }, [handleReloadPage, selectedDistributor]);

  const handleRejectToDeleteDistributor = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedDistributor({});
  }, []);

  return (
    <Card className="DistributorInformationPage h-100">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={8}>
            <Form.Item name="distributorCode" label="Mã nhà phân phối">
              <Input placeholder="Nhập mã nhà phân phối" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={8}>
            <Form.Item name="distributorName" label="Tên nhà phân phối">
              <Input placeholder="Nhập tên nhà phân phối" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={8}>
            <Form.Item name="representative" label="Người đại diện">
              <Input placeholder="Nhập tên đại diện" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8]}>
          <Col lg={24} xl={8}>
            <Form.Item label="Tỉnh thành" name="provinceObj">
              <Select
                placeholder="Chọn tỉnh thành"
                showSearch
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue
                onChange={handleChangeProvince}
                allowClear
              >
                {_.map(province, (item, id) => (
                  <Select.Option key={id} value={item?.id}>
                    {item?.provinceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={8}>
            <Form.Item label="Quận huyện" name="districtObj">
              <Select
                placeholder="Chọn quận huyện"
                showSearch
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue
                allowClear
              >
                {district.map((item, id) => (
                  <Select.Option key={id} value={item?.id}>
                    {item?.districtName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={8}>
            <Form.Item name="phoneNumber" label="Số điện thoại">
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={[8]}>
          <Col >
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              onClick={handleConfirmFilter}
            >
              <span className="d-flex align-items-center">
                <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          <Col >
            <Button onClick={handleCreateDistributorModal}>
              <span className="d-flex align-items-center">
                <PlusOutlined />
                <span className="ml-1">Tạo mới</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        className="mt-2"
        size="small"
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 1200 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
        loading={loading}
        locale={{ emptyText: "Không có nhà phân phối" }}
      />
      <DistributorModal
        visible={isOpenDistributorModal}
        mode={distributorModalMode}
        onCancel={handleCancelDistributorModal}
        dataItem={selectedDistributor}
        onReloadPage={handleReloadPage}
        listProvince={province}
      />
      <ConfirmModal
        title="Xóa nhà phân phối"
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeleteDistributor}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa{" "}
          <strong>{selectedDistributor?.distributorName}</strong> không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleRejectToDeleteDistributor}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeleteDistributor}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Card>
  );
};
export default DistributorInformationPage;
