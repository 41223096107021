/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Modal Thêm/Sửa nhân viên
 * */

import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  message,
  Upload,
} from "antd";
import PropTypes from "prop-types";
import utils from "utils";
import services from "services";
import BaseSelect from "components/selects/baseSelect";
import { map } from "lodash";
import moment from "moment";
import {
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const UserModal = function ({ config, onCancel, listDistributor }) {
  const { visible, dataSource, mode } = config;
  const [form] = Form.useForm();
  const [fetchingSaveUser, setFetchingSaveUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUploadData, setImageUploadData] = useState({});

  const [variableFile, setVariableFile] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [AvtImg, setAvtImg] = useState("");

  const handleBrowseRoles = useCallback((search) => {
    return services.organization.titleDefinition_GetBsWithPagination(1, 100);
  }, []);
  const handleConfirm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleSubmit = useCallback(
    (values) => {
      let imgData;
      if (mode === utils.$const.COMMON.MODAL_MODE.ADD) {
        if (variableFile !== null && variableFile !== undefined && variableFile !== ""){
          services.fileManagement
          .photo_PostPhotoAttachment(variableFile, "Photo", "Products")
          .then((res) =>{
            imgData = res.data;
            createUser(values, imgData);
          });
        } else {
          createUser(values, imgData);
        }
      } else {
        setFetchingSaveUser(true);
        services.userManagementService
          .profile_UpdateUserProfile({
            userId: dataSource.identifier?.userId,
            status: values.status,
            userCode : values.userCode,
            fullName: values.fullName,
            email : values.email,
            phoneNumber : values.phoneNumber,
            address : values.address,
            avatar : dataSource.avatar,
            birthDay: (values.birthDay !== undefined && values.birthDay !== null) ? moment(values.birthDay).format("YYYY-MM-DD") : undefined,
            startDate: (values.startDate !== undefined && values.startDate !== null) ? moment(values.startDate).format("YYYY-MM-DD") : undefined,
            endDate: (values.endDate !== undefined && values.endDate !== null) ? moment(values.endDate).format("YYYY-MM-DD") : undefined,
            genderType : values.genderType,
            titleReferenceId: values.titleReferenceObj?.value,
            distributorId: values.distributorObj?.value,
            //not use yet
            lastName : "",
            sirName : "",
          })
          .then((res) => {
            message.success("Chỉnh sửa tài khoản thành công.");
            form.resetFields();
            setTimeout(onCancel, 500);
          })
          .catch((error) =>
            message.error("Chỉnh sửa tài khoản không thành công.")
          )
          .finally(() => {
            setFetchingSaveUser(false);
          });
      }
    },
    [variableFile, mode, form, onCancel, dataSource.identifier?.userId]
  );

  const createUser = (values, imgData) => {
    services.userManagementService
    .admin_CreateUser([
      {
        ...values,
        avatar: imgData,
        titleReferenceId: values.titleReferenceObj?.value,
        distributorId: values.distributorObj?.value,
        lastName : "",
        sirName : "",
      }
    ])
    .then((res) => {
      message.success("Tạo mới tài khoản thành công.");
      form.resetFields();
      setTimeout(onCancel, 500);
    })
    .catch((error) =>
      message.error("Tạo mới tài khoản không thành công.")
    )
    .finally(() => {
      setFetchingSaveUser(false);
    });
  }

  const handleCancelModal = () => {
    form.resetFields();
    onCancel();
  };
  const handleChangeUploadImage = (info) => {
    const reader = new FileReader();
    reader.addEventListener("load", (imageUrl) => {
      setImageUrl(reader.result);
    });
    reader.readAsDataURL(info.file);
    const fileToUpload = {
      data: info.file,
      fileName: info.file.name,
    };
    setImageUploadData(fileToUpload);
  };
  const handleBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Vui lòng chọn ảnh có định dạng JPG hoặc PNG");
    }
    return false;
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (dataSource?.avatar !== "") {
      setImageUrl(dataSource.avatar);
    }
    if (visible && mode === utils.$const.COMMON.MODAL_MODE.EDIT) {
      // set dữ liệu trong dataSource vào form
      form.setFieldsValue({
        id: dataSource.id,
        currentState: dataSource.currentState,
        userName: dataSource.userName,
        fullName: dataSource.fullName,
        status: dataSource.status,
        email: dataSource.identifier?.email,
        phoneNumber: dataSource.identifier?.phoneNumber,
        address: dataSource.address,
        userCode: dataSource.userCode,
        genderType: dataSource.genderType,
        birthDay: utils.$datetime.stringToDateTime(dataSource.birthDay),
        startDate: utils.$datetime.stringToDateTime(dataSource.startDate),
        endDate: utils.$datetime.stringToDateTime(dataSource.endDate),
        titleReferenceObj: {
          value: dataSource.titleReferenceId,
          label: dataSource.titleReferenceName,
        },
        distributorObj: {
          value: dataSource.distributorId,
          label: dataSource.distributorName,
        },
      });
    }
  }, [dataSource, visible, mode, form]);

  const renderTitle = () =>
    mode === utils.$const.COMMON.MODAL_MODE.ADD
      ? `Thêm mới nhân viên`
      : "Chỉnh sửa nhân viên";

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isAccept = true;
    return isJpgOrPng && isAccept;
  };
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
    const fileToUpload = {
      data: img,
      fileName: img.name,
    };
    setVariableFile(fileToUpload);
    if (
      variableFile != undefined &&
      variableFile != null &&
      variableFile != ""
    ) {
      services.fileManagement
        .photo_PostPhotoAttachment(variableFile, "Photo", "profiles")
        .then((res) => {
          if (res.succeeded == true) {
            setAvtImg(res.data);
          }
        });
    }
  }
  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => setImageUrl(imageUrl));
  };
  
  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      width={1024}
      onCancel={handleCancelModal}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleConfirm}
          loading={fetchingSaveUser}
        >
          Xác nhận
        </Button>,
        <Button key="back" onClick={handleCancelModal}>
          Quay lại
        </Button>,
      ]}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="horizontal"
        labelCol={{ span: 8 }}
        labelAlign="left"
        initialValues={{
          avatar: [],
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span="10">
            <Form.Item
              name="userCode"
              label="Mã nhân viên"
              rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <Input placeholder="Nhập nhân viên" />
            </Form.Item>
            <Form.Item
              name="fullName"
              label="Họ và tên"
              rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <Input placeholder="Nhập họ và tên" />
            </Form.Item>
          </Col>
          <Col span="14">
            <Form.Item
              labelCol={{ span: 10 }}
              name="email"
              label="Email"
              rules={[
                { require: true, type: "email" },
                { required: true, message: "Vui lòng nhập" },
                {
                  type: "email",
                  message: "Sai định dạng",
                },
              ]}
            >
              <Input
                disabled={mode === utils.$const.COMMON.MODAL_MODE.EDIT}
                placeholder="Nhập địa chỉ email"
              />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 10 }}
              name="distributorObj"
              label="Nhà phân phối/ Tổ chức"
              // rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <Select placeholder='Chọn nhà phân phối' 
                showSearch
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                labelInValue
                allowClear>
                {listDistributor?.map((distributor, id) => (
                  <Select.Option key={id} value={distributor?.id}>
                    {distributor?.distributorName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span="10">
            <Form.Item
              name="titleReferenceObj"
              label="Chức danh"
            >
              <BaseSelect
                searchName="titleReferenceObj"
                itemValue="id"
                itemLabel="titleName"
                placeholder="Chọn chức danh"
                searchOnMount
                labelInValue
                onSearch={handleBrowseRoles}
              />
            </Form.Item>
          </Col>
          <Col span="14">
            <Form.Item
              name="phoneNumber"
              label="Điện thoại"
              labelCol={{ span: 10 }}
              rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <Input placeholder="Nhập điện thoại" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span="10">
            <Form.Item
              name="address"
              label="Địa chỉ"
            // rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <Input placeholder="Nhập địa chỉ" />
            </Form.Item>
          </Col>
          <Col span="14">
            <Form.Item
                labelCol={{ span: 10 }}
                name="genderType"
                label="Giới tính"
                rules={[{ required: true, message: "Vui lòng nhập" }]}
              >
                <Select placeholder="Chọn giới tính">
                  <Select.Option value={0}>Nam</Select.Option>
                  <Select.Option value={1}>Nữ</Select.Option>
                  <Select.Option value={2}>Khác</Select.Option>
                </Select>
              </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span="10">
            <Form.Item
              name="birthDay"
              label="Ngày sinh"
            // rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <DatePicker
                placeholder="Chọn ngày sinh"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="status"
              label="Trạng thái"
              rules={[{ required: true, message: "Vui lòng chọn" }]}
            >
              <Select placeholder="Chọn trạng thái">
                {map(utils.$const.USER.STATUS, (item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="14">
            <Form.Item
              labelCol={{ span: 10 }}
              name="startDate"
              label="Ngày vào làm"
            >
              <DatePicker
                placeholder="Chọn ngày vào làm"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 10 }}
              name="endDate"
              label="Ngày nghỉ việc"
            >
              <DatePicker
                placeholder="Chọn ngày nghỉ việc"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8,8]}>

          <Col span={12}>
            <div className="uploadImg">
              <Form.Item
                label="Ảnh Đại Diện"
                labelCol={{ span: 6 }}
                className="uploadImg"
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                    {imageUrl ? (
                      <img
                        className="avtCustom"
                        style={{
                          marginTop:30
                        }}
                        src={imageUrl}
                        alt="avatar"
                      />
                    ) : (
                      uploadButton
                    )}
                </Upload>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Form.Item hidden name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};
UserModal.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
};
export default UserModal;
