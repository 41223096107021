import React, { memo, useCallback } from "react";
import { Row, Col, Select, DatePicker, Button } from "antd";
import _ from "lodash";
//import moment from "moment";
import styled from "styled-components";
import classNames from "classnames";
import { SearchOutlined } from "@ant-design/icons";
import services from "services";

const { Option } = Select;

const Fillter = memo(
  ({ className, params, setParams, data, dataFilter, setFilter, filter,setDataFilter }) => {
    const getQuery = useCallback(
      (value, name) => {
        setParams((preState) => {
          let nextState = { ...preState };
          nextState[name] = value;
          return nextState;
        });
      },
      [params]
    );
    const getFilter = () => {
      setFilter(!filter);
    };

    // const onDate = (dates, dateStrings) => {
    //   if (_.isArray(dates)) {
    //     let dateTo = dates[0];
    //     let dateFrom = dates[1];
    //     getQuery(dateTo, "fromDate");
    //     getQuery(dateFrom, "toDate");
    //   } else {
    //     let resetTo = moment().startOf("week");
    //     let resetFrom = moment().endOf("week");
    //     getQuery(resetTo, "fromDate");
    //     getQuery(resetFrom, "toDate");
    //   }
    // };
    const dataFilterNpp = useCallback((id) => {
      // danh sach npp
      services.generalService
        .distributor_GetDistributorByProvince({provinceId:id})
        .then((res) => {
          let arrData = _.map(res, (item, index) => {
            item.index = index + 1;
            item.name = _.get(item, "distributorName");
            return item;
          });
          setDataFilter((preState) => {
            let nextState = { ...preState };
            nextState.arrNpp = arrData;
            nextState.arrUser = [];
            return nextState;
          });
          setParams((preState) => {
            let nextState = { ...preState };
            nextState.staffId = undefined;
            nextState.distributorId = undefined;
            return nextState;
          });
        })
        .catch((error) => {});
    }, []);
    // const dataFilterUser = useCallback((id) => {
    //   // danh sach user
    //   services.userManagementService
    //     .resource_GetByDistributorId(id)
    //     .then((res) => {
    //       let arrData = _.map(res, (item, index) => {
    //         item.index = index + 1;
    //         item.name = _.get(item, "fullName");
    //         item.id = _.get(item, "userId");
    //         return item;
    //       });
    //       setDataFilter((preState) => {
    //         let nextState = { ...preState };
    //         nextState.arrUser = arrData;
    //         return nextState;
    //       });
    //     })
    //     .catch((error) => {});
    // }, []);
    return (
      <div>
        <Row
          gutter={15}
          className={classNames({
            [className]: true,
          })}
        >
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Tỉnh</span>
            <Select
              style={{ width: "100%", marginTop: 10 }}
              placeholder="Tỉnh"
              allowClear
              onChange={(e) => {
                dataFilterNpp(e)
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(_.get(dataFilter, "arrUserProvice"), (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Nhà phân phối</span>
            <Select
              style={{ width: "100%", marginTop: 10 }}
              placeholder="Nhà phân phối"
              allowClear
              onChange={(e) => {
                getQuery(e, 'distributorId')
                // dataFilterUser(e)
              }}
              value={_.get(params, 'distributorId')}

              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(_.get(dataFilter, "arrNpp"), (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          {/* <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Nhân viên</span>
            <Select
              value={_.get(params, "staffId")}
              style={{ width: "100%", marginTop: 10 }}
              placeholder="Nhân viên"
              allowClear
              onChange={(e) => {
                getQuery(e, "staffId");
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(_.get(dataFilter, "arrUser"), (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col> */}
          {/* <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Khách hàng</span>
            <Select
              value={_.get(params, "customerId")}
              style={{ width: "100%", marginTop: 10 }}
              placeholder="Khách hàng"
              allowClear
              onChange={(e) => {
                getQuery(e, "customerId");
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(_.get(dataFilter, "arrCustomer"), (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col> */}
          
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Ngày xem</span>
            <DatePicker
              placeholder="Ngày xem"
              style={{ width: "100%",marginTop: 10 }}
              value={params.checkDate}
              format={"DD-MM-YYYY"}
              onChange={(e) => {
                getQuery(e, "checkDate");
              }}
            />
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 5 }}>
          <Col>
            <Button
              type="primary"
              onClick={getFilter}
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
);
export default styled(Fillter)`
  .ant-picker {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .clearParams {
    text-align: right;
  }
`;
