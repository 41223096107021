/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 07/07/2021.
 * @description Modal Thêm/Sửa danh sách menu
 * */

import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, message, Select, Row, InputNumber } from "antd";
import PropTypes from "prop-types";
import services from "services";
import utils from "utils";
import {map, get, toNumber} from "lodash";
import BaseSelect from "components/selects/baseSelect";
import Text from "antd/lib/typography/Text";

const SubMenuControlModal = function ({ config, onCancel, onReloadPage}) {
  const { visible, dataSource, mode } = config;
  const [form] = Form.useForm();
  const [isContinue, setIsContinue] = useState(false);
  const [permission, setPermission] = useState([]);
  const [fatherMenu, setFatherMenu] = useState([]);

  const handleConfirm = useCallback(() => {
      setIsContinue(false);
      setTimeout(() => form.submit(), 400);
    },[form]);

  const handleConfirmAndContinue = useCallback(() => {
    setIsContinue(true);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleSubmit = useCallback(
    (values) => {
      if (mode === utils.$const.COMMON.MODAL_MODE.ADD) {
        services.accessManagementService
          .subMenu_Create({
            ...values,
            menuDefinitionId : values.menuDefinitionIdObj?.value,
            permissionId: values.permissionIdObj?.value,
            // unused
            permissionIdObj: undefined,
            menuDefinitionIdObj: undefined,
          })
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            if(isContinue){
              var index = form.getFieldValue("index");
              form.resetFields();
              form.setFieldsValue("index", toNumber(index) +1);
            }else{
              onCancel();
              form.resetFields();
            }
          })
          .catch((error) =>{
            message.error("Lưu không thành công, hãy thử lại.");
          });
      } else {
        services.accessManagementService
          .subMenu_Update({
            ...values,
            permissionId: values.permissionIdObj?.value,
            menuDefinitionId: values.menuDefinitionIdObj?.value,
            // unused
            permissionIdObj: undefined,
          })
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onReloadPage();
            onCancel();
            form.resetFields();
          })
          .catch((error) =>{
            message.error("Lưu không thành công, hãy thử lại.");
          });
      }
    },
    [mode, isContinue, form, onCancel, onReloadPage]
  );

  const renderTitle = () =>
    mode === utils.$const.COMMON.MODAL_MODE.ADD
      ? `Thêm mới Sub-Menu`
      : ` Chỉnh sửa Sub-Menu`;

  const handleCloseModal = useCallback(() => {
      onCancel && onCancel();
      form.resetFields();
    }, [form, onCancel]);

  useEffect(() => {
    if (visible && mode === utils.$const.COMMON.MODAL_MODE.EDIT) {
      // set dữ liệu trong dataSource vào form
      let findPermission = permission.find((item) => item.id === dataSource?.permissionId);
      let findFatherMenu = fatherMenu.find((item) => item.id === dataSource?.menuDefinitionId);
      form.setFieldsValue({
        id: dataSource.id,
        status: dataSource.status,
        currentState: dataSource.currentState,
        subMenuCode: dataSource.subMenuCode,
        subMenuName: dataSource.subMenuName,
        subMenuPath: dataSource.subMenuPath,
        index: dataSource.index,
        description: dataSource.description,
        permissionIdObj: {
          label: findPermission?.permissionName,
          value: findPermission?.id,
        },
        menuDefinitionIdObj: {
          label: findFatherMenu?.menuName,
          value: findFatherMenu?.id,
        },
      });
    }
  }, [dataSource, visible, mode, form, permission, fatherMenu]);

  useEffect(() => {
    services.accessManagementService.permissionDefinition_GetBsWithPagination(1,1000)
        .then((res)=>{
          setPermission(res.items);
        });
  },[]);
  
  useEffect(() => {
    services.accessManagementService.mainMenu_GetBsWithPagination(1,1000)
        .then((res)=>{
          setFatherMenu(res.items);
        });
  },[]);

  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      onCancel={onCancel}
      footer={[
        <Form.Item>
          <Button onClick={handleConfirm} type="primary">
            {dataSource?.id ? "Sửa" : "Thêm"}
          </Button>
          {mode === utils.$const.COMMON.MODAL_MODE.ADD ?
            <Button
              title='Thêm và tiếp tục'
              key='submitAndContinute'
              type="primary"
              onClick={handleConfirmAndContinue}
            >
              Thêm và tiếp tục
            </Button> : ''
          }
          <Button type='default' onClick={handleCloseModal}>
            Quay lại
          </Button>
        </Form.Item>
      ]}
    >
      <Form onFinish={handleSubmit} form={form} initialValues={{index: 0, status: 1}} labelAlign="left">
        <Form.Item
          label="Thứ tự"
          required
          name="index"
          rules={[{ required: true, message: "Mã sản phẩm không được trống" }]}
          labelCol={{ span: 6 }}
        >
          <InputNumber placeholder="Nhập thứ tự" />
        </Form.Item>
        <Form.Item
          label="Mã màn hình"
          required
          name="subMenuCode"
          rules={[{ required: true, message: "Mã sản phẩm không được trống" }]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Nhập mã màn hình" />
        </Form.Item>
        <Form.Item
          label="Tên màn hình"
          name="subMenuName"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Nhập tên màn hình" />
        </Form.Item>
        <Form.Item
          label="Đường dẫn"
          name="subMenuPath"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Input
            placeholder="Nhập đường dẫn"
            addonBefore="http://*/"
            addonAfter=""
          />
        </Form.Item>
        <Form.Item
          label="Phân quyền"
          name="permissionIdObj"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Select labelInValue allowClear>
            {permission.map((item)=>(
              <Select.Option key={item.id} value={item.id}>{item.permissionName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="status"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Select defaultValue={1} placeholder="Chọn trạng thái">
            {map(utils.$const.MENU_CONTROL.STATUS, (stat) => (
              <Select.Option key={stat.id} value={stat.id}>
                {stat.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item label="Mô tả" name="description" labelCol={{ span: 6 }}>
          <Input.TextArea placeholder="Nhập ghi chú" rows={5} />
        </Form.Item> */}
        <Form.Item
          label="Thuộc Menu"
          name="menuDefinitionIdObj"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
          shouldUpdate={(prevValues, curValues) =>
            prevValues.menuDefinitionIdObj !== curValues.menuDefinitionIdObj
            }
        >
          <Select labelInValue allowClear>
            {fatherMenu.map((item)=>(
              <Select.Option key={item.id} value={item.id}>{item.menuName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* Hidden Form Item */}
        <Form.Item hidden name="id">
          <Input disabled></Input>
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
SubMenuControlModal.propTypes = {
  config: PropTypes.any,
  onCancel: PropTypes.func,
};

export default SubMenuControlModal;
