import React, { memo, useState, useEffect, useCallback } from "react";
import { Spin, Card, Divider } from "antd";
import _ from "lodash";
import List from "./List/index";
import Fillter from "./Fillter/index";
import Pagination from "components/Paginate/index";
import services from "services";
import { useLocation } from "react-router-dom";
import moment from "moment";
let time = null;

const Index = memo(() => {
  let location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [dataFilter, setDataFilter] = useState({
    arrProblem: [],
    arrCustomer: [],
    arrUser: [],
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [totalLength, setTotalLength] = useState(0);
  const [visible, setVisible] = useState({
    isShow: false,
    isShowCreate: false,
    type: "create",
    data: {},
    row: {},
    index: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState({
    data: [],
    key: [],
  });
  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [filter, setFilter] = useState({
    customerId: undefined,
    problemId: undefined,
    pageNumber: 1,
    pageSize: 10,
    filter: false,
  });
  const getProducts = useCallback(() => {
    if (id || _.get(filter, "customerId")) {
      setLoading(true);
      services.customerService
        .visitComment_GetBsWithPagination({
          customerId: id ? id : _.get(filter, "customerId"),
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        })
        .then((res) => {
          setLoading(false);
          let arrData = _.map(_.get(res, "items"), (item, index) => {
            item.key = index + 1;
            item.comments = _.get(item, "comments");
            item.createTimeCommet =
              item.createTime &&
              moment(item.createTime).format("DD-MM-YYYY HH:mm");
            return item;
          });
          setData(arrData);
          setTotalLength(_.get(res, "totalCount"));
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    // danh sách vấn đề
    services.generalService
      .problemType_GetListProblem()
      .then((res) => {
        let arrData = _.map(res, (item, index) => {
          item.index = index + 1;
          item.id = _.get(item, "id");
          item.name = _.get(item, "problemTypeName");
          return item;
        });
        setDataFilter((preState) => {
          let nextState = { ...preState };
          nextState.arrProblem = arrData;
          return nextState;
        });
      })
      .catch((error) => {});

    // danh sach khách hàng
    services.generalService
      .customerInformation_GetListCustomer()
      .then((res) => {
        let arrData = _.map(res, (item, index) => {
          item.index = index + 1;
          item.id = _.get(item, "id");
          item.name = _.get(item, "customerName");
          return item;
        });
        setDataFilter((preState) => {
          let nextState = { ...preState };
          nextState.arrCustomer = arrData;
          return nextState;
        });
      })
      .catch((error) => {});

    // danh sach nv
    services.organization
      .humanResource_GetAll()
      .then((res) => {
        let arrData = _.map(res?.items, (item, index) => {
          item.index = index + 1;
          item.id = _.get(item, "userId");
          item.name = _.get(item, "fullName");
          return item;
        });
        setDataFilter((preState) => {
          let nextState = { ...preState };
          nextState.arrUser = arrData;
          return nextState;
        });
      })
      .catch((error) => {});
  }, [params]);
  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(getProducts, 800);
  }, [getProducts]);

  return (
    <Spin spinning={loading} tip="Đang lấy dữ liệu...">
      <Card className="ProductBrand-card">
        <Divider style={{ margin: 10 }} />
        {/* khi không có param id thì hiển thị filter */}
        <Fillter
          params={params}
          setParams={setParams}
          setLoading={setLoading}
          visible={visible}
          setVisible={setVisible}
          totalLength={totalLength}
          data={data}
          id={id}
          setData={setData}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          setTotalLength={setTotalLength}
          filter={filter}
          setFilter={setFilter}
          setDataFilter={setDataFilter}
          dataFilter={dataFilter}
        />
        <Divider style={{ margin: 10 }} />
        <List
          loading={loading}
          setParams={setParams}
          totalLength={totalLength}
          visible={visible}
          setVisible={setVisible}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          params={params}
          data={data}
          setData={setData}
          filter={filter}
          id={id}
          setFilter={setFilter}
          setDataFilter={setDataFilter}
          dataFilter={dataFilter}
        />
        <Pagination
          className="mt_10 mb_10 d-flex justify-content-end"
          total={totalLength}
          setParams={setParams}
          params={params}
        />
      </Card>
    </Spin>
  );
});

export default Index;
