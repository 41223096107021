import React, { memo, useCallback, useState } from "react";
import { Row, Col, Select, Button, DatePicker, message } from "antd";
import _ from "lodash";
import services from "services";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import classNames from "classnames";
import moment from "moment";
import {saveFileAs} from 'utils/excel';

const { Option } = Select;
const dateFormat = "DD-MM-YYYY";

const fillter = memo(({ 
  paging,
  setPaging,
  params,
  setParams,
  setFilter,
  dataFilter,
  filter,
  loading,
  setLoading
}) => {

  const [selectedRegionId, setSelectedRegionId] = useState(undefined);
  const [selectedProvinceId, setSelectedProvinceId] = useState();
  const [selectedStaffId, setSelectedStaffId] = useState();
  const [filteredProvinces, setFilteredProvinces] = useState([
    ..._.get(dataFilter, 'arrProvinces'),
  ]);
  const [filteredStaffs, setFilteredStaffs] = useState([
    ..._.get(dataFilter, 'arrStaffs')
  ]);

  // useEffect(() => {
  //   var regions = _.get(dataFilter, 'arrBusinessRegions');
  //   var provinces = _.get(dataFilter, 'arrProvinces');
  //   var staffs = _.get(dataFilter, 'arrStaffs');
  //   if(regions && regions.length > 0 && provinces && provinces.length > 0 && staffs && staffs.length > 0){
  //     let defaultRegionId = _.get(regions, "[0].id");
  //     filterProvinces(defaultRegionId);
  //     //load kpis after selecting default region on beginning
  //     setFilter(!filter);
  //   }
  // }, [dataFilter]);

  const getQuery = useCallback(
    (value, name) => {
      setParams((preState) => {
        let nextState = { ...preState };
        nextState[name] = value;
        return nextState;
      });
    },
    [params]
  );

  const getFilter = () => {
    setPaging({ page: 1, pageSize: paging.pageSize });
    setFilter(!filter);
  };
  
  const filterProvinces = (value) => {
    if (value) {
      let arrProvinces = _.filter(
        _.get(dataFilter, 'arrProvinces'),
        (item, index) => {
          return item?.businessRegionId === value;
        },
      )
      setSelectedProvinceId(null);
      setFilteredProvinces(arrProvinces);
      
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.regionId === value;
        },
      )
      setSelectedStaffId(null);
      setFilteredStaffs(arrStaffs);
    } else {
      setFilteredProvinces([..._.get(dataFilter, 'arrProvinces')])
      setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')])
    }
    setSelectedRegionId(value);
  }

  const filterStaffs = (value) => {
    if (value) {        
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.provinceId === value;
        },
      )
      setSelectedStaffId(null);
      setFilteredStaffs(arrStaffs);
    } else {
      if (selectedRegionId) {
        let arrStaffs = _.filter(
          _.get(dataFilter, 'arrStaffs'),
          (item, index) => {
            return item?.regionId === selectedRegionId;
          },
        )
        setFilteredStaffs(arrStaffs);
      } else {
        setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
      }
    }
    setSelectedProvinceId(value);
  }

  const exportExcel = () => {
    setLoading(true);
    services.attendanceService
      .attendanceTracking_ExportReportAttendanceTracking({
        regionId: params.regionId,
        provinceId: params.provinceId,
        staffId: params.staffId,
        toDate: params.toDate && params.toDate.format(),
        pageNumber: paging.page,
        pageSize: paging.pageSize,
      })
      .then(function(response) {
        saveFileAs(response.data, 'BC chấm công '+ moment().format("DD-MM-YYYY") + '.xlsx');
      })
      .catch((error) => {
        message.error('Có lỗi khi xuất excel');
      }).finally(()=> setLoading(false));
  };

  return (
    <>
      <Row
        gutter={[15, 15]}
        className={classNames({
          [classNames]: true,
        })}
      >
        <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
          <span>Khu vực</span>
          <Select
            style={{ width: "100%" }}
            placeholder="Khu vực"
            allowClear
            value={selectedRegionId}
            onChange={(value) => {
              getQuery(value, "regionId");
              filterProvinces(value);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {_.map(_.get(dataFilter, "arrBusinessRegions"), (item, index) => {
              return (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
          <span>Tỉnh</span>
          <Select
            style={{ width: "100%" }}
            placeholder="Tỉnh"
            allowClear
            value={selectedProvinceId}
            onChange={(value) => {
              getQuery(value, "provinceId");
              filterStaffs(value);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {_.map(filteredProvinces, (item, index) => {
              return (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
          <span>Nhân viên</span>
          <Select
            style={{ width: "100%" }}
            placeholder="Nhân viên"
            allowClear
            value={selectedStaffId}
            onChange={(value) => { 
              getQuery(value, "staffId");
              setSelectedStaffId(value);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {_.map(filteredStaffs, (item, index) => {
              return (
                <Option value={item.userId} key={item.userId}>
                  {item.fullName}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
          <span>Đầu tháng đến ngày</span>
          <DatePicker
            value={params.toDate}
            allowClear={false}
            style={{ width: "100%", float: "right" }}
            format={dateFormat}
            onChange={(value) => {
              if (value) {
                getQuery(value, "toDate");
              } else {
                let setDate = moment().format("YYYY-MM-DD");
                getQuery(setDate, "toDate");
              }
            }}
          />
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: 5,marginBottom:10 }}>
        <Col>
          <Button
            type="primary"
            disabled={loading}
            onClick={getFilter}
            icon={<SearchOutlined />}>
              Tìm kiếm
          </Button>
        </Col>
        <Col className='ml-2'>
          <Button
            type='dashed'
            disabled={loading}
            onClick={exportExcel}
          >
            <span className='d-flex align-items-center'>
              <DownloadOutlined /> <span className='ml-1'>Xuất file excel</span>
            </span>
          </Button>
        </Col>
      </Row>
    </>
  );
});
export default fillter;
