import { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Button,
  Divider,
  Tabs,
  message,
  Table,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
//import { map } from 'lodash';
import utils from 'utils';
// import OrderManagement from 'components/staticShippingOrder/OrderManagement';
// import CustomerManagement from 'components/staticShippingOrder/CustomerManagement';
// import ProductManagement from 'components/staticShippingOrder/ProductManagement';
import moment from 'moment';
import services from 'services';

const { RangePicker } = DatePicker;

const { TabPane } = Tabs;

const StatisticPurchaseOrderPage = () => {
  const [form] = Form.useForm();
  //const [params, setParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  
  const [productItems, setProductItems] = useState([]);
  const [selectTab, setSelectTab] = useState(0);
  const [isPurchase, setPurchase] = useState(false);
  const [isSaler, setSaler] = useState(false);
  const [isCustomer, setCustomer] = useState(true);
  const [isProduct, setProduct] = useState(true);
  const [listSaler, setListSaler] = useState([]);
  const [fromToDate, setFromToDate] = useState({
    fromDate: utils.$datetime.currentFirstDate(),
    toDate: utils.$datetime.currentLastDate(),
  });
  const [customerItems, setCustomerItems] = useState([]);

  const [listPurchaseOrder, setListPurchaseOrder] = useState([]);
  const [listOrderByProduct, setListOrderByProduct] = useState([]);
  const [listOrderByCustomer, setListOrderByCustomer] = useState([]);

  const summaryColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'purchaseId',
      key: 'purchaseId',
      title: 'Mã phiếu',
      width: 150,
    },
    {
      dataIndex: 'orderDate',
      key: 'orderDate',
      title: 'Ngày tạo',
      align: 'center',
      width: 150,
      render: (value) => (value ? moment(value).format('DD-MM-YYYY') : ''),
    },
    {
      dataIndex: 'saler',
      key: 'saler',
      title: 'Người tạo',
      align: 'center',
      width: 150,
      render: (_, row) => row?.saler?.fullName,
    },
    {
      dataIndex: 'shippingEstDate',
      key: 'shippingEstDate',
      title: 'Ngày giao',
      align: 'center',
      width: 150,
      render: (value) => (value ? moment(value).format('DD-MM-YYYY') : ''),
    },
    {
      dataIndex: 'customerCode',
      key: 'customerCode',
      title: 'Mã khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'customerName',
      key: 'customerName',
      title: 'Tên khách hàng',
      width: 150,
      align: 'center',
    },
    {
      dataIndex: 'distributorCode',
      key: 'distributorCode',
      title: 'Mã nhà phân phối',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'distributorName',
      key: 'distributorName',
      title: 'Tên nhà phân phối',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'warehouseCode',
      key: 'warehouseCode',
      title: 'Mã kho xuất',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      title: 'Tên kho xuất',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'productCode',
      key: 'productCode',
      title: 'Mã sản phẩm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: 'Tên sản phẩm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'orderQuantity',
      key: 'orderQuantity',
      title: 'Số lượng',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'salePrice',
      key: 'salePrice',
      title: 'Đơn giá',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'unitTypeName',
      key: 'unitTypeName',
      title: 'Đơn vị tính',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'notes',
      key: 'notes',
      title: 'Thành tiền',
      align: 'right',
      width: 150,
      render: (_, row) =>
        row?.orderQuantity && row?.salePrice
          ? utils.$number.numberFormatter(row?.orderQuantity * row?.salePrice)
          : 0,
    },
    {
      dataIndex: 'vat',
      key: 'vat',
      title: 'Tiền VAT',
      align: 'right',
      width: 150,
      render: (value) =>
        value ? `${utils.$number.numberFormatter(value)}%` : '0%',
    },
    {
      dataIndex: 'vatAmount',
      key: 'vatAmount',
      title: 'Tổng tiền VAT',
      align: 'right',
      width: 150,
      render: (_, row) =>
        row?.orderQuantity && row?.vat
          ? utils.$number.numberFormatter((row?.orderQuantity * row?.vat) / 100)
          : 0,
    },
    {
      dataIndex: 'discount',
      key: 'discount',
      title: 'Tiền khuyến mãi',
      align: 'right',
      width: 150,
      render: (value) =>
        value ? `${utils.$number.numberFormatter(value)}%` : '0%',
    },
    {
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      title: 'Tổng tiền khuyến mãi',
      align: 'right',
      width: 200,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Trạng thái',
      align: 'center',
      width: 150,
    },
  ];

  const customerColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      width: 50,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'customerCode',
      key: 'customerCode',
      title: 'Mã khách hàng',
      width: 125,
    },
    {
      dataIndex: 'customerName',
      key: 'customerName',
      title: 'Tên Khách hàng',
      width: 125,
    },
    {
      dataIndex: 'productCode',
      key: 'productCode',
      title: 'Mã sản phẩm',
      align: 'center',
      width: 125,
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: 'Tên sản phẩm',
      align: 'center',
      width: 125,
    },
    {
      dataIndex: 'orderQuantity',
      key: 'orderQuantity',
      title: 'Số lượng',
      align: 'right',
      width: 125,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      title: 'Thành tiền',
      align: 'right',
      width: 125,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'vatAmount',
      key: 'vatAmount',
      title: 'Tổng tiền VAT',
      align: 'right',
      width: 125,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      title: 'Tổng tiền khuyến mãi',
      align: 'right',
      width: 125,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
  ];

  const productColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'productCode',
      key: 'productCode',
      title: 'Mã sản phẩm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: 'Tên sản phẩm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'orderQuantity',
      key: 'orderQuantity',
      title: 'Số lượng',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      title: 'Tổng tiền',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'vatAmount',
      key: 'vatAmount',
      title: 'Tổng tiền VAT',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      title: 'Tổng tiền khuyến mãi',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
  ];
  
  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    },
    [paging?.pageSize]
  );

  const handleConfirmfilter = useCallback(() => {
    form.submit();
  }, [form]);

  const handleSelectTab = useCallback(
    (value) => {
      setSelectTab(value);
      if(value ==="0"){
        setPurchase(false);
        setSaler(false);
        setCustomer(true);
        setProduct(true);
      }else if(value==="1"){
        setPurchase(true);
        setSaler(true);
        setCustomer(false);
        setProduct(true);
      }else if(value==="2"){
        setPurchase(true);
        setSaler(true);
        setCustomer(true);
        setProduct(false);
      }
      form.resetFields();
    },
    [form]
  );
  
  const handleChangeRangePicker = useCallback((_, dateString) => {
    setFromToDate({
      fromDate: dateString?.[0],
      toDate: dateString?.[1],
    });
  }, [setFromToDate]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    let fromDate = form.getFieldValue('date')[0];
    let toDate = form.getFieldValue('date')[1];
    let customer = form.getFieldValue('customerObj');
    let saler = form.getFieldValue('salerObj');
    let product = form.getFieldValue('productObj');
    let purchase = form.getFieldValue('purchaseId');
    let params = {
      fromDate: fromDate,
      toDate: toDate,
      customerId: customer?.key,
      salerId: saler?.key,
      productCode: product?.key,
      purchaseId: purchase,
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
    };

    if (selectTab === '0') {
      // Thống kê đơn hàng
      services.satatisticReportService
        .order_OrderReport(params)
        .then((res) => {
          setIsLoading(false);
          setTotal(res?.totalCount ?? 0);
          setListPurchaseOrder(res.items);
          if (res.items.length === 0) {
            message.error('Không tìm thấy sản phẩm');
          } else {
            message.success('Tìm kiếm thành công');
          }
        })
        .catch((err) => {
          setTotal(0);
          setListPurchaseOrder([]);
          setIsLoading(false);
        });
    } else if (selectTab === '1') {
      // Thống kê theo khách hàng
      services.satatisticReportService
        .order_OrderByCustomerReport(params)
        .then((res) => {
          setListOrderByCustomer(res.items);
          setIsLoading(false);
          if (res.items.length === 0) {
            message.error('Không tìm thấy sản phẩm');
          } else {
            message.success('Tìm kiếm thành công');
          }
        })
        .catch((err) => {
          setTotal(0);
          setListOrderByCustomer([]);
          setIsLoading(false);
        });
    } else if (selectTab === '2') {
      // Thống kê theo sản phẩm
      services.satatisticReportService
        .order_OrderByProductReport(params)
        .then((res) => {
          setListOrderByProduct(res.items);
          setIsLoading(false);
          if (res.items.length === 0) {
            message.error('Không tìm thấy sản phẩm');
          } else {
            message.success('Tìm kiếm thành công');
          }
        })
        .catch((err) => {
          setTotal(0);
          setListOrderByProduct([]);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    services.generalService
      .products_GetAllProducts()
      .then((res) => {
        setProductItems(res);
      })
      .catch((err) => {
        message.error('Không tìm thấy thông tin sản phẩm');
      });
    setTimeout(() => {}, 300);
  }, [setProductItems]);

  useEffect(() => {
    services.generalService
      .customerInformation_GetListCustomer()
      .then((res) => {
        setCustomerItems(res);
      })
      .catch((err) => {
        message.error('Không tìm thấy thông tin người bán');
      });
    setTimeout(() => {}, 300);
  }, [setCustomerItems]);

  useEffect(() => {
    services.organization.humanResource_GetAll()
      .then((res) => {
        setListSaler(res.items);
      })
      .catch((err) => {
        message.error('Không tìm thấy thông tin người bán');
      });
    setTimeout(() => {}, 300);
  }, [setListSaler]);

  return (
    <Card className='orderManagementPage'>
      <div>
        <h4>Thông tin đặt hàng</h4>
      </div>
      <Form
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        initialValues={{
          date: [moment(fromToDate.fromDate), 
                moment(fromToDate.toDate)
          ],
        }}
      >
        <Row gutter={[8]}>
          <Col lg={8} xl={4}>
            <Form.Item name='purchaseId' label='Mã đơn hàng'>
              <Input placeholder='Nhập mã đơn hàng' disabled={isPurchase}/>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='salerObj' label='Người bán'>
              <Select
                labelInValue
                allowClear
                showArrow={true}
                filterOption={false}
                disabled={isSaler}
              >
                {listSaler.map((item, id) => (
                  <Select.Option key={id} value={item.userId}>
                    {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='customerObj' label='Khách hàng'>
              <Select
                labelInValue
                allowClear
                showArrow={true}
                filterOption={false}
                disabled={isCustomer}
              >
                {customerItems.map((item) => (
                  <Select.Option key={item.id} value={item.customerCode}>
                    {item.customerName}
                  </Select.Option>
                ))}
              </Select>
              
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item label='Sản phẩm' name='productObj'>
              <Select
                labelInValue
                allowClear
                showArrow={true}
                filterOption={false}
                disabled={isProduct}
              >
                {productItems.map((item) => (
                  <Select.Option key={item.id} value={item.productCode}>
                    {item.productName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='date' label='Từ ngày - đến ngày'>
              <RangePicker
                format={utils.$const.COMMON.DATE_TIME_FORMAT['YYYY-MM-DD']}
                onChange={handleChangeRangePicker}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' gutter={[8]}>
          <Col>
            <Button
              type='primary'
              onClick={handleConfirmfilter}
              disabled={isLoading}
            >
              <span className='d-flex align-items-center'>
                <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button type='dashed'>
              <span className='d-flex align-items-center'>
                <DownloadOutlined />{' '}
                <span className='ml-1'>Xuất file Excel</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Tabs defaultActiveKey='0' type='card' onChange={handleSelectTab}>
        <TabPane tab='Thống kê theo đơn hàng' key='0'>
          {/* <OrderManagement params={params} /> */}
          <Table
            key='statisticByOrders'
            className='shippingOrderList'
            columns={summaryColumns}
            dataSource={listPurchaseOrder}
            total={listPurchaseOrder?.length ?? 0}
            loading={isLoading}
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: paging?.pageNumber ?? 1,
              pageSize: paging?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
            }}
            scroll={{ x: 3000, y: 680 }}
          />
        </TabPane>
        <TabPane tab='Thống kê theo khách hàng' key='1'>
          {/* <CustomerManagement params={params} /> */}
          <Table
            key='statisticByCustomers'
            className='shippingOrderList'
            columns={customerColumns}
            dataSource={listOrderByCustomer}
            total={listOrderByCustomer?.length ?? 0}
            loading={isLoading}
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: paging?.pageNumber ?? 1,
              pageSize: paging?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
            }}
            scroll={{ x: 2200, y: 680 }}
          />
        </TabPane>
        <TabPane tab='Thống kê theo sản phẩm' key='2'>
          {/* <ProductManagement params={params} /> */}
          <Table
            key='statisticByProducts'
            className='shippingOrderList'
            columns={productColumns}
            dataSource={listOrderByProduct}
            total={listOrderByProduct?.length ?? 0}
            loading={isLoading}
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: paging?.pageNumber ?? 1,
              pageSize: paging?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
            }}
            scroll={{ x: 1400, y: 680 }}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default StatisticPurchaseOrderPage;
