import React, { memo, useState, useEffect, useCallback } from "react";
import { Spin, Row, Col, Card } from "antd";
import _ from "lodash";
import List from "./List/index";
import Filter from "./Filter/index";
import moment from "moment";
import services from "services";

let time = null;
const quanlity = memo(({ profile }) => {
  const [dataFilter, setDataFilter] = useState({
    arrBusinessRegions: [],
    arrStaffs: [],
    arrProvinces: [],
  });
  const [params, setParams] = useState({
    toDate: moment(),
    regionId: undefined,
    provinceId: undefined,
    staffId: undefined,
  });
  const [filter, setFilter] = useState(false);
  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    arrHeader: [],
    arrColome: [],
  });


  useEffect(() => {
    // api tỉnh
    services.organization
    .province_GetAllProvincesWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });

      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrProvinces = array;
        return nextState;
      });
    })
    .catch((error) => {});

    // api nhân viên        
    services.userManagementService.resource_GetAllSalersWithPermission().then((res) => {
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrStaffs = res;
        return nextState;
      });
    });

    // api khu vực
    services.organization
    .businessRegion_GetAllRegionsWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.regionName;
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrBusinessRegions = array;
        return nextState;
      });      
    })
    .catch((error) => {});
  }, [setDataFilter, setFilter]);

  const browserLoad = useCallback(async () => {

    let endDate = params.toDate;
    let fromDate = endDate.clone().startOf("month");
    let dataMonth = endDate.diff(fromDate, "days");
    let formatData = endDate.clone().startOf("month").format("YYYY-MM-DD");
    let _i;
    let arrHeader = [];
    for (_i = 0; _i <= dataMonth; _i++) {
      let month = moment(
        moment(formatData).add(_i, "d").format("YYYY-MM-DD")
      ).format("YYYY-MM-DD");
      let arr = {
        date: month,
        dateOfWeek: month ? moment(month).format("ddd") : "",
      };
      arrHeader.push(arr);
    }
    arrHeader.unshift(
      { date: "STT", dateOfWeek: "", key: 1 },
      { date: "Mã NV", dateOfWeek: "", key: 1 },
      { date: "Tên NV", dateOfWeek: "", key: 1 },
      { date: "NPP", dateOfWeek: "", key: 1 },
      { date: "Ngày", dateOfWeek: "Thứ", key: 1 }
    );
    arrHeader.push({ date: "Tổng công", dateOfWeek: "", key: 1 });

    setLoading(true);
    services.attendanceService
      .attendanceTracking_ReportAttendanceTracking({
        regionId: params.regionId,
        provinceId: params.provinceId,
        staffId: params.staffId,
        toDate: params.toDate && params.toDate.format(),
        pageNumber: paging.page,
        pageSize: paging.pageSize,
      })
      .then(async (res) => {
        setLoading(false);
        let array = _.map(res.items, (item, index) => {
          item.data = _.remove(item.timesheet, (v, k) => {
            return k <= 1;
          });
          return item;
        });
        setData((preState) => {
          let nextState = { ...preState };
          nextState.arrColome = array;
          nextState.arrHeader = arrHeader;
          return nextState;
        });
        setTotal(res.totalCount)
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [filter,paging]);

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(browserLoad, 800);
  }, [browserLoad]);
  
  return (
    <Card>
      <Row gutter={16}>
        <Col xs={24}>
          <Filter
            paging={paging}
            setPaging={setPaging}
            params={params}
            setParams={setParams}
            dataFilter={dataFilter}
            setFilter={setFilter}
            filter={filter}
            loading={loading}
            setLoading={setLoading}
          />
        </Col>
        <Col xs={24}>
          <Spin spinning={loading} tip="Đang lấy dữ liệu...">
            <List
              data={data}
              setData={setData}
              params={params}
              setParams={setParams}
              setPaging={setPaging}
              paging={paging}
              total={total}
            />
          </Spin>
        </Col>
      </Row>
    </Card>
  );
});
export default quanlity;
