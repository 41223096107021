import { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Button,
  Table,
  message,
  Typography,
  Row,
  Col,
  Input,
  Form,
  Select,
  Upload
} from 'antd';
import { EditOutlined, SearchOutlined, PlusOutlined, LoadingOutlined, DownloadOutlined } from '@ant-design/icons';
import services from 'services';
import APP_CONST from 'utils/constants';
import { filter, map, get } from 'lodash';
import SaleRouteManagerModal from 'components/modals/sale-route/SaleRouteManagerModal';
import SaleRouteAddCustomerModal from 'components/modals/sale-route/SaleRouteAddCustomerModal';

const SaleRouteMonitoring = function () {
  const [form] = Form.useForm();

  const [fetchingListSaleRoute, setFetchingListSaleRoute] = useState(false);
  const [listSaleRoute, setListSaleRoute] = useState([]);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [listProvinces, setListProvinces] = useState([]);
  const [listDistributors, setListDistributors] = useState([]);
  const [listFilteredDistributors, setListFilteredDistributors] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    provinceId: null,
    distributorId: null,
    saleRouteCode: null,
    saleRouteName: null,
    status: null});

  const [addCustomerModalConfig, setAddCustomerModalConfig] = useState({
    visible: false,
    dataSource: {},
  });

  const [saleRouteModalConfig, setSaleRouteModalConfig] = useState({
    visible: false,
    dataSource: null,
  });

  const handleBrowseSaleRoute = useCallback(
    (param) => {
      setFetchingListSaleRoute(true);
      const pageNumber = paging?.pageNumber ?? 1;
      const pageSize = paging?.pageSize ?? 10;
      services.generalService
        .saleRoute_FilterSaleRoute({
          provinceId: filterCriteria?.provinceId,
          distributorId: filterCriteria?.distributorId,
          saleRouteCode: filterCriteria?.saleRouteCode,
          saleRouteName: filterCriteria?.saleRouteName,
          status: filterCriteria?.status,
          pageNumber,
          pageSize,
        })
        .then((res) => {
          const items = get(res, 'items');
          const total = get(res, 'totalCount');
          setListSaleRoute(
            items.map((item) => ({
              ...item,
              createdAt: '02/10/2021',
              updatedAt: '11/10/2021',
              createdBy: 'admin',
              updatedBy: 'admin',
            }))
          );
          setTotal(total);
        })
        .catch((error) => {
          message.error('Có lỗi khi lấy danh sách tuyến.');
          setListSaleRoute([]);
        })
        .finally(() => setFetchingListSaleRoute(false));
    },
    [paging]
  );

  const handleSubmit = (values) => {
    setFilterCriteria({ ...values });
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
  };

  const handleChangePaging = (pageNumber, pageSize) => {
    const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
    setPaging({ pageNumber: currentPage, pageSize });
  };

  const handleCancelAddCustomerModal = () => {
    setAddCustomerModalConfig({
      visible: false,
      dataSource: {},
    });
    setTimeout(() => {
      setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
    }, 300);
  };

  const handleCancelSaleRouteModal = () => {
    setSaleRouteModalConfig({
      visible: false,
      dataSource: null,
    });
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
  };

  const openNewSaleRouteModal = useCallback(() => {
    setSaleRouteModalConfig({
      visible: true,
      dataSource: null,
      mode: APP_CONST.COMMON.MODAL_MODE.ADD,
    });
  }, []);

  const filterDistributors = (value) => {
    if (value) {
      let arrData = filter(listDistributors, (item, index) => {
        return item.provinceId === value
      });
      form.setFieldsValue({distributorId: null});
      setListFilteredDistributors(arrData);
    } else {
      setListFilteredDistributors([...listDistributors]);
    }
  }

  // Start Import
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  function beforeUpload(file) {
    const isExcelFile = file.type === "application/vnd.ms-excel" ||
     file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcelFile) {
      message.error("Chỉ import được file excel!");
    }
    return isExcelFile ? true : Upload.LIST_IGNORE;;
  }

  const importSaleRoutes = (data) => {
    if (data.fileList.length > 0) {
      setLoading(true);
      const fileToUpload = {
        data: data.fileList[0].originFileObj,
        fileName: data.fileList[0].name
      };

      services.generalService
          .saleRoute_ImportSaleRoutesData(fileToUpload)
          .then((res) => {
            message.success('Import danh sách tuyến thành công.');
          })
          .catch((error) => {
            message.error('Có lỗi khi import danh sách tuyến.');
          })
          .finally(() => {
            setLoading(false);
            setFileList([]);
          });
    }
  }
  //End import

  useEffect(() => {
    handleBrowseSaleRoute({});
  }, [handleBrowseSaleRoute]);

  useEffect(() => {
    //get list province
    services.organization
    .province_GetAllProvincesWithPermission()
    .then((res) => {
      let arrData = map(res, (item, index) => {
        return {provinceId: item?.id, provinceName: item?.provinceName}
      });
      setListProvinces(arrData);
    })
    .catch((error) => {});
  }, [setListProvinces]);
  
  useEffect(() => {    
    //get list distributors
    services.generalService
      .distributor_GetAllDistributorsWithPermission()
      .then((res) => {
        let arrData = map(res, (item, index) => {
          return {distributorId: item?.id, distributorName: item?.distributorName, provinceId: item?.provinceId}
        });
        setListDistributors(arrData);
        filterDistributors(filterCriteria?.provinceId);
      })
      .catch((error) => {});
  }, [setListDistributors]);

  const renderTitle = () => {
    if (window.location.pathname === '/customer-management/sale-route') {
      return <Typography.Text>THÔNG TIN TUYẾN BÁN HÀNG</Typography.Text>;
    }
    return null;
  };

  const columns = [
    {
      title: 'Mã tuyến',
      dataIndex: 'saleRouteCode',
      key: 'saleRouteCode',
      width: 200,
      // align: "center",
    },
    {
      title: 'Tên tuyến',
      dataIndex: 'saleRouteName',
      key: 'saleRouteName',
      width: 300,
      // align: "center",
    },
    {
      title: 'Tỉnh',
      dataIndex: 'provinceName',
      key: 'provinceName',
      width: 150
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'salerCode',
      key: 'salerCode',
      width: 150,
      // align: "center",
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'salerName',
      key: 'salerName',
      width: 250,
      // align: "center",
    },
    {
      title: 'Nhà phân phối',
      dataIndex: 'distributorName',
      key: 'distributorName',
      width: 250,
      // align: "center",
    },
    {
      title: 'Tổng số KH',
      dataIndex: 'totalCustomer',
      key: 'totalCustomer',
      width: 200,
      align: 'right',
    },

    {
      title: 'Mô tả',
      dataIndex: 'notes',
      key: 'notes',
      width: 300,
      // align: "center",
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      // align: "center",
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 200,
      // align: "center",
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 200,
      // align: "center",
    },
    {
      title: 'Người cập nhật',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 200,
      // align: "center",
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      align: 'center',
      render: (value) => (
        <div className={`status--${value ? 'active' : 'inactive'}`}>
          {
            APP_CONST?.ROUTE_INFO?.STATUS?.find(
              (status) => status?.id === value
            )?.name
          }
        </div>
      ),
    },
    {
      dataIndex: 'action',
      key: 'action',
      title: '#',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (_, item) => {
        return (
          <div className='warehouseInfo-card__button'>
            <Button
              type='primary'
              onClick={() =>
                setSaleRouteModalConfig({
                  visible: true,
                  mode: APP_CONST.COMMON.MODAL_MODE.EDIT,
                  dataSource: { ...item },
                })
              }
              size='small'
            >
              <span className='d-flex align-items-center' title='Sửa'>
                <EditOutlined />
              </span>
            </Button>
            <Button
              size='small'
              onClick={() =>
                setAddCustomerModalConfig({
                  visible: true,
                  dataSource: {
                    saleRouteCode: item.saleRouteCode,
                    saleRouteId: item.id,
                    currentState: item.currentState,
                  },
                })
              }
              style={{ display: 'flex', justifyContent: 'center' }}
              title='Thêm khách hàng vào tuyến'
              icon={<PlusOutlined />}
            >
              {/* <span
                // className="d-flex align-items-center"
                // style={{border:'1px solid black'}}
                
              >
                <PlusOutlined />
              </span> */}
            </Button>
          </div>
        );
      },
    },
  ];

  const listStatuses = [
    {
      value: 1,
      name: 'Hoạt động',
    },
    {
      value: 0,
      name: 'Không hoạt động',
    },
  ];

  return (
    <Card size='small' title={renderTitle()}>
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={6}>
            <Form.Item name='provinceId' label='Tỉnh' className='mb-0'>
            <Select
              style={{ width: "100%" }}
              placeholder="Tỉnh"
              allowClear
              onChange={(e) => {
                filterDistributors(e)
              }}
              optionFilterProp="children"
              showSearch
            >
              {map(listProvinces, (item, index) => {
                return (
                  <Select.Option value={item.provinceId} key={item.provinceId}>
                    {item.provinceName}
                  </Select.Option>
                );
              })}
            </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name='distributorId' label='Nhà phân phối' className='mb-0'>
              <Select
                style={{ width: '100%' }}
                placeholder="Nhà phân phối"
                allowClear
                optionFilterProp="children"
                showSearch
              >
                {map(listFilteredDistributors, (item, index) => {
                  return (
                    <Select.Option value={item.distributorId} key={item.distributorId}>
                      {item.distributorName}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name='saleRouteCode' label='Mã tuyến' className='mb-0'>
              <Input placeholder='Nhập mã tuyến' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name='saleRouteName' label='Tên tuyến' className='mb-0'>
              <Input placeholder='Nhập tên tuyến' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name='status' label='Trạng thái' className='mb-0'>
              <Select allowClear placeholder='Chọn trạng thái'>
                {listStatuses.map((status) => (
                  <Select.Option value={status?.value} key={status?.value}>
                    {status?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8]} justify='end' className='my-2'>
          <Col>
            <Button
              type='primary'
              htmlType='submit'
              disabled={fetchingListSaleRoute}
            >
              <span className='d-flex align-items-center'>
                <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button onClick={openNewSaleRouteModal}>
              <span className='d-flex align-items-center'>
                <PlusOutlined /> <span className='ml-1'>Tạo mới</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Upload
              className='import-export ant-btn'
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              showUploadList={false}
              fileList={fileList}
              beforeUpload={beforeUpload}
              onChange={importSaleRoutes}
            >
              <span className='d-flex align-items-center text-white'>
                {loading ? <LoadingOutlined /> : <DownloadOutlined />}
                <span className='ml-1'>Import tuyến</span> 
              </span>
            </Upload>
          </Col>
        </Row>
      </Form>
      <Table
        rowKey='id'
        bordered
        size='small'
        columns={columns}
        dataSource={listSaleRoute}
        locale={{ emptyText: 'Không có tuyến bán hàng' }}
        scroll={{ x: 1200 }}
        style={{ marginTop: '1em' }}
        loading={fetchingListSaleRoute}
        pagination={{
          total: total ?? 0,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
      />

      <SaleRouteManagerModal
        config={saleRouteModalConfig}
        onCancel={handleCancelSaleRouteModal}
      />
      <SaleRouteAddCustomerModal
        config={addCustomerModalConfig}
        onCancel={handleCancelAddCustomerModal}
      />
    </Card>
  );
};
export default SaleRouteMonitoring;
