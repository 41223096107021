import React from "react";
import styled from "styled-components";
import _ from "lodash";

const Dashboard = ({ className, profile, data }) => {
  return (
    <div style={{ background: "white", padding: 20, marginBottom: 100 }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {_.map(data, (item, index) => {
          return (
            <div
              key={index}
              style={{
                width: 160,
                height: 160,
                background: `${item.color}`,
                borderTopLeftRadius: 30,
                borderBottomRightRadius: 30,
                marginRight: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div 
                key={index}
                style={{ padding: 10, textAlign: "center" }}>
                <b style={{ color: "white", display: "block", fontSize: 26 }}>
                  {_.isNumber(item.total) ? item.total.toLocaleString() : 0}
                </b>
                <b style={{ color: "white", display: "block", fontSize: 16 }}>
                  {item.name}
                </b>
              </div>
            </div>
          );
        })}
      </div>

      <i
        style={{
          fontSize: 16,
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        Báo Cáo Tổng Hợp Trong Tháng
      </i>
    </div>
  );
};

export default styled(Dashboard)``;
