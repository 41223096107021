import moment, { isMoment } from 'moment'
import utils from 'utils'

const datetime = {
  formatDatetime(
    value,
    format = utils.$const.COMMON.DATE_TIME_FORMAT['DD-MM-YYYY']
  ) {
    if (!value) return value
    if (isMoment(value)) return moment(value).format(format)
    return moment(value).format(format)
  },
  stringToDateTime(str) {
    if (!str) return undefined
    return moment(str)
  },
  currentFirstDate() {
    // var today = new Date()
    // var firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
    // var mm = String(today.getMonth() + 1).padStart(2, '0')
    // var yyyy = today.getFullYear()
    // return yyyy + '-' + mm + '-' + firstDay

    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    return moment(firstDay).format(
      utils.$const.COMMON.DATE_TIME_FORMAT['YYYY-MM-DD']
    )
  },

  currentLastDate() {
    var date = new Date()
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    return moment(lastDay).format(
      utils.$const.COMMON.DATE_TIME_FORMAT['YYYY-MM-DD']
    )
  },
}
export default datetime
