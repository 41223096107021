import React from "react";
import { Modal } from "antd";

function ConfirmModal({ children, ...props }) {
  return (
    <Modal footer={false} {...props}>
      {children}
    </Modal>
  );
}

export default ConfirmModal;
