import React, { memo, useEffect } from "react";
import _ from "lodash";
import Pagination from "components/Paginate/index";

import styled from "styled-components";
import classNames from "classnames";
import moment from "moment";

const Index = memo(
  ({
    className,
    data,
    setPaging, paging, total
  }) => {

    useEffect(() => {}, [data]);
    return (
      <div
        className={classNames({
          [className]: true,
        })}
      >
       
        <table className="tg">
          <thead>
            <tr>
              {/* map dữ liệu header */}
              {_.map(data.arrHeader, (item, index) => {
                let classItem = "headerSticky";
                if (index === 0) {
                  classItem = "headerStickyOne width20";
                } else if (index === 1) {
                  classItem = "headerStickyTwo width150";
                }
                else if (index === 2) {
                  classItem = "headerStickyThree width150";
                }
                else if (index === 3) {
                  classItem = "headerStickyFour width200";
                }
                return (
                  <th className={`tg-0pky header text-center ${classItem}`} key={index}>
                    <div>
                      {item.dateOfWeek && item.key !== 1
                        ? moment(item.date).format("DD-MM")
                        : item.date}
                    </div>
                    <div>{item.dateOfWeek}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {_.map(data.arrColome, (item, tuyenId) => {
              let _renderBks = (
                <>
                  <td
                    className="tg-73oq fixLeft lastItem text-center"
                    style={{
                      minWidth: "20px",
                    }}
                    rowSpan="6"
                    key={`${tuyenId}`}
                  >
                    {tuyenId + 1}
                  </td>
                  <td
                    className="tg-73oq fixLeftOne lastItem text-center"
                    style={{
                      minWidth: "150px",
                    }}
                    rowSpan="6"
                    key={`${item.staffCode}+${tuyenId}`}
                  >
                    {item.staffCode}
                  </td>
                  <td
                    className="tg-73oq fixLeftTwo lastItem text-center"
                    style={{
                      minWidth: "150px",
                    }}
                    rowSpan="6"
                    key={`${item.staffName}+${tuyenId}`}
                  >
                    {item.staffName}
                  </td>
                  <td
                    className="tg-73oq fixLeftThree lastItem text-center"
                    style={{
                      minWidth: "200px",
                    }}
                    rowSpan="6"
                    key={`${item.distributorName}+${tuyenId}`}
                  >
                    {item.distributorName}
                  </td>
                </>
              );

              {/* Tổng công */}
              let _renderTotalPaids = (
                <>
                  <td
                    className="text-center"
                    style={{
                      minWidth: "50px",
                      fontWeight: "bold"
                    }}
                    rowSpan="6"
                  >
                    {item.totalPaids}
                  </td>
                </>
              );

              let _render = [];
              // item thứ
              _.map(item.timesheet, (time, timeId) => {

                _render.push([
                  <tr key={timeId} className="">
                    {timeId === 0 ? _renderBks : null}
                    <td
                      className={`tg-j3py  `}
                      style={{
                        minWidth: "90px",
                      }}
                    >
                      {time.name}
                    </td>

                    {/* các ngày thứ */}
                    {_.map(time.data, (name, nameId) => {
                      let nameDate = "";
                      if (timeId === 0) {
                        nameDate = name ? moment(name).format("HH:mm") : "";
                      } else if (timeId === 1) {
                        nameDate = name ? moment(name).format("HH:mm") : "";
                      } else if (timeId === 2) {
                        nameDate = name ;
                      } else if (timeId === 3) {
                        nameDate = name ;
                      } else if (timeId === 4) {
                        nameDate = name
                      } else if (timeId === 5) {
                        nameDate = name ? 1 : 0;
                      }
                      return (
                        // item ngày
                        <td
                          key={nameId}
                          style={{
                            height: "35px",
                            minWidth: "70px",
                          }}
                        >
                          <div className="d-flex height100">
                            <span
                              style={{ width: "80%" }}
                              className="d-flex align-items-center justify-content-center"
                            >
                              {nameDate}
                            </span>
                          </div>
                        </td>
                      );
                    })}

                    {/* Tổng công */}
                    {timeId === 0 ? _renderTotalPaids : null}
                  </tr>,
                ]);
              });
              return _render;
            })}
          </tbody>
        </table>
        <Pagination total={total} setPaging={setPaging} paging={paging} />
      </div>
    );
  }
);
export default styled(Index)`
  table {
    display: block;
  }

  .tg {
    border-color: rgb(242, 243, 248);
    width: 100%;
    border-collapse: separate;
    overflow-x: auto;
    overflow-y: auto;
    height: calc(100vh - 280px);
  }

  .tg td {
    background-color: #fff;
    border: 1px solid #cccccc;
    color: #444;
    // overflow: hidden;
    // word-break: normal;
  }
  .tg th {
    border: 1px solid #cccccc;
    color: black;
    // overflow: hidden;
    // padding: 5px 5px;
    // word-break: normal;
  }
  .fixLeftOne {
    position: sticky;
    z-index: 2;
    left: 20px;
    background: #fff;
  }
  .fixLeftTwo {
    position: sticky;
    z-index: 2;
    left: 170px;
    background: #fff;
  }
  .fixLeftThree {
    position: sticky;
    z-index: 2;
    left: 320px;
    background: #fff;
  }
  .header {
    background: #023561;
    color:#fff !important;
  }
  .fixLeft {
    position: sticky;
    z-index: 2;
    left: 0px;
    background: #fff;
    border: 1px solid #ccc;
    color: red;
    border-color: red;
  }
  .tg .tg-iks7 {
    border-color: rgb(242, 243, 248);
  }
  .tg .tg-mois {
    background-color: #ffffff;
    border-color: #000000;
    text-align: left;
  }

  .hidden-item {
    background: #efeded !important;
  }
  .sticky {
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%;
  }
  .headerSticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .headerStickyOne {
    position: sticky;
    top: 0;
    z-index: 3;
    left: 0;
  }
  .headerStickyTwo {
    position: sticky;
    top: 0;
    z-index: 3;
    left: 20px;
  }
  .headerStickyThree {
    position: sticky;
    top: 0;
    z-index: 3;
    left: 170px;
  }
  .headerStickyFour {
    position: sticky;
    top: 0;
    z-index: 3;
    left: 320px;
  }
  .lastItem {
    border-bottom: 3px solid #ccc !important;
  }
  .customerButton {
    background: #efeded !important;
  }

  .height100 {
    height: 100%;
  }
  .width20 {
    width: 20px;
  }
  .width150 {
    width: 150px;
  }
  .width200 {
    width: 200px;
  }
  
`;
