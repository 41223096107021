import { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Card,
  Table,
  Checkbox,
  message,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";

import SaleRouteAddCustomerModal from "components/modals/sale-route/SaleRouteAddCustomerModal";
import EditCustomerInSaleRouteModal from "components/modals/CustomermanagementModal";

import utils from "utils";
import services from "services";
import _ from 'lodash'

const DAY_OF_WEEKS = {
  Monday: "Thứ 2",
  Tuesday: "Thứ 3",
  Wednesday: "Thứ 4",
  Thursday: "Thứ 5",
  Friday: "Thứ 6",
  Saturday: "Thứ 7",
  Sunday: "Chủ nhật",
};

const SaleRouteCustomerManager = ({ saleRouteInfo }) => {
  const { generalService, organization } = services;
  const [form] = Form.useForm();

  const [listCustomers, setListCustomers] = useState([]);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [isReload, setIsReload] = useState(0);
  const [listCustomerPagination, setListCustomerPagination] = useState({
    total: 0,
    pageSize: 20,
  });

  const [addCustomerModalConfig, setAddCustomerModalConfig] = useState({
    visible: false,
    dataSource: {},
  });

  const [customerManagementModalConfig, setCustomerManagementModalConfig] =
    useState({
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
      visible: false,
      dataSource: {},
    });

  const handleCancelAddCustomerModal = useCallback(() => {
    setAddCustomerModalConfig({
      visible: false,
      dataSource: {},
    });
    setIsReload(String(Math.random()));
  }, []);

  const handleCancelEditCustomerModal = useCallback(() => {
    setCustomerManagementModalConfig({
      visible: false,
      dataSource: {},
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    setIsReload(String(Math.random()));
  }, []);

  const handleGetCustomers = useCallback(
    async (saleRouteId) => {
      setIsFetchingCustomers(true);
      try {
        const res = await generalService.saleRoute_GetSaleRouteById(
          saleRouteId
        );
        setListCustomerPagination({
          total: res?.saleRouteDetails.length,
          pageSize: 20,
        });
        setListCustomers(res?.saleRouteDetails || []);
      } catch {
        setListCustomers([]);
      } finally {
        setIsFetchingCustomers(false);
      }
    },
    [generalService]
  );

  useEffect(() => {
    if (!saleRouteInfo) {
      setListCustomers([]);
      return;
    }

    handleGetCustomers(saleRouteInfo?.id);
  }, [handleGetCustomers, saleRouteInfo, isReload]);

  const columns = [
    {
      title: "Mã KH",
      dataIndex: "customerCode",
      key: "customerCode",
      render: (value, row) => {
        return row?.customerInformation?.customerCode;
      },
      width: 200,
    },
    {
      title: "Tên KH",
      dataIndex: "customerName",
      key: "customerName",
      render: (value, row) => {
        return row?.customerInformation?.customerName;
      },
      width: 350,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      render: (value, row) => {
        return row?.customerInformation?.address;
      },
      width: 350,
    },
    {
      title: "Thứ tự VT",
      dataIndex: "visitAttendanceIndex",
      key: "visitAttendanceIndex",
      width: 150,
    },
    {
      title: "Ngày VT",
      dataIndex: "dateOfVisitings",
      key: "dateOfVisitings",
      width: 150,
      render: (value, row) => {
        if (!value) return "";

        return value
          .map((day) => DAY_OF_WEEKS[day?.date || "Monday"])
          .join(", ");
      },
    },
    {
      title: "Tần suất",
      children: [...Array(4)].map((_, index) => ({
        title: `W${index + 1}`,
        dataIndex: `visitFrequency_${index}`,
        key: `visitFrequency_${index}`,
        width: 50,
        render: (value, row) => {
          const checkedWeeks = row.visitFrequency.map((t) => t.weekIndex);
          return (
            <Checkbox checked={checkedWeeks.includes(index + 1)} disabled />
          );
        },
      })),
    },
    {
      dataIndex: "action",
      key: "action",
      title: "Hành động",
      align: "center",
      width: 130,
      render: (_, row) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            type="primary"
            size="small"
            onClick={() => openEditCustomerModal(row)}
          >
            <span className="d-flex align-items-center">
              <EditOutlined title="Sửa"/> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            size="small"
            type="danger"
            className="ml-3"
            onClick={() => handleShowConfirmDelete(row)}
          >
            <span className="d-flex align-items-center">
              <DeleteOutlined title="Xóa"/> <span className="ml-1"></span>
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const handleShowConfirmDelete = useCallback(
    (row) => {
      Modal.confirm({
        title: (
          <span>
            Xác nhận xóa KH{" "}
            <b className="text-danger">
              {row?.customerInformation?.customerName}
            </b>{" "}
            ra khỏi tuyến
          </span>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: "Xác nhận",
        okType: "danger",
        cancelText: "Hủy",
        content: "",
        onOk() {
          generalService
            .saleRoute_MoveCustomerOut({
              saleRouteId: saleRouteInfo?.id,
              customerIds: [row?.customerInformation?.id],
            })
            .then(() => {
              message.success("Xóa thành công.");
              setIsReload(String(Math.random()));
            })
            .catch(() =>
              message.error("Xóa không thành công. Vui lòng thử lại")
            );
        },
        onCancel() {},
      });
    },
    [generalService, saleRouteInfo?.id]
  );

  const openEditCustomerModal = (record) => {
    setCustomerManagementModalConfig({
      visible: true,
      mode: utils.$const.COMMON.MODAL_MODE.EDIT,
      dataSource: {
        ...record,
        saleRouteId: saleRouteInfo?.id,
        currentState: saleRouteInfo?.currentState || 0,
      },
    });
  };

  const openAddCustomerModal = () => {
    setAddCustomerModalConfig({
      visible: true,
      dataSource: {
        saleRouteCode: saleRouteInfo?.saleRouteCode,
        saleRouteId: saleRouteInfo?.id,
        currentState: saleRouteInfo?.currentState,
      },
    });
  };

  const handleSearch = () => {
    if (!saleRouteInfo) return;

    const keyword = form.getFieldValue("keyword");
    const dateOfVisitings = form.getFieldValue("dateOfVisitings") || [];
    const saleRouteId = saleRouteInfo?.id || null;

    let arr1 = listCustomers
    let arr2 = dateOfVisitings
    let  res = _.map(listCustomers,(item,index)=>{
    return  item.dateOfVisitings.filter(_item => arr2.indexOf(_item.date));
      // item.dateOfVisitings.filter(_item => arr2.includes(_item.date));
    })
    handleGetCustomers(saleRouteId);
  };

  return (
    <>
      <Card size="small">
        <Form form={form} onFinish={handleSearch} labelCol={{ span: 24 }}>
          <Row gutter={[16]}>
            <Col lg={24} xl={6}>
              <Form.Item name="keyword" label="Tìm kiếm theo từ khoá">
                <Input placeholder="Mã KH/Tên KH/Địa chỉ..." />
              </Form.Item>
            </Col>
            <Col lg={24} xl={6}>
              <Form.Item name="dateOfVisitings" label="Ngày VT Trong tuần">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Chọn ngày VT"
                >
                  {Object.entries(DAY_OF_WEEKS).map(([key, value], index) => (
                    <Select.Option key={key} value={key}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={24} xl={6} style={{ display: "flex", marginTop: "40px" }}>
              <Button
                disabled={!saleRouteInfo}
                type="primary"
                onClick={() => form.submit()}
              >
                <span className="d-flex align-items-center">
                  <SearchOutlined />
                  <span className="ml-1">Tìm kiếm</span>
                </span>
              </Button>
            </Col>
          </Row>
          <Row justify="end" gutter={[8]}>
            <Col>
              <Button disabled={!saleRouteInfo} onClick={openAddCustomerModal}>
                <span className="d-flex align-items-center">
                  <PlusOutlined />
                  <span className="ml-1">Thêm KH vào tuyến</span>
                </span>
              </Button>
            </Col>
            {/* <Col>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <span className="d-flex align-items-center">
                  <EnvironmentOutlined />
                  <span className="ml-1">Xem trên bản đồ</span>
                </span>
              </Button>
            </Col> */}
          </Row>
        </Form>
        <Table
          rowKey="id"
          bordered
          size="small"
          columns={columns}
          dataSource={listCustomers}
          locale={{ emptyText: "Không có khách hàng" }}
          scroll={{ x: 1200 }}
          style={{ marginTop: "1em" }}
          pagination={listCustomerPagination}
          loading={isFetchingCustomers}
        />
      </Card>

      <SaleRouteAddCustomerModal
        config={addCustomerModalConfig}
        onCancel={handleCancelAddCustomerModal}
      />

      <EditCustomerInSaleRouteModal
        config={customerManagementModalConfig}
        onCancel={handleCancelEditCustomerModal}
      />
    </>
  );
};

export default SaleRouteCustomerManager;
