import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  message,
  Divider,
  Col,
  Row
} from "antd";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteFilled,
  UnorderedListOutlined
} from "@ant-design/icons";
import moment from "moment";
import PromotionModal from "components/modals/PromotionModal";
import services from "services";
import _ from "lodash";

let time;

export default function Promotion() {
  const [listPromotion, setListPromotion] = useState([]);
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState({});

  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);

  const [isMode, setIsMode] = useState("Edit");

  const [isLoading, setIsLoading] = useState(true);
 
  const handleGetListPromotion = useCallback(() => {
    setIsLoading(true);
    services.shippingOrderService
      .promotion_FilterPromotions({
        pageNumber: paging?.pageNumber,
        pageSize: paging?.pageSize
      })
      .then((res) => {
        setListPromotion(res?.items);
        setIsLoading(false);
        setTotal(res?.totalCount ?? 0);
      })
      .catch((error) => {
        setTotal(0);
      });
  }, [paging]);

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(() => {
      handleGetListPromotion();
    }, 800);
  }, [handleGetListPromotion]);

  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa chương trình khuyến mãi
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.shippingOrderService
          .promotion_Delete({
            id: row.id,
            currentState: row.currentState,
          })
          .then((res) => {
            message.success("Xóa chương trình khuyến mãi thành công.");
            handleGetListPromotion();
          })
          .catch((erro) => {
            message.error("Có lỗi xảy ra!");
          });
      },
      onCancel() {},
    });
  }, [handleGetListPromotion]);

  const columns = [,
    {
      dataIndex: "name",
      key: "name",
      title: "Tên chương trình",
      width: 100,
    },
    {
      dataIndex: "startDate",
      key: "startDate",
      title: "Ngày bắt đầu",
      align: "right",
      width: 80,
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
    },
    {
      dataIndex: "endDate",
      key: "endDate",
      title: "Ngày kết thúc",
      align: "right",
      width: 80,
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
    },
    {
      dataIndex: "turnoverFormatted",
      key: "turnoverFormatted",
      title: "Doanh số",
      width: 120
    },
    {
      dataIndex: 'promotionTypeName',
      key: "promotionTypeName",
      title: 'Loại khuyến mãi',
      width: 150
    },
    {
      dataIndex: 'bonusPercentage',
      key: "bonusPercentage",
      title: '% thưởng',
      width: 60
    },
    {
      dataIndex: 'description',
      key: "description",
      title: 'Mô tả',
      width: 150
    },
    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      width: 120,
      fixed: "right",
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Button
            onClick={handleModalPromotion(row, "Edit")}
            type="primary"
            size="small"
          >
            <span className="d-flex align-items-center">
              <EditOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            danger
            size="small"
            onClick={() => handleShowConfirmDelete(row)}
          >
            <span className="d-flex align-items-center">
              <DeleteFilled /> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            type="ghost"
            size="small"
            className="ml-1"
            onClick={handleModalPromotion(row, "View")}
          >
            <span className="d-flex align-items-center">
              <UnorderedListOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const handleCreatePromotion = useCallback(
    (mode) => (e) => {
      setShowPromotionModal(true);
      setIsMode(mode);
    },
    []
  );

  const handleModalPromotion = useCallback(
    (PromotionInfor, mode) => (e) => {
      setShowPromotionModal(true);
      setSelectedPromotion(PromotionInfor);
      setIsMode(mode);
    },
    []
  );

  const handleCancelModal = useCallback(() => {
    setSelectedPromotion({});
    setShowPromotionModal(false);
  }, []);


  const handleSaveModal = useCallback(() => {
    setShowPromotionModal(false);
    setSelectedPromotion({});
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize });
  }, [paging]);

  const handleChangePaging = (pageNumber, pageSize) => {
    const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
    setPaging({ pageNumber: currentPage, pageSize });
  };

  return (
    <>     
      <Card className="promotion-card">
        <Row justify="end" gutter={[8]}>
          <Col>
            <Button size="middle" onClick={handleCreatePromotion("Create")}>
              <span className="d-flex align-items-center">
                <PlusOutlined />
                <span className="ml-1">Thêm mới</span>
              </span>
            </Button>
          </Col>
        </Row>

        <Divider style={{margin:10}}/>

        <Table
          rowKey="id"
          className="promotion-card__table"
          columns={columns}
          dataSource={listPromotion}
          scroll={{ x: 1200 }}
          bordered
          loading={isLoading}
          pagination={{
            total,
            pageSizeOptions: [10, 20, 50],
            current: paging?.pageNumber ?? 1,
            pageSize: paging?.pageSize ?? 10,
            onChange: handleChangePaging,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} trong ${total} dòng`,
          }}
          size="small"
          locale={{ emptyText: "Không có chương trình khuyến mãi nào" }}
        />

        <PromotionModal
          visible={showPromotionModal}
          onCancel={handleCancelModal}
          onSave={handleSaveModal}
          selectedPromotion={selectedPromotion}
          mode={isMode}
        />
      </Card>
    </>
  );
}
