/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 20/09/2021.
 * @description Màn hình `Danh sách duyền truy cập`. Permission `/access-management/permission`
 * */

import { useCallback, useState, useEffect } from "react";
import { Card, Button, Table, message, Typography,Form, Divider, Row, Col, Input } from "antd";
import { PlusOutlined,SearchOutlined,EditOutlined, DeleteFilled } from "@ant-design/icons";
import services from "services";
import ConfirmModal from "components/modals/confirm";
import PermissionModal from "components/modals/PermissionModal";

const PermissionPage = function (){
  const [form] = Form.useForm();
    const [data, setData] = useState([]);

    const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [isReloadPage, setIsReloadPage] = useState(false);

    useEffect(() => {
      setLoading(true);
      services.accessManagementService.permissionDefinition_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res)=>{
        setLoading(false);
        setTotal(res?.totalCount ?? 0);
        setData(res.items);
      })
      .catch((error)=>{
        setTotal(0);
        setLoading(false);
      })
    }, [paging,isReloadPage]);

    const columns = [
        {
            title: "Quyền truy cập",
            dataIndex: "permissionName",
            key:"permissionName",
            width: 150,
            align: "left",
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            key:"description",
            width: 500,
            align: "left"
        },
        {
            dataIndex: "action",
            key: "action",
            title: "#",
            align: "center",
            fixed: "right",
            width: 180,
            render: (_, row) => (
              <div className="warehouseInfo-card__button">
                <Button
                    type="primary"
                    size="small"
                    onClick={handleEditPermission(row)}
                >
                  <span className="d-flex align-items-center">
                    <EditOutlined /> <span className="ml-1"></span>
                  </span>
                </Button>
                <Button
                    className="warehouseInfo-card__button--right"
                    type="danger"
                    size="small"
                    onClick={handleDeletePermission(row)}
                >
                  <span className="d-flex align-items-center">
                    <DeleteFilled /> <span className="ml-1"></span>
                  </span>
                </Button>
              </div>
            ),
        },
    ];
    
    const [selectedPermission, setSelectedPermission] = useState({});
    const [isOpenPermissionModal, setIsOpenPermissionModal] = useState(false);
    const [permissionModalMode, setPermissionModalMode] = useState("add");
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    const handleCreatePermissionModal = useCallback(() => {
    setPermissionModalMode("add");
    setIsOpenPermissionModal(true);
    setSelectedPermission([]);
  }, []);
  
  const handleEditPermission = useCallback(
    (currencyUnit) => (e) => {
      setPermissionModalMode("edit")
      setIsOpenPermissionModal(true);
      setSelectedPermission(currencyUnit);
    },
    [setSelectedPermission,setPermissionModalMode,setIsOpenPermissionModal]
  );

  const handleCancelPermissionModal = useCallback(() => {
    setIsOpenPermissionModal(false);
    setSelectedPermission({});
  }, [setIsOpenPermissionModal, setSelectedPermission]);

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedPermission({});
  }, [setSelectedPermission, setIsReloadPage, isReloadPage]);
  
  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ page: currentPage, pageSize });
    },
    [paging]
  );

  const handleDeletePermission = useCallback(
    (currencyUnit) => () => {
      setSelectedPermission(currencyUnit);
      setIsShowConfirmModal(true);
    },
    [setSelectedPermission, setIsShowConfirmModal]
  );

  const handleApproveToDeletePermission = useCallback(() => {
    services.accessManagementService
      .permissionDefinition_Delete([{
        id: selectedPermission?.id,
        currentState: selectedPermission?.currentState
      }])
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedPermission({});
        handleReloadPage();
      })
      .catch((err) => {
        message.error(err?.message || "Xóa kho thất bại");
      });
  }, [handleReloadPage, selectedPermission]);

  const handleRejectToDeletePermission = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedPermission({});
  }, [setSelectedPermission]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    let filter = {
      ...form.getFieldsValue(),
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize
    };
    services.accessManagementService.permissionDefinition_Filtering(filter)
    .then(res =>{
      setIsLoading(false);
      setData(res.items);
      setTotal(res?.totalCount ?? 0);
      if (res.items.length === 0) {
        message.error("Không tìm thấy thông tin");
      }
      else {
        message.success("Tìm kiếm thành công");
      }
    })
    .catch(err =>{
      setTotal(0);
      setIsLoading(false);
    })
  };

  return (
    <Card className="CurrencyUnitPage">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={10}>
            <Form.Item name='keyword' label='Tìm theo từ khóa'>
              <Input placeholder='Nhập từ khóa' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button
                type='primary'
                htmlType='submit'
                disabled={isLoading}
              >
                <span className='d-flex align-items-center'>
                  <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
                </span>
              </Button>
          </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height ' label=' '>
              <Button onClick={handleCreatePermissionModal}>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Tạo mới</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Export excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Import excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        size="small"
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 1200 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.page ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        }}
        loading={loading}
        locale={{ emptyText: "Không có nhà cung cấp" }}
      />
      <PermissionModal
        visible={isOpenPermissionModal}
        mode = {permissionModalMode}
        onCancel = {handleCancelPermissionModal}
        dataItem = {selectedPermission}
        onReloadPage={handleReloadPage}
      />
      <ConfirmModal
        title="Xóa chức năng, quyền"
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeletePermission}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa{" "}
          <strong>{selectedPermission?.permissionName}</strong> không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleRejectToDeletePermission}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeletePermission}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Card>
  );
};
export default PermissionPage;