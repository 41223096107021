import React, { useState, memo } from "react";
import {
  Marker,
  InfoWindow,
} from "react-google-maps";
import styled from "styled-components";
import _ from "lodash";
import Map1 from "../../../../images/map1.png";
import Map2 from "../../../../images/map2.png";
import Map3 from "../../../../images/map3.png";
import Map4 from "../../../../images/map4.png";
import {
  UserOutlined,
  FlagOutlined,
} from "@ant-design/icons";
const customerMarker = memo(({ item, rowId }) => {
  const [isShow, setIsShow] = useState({
    image: "",
    click: false,
  });

  const onMarker = () => {
    setIsShow((preState) => {
      let nextState = { ...preState };
      nextState.click = !isShow.click;
      return nextState;
    });
  };
  let img = "";
  if (item.isVisit === false) {
    img = Map2;
  } else if (item.isVisit && item.isPo === false) {
    img = Map1;
  } else if (item.isVisit && item.isPo) {
    img = Map4;
  } else if (item.isVisit && item.isRoute === false) {
    img = Map3;
  }
  return (
    <div>
      <Marker
        position={{ lat: item.latitude, lng: item.longitude }}
        onClick={onMarker}
        icon={{
          url: img,
          scaledSize: new window.google.maps.Size(50, 50),
        }}
      />
      {isShow.click && (
        <div>
          <InfoWindow
            className="test"
            onCloseClick={() => {
              setIsShow((preState) => {
                let nextState = { ...preState };
                nextState.click = false;
                return nextState;
              });
            }}
            position={{
              lat: item.latitude,
              lng: item.longitude,
            }}
            onClose={isShow.click}
            options={
              {
                // pixelOffset: new google.maps.Size(0, -23),
              }
            }
          >
            <div style={{ border: "1px solid 1px", display: "grid" }}>
              <span
                style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}
              >
                <UserOutlined /> {_.get(item, "customerName", "")} -{" "}
                {_.get(item, "customerCode", "")}
              </span>

              <span
                style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}
              >
                <FlagOutlined style={{ color: "blue" }} />{" "}
                {_.get(item, "address", "")}
              </span>
            </div>
          </InfoWindow>
        </div>
      )}
    </div>
  );
});
export default styled(customerMarker)``;
