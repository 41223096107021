import { Button, Form, Input, Modal, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect } from "react";
import services from "services";

const BusinessTypeModal = ({
  visible,
  onCancel,
  selectedBusinessType = {},
  listBusinessType = [],
  onReloadPage,
  ...props
}) => {
  const [form] = Form.useForm();
  //const [currentCusId, setcurrentCusId] = React.useState("");
  useEffect(() => {
    if (Object.keys(selectedBusinessType).length > 0) {
      form.setFieldsValue(selectedBusinessType);
    } else {
      form.resetFields();
    }
  }, [selectedBusinessType, form]);

  const onCloseModal = () => {
    onCancel();
    onReloadPage();
  };

  const onSubmit = useCallback(
    (isContinue) => {
      const values = form.getFieldsValue();
      if (!selectedBusinessType?.id) {
        services.generalService
          .businessType_Create({ ...values })
          .then((res) => {
            if (res?.succeeded) {
              message.success("Thêm hình thức thành công");
              // onReloadPage();
              // form.resetFields();
              // onCancel();
              if (isContinue) {
                form.resetFields();
              } else {
                onCloseModal();
              }
            }
          })
          .catch((err) => {
            message.error(
              err?.message ?? "Thêm thất bại, có thể mã hình thức đã tồn tại"
            );
          });
      } else {
        services.generalService
          .businessType_Update({
            id: selectedBusinessType?.id,
            currentState: selectedBusinessType?.currentState,
            ...values,
          })
          .then((res) => {
            if (res?.succeeded) {
              message.success("Sửa hình thức thành công");
              onReloadPage();
              form.resetFields();
              onCancel();
            }
          })
          .catch((err) =>
            message.success(err?.message ?? "Sửa hình thức thành công")
          );
      }
    },
    [form, onCancel, onReloadPage, selectedBusinessType]
  );

  const handleConfirm = useCallback(
    (isContinue) => {
      form.submit();
      form.validateFields().then(() => {
        onSubmit(isContinue);
      });
    },
    [form, onSubmit]
  );
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCloseModal}
      {...props}
      title={
        selectedBusinessType?.id
          ? "Sửa hình thức kinh doanh"
          : "Thêm hình thức kinh doanh"
      }
      // footer={false}
      footer={[
        <Button type="primary" onClick={() => handleConfirm(false)}>
          Lưu
        </Button>,
        // khi thêm mới nó nút này
        <Button
          style={{ display: selectedBusinessType?.id ? "none" : "" }}
          type="primary"
          onClick={() => handleConfirm(true)}
        >
          Lưu và tiếp tục
        </Button>,
        <Button key="back" onClick={onCloseModal}>
          Quay lại
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={() => {}}
        // onFieldsChange={(e) =>
        //   e?.map((item, key) => {
        //     if (item.name[0] === "customerGroupCode") {
        //       setcurrentCusId(item?.value);
        //     }
        //   })
        // }
        {...layout}
        labelAlign="left"
      >
        <Form.Item
          label="Mã"
          required
          name="businessCode"
          rules={[{ required: true, message: "Mã KH không được trống" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Mã" />
        </Form.Item>

        <Form.Item
          label="Hình thức"
          required
          name="businessName"
          rules={[{ required: true, message: "Hình thức không được trống" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập tên hình thức" />
        </Form.Item>

        <Form.Item
          label="Trạng thái "
          required
          name="status"
          rules={[{ required: true, message: "Trạng thái không được trống" }]}
          labelCol={{ span: 7 }}
        >
          <Select placeholder="Chọn Trạng thái">
            <Select.Option value={1}>Hoạt động</Select.Option>
            <Select.Option value={0}>Ngừng hoạt động</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Ghi chú" name="notes" labelCol={{ span: 7 }}>
          <TextArea placeholder="Nhập ghi chú" rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BusinessTypeModal;
