import { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Card,
  Table,
  Popconfirm,
  DatePicker,
  message,
  InputNumber,
  Typography,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  DeleteOutlined,
  EditFilled,
} from '@ant-design/icons';
import { map, filter, get } from 'lodash';
import moment from 'moment';

import utils from 'utils';
import services from 'services';
import Text from 'antd/lib/typography/Text';
import ConfirmModal from './confirm';

const actions = {
  APPROVE: 'approve',
  CANCEL: 'cancel',
};

const OutboundModal = function () {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  const [documentType, setDocumentType] = useState({
    customer: true,
    warehouse: false,
    editQuantity: false,
  });
  const [listDistributor, setListDistributor] = useState([]);
  const [warehouseInfoDto, setWarehouseInfoDto] = useState([]);
  const [warehouseToInfoDto, setWarehouseToInfoDto] = useState([]);
  const [listCustomer, setListCustomer] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listOrder, setListOrder] = useState([]);
  const [index, setIndex] = useState(-1);
  const [promotionIndex, setPromotionIndex] = useState(-1);
  const [outboundDetail, setOutboundDetail] = useState({});
  const [selectedOrder, setSelectedOrder] = useState({});
  const [isReloadPage, setIsReloadPage] = useState(false);
  const [configModal, setConfigModal] = useState({
    visible: false,
    type: actions.APPROVE,
    data: {},
  });

  const [productOutboundDetails, setProductOutboundDetails] = useState([]);
  const [promotionOutboundDetails, setPromotionOutboundDetails] = useState([]);

  useEffect(() => {
    services.generalService
      .products_GetProductIncludeUnit()
      .then((res) => setListProduct(res))
      .catch(() => setListProduct([]));
  }, []);

  useEffect(() => {
    const getAllDistributor = () => {
      services.generalService.distributor_GetAllDistributorsWithPermission().then((res) => {
        setListDistributor(res);
      });
    };
    getAllDistributor();
  }, []);

  useEffect(() => {
    const getAllOrder = () => {
      services.shippingOrderService
        .purchaseOrders_GetAllPurchaseOrders(1)
        .then((res) => {
          setListOrder(res || []);
        });
    };
    getAllOrder();
  }, []);

  useEffect(() => {
    const getListCustomer = () => {
      services.generalService
        .customerInformation_GetListCustomer()
        .then((res) => setListCustomer(res));
    };
    getListCustomer();
  }, []);

  useEffect(() => {
    if (form.getFieldsValue()?.fromDistributorId) {
      const currentDistributor = listDistributor.find(
        (distributor) =>
          distributor?.id === form.getFieldsValue()?.fromDistributorId
      );
      setWarehouseInfoDto(currentDistributor?.warehouseInfoDtos || []);
    }
  }, [form.getFieldsValue().fromDistributorId, listDistributor]);

  useEffect(() => {
    if (form.getFieldsValue()?.toDistributorId) {
      const currentDistributor = listDistributor.find(
        (distributor) =>
          distributor?.id === form.getFieldsValue()?.toDistributorId
      );
      setWarehouseToInfoDto(currentDistributor?.warehouseInfoDtos || []);
    }
  }, [form.getFieldsValue().toDistributorId, listDistributor]);

  useEffect(() => {
    if (id) {
      services.warehouseService
        .productOutbound_GetProductInboundByID(id)
        .then((res) => {
          setOutboundDetail(res);
        });
    }
  }, [id, isReloadPage]);

  useEffect(() => {
    if (Object.values(outboundDetail).length > 0) {
      if (outboundDetail?.documentType === 'CustomerOrder') {
        setDocumentType({
          customer: true,
          warehouse: false,
          editQuantity: false,
        });
        form.setFieldsValue({ toWarehouseId: null });
      } else if (outboundDetail?.documentType === 'WarehouseTransferring') {
        setDocumentType({
          customer: false,
          warehouse: true,
          editQuantity: false,
        });
      } else if (outboundDetail?.documentType === 'EditQuantity') {
        setDocumentType({
          customer: false,
          warehouse: false,
          editQuantity: true,
        });
      }

      const currentDistributor = listDistributor.find(
        (distributor) => distributor?.id === outboundDetail?.fromDistributorId
      );
      setWarehouseInfoDto(currentDistributor?.warehouseInfoDtos || []);

      const currentToDistributor = listDistributor.find(
        (distributor) => distributor?.id === outboundDetail?.toDistributorId
      );
      setWarehouseToInfoDto(currentToDistributor?.warehouseInfoDtos || []);

      form.setFieldsValue({
        documentCode: outboundDetail?.documentCode,
        documentType: outboundDetail?.documentType,
        outDate: outboundDetail.outDate
          ? moment(outboundDetail?.outDate)
          : null,
        customerId: outboundDetail?.customerId,
        fromDistributorId: outboundDetail?.fromDistributorId,
        fromWarehouseId: outboundDetail?.fromWarehouseId,
        toDistributorId: outboundDetail?.toDistributorId,
        toWarehouseId: outboundDetail?.toWarehouseId,
        referenceId: outboundDetail?.referenceId,
        notes: outboundDetail?.notes,
        distributorId: outboundDetail?.distributorId,
        purchaseId: outboundDetail?.referenceId,
      });
      setProductOutboundDetails(
        outboundDetail?.productOutboundDetails.map((product) => ({
          ...product,
          id: product?.productId,
        }))
      );
      setPromotionOutboundDetails(
        outboundDetail.promotionOutboundDetails.map((product) => ({
          ...product,
          id: product?.productId,
        }))
      );
    }
  }, [outboundDetail]);

  const handleChangeOutboundType = (value) => {
    if (value === 'CustomerOrder') {
      setDocumentType({
        customer: true,
        warehouse: false,
        editQuantity: false,
      });
      form.setFieldsValue({
        toWarehouseId: null,
        fromWarehouseId: null,
        fromDistributorId: null,
        toDistributorId: null,
        referenceId: null,
      });
    } else if (value === 'WarehouseTransferring') {
      setDocumentType({
        customer: false,
        warehouse: true,
        editQuantity: false,
      });
      form.setFieldsValue({
        purchaseId: null,
        customerId: null,
        notes: null,
        fromDistributorId: null,
        fromWarehouseId: null,
        toDistributorId: null,
        referenceId: null,
      });
      setProductOutboundDetails([]);
      setPromotionOutboundDetails([]);
      setSelectedOrder({});
    } else if (value === 'EditQuantity') {
      setDocumentType({
        customer: false,
        warehouse: false,
        editQuantity: true,
      });
      form.setFieldsValue({
        purchaseId: null,
        customerId: null,
        notes: null,
        fromDistributorId: null,
        toDistributorId: null,
        toWarehouseId: null,
        fromWarehouseId: null,
        referenceId: null,
      });
      setProductOutboundDetails([]);
      setPromotionOutboundDetails([]);
      setSelectedOrder({});
    }
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: 75,
      render: (item, row, index) => ++index,
    },
    {
      title: 'Mã Sản phẩm',
      dataIndex: 'productCode',
      key: 'productCode',
      width: 200,
    },
    {
      title: 'Tên Sản phẩm',
      dataIndex: 'productName',
      key: 'productName',
      width: 200,
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'unitType',
      key: 'unitType',
      width: 200,
      render: (item, row, idx) => {
        return row?.unitTypeName;
      },
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
      render: (itemValue, row, idx) => {
        return !isReadOnly ? (
          <InputNumber
            placeholder={0}
            min={0}
            defaultValue={row?.quantity}
            onChange={handleChangeQuatityProduct(idx)}
          />
        ) : (
          row?.quantity
        );
      },
    },
    {
      title: '#',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, row, index) => (
        <div className='warehouseInfo-card__button'>
          {!isReadOnly && (
            <Popconfirm
              placement='topRight'
              title='Xác nhận xóa sản phẩm ?'
              onConfirm={() => {
                setProductOutboundDetails((prev) => {
                  let next = [...prev];
                  next = filter(next, (x, idx) => idx !== index);
                  return next;
                });
              }}
              okText='Xác nhận'
              cancelText='Quay lại'
            >
              <Button
                className='warehouseInfo-card__button--right'
                icon={<DeleteOutlined className='warehouseInfo-card__icon' />}
                type='danger'
              />
            </Popconfirm>
          )}
          {id && (
            <Button
              icon={<EditFilled className='warehouseInfo-card__icon' />}
              type='primary'
              onClick={handleChangeProductOutbound(row, index)}
            />
          )}
        </div>
      ),
    },
  ];

  const promotionColumns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: 75,
      render: (item, row, index) => ++index,
    },
    {
      title: 'Mã Sản phẩm',
      dataIndex: 'productCode',
      key: 'productCode',
      width: 200,
    },
    {
      title: 'Tên Sản phẩm',
      dataIndex: 'productName',
      key: 'productName',
      width: 200,
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'unitType',
      key: 'unitType',
      width: 200,
      render: (item, row, idx) => {
        return row?.unitTypeName;
      },
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
      render: (itemValue, row, idx) => {
        return !isReadOnly ? (
          <InputNumber
            placeholder={0}
            min={0}
            defaultValue={row?.quantity}
            onChange={handleChangeQuatityPromotionProduct(idx)}
          />
        ) : (
          row?.quantity
        );
      },
    },
    {
      title: '#',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, row, index) => (
        <div className='warehouseInfo-card__button'>
          {!isReadOnly && (
            <Popconfirm
              placement='topRight'
              title='Xác nhận xóa sản phẩm ?'
              onConfirm={() => {
                setPromotionOutboundDetails((prev) => {
                  let next = [...prev];
                  next = filter(next, (x, idx) => idx !== index);
                  return next;
                });
              }}
              okText='Xác nhận'
              cancelText='Quay lại'
            >
              <Button
                className='warehouseInfo-card__button--right'
                icon={<DeleteOutlined className='warehouseInfo-card__icon' />}
                type='danger'
              />
            </Popconfirm>
          )}
          {id && (
            <Button
              icon={<EditFilled className='warehouseInfo-card__icon' />}
              type='primary'
              onClick={handleChangePromotionProductOutbound(row, index)}
            />
          )}
        </div>
      ),
    },
  ];

  const handleChangeQuatityPromotionProduct = (index) => (value) => {
    const newPromotionOutboundDetails = promotionOutboundDetails.map(
      (product, idx) => {
        if (idx === index) {
          return {
            ...product,
            quantity: value,
          };
        }
        return product;
      }
    );
    setPromotionOutboundDetails(newPromotionOutboundDetails);
  };

  const handleChangePromotionProductOutbound = (row, idx) => () => {
    if (idx === promotionIndex) {
      setPromotionIndex(-1);
    } else {
      setPromotionIndex(idx);
    }
  };

  const handleChangeProductOutbound = (row, idx) => () => {
    if (idx === index) {
      setIndex(-1);
    } else {
      setIndex(idx);
    }
  };

  const checkDuplicateProduct = (productOutboundDetails) => {
    for (let i = 0; i < productOutboundDetails.length; i++) {
      const standardProduct = productOutboundDetails[i];
      const lessProductOutbound = productOutboundDetails.filter(
        (a, index) => index !== i
      );
      for (let j = 0; j < lessProductOutbound.length; j++) {
        if (
          lessProductOutbound[j]?.id === standardProduct?.id &&
          lessProductOutbound[j]?.unitTypeId === standardProduct?.unitTypeId
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSubmit = (values) => {
    const isEmptyProduct = productOutboundDetails.find(
      (product) => !product?.unitTypeId || !product?.quantity
    );

    if (productOutboundDetails.length === 0 || isEmptyProduct) {
      return message.error('Vui lòng chọn đầy đủ sản phẩm');
    }

    if (checkDuplicateProduct(productOutboundDetails)) {
      return message.error('Vui lòng không chọn sản phẩm giống nhau');
    }

    if (
      form?.getFieldsValue()?.documentType ===
      utils.$const.OUTBOUND.DOCUMENT_TYPE[0]?.value
    ) {
      const isEmptyProduct = promotionOutboundDetails.find(
        (product) => !product?.unitTypeId || !product?.quantity
      );

      if (promotionOutboundDetails.length === 0 || isEmptyProduct) {
        return message.error('Vui lòng chọn đầy đủ sản phẩm');
      }

      if (checkDuplicateProduct(promotionOutboundDetails)) {
        return message.error('Vui lòng không chọn sản phẩm giống nhau');
      }
    }

    // setLoading(true);

    const payload = {
      ...values,
      referenceId:
        documentType.warehouse || documentType.editQuantity
          ? null
          : selectedOrder?.purchaseId || outboundDetail?.referenceId,
      purchaseId: undefined,
      productOutboundDetails: productOutboundDetails.map((product) => ({
        unitTypeId: product?.unitTypeId,
        quantity: product?.quantity,
        productId: product?.productId || product?.id,
        currencyId: product?.currencyId,
        notes: product?.notes,
      })),
      promotionOutboundDetails: promotionOutboundDetails.map((product) => ({
        unitTypeId: product?.unitTypeId,
        quantity: product?.quantity,
        productId: product?.productId || product?.id,
        notes: product?.notes,
      })),
    };

    if (!id) {
      services.warehouseService
        .productOutbound_CreateProductOutbound(payload)
        .then((res) => {
          if (res?.succeeded) {
            message.success('Thêm phiếu thành công');
            handleRedirectOutboundPage();
          } else {
            message.error('Thêm phiếu thất bại');
          }
        })
        .catch(() => message.error('Thêm phiếu thất bại'))
        .finally(() => setLoading(false));
    } else {
      services.warehouseService
        .productOutbound_UpdateProductOutbound(id, {
          ...payload,
          currentState: outboundDetail?.currentState,
        })
        .then((res) => {
          if (res?.succeeded) {
            message.success('Sửa phiếu thành công');
            handleRedirectOutboundPage();
          } else {
            message.error('Sửa phiếu thất bại');
          }
        })
        .catch(() => message.error('Sửa phiếu thất bại'))
        .finally(() => setLoading(false));
    }
  };

  const handleChangeOrder = (value) => {
    const curOrder = listOrder.find((order) => order?.purchaseId === value);
    setSelectedOrder(curOrder);
    const currentDistributor = listDistributor.find(
      (distributor) => distributor?.id === curOrder?.distributorId
    );
    setWarehouseInfoDto(currentDistributor?.warehouseInfoDtos || []);
    setProductOutboundDetails(
      curOrder?.purchaseOrderDetails.map((product) => {
        return {
          quantity: product?.orderQuantity,
          productId: product?.productId,
          currencyId: product?.currencyId,
          notes: product?.notes,
          productName: product?.productName,
          productCode: product?.productCode,
          unitTypeName: product?.unitTypeName,
          unitTypeId: product?.unitTypeId,
        };
      })
    );
    setPromotionOutboundDetails(
      curOrder?.promotionOrderDetails.map((product) => {
        return {
          quantity: product?.orderQuantity,
          productId: product?.productId,
          currencyId: product?.currencyId,
          notes: product?.notes,
          productName: product?.productName,
          productCode: product?.productCode,
          unitTypeName: product?.unitTypeName,
          unitTypeId: product?.unitTypeId,
        };
      })
    );
    form.setFieldsValue({
      notes: curOrder?.notes,
      customerId: curOrder?.customerId,
      fromDistributorId: curOrder?.distributorId,
      fromWarehouseId: curOrder?.warehouseId,
      referenceId: curOrder?.purchaseId,
    });
  };

  const handleChangeQuatityProduct = (index) => (value) => {
    const newProductOutboundDetails = productOutboundDetails.map(
      (product, idx) => {
        if (idx === index) {
          return {
            ...product,
            quantity: value,
          };
        }
        return product;
      }
    );
    setProductOutboundDetails(newProductOutboundDetails);
  };

  const handleApproveOrCancelOutbound = (row, type) => () =>
    setConfigModal({
      visible: true,
      data: row,
      type,
    });

  const handleChangeProduct = (type) => (value) => {
    if (!value) {
      return null;
    }
    const [productId, unitTypeId] = value?.split('+');
    const curProduct = listProduct.find(
      (product) =>
        product?.id === productId && product?.unitTypeId === unitTypeId
    );
    if (type === 'order') {
      setProductOutboundDetails([...productOutboundDetails, curProduct]);
    } else {
      setPromotionOutboundDetails([...promotionOutboundDetails, curProduct]);
    }
  };

  const handleChangeFromDistributor = (value) => {
    const distributorItem = listDistributor.find((item) => item.id === value);
    setWarehouseInfoDto(distributorItem?.warehouseInfoDtos || []);
    form.getFieldValue().fromWarehouseId = null;
  };

  const handleChangeToDistributor = (value) => {
    const distributorItem = listDistributor.find((item) => item.id === value);
    setWarehouseToInfoDto(distributorItem?.warehouseInfoDtos || []);
    form.getFieldValue().toWarehouseId = null;
  };

  const handleRedirectOutboundPage = () => {
    history.push(`/warehouse/product-outbound`);
  };

  const handleCancelAction = () =>
    setConfigModal({
      visible: false,
      data: {},
      type: actions.APPROVE,
    });

  const handleApproveAction = () => {
    if (configModal.type === actions.APPROVE) {
      handleApproveOutbound();
    } else {
      handleCancelOutbound();
    }
  };

  const handleApproveOutbound = () => {
    services.warehouseService
      .productOutbound_UpdateStatusProductInbound({
        id: configModal.data?.id,
        fromStatus: 'pending',
        toStatus: 'approved',
        currentState: configModal.data?.currentState,
      })
      .then((res) => {
        if (res?.succeeded) {
          message.success('Duyệt phiếu thành công');
          setIsReloadPage(!isReloadPage);
        } else {
          message.error('Duyệt phiếu thất bại');
        }
      })
      .catch((err) => {
        message.error('Duyệt phiếu thất bại');
      })
      .finally(() => handleCancelAction());
  };

  const handleCancelOutbound = () => {
    services.warehouseService
      .productOutbound_CancelProductOutbound({
        id: configModal?.data?.id,
        currentState: configModal?.data?.currentState ?? 1,
      })
      .then((res) => {
        if (res?.succeeded) {
          message.success('Hủy phiếu thành công');
          setIsReloadPage(!isReloadPage);
        } else {
          message.error('Hủy phiếu thất bại');
        }
      })
      .catch(() => message.error('Hủy phiếu thất bại'))
      .finally(() => handleCancelAction());
  };

  const isReadOnly =
    pathname.includes('detail') ||
    (warehouseInfoDto?.length > 0 &&
      id &&
      outboundDetail?.status !== utils.$const.OUTBOUND.STATUS[0].id);

  const customColumns = isReadOnly
    ? columns.slice(0, columns.length - 1)
    : columns;

  const customPromotionColumns = isReadOnly
    ? promotionColumns.slice(0, promotionColumns.length - 1)
    : promotionColumns;

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      labelCol={{ span: 8 }}
      labelAlign='left'
      className='outbound-modal'
      initialValues={{
        documentType: utils.$const.OUTBOUND.DOCUMENT_TYPE[0]?.value,
      }}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Card headStyle={{ border: 'none' }}>
            <Row gutter={[16]}>
              <Col span={10}>
                <Form.Item
                  label='Mã phiếu xuất'
                  labelAlign='left'
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.documentCode !== curValues.documentCode
                  }
                >
                  {() => {
                    if (isReadOnly) {
                      return (
                        <Typography.Text italic type='success'>
                          {get(outboundDetail, 'documentCode')}
                        </Typography.Text>
                      );
                    }
                    return (
                      <Typography.Text italic type='success'>
                        {!id
                          ? 'Mã phiếu xuất được tạo tự động'
                          : outboundDetail?.documentCode}
                      </Typography.Text>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label='Hình thức xuất'
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.documentType !== curValues.documentType
                  }
                >
                  {() => {
                    if (isReadOnly) {
                      const documentType =
                        utils.$const.OUTBOUND.DOCUMENT_TYPE.find(
                          (x) => x.value === outboundDetail?.documentType
                        );
                      return <Text>{get(documentType, 'name')}</Text>;
                    }
                    return (
                      <Form.Item
                        name='documentType'
                        required
                        rules={[{ required: true, message: 'Vui lòng nhập' }]}
                      >
                        <Select
                          placeholder='Hình thức xuất'
                          allowClear
                          onChange={handleChangeOutboundType}
                        >
                          {map(utils.$const.OUTBOUND.DOCUMENT_TYPE, (stat) => (
                            <Select.Option key={stat.id} value={stat.value}>
                              {stat.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              {(form?.getFieldsValue()?.documentType ===
                utils.$const.OUTBOUND.DOCUMENT_TYPE[0]?.value ||
                (isReadOnly &&
                  outboundDetail.documentType ===
                    utils.$const.OUTBOUND.DOCUMENT_TYPE[0]?.value)) && (
                <Col span={10}>
                  <Form.Item
                    label='Đơn hàng'
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.purchaseId !== curValues.purchaseId
                    }
                  >
                    {() => {
                      if (isReadOnly) {
                        return (
                          <Text>{get(outboundDetail, 'referenceId')}</Text>
                        );
                      }
                      return (
                        <Form.Item
                          name='purchaseId'
                          required={documentType.customer}
                          rules={[
                            {
                              required: documentType.customer,
                              message: 'Chọn đơn hàng',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Chọn đơn hàng'
                            allowClear
                            onChange={handleChangeOrder}
                          >
                            {listOrder.map((order) => (
                              <Select.Option
                                value={order?.purchaseId}
                                key={order?.purchaseId}
                              >
                                {order?.purchaseId}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}

              <Col span={10}>
                <Form.Item
                  label={
                    form?.getFieldsValue()?.documentType ===
                      utils.$const.OUTBOUND.DOCUMENT_TYPE[1]?.value ||
                    (isReadOnly &&
                      outboundDetail?.documentType ===
                        utils.$const.OUTBOUND.DOCUMENT_TYPE[1]?.value)
                      ? 'Nhà phân phối đi'
                      : 'Nhà phân phối'
                  }
                  labelAlign='left'
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.distributorId !== curValues.distributorId
                  }
                >
                  {({ getFieldValue }) => {
                    if (isReadOnly) {
                      const documentType = (listDistributor || []).find(
                        (x) => x.id === getFieldValue('fromDistributorId')
                      );
                      return (
                        <Text>{get(documentType, 'distributorName')}</Text>
                      );
                    }
                    return (
                      <Form.Item
                        name='fromDistributorId'
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập',
                          },
                        ]}
                      >
                        <Select
                          onChange={handleChangeFromDistributor}
                          placeholder='Chọn nhà phân phối'
                          disabled={documentType.customer}
                        >
                          {listDistributor.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                              {item.distributorName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col span={10}>
                <Form.Item
                  label='Từ kho'
                  labelAlign='left'
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.customerIdObj !== curValues.customerIdObj
                  }
                >
                  {({ getFieldValue }) => {
                    if (isReadOnly) {
                      const warehouse = (warehouseInfoDto || []).find(
                        (x) => x.id === getFieldValue('fromWarehouseId')
                      );
                      return <Text>{get(warehouse, 'warehouseName')}</Text>;
                    }
                    return (
                      <Form.Item
                        name='fromWarehouseId'
                        required
                        rules={[
                          { required: true, message: 'Vui lòng chọn kho đi' },
                        ]}
                      >
                        <Select
                          placeholder='Chọn kho đi'
                          disabled={documentType?.customer}
                        >
                          {warehouseInfoDto.map((item, i) => (
                            <Select.Option key={i} value={item.id}>
                              {item.warehouseName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              {(form?.getFieldsValue()?.documentType ===
                utils.$const.INBOUND.DOCUMENT_TYPE[1]?.value ||
                outboundDetail?.documentType ===
                  utils.$const.INBOUND.DOCUMENT_TYPE[1]?.value) && (
                <Col span={10}>
                  <Form.Item
                    label='Nhà phân phối đến'
                    labelAlign='left'
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.distributorId !== curValues.distributorId
                    }
                  >
                    {({ getFieldValue }) => {
                      if (isReadOnly) {
                        const documentType = (listDistributor || []).find(
                          (x) => x.id === getFieldValue('toDistributorId')
                        );
                        return (
                          <Text>{get(documentType, 'distributorName')}</Text>
                        );
                      }
                      return (
                        <Form.Item
                          name='toDistributorId'
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập',
                            },
                          ]}
                        >
                          <Select
                            onChange={handleChangeToDistributor}
                            placeholder='Chọn nhà phân phối'
                          >
                            {listDistributor.map((item) => (
                              <Select.Option value={item.id} key={item.id}>
                                {item.distributorName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}

              {(form?.getFieldsValue()?.documentType ===
                utils.$const.OUTBOUND.DOCUMENT_TYPE[1]?.value ||
                (isReadOnly &&
                  outboundDetail?.documentType ===
                    utils.$const.OUTBOUND.DOCUMENT_TYPE[1]?.value)) && (
                <Col span={10}>
                  <Form.Item
                    label='Đến kho'
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.customerIdObj !== curValues.customerIdObj
                    }
                  >
                    {({ getFieldValue }) => {
                      if (isReadOnly) {
                        const documentType = (warehouseToInfoDto || []).find(
                          (x) => x.id === getFieldValue('toWarehouseId')
                        );
                        return (
                          <Text>{get(documentType, 'warehouseName')}</Text>
                        );
                      }
                      return (
                        <Form.Item
                          name='toWarehouseId'
                          required={documentType.warehouse}
                          rules={[
                            {
                              required: documentType.warehouse,
                              message: 'Vui lòng chọn kho đến',
                            },
                          ]}
                        >
                          <Select
                            disabled={documentType?.customer}
                            placeholder='Chọn kho đến'
                          >
                            {warehouseToInfoDto.map((item, i) => (
                              <Select.Option key={i} value={item.id}>
                                {item.warehouseName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}

              {form?.getFieldsValue()?.documentType ===
                utils.$const.OUTBOUND.DOCUMENT_TYPE[1]?.value && (
                <Col span={10}>
                  <Form.Item
                    label='Người đại diện'
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.documentCode !== curValues.documentCode
                    }
                  >
                    {({ getFieldValue }) => {
                      if (isReadOnly) {
                        return <Text>{getFieldValue('referenceId')}</Text>;
                      }
                      return (
                        <Form.Item
                          name='referenceId'
                          required
                          rules={[
                            {
                              required: documentType.customer,
                              message: 'Nhập người đại diện',
                            },
                          ]}
                        >
                          <Input disabled placeholder='Nhập người đại diện' />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}

              {(form?.getFieldsValue()?.documentType ===
                utils.$const.OUTBOUND.DOCUMENT_TYPE[0]?.value ||
                (isReadOnly &&
                  outboundDetail?.documentType ===
                    utils.$const.OUTBOUND.DOCUMENT_TYPE[0]?.value)) && (
                <Col span={10}>
                  <Form.Item
                    label='Khách hàng'
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.customerId !== curValues.customerId
                    }
                  >
                    {() => {
                      if (isReadOnly) {
                        return (
                          <Text>
                            {get(outboundDetail, 'customer.customerName')}
                          </Text>
                        );
                      }
                      return (
                        <Form.Item
                          name='customerId'
                          required={documentType.customer}
                          rules={[
                            {
                              required: documentType.customer,
                              message: 'Chọn khách hàng',
                            },
                          ]}
                        >
                          <Select
                            placeholder='Chọn khách hàng'
                            allowClear
                            disabled={documentType?.customer}
                          >
                            {listCustomer.map((customer) => (
                              <Select.Option
                                value={customer?.id}
                                key={customer?.id}
                              >
                                {customer?.customerName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}

              <Col span={10}>
                <Form.Item
                  label='Ngày xuất'
                  labelAlign='left'
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.documentType !== curValues.documentType
                  }
                >
                  {({ getFieldValue }) => {
                    if (isReadOnly) {
                      return (
                        <Text>
                          {moment(getFieldValue('outDate')).format(
                            'DD/MM/YYYY'
                          )}
                        </Text>
                      );
                    }
                    return (
                      <Form.Item
                        name='outDate'
                        required
                        rules={[{ required: true, message: 'Vui lòng nhập' }]}
                        disabled={documentType?.customer}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          placeholder='Chọn ngày xuất'
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              {id && (
                <Col span={10}>
                  <Form.Item
                    label='Trạng thái'
                    labelAlign='left'
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.inDate !== curValues.inDate
                    }
                  >
                    {() => {
                      const curStatus = utils.$const.OUTBOUND.STATUS.find(
                        (x) => x.id === outboundDetail?.status
                      );
                      return curStatus ? (
                        <div className={`text--${curStatus?.color}`}>
                          {curStatus?.name}
                        </div>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row gutter={[16]}>
              <Col span={20}>
                <Form.Item
                  label='Ghi chú'
                  labelCol={{ span: 4 }}
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.documentCode !== curValues.documentCode
                  }
                >
                  {({ getFieldValue }) => {
                    if (isReadOnly) {
                      return <Text>{getFieldValue('notes')}</Text>;
                    }
                    return (
                      <Form.Item name='notes' required>
                        <TextArea
                          placeholder='Nhập ghi chú'
                          rows={5}
                          disabled={documentType?.customer}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Card>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div className='d-flex align-items-center'>
              <h5 className='mb-0 mr-2' style={{ whiteSpace: 'nowrap' }}>
                Chi tiết xuất hàng
              </h5>
              {!isReadOnly && (
                <Select
                  showSearch
                  style={{ width: 300 }}
                  placeholder='Chọn sản phẩm'
                  onSelect={handleChangeProduct('order')}
                  allowClear
                >
                  {listProduct.map((product) => (
                    <Select.Option
                      key={`${product?.id}-${product?.unitTypeId}`}
                      value={`${product?.id}+${product?.unitTypeId}`}
                    >
                      {product.productName} - {product.unitTypeName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>
          </Col>
          <Col span={24}>
            <Table
              dataSource={productOutboundDetails}
              columns={customColumns}
              pagination={false}
              style={{ maxHeight: '300px', overflow: 'auto' }}
            />
          </Col>
        </Row>
      </Card>
      {(form?.getFieldsValue()?.documentType ===
        utils.$const.OUTBOUND.DOCUMENT_TYPE[0]?.value ||
        (isReadOnly &&
          outboundDetail?.documentType ===
            utils.$const.OUTBOUND.DOCUMENT_TYPE[0]?.value)) && (
        <Card>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <div className='d-flex align-items-center'>
                <h5 className='mb-0 mr-2' style={{ whiteSpace: 'nowrap' }}>
                  Chi tiết khuyến mãi
                </h5>
                {!isReadOnly && (
                  <Select
                    showSearch
                    style={{ width: 300 }}
                    placeholder='Chọn sản phẩm'
                    onSelect={handleChangeProduct('promotion')}
                    allowClear
                  >
                    {listProduct.map((product) => (
                      <Select.Option
                        key={`${product?.id}-${product?.unitTypeId}`}
                        value={`${product?.id}+${product?.unitTypeId}`}
                      >
                        {product.productName} - {product.unitTypeName}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </div>
            </Col>
            <Col span={24}>
              <Table
                dataSource={promotionOutboundDetails}
                columns={customPromotionColumns}
                pagination={false}
                style={{ maxHeight: '300px', overflow: 'auto' }}
              />
            </Col>
          </Row>
        </Card>
      )}
      <Row>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '15px',
            marginTop: '10px',
          }}
        >
          {outboundDetail?.status === utils.$const.OUTBOUND.STATUS[0].id &&
            !isReadOnly && (
              <Fragment>
                <Button
                  type='ghost'
                  onClick={handleApproveOrCancelOutbound(
                    outboundDetail,
                    actions.APPROVE
                  )}
                  style={{ backgroundColor: '#22d368' }}
                >
                  <span className='d-flex align-items-center'>Duyệt</span>
                </Button>
                <Button
                  type='danger'
                  onClick={handleApproveOrCancelOutbound(
                    outboundDetail,
                    actions.CANCEL
                  )}
                >
                  <span className='d-flex align-items-center'>Hủy</span>
                </Button>
              </Fragment>
            )}
          {!isReadOnly && (
            <Button
              htmlType='submit'
              type='primary'
              loading={loading}
              disabled={loading}
            >
              Lưu
            </Button>
          )}
          <Button onClick={handleRedirectOutboundPage} disabled={loading}>
            Quay lại
          </Button>
        </Col>
      </Row>
      <ConfirmModal
        title={
          configModal.type === actions.APPROVE
            ? 'Duyệt phiếu xuất'
            : 'Hủy phiếu xuất'
        }
        visible={configModal.visible}
        onCancel={handleCancelAction}
      >
        <div className='confirm-modal__content'>
          Bạn có chắc muốn{' '}
          <strong>
            {configModal.type === actions.APPROVE ? 'duyệt' : 'hủy'}
          </strong>{' '}
          phiếu <strong>{configModal?.data?.documentCode}</strong> không?
        </div>
        <div className='confirm-modal__footer'>
          <Button type='danger' onCancel={handleCancelAction}>
            Không
          </Button>
          <Button type='primary' onClick={handleApproveAction}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Form>
  );
};

export default OutboundModal;
