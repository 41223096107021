import { Button, Modal, Row, Col, Spin, Image } from "antd";
import React, { useEffect, useState } from "react";
import services from "services";
import moment from "moment";
import NoImg from './../../../images/NoImg.jpg';

const VitsitControlModal = ({
  visible,
  onCancel,
  // selectedProductInfo,
  // onReload,
  detailCheckin,
  ...rest
}) => {

  const [staffInfo, setStaffInfo] = useState(
    {
      fullName : '',
      email : '',
      phoneNumber : '',
      avatar : ''
    }
  );
  
  const [isShowSpiner, setShowSpiner] = useState(false);

  useEffect(() => {
    setShowSpiner(true)
    services.userManagementService.resource_GetAllResources().then(res => {
      let staff = res.find(x => x.userId === detailCheckin.staffId)
      let staffInfo = {
        fullName : staff.fullName,
        email : staff.identifier.email,
        phoneNumber : staff.identifier.phoneNumber,
        avatar : staff.avatar
      }
      setStaffInfo(staffInfo);
      setShowSpiner(false)
    }).catch(erro =>{
      let staffInfo = {
        fullName : '',
        email : '',
        phoneNumber : '',
        avatar : NoImg,
      }
      setStaffInfo(staffInfo);
      setShowSpiner(false)
    });

  }, [detailCheckin]);


  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      {...rest}
      title="Thông tin chi tiết"
      loading={true}
      width="50%"
      footer={
        [
          <Button
            key="back"
            onClick={onCancel}
          >
            Quay lại
          </Button>,
        ]
      }
    >
      <Row>
        <Col span={12}>
          <label>Tên Nhân Viên: </label>
          <strong> {staffInfo.fullName}</strong>
        </Col>
        <Col span={12}>
          Hình Đại Diện
          <Image
            width={150}
            style={{ position: "absolute", top: "-30px" }}
            src={staffInfo.avatar}
          />
        </Col>
      </Row>
      <Col span={12}>
          <label>Email: </label>
          <strong> {staffInfo.email}</strong>
        </Col>
        <Col span={12}>
          <label>Số ĐT: </label>
          <strong> {staffInfo.phoneNumber}</strong>
        </Col>
        <Col span={12}>
          <label>Thời Gian Check In: </label>
          <strong> {moment(detailCheckin?.startInspectionTime).format('HH:mm:ss / DD-MM-YYYY')}</strong>
        </Col>
       
      <Row>
        <Col span={12}>
          <label>Thời Gian Check Out: </label>
          <strong> {moment(detailCheckin?.endInspectionTime).format('HH:mm:ss / DD-MM-YYYY')}</strong>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <strong style={{color:"red"}}>Ghi Chú: </strong>
          <p>{detailCheckin?.comment?.comments}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <strong>Danh Sách Hình Check In: </strong>
        </Col>
      </Row>
      <Row style={{flexFlow:"inherit", overflow:"auto"}}>
        <Image.PreviewGroup>
        {detailCheckin?.imageList?.map((item, i) =>
          (
            <Image
            width={150}
            style={{width:"150px", height:"150px"}}
            src={item?.imageFullUrl}
          />
          ))}
        
        </Image.PreviewGroup>
      </Row>
      <Spin style={{ position: "absolute", left: "50%", right: "50%", top: "50%", display: isShowSpiner ? "" : "none" }} size="large" />
    </Modal>
  );
};

export default VitsitControlModal;
