/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Modal Thêm/Sửa nhân viên
 * */

import { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
} from "antd";
import PropTypes from "prop-types";
import utils from "utils";
import services from "services";
import { map } from "lodash";
const UserRoleModal = function ({ config, onCancel }) {
  const { visible, dataSource, mode } = config;
  const [form] = Form.useForm();
  const [fetchingSaveUser, setFetchingSaveRole] = useState(false);

  const handleConfirm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleSubmit = useCallback(
    (values) => {
      setFetchingSaveRole(true);
      if (mode === utils.$const.COMMON.MODAL_MODE.EDIT) {
        services.organization
          .roleDefinition_Update(values)
          .then((res) => {
            message.success("Lưu chức danh thành công.");
            onCancel();
            form.resetFields();
          })
          .catch((error) =>
            message.error("Lưu chức danh không thành công.")
          )
          .finally(() => {
            setFetchingSaveRole(false);
          });
      }
    },
    [mode, onCancel, form]
  );
  const handleCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (visible && mode === utils.$const.COMMON.MODAL_MODE.EDIT) {
      // set dữ liệu trong dataSource vào form
      form.setFieldsValue({
        id: dataSource.id,
        currentState: dataSource.currentState,
        titleCode: dataSource.titleCode,
        titleName: dataSource.titleName,
        description: dataSource.description,
        status: dataSource.status,
      });
    }
  }, [dataSource, visible, mode, form]);

  const renderTitle = () =>
    mode === utils.$const.COMMON.MODAL_MODE.ADD
      ? `Thêm mới chức danh`
      : "Chỉnh sửa chức danh";
  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      width={600}
      onCancel={handleCancelModal}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleConfirm}
          loading={fetchingSaveUser}
        >
          Xác nhận
        </Button>,
        <Button key="back" onClick={handleCancelModal}>
          Quay lại
        </Button>,
      ]}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        labelAlign="left"
        initialValues={{
          status: 1,
        }}
      >
        <Form.Item
          label="Mã chức danh"
          name="titleCode"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Input placeholder="Nhập mã chức danh" />
        </Form.Item>
        <Form.Item
          label="Tên chức danh"
          name="titleName"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Input placeholder="Nhập tên chức danh" />
        </Form.Item>
        <Form.Item
          label="Mô tả"
          name="description"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Input.TextArea rows={2} placeholder="Nhập mô tả chức danh" />
        </Form.Item>
        <Form.Item
          name="status"
          label="Trạng thái"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Select placeholder="Chọn trạng thái">
            {map(utils.$const.ROLE.STATUS, (item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item hidden name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};
UserRoleModal.propTypes = {
  config: PropTypes.any,
  onCancel: PropTypes.func,
};
export default UserRoleModal;
