import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Table,
  Checkbox,
  InputNumber,
  message,
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import services from "services";

const DAY_OF_WEEKS = {
  Monday: "Thứ 2",
  Tuesday: "Thứ 3",
  Wednesday: "Thứ 4",
  Thursday: "Thứ 5",
  Friday: "Thứ 6",
  Saturday: "Thứ 7",
  Sunday: "Chủ nhật",
};

const SaleRouteAddCustomerModal = function ({ config, onCancel }) {
  const { visible, dataSource } = config;
  const [form] = Form.useForm();
  const { saleRouteId, saleRouteCode, currentState } = dataSource;
  const [listBrandGroups, setListBrandGroups] = useState([]);
  const [fetchingCustomer, setFetchingCustomer] = useState(false);
  const [listCustomer, setListCustomer] = useState([]);
  const [listCustomerPagination, setListCustomerPagination] = useState({
    total: 0,
    pageSize: 20,
  });
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const handleConfirm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleCancelModal = useCallback(() => {
    form.resetFields();
    onCancel();
    setListCustomer([])
  }, [form, onCancel]);

  const rowSelection = {
    selectedCustomers,
    onChange: (selectedRows) => {
      setSelectedCustomers(selectedRows);
    },
  };

  const renderTitle = "Thêm khách hàng vào tuyến";

  const columns = [
    {
      title: "Mã KH",
      dataIndex: "customerCode",
      key: "customerCode",
      sorter: {
        compare: (a, b) => b.customerCode > a.customerCode,
        multiple: 5,
      },
      width: 50,
    },
    {
      title: "Tên KH",
      dataIndex: "customerName",
      key: "customerName",
      sorter: {
        compare: (a, b) => b.customerName > a.customerName,
        multiple: 4,
      },
      width: 120,
    },
    {
      title: "Điện thoại",
      dataIndex: "phonenumber",
      key: "phonenumber",
      sorter: {
        compare: (a, b) => b.phonenumber > a.phonenumber,
        multiple: 3,
      },
      width: 100,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      sorter: {
        compare: (a, b) => b.address > a.address,
        multiple: 3,
      },
      width: 150,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 100,
      align: "center",
      render: (value, row) => {
        return (
          <div className={`status--${value ? "active" : "inactive"}`}>
            {value ? "Hoạt động" : "Không hoạt động"}
          </div>
        );
      },
    },
  ];

  const handleGetListBrandGroups = useCallback(async () => {
    try {
      services.generalService
        .customerGroup_GetBsWithPagination(1, 100)
        .then((res) => {
          setListBrandGroups(res?.items ?? []);
        })
        .catch(() => {
          setListBrandGroups([]);
        });
    } catch (error) {
      setListBrandGroups([]);
    }
  }, []);

  useEffect(() => {
    handleGetListBrandGroups();
  }, [handleGetListBrandGroups]);

  const handleConfirmSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const handleSearch = (values) => {
    setFetchingCustomer(true);
    let filter = {
      keyword: form.getFieldValue()?.searchKeyword,
      saleRouteId: form.getFieldValue()?.isHasSaleRouteId ? saleRouteId : null,
      customerGroupId: form.getFieldValue()?.customerGroupId,
      // isHasSaleRouteId: form.getFieldValue()?.isHasSaleRouteId,
      pageNumber: 1,
      pageSize: 100,
    };
    services.generalService
      .saleRoute_FindCustomerWithFilter(filter)
      .then((res) => {
        setFetchingCustomer(false);
        setListCustomer(res.items);
        setListCustomerPagination({ total: res.totalCount, pageSize: 20 });
      })
      .catch((err) => {
        setListCustomer([]);
        setFetchingCustomer(false);
        setListCustomerPagination({ total: 0, pageSize: 20 });
      });
  };

  const handleSubmit = (values) => {
    // form.submit();
    const params = {
      saleRouteId: saleRouteId,
      currentState: currentState,
      saleRouteDetails: selectedCustomers.map((cus) => ({
        customerInformationId: cus,
        visitFrequency: values.visitFrequency.map((index) => ({
          weekIndex: index,
        })),
        visitAttendanceIndex: values.visitAttendanceIndex,
        dateOfVisitings: values.dateOfVisitings.map((date) => ({ date: date })),
      })),
    };
    services.generalService
      .saleRoute_AddCustomerToSaleRoute(params)
      .then((res) => {
        setTimeout(() => {
          message.success("Thêm thành công.");
        }, 300);
        form.resetFields();
        handleCancelModal();
      })
      .catch((err) => message.error("Lưu không thành công, hãy thử lại."));
  };
  return (
    <Modal
      visible={visible}
      title={renderTitle}
      onOk={handleConfirm}
      centered
      onCancel={handleCancelModal}
      width="80%"
      footer={[
        <Button onClick={handleConfirmSubmit} key="submit" type="primary">
          Thêm vào tuyến
        </Button>,
        <Button onClick={handleCancelModal} key="back">
          Quay lại
        </Button>,
      ]}
    >
      <Form
        // initialValues={{ saleRouteId: saleRouteCode }}
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
      >
        <Row gutter={[24]}>
          {/* <Col lg={24} xl={6}>
            <Form.Item name="saleRouteId" label="Tuyến bán hàng">
              <Input value={saleRouteCode} disabled />
            </Form.Item>
          </Col> */}
          <Col lg={24} xl={6}>
            <Form.Item name="customerGroupId" label="Nhóm KH">
              <Select placeholder="Chọn nhóm KH">
                {listBrandGroups.map((brand) => (
                  <Select.Option key={brand.id} value={brand.id}>
                    {brand.customerGroupName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name="searchKeyword" label="Tìm kiếm theo từ khoá">
              <Input placeholder="Nhập từ khoá" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name="isHasSaleRouteId" label="KH đã có tuyến">
              <Select placeholder="KH đã vào tuyến">
                <Select.Option key={true} value={true}>
                  KH đã có tuyến
                </Select.Option>
                <Select.Option key={false} value={false}>
                  KH chưa có tuyến
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" gutter={[8]}>
          <Col>
            <Button type="primary" onClick={handleSearch}>
              <span className="d-flex align-items-center">
                <SearchOutlined />
                <span className="ml-1">Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          {/* <Col>
            <Button onClick={handleConfirmSubmit} key="submit">
              <span className="d-flex align-items-center">
                <PlusOutlined />
                <span className="ml-1">Tạo mới</span>
              </span>
            </Button>
          </Col> */}
        </Row>

        <Table
          rowSelection={rowSelection}
          rowKey="id"
          bordered
          size="small"
          columns={columns}
          dataSource={listCustomer}
          locale={{ emptyText: "Không có khách hàng" }}
          scroll={{ x: 1200, y: 500 }}
          style={{ marginTop: "1em" }}
          pagination={listCustomerPagination}
          loading={fetchingCustomer}
          onChange={() => { }}
        />
        <Row gutter={[24]}>
          <Col lg={8} xl={8}>
            <Form.Item
              name="visitAttendanceIndex"
              label="Thứ tự viếng thăm"
              // required
              rules={[
                // { required: true, message: "Thiếu Thứ tự viếng thăm" },
                {
                  type: "number",
                  min: 0,
                  message: "Thứ tự viếng thăm không được nhỏ hơn 0",
                },
                {
                  type: "number",
                  max: 99,
                  message: "Thứ tự viếng thăm nhỏ hơn 100",
                },
              ]}
            >
              <InputNumber
                style={{ minWidth: "100%" }}
                placeholder="Nhập thứ tự viếng thăm"
              />
            </Form.Item>
          </Col>

          <Col lg={8} xl={8}>
            <Form.Item
              required
              name="dateOfVisitings"
              label="Ngày VT Trong tuần"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Chọn ngày VT"
                rules={[
                  { required: true, message: "Thiếu Ngày VT Trong tuần" }]}
              >
                {Object.entries(DAY_OF_WEEKS).map(([key, value], index) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={8}>
            <Form.Item
              rules={[
                { required: true, message: "Thiếu Tần suất viếng thăm" }]}
              name="visitFrequency"
              label="Tần suất viếng thăm"
            >
              <Checkbox.Group>
                <Row>
                  <Col span={6}>
                    <Checkbox value={1} style={{ lineHeight: "32px" }}>
                      W1
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={2} style={{ lineHeight: "32px" }}>
                      W2
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={3} style={{ lineHeight: "32px" }}>
                      W3
                    </Checkbox>
                  </Col>
                  <Col span={6}>
                    <Checkbox value={4} style={{ lineHeight: "32px" }}>
                      W4
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

SaleRouteAddCustomerModal.propTypes = {
  config: PropTypes.any,
  onCancel: PropTypes.func,
};

export default SaleRouteAddCustomerModal;
