import { memo, useState, useEffect } from 'react';
import { Table, Card, Select, Col, Row, Button, Form, Divider } from 'antd';
import _, { map, omit } from 'lodash';
import utils from 'utils';

import { SearchOutlined } from '@ant-design/icons';
import services from 'services';

const Index = memo(() => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [listProduct, setListProduct] = useState([]);
  const [listDistributor, setListDistributor] = useState([]);
  const [warehouseInfoDto, setWarehouseInfoDto] = useState([]);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    services.generalService
      .products_GetAllProducts()
      .then((res) => setListProduct(res));
  }, []);

  useEffect(() => {
    const getAllDistributor = () => {
      services.generalService.distributor_GetAllDistributorsWithPermission().then((res) => {
        setListDistributor(res);
      });
    };
    getAllDistributor();
  }, []);

  const [params, setParams] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const getProducts = () => {
    setLoading(true);
    services.warehouseService
      .productInventory_GetBsWithPagination(params.pageNumber, params.pageSize)
      .then((res) => {
        setData(res?.items || []);
        setTotal(_.get(res, 'totalCount'));
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterProduct = () => {
    setLoading(true);
    services.warehouseService
      .productInventory_GetProductInventoryFilter(params)
      .then((res) => {
        setData(res?.items || []);
        setTotal(_.get(res, 'totalCount'));
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const newParams = omit(params, ['pageNumber', 'pageSize']);
    if (Object.values(newParams).length === 0) {
      getProducts();
    } else {
      handleFilterProduct();
    }
  }, [params]);

  const handleChangePaging = (page, pageSize) => {
    const currentPage = pageSize !== params?.pageSize ? 1 : page;
    setParams({ ...params, page: currentPage, pageSize });
  };

  const handleSubmit = (values) => {
    setParams({ ...params, ...values });
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      width: 80,
      render: (text, row, index) => {
        return index + 1;
      },
    },
    {
      title: 'Mã nhà phân phối',
      dataIndex: 'distributorCode',
      width: 250,
      ellipsis: true,
      render: (text, row) => {
        return _.get(row, 'warehouse.distributor.distributorCode');
      },
    },
    {
      title: 'Tên nhà phân phối',
      dataIndex: 'distributorName',
      width: 250,
      ellipsis: true,
      render: (text, row) => {
        return _.get(row, 'warehouse.distributor.distributorName');
      },
    },
    {
      title: 'Mã kho',
      dataIndex: 'warehouseCode',
      width: 200,
      ellipsis: true,
      render: (text, row) => {
        return {
          children: _.get(row, 'warehouse.warehouseCode'),
        };
      },
    },
    {
      title: 'Tên kho',
      dataIndex: 'warehouseName',
      width: 250,
      ellipsis: true,
      render: (text, row) => {
        return {
          children: _.get(row, 'warehouse.warehouseName'),
        };
      },
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'number_customer',
      width: 130,
      ellipsis: true,
      render: (text, row) => {
        return {
          children: _.get(row, 'product.productCode'),
        };
      },
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'productName',
      width: 200,
      ellipsis: true,
      render: (text, row) => {
        return {
          children: _.get(row, 'product.productName'),
        };
      },
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'unitTypeName',
      width: 130,
      ellipsis: true,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'salePrice',
      width: 130,
      ellipsis: true,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      title: 'Số lượng tồn',
      dataIndex: 'inventory',
      width: 130,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'totalAmount',
      width: 130,
      align: 'right',
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'notes',
      width: 200,
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updateTime',
      width: 150,
      render: (text, row) => {
        return utils.$datetime.formatDatetime(text);
      },
    },
    {
      title: 'Người cập nhật',
      dataIndex: 'number_customer',
      width: 150,
    },
  ];

  const handleChangeDistributor = (value) => {
    const distributorItem = listDistributor.find((item) => item.id === value);
    setWarehouseInfoDto(distributorItem?.warehouseInfoDtos);
    form.setFieldsValue({ warehouseId: null });
  };

  return (
    <Card className='ProductInboundPage'>
      <Form onFinish={handleSubmit} form={form} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='distributorId' label='Nhà phân phối'>
              <Select
                placeholder='Chọn nhà phân phối'
                allowClear
                onChange={handleChangeDistributor}
              >
                {listDistributor.map((item) => (
                  <Select.Option value={item.id}>
                    {item.distributorName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='productId' label='Sản phẩm'>
              <Select placeholder='Chọn sản phẩm' allowClear>
                {listProduct.map((item) => (
                  <Select.Option id={item.id} value={item.id}>
                    {item.productName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='warehouseId' label='Kho'>
              <Select placeholder='Chọn kho' allowClear>
                {map(warehouseInfoDto, (warehouse) => (
                  <Select.Option key={warehouse?.id} value={warehouse?.id}>
                    {warehouse?.warehouseName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' gutter={[8]}>
          <Col>
            <Button type='primary' htmlType='submit' disabled={loading}>
              <span className='d-flex align-items-center'>
                <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Table
        className='warehouseInfo-card__table'
        columns={columns}
        dataSource={data}
        bordered
        scroll={{ x: 1200 }}
        rowKey={(row) => row?.id}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: params?.pageNumber ?? 1,
          pageSize: params?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
        loading={loading}
        locale={{ emptyText: 'Không có dữ liệu' }}
      />
    </Card>
  );
});

export default Index;
