import React, {
  memo,
} from "react";
import {
  lineSymbol,
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline
} from "react-google-maps";
import _ from "lodash";
import CustomerMarker from "./CustomerMarker/index";

const Map = memo(({ dataMerker, dataUser, data, dataPolyline }) => {
  const centerUser = {
    lat: (data && data.length > 0)  ? data[0].latitude : 15.818135592909831,
    lng: (data && data.length > 0)  ? data[0].longitude : 108.2856855718962
  };
  const symbolOne = {
    path: 'M 0,0 5,15 -5,15 0,0 z', // 0,0 is the tip of the arrow
    fillColor: '#00FF00',
    fillOpacity: 2.0,
    strokeColor: 'red',
    strokeWeight: 0.5,
  };
  return (
    <div>
      <GoogleMap
        defaultZoom={13}
        center={centerUser}
        options={{
          gestureHandling: "greedy"        
        }}
      >
        {_.map(data, (item, index) => {
          return <CustomerMarker item={item} key={item.keyRow} data={data}/>;
        })}
         {dataPolyline !== undefined && dataPolyline.length > 1 && (
            <Polyline
                path={dataPolyline}
                geodesic={true}
                options={{
                    strokeColor: "#ff2527",
                    strokeOpacity: 0.75,
                    strokeWeight: 2,
                    icons: [
                        {
                            icon: symbolOne,
                            offset: "0",
                            repeat: "300px"
                        }
                    ]
                }}
            />
          )}

      </GoogleMap>
    </div>
  );
});

const MapWrapped = withScriptjs(withGoogleMap(Map));
const App = memo(({ dataMap, dataUser, data, dataPolyline, ggMapKey }) => {
  return (
    <div style={{ width: "100%", height: "95vh" }}>
      <MapWrapped
        dataMerker={dataMap}
        dataUser={dataUser}
        data={data}
        dataPolyline={dataPolyline}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places${ggMapKey ? ('&key=' + ggMapKey) : ''}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
});
export default App;
