import React, { useCallback, useState } from "react";
// Services
import services from "services";
// Redux
import AuthSlice from "redux/auth/authSlice";

import { Form, Input, Button, Row, Col, Checkbox, Typography } from "antd";
import Logo from "images/logo-dms.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { get } from "lodash";
import ForgetPassworModal from "components/modals/ForgetPasswordModal";
import BackGroundLogin from "images/back-ground.png";
const { Text } = Typography;

const LoginPage = function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [message, setMessage] = useState("");
  const [fetchingLogin, setFetchingLogin] = useState(false);
  const [isShowRegisterForm, setIsShowRegisterForm] = useState(false);

  const handleSubmit = useCallback(
    (values) => {
      setFetchingLogin(true);
      setMessage("");
      services.userManagementService
        .user_Login(values)
        .then((res) => {
          const accessToken = get(res, "accessToken");
          const refreshToken = get(res, "refreshToken");
          const originalUserName = get(res, "originalUserName");
          const userName = get(res, "originalUserName");
          const userStatus = get(res, "userStatus");
          const userId = get(res, "userId");
          const roles = get(res, "roles");
          if (accessToken) {
            dispatch(
              AuthSlice.actions.setAuthenticate({
                isAuthenticated: true,
                user: { originalUserName, userName, userStatus, roles, userId ,accessToken},
                accessToken: accessToken,
                refreshToken: refreshToken,
              })
            );
            setTimeout(() => {
              history.push("/");
              window.location.reload(true); //refresh token when calling api
            }, 100);
          } else {
            setMessage("Có lỗi khi đăng nhập vui lòng thử lại");
            setFetchingLogin(false);
          }
        })
        .catch((error) => {
          setMessage("Có lỗi khi đăng nhập vui lòng thử lại");
          setFetchingLogin(false);
        });
    },
    [history, dispatch]
  );
  const handleShowUserRegisterModal = useCallback(() => {
    setIsShowRegisterForm(true);
  }, []);
  const handleCancelUserRegisterModal = useCallback(() => {
    setIsShowRegisterForm(false);
  }, []);
  return (
    <div
      id="login-page"
      style={{
        backgroundImage: `url(${BackGroundLogin})`,
      }}
    >
      <Form
        initialValues={{
          userName: "admin",
          password: "ad@123456",
        }}
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        form={form}
        className="login__form"
      >
        <div className="login__logo">
          <img src={Logo} alt="Danh Huy Soft" height="73" width="300" />
        </div>
        <h3 className="text-dark text-center mb-5">Đăng nhập</h3>
        <Form.Item
          label="Tên tài khoản"
          name="userName"
          rules={[{ required: true, message: "Vui lòng nhập tên đăng nhập" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}
        >
          <Input.Password />
        </Form.Item>
        <Row className="login__form-extra-action">
          <Col span={12}>
            <Form.Item
              name="rememberMe"
              valuePropName="checked"
              wrapperCol={{ span: 16 }}
            >
              <Checkbox>Ghi nhớ</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <div
              className="h-100 d-flex justify-content-end"
              style={{ cursor: "pointer" }}
            >
              <Text type="secondary" onClick={handleShowUserRegisterModal}>
                Quên mật khẩu?
              </Text>
            </div>
          </Col>
        </Row>
        <div className="login__form-message">{message}</div>
        <div className="login__form-action">
          <Button
            loading={fetchingLogin}
            type="primary"
            htmlType="submit"
            className="submit__button"
          >
            Đăng nhập
          </Button>
        </div>
      </Form>
      <ForgetPassworModal
        visible={isShowRegisterForm}
        onCancel={handleCancelUserRegisterModal}
      />
      {/* <UserRegisterModal
        visible={isShowRegisterForm}
        onCancel={handleCancelUserRegisterModal}
      /> */}
    </div>
  );
};
export default LoginPage;
