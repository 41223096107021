/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Layout khi chưa login.
 * */

const PublicLayout = function ({ children }) {
  return <div id="publicLayout">{children}</div>;
};
export default PublicLayout;
