/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import SaleRouteInfo from "components/modals/sale-route/SaleRouteInfo";
import SaleRouteCustomerManager from "./SaleRouteCustomerManager";
import APP_CONST from "utils/constants";

const SaleRouteManagerModal = function ({ config, onCancel }) {
  const { visible, dataSource, mode } = config;
  const [saleRouteInfo, setSaleRouteInfo] = useState(null);

  useEffect(() => {
    setSaleRouteInfo(dataSource);
  }, [dataSource]);

  const renderTitle =
    mode === APP_CONST.COMMON.MODAL_MODE.ADD
      ? "Thêm mới tuyến bán hàng"
      : "Sửa tuyến bán hàng";

  return (
    <Modal
      visible={visible}
      title={renderTitle}
      centered
      onCancel={onCancel}
      width="80%"
      footer={null}
    >
      <SaleRouteInfo
        saleRouteInfo={saleRouteInfo}
        setSaleRouteInfo={setSaleRouteInfo}
        onCancel={onCancel}
      />

      <SaleRouteCustomerManager saleRouteInfo={saleRouteInfo} />
    </Modal>
  );
};

SaleRouteManagerModal.propTypes = {
  config: PropTypes.any,
  onCancel: PropTypes.func,
};

export default SaleRouteManagerModal;
