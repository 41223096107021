import { useCallback, useState, useEffect } from "react";
import {
  Card,
  Form,
  Table,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Button,
  message,
  Space,
  Modal,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import AddEditShippingOrderModal from "components/modals/AddEditShippingOrderModal";
import {map, get, filter} from "lodash";
import utils from "utils";
import services from "services";

const AllOrderManagementPage = () => {
  const [form] = Form.useForm();
  const [params, setParams] = useState({});
  const [filteredStaffs, setFilteredStaffs] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    arrStaffs: [],
    arrProvinces: [],
  });
  const [fetchingListShippingOrder, setFetchingListShippingOrder] = useState(false);
  const [shippingOrderModalConfig, setShippingOrderModalConfig] = useState({
    mode: utils.$const.COMMON.MODAL_MODE.ADD,
    visible: false,
    dataSource: {},
  });
  const [listShippingOrder, setListShippingOrder] = useState([]);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);

  const handleChangePaging = useCallback(
    (pageNumber, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
      setPaging({ pageNumber: currentPage, pageSize });
    }
  );
    
  const handleBrowseShippingOrder = useCallback(() => {
    setFetchingListShippingOrder(true);
    services.shippingOrderService
      .purchaseOrders_PurchaseOrderFilterFullOpt({
        ...params,
        pageNumber: paging?.pageNumber ?? 1,
        pageSize: paging?.pageSize ?? 10,
      })
      .then((res) => {
        const items = get(res, "items");
        const total = get(res, 'totalCount');
        setTotal(total);
        setListShippingOrder(items);
      })
      .catch((error) => {
        message.error("Có lỗi khi lấy danh sách đơn hàng.");
        setListShippingOrder([]);
      })
      .finally(() => setFetchingListShippingOrder(false));
  }, [paging]);
  
  const handleSubmit = (values) => {
    setParams(values);
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize });
  };

  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận hủy đơn hàng <b className="text-danger">{row.purchaseId}</b>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.shippingOrderService
          .purchaseOrders_ChangePurchaseOrderStatus({
            id: row.id,
            currentState: row.currentState,
            status: 3,
          })
          .then(() => {
            message.success("Hủy đơn hàng thành công.");
            setListShippingOrder((prev) => {
              let next = [...prev];
              next = filter(next, (x) => x.purchaseId !== row.purchaseId);
              return next;
            });
          })
          .catch(() =>
            message.error("Hủy đơn hàng không thành công.Vui lòng thử lại")
          );
      },
      onCancel() {},
    });
  }, []);

  let columns = [
    {
      title: "Mã ĐH",
      dataIndex: "purchaseId",
      key: "purchaseId",
      width: 170,
      render: (value, row) => (
        <div className="d-flex align-items-center justify-content-between">
          <Button
            onClick={() =>
              setShippingOrderModalConfig({
                dataSource: row,
                visible: true,
                mode: utils.$const.COMMON.MODAL_MODE.VIEW,
              })
            }
            type="link"
            size="small"
          >
            <span className="d-flex align-items-center">{value}</span>
          </Button>
        </div>
      ),
    },
    {
      title: "Mã KH",
      dataIndex: "customer",
      key: "customerCode",
      width: 140,
      render: (value) => value?.customerCode,
    },
    {
      title: "Tên KH",
      dataIndex: "customer",
      key: "customer",
      width: 120,
      render: (value) => value?.customerName,
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
      render: (value) => {
        var findedStatus = utils.$const.SHIPPING_ORDER.STATUS.find(
          (x) => x.id === value
        );
        return (
          <span style={{ color: findedStatus?.color }}>
            {findedStatus?.name}
          </span>
        );
      },
    },
    {
      title: "Mã NV",
      dataIndex: "saler",
      key: "userCode",
      width: 100,
      render: (value) => value?.userCode,
    },
    {
      title: "Tên NV",
      dataIndex: "saler",
      key: "username",
      width: 150,
      render: (value) => value?.fullName,
    },
    {
      title: "Mã tuyến",
      dataIndex: "saleRouteCode",
      key: "saleRouteCode",
      width: 125
    },
    {
      title: "Tên tuyến",
      dataIndex: "saleRouteName",
      key: "saleRouteName",
      width: 200
    },
    {
      title: "Mã NPP",
      dataIndex: "distributor",
      key: "distributorCode",
      width: 100,
      render: (value) => value?.distributorCode,
    },
    {
      title: "Tên NPP",
      dataIndex: "distributor",
      key: "distributorName",
      width: 150,
      render: (value) => value?.distributorName,
    },

    {
      title: "Ngày đặt",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 100,
      render: (value) => utils.$datetime.formatDatetime(value),
    },
    {
      title: "Tổng giá trị",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: 100,
      render: (value) => utils.$number.numberFormatter(value),
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
      width: 200,
    },
    {
      title: "#",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: 50,
      fixed: "right",
      render: (_, row) => (
        <Space className="d-flex align-items-center justify-content-center">
          <EditOutlined
            className="text-primary"
            onClick={() =>
              setShippingOrderModalConfig({
                dataSource: row,
                visible: true,
                mode: utils.$const.COMMON.MODAL_MODE.EDIT,
              })
            }
          />
          {row.status === 1 && (
            <CloseOutlined
              title="Hủy đơn hàng"
              className="text-danger"
              onClick={() => handleShowConfirmDelete(row)}
            />
          )}
        </Space>
      ),
    },
  ];

  const handleCancelAddEditShippingOrderModal = () => {
    setShippingOrderModalConfig({
      dataSource: {},
      visible: false,
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
  };

  const handleChangeTable = (pagination, filters, sorter, extra) => () => {
      const pageNumber = get(pagination, "pageNumber", 1);
      const pageSize = get(pagination, "pageSize", 10);
      setPaging({ pageNumber, pageSize });
  }
  
  useEffect(() => {
    handleBrowseShippingOrder();
  }, [handleBrowseShippingOrder]);

  useEffect(() => {
    // api nhân viên        
    services.userManagementService.resource_GetAllSalersWithPermission().then((res) => {
      let arrData = map(res, (item, index) => {
        item.index = index + 1;
        item.id = get(item, "userId");
        item.name = get(item, "fullName");
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrStaffs = arrData;
        return nextState;
      });
    });

    // api tỉnh
    services.organization
    .province_GetAllProvincesWithPermission()
    .then(async (res) => {
      let array = map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });

      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrProvinces = array;
        return nextState;
      });
    })
    .catch((error) => {});

  }, [setDataFilter]);

  useEffect(() => {
    setFilteredStaffs([...get(dataFilter, 'arrStaffs')]);
  }, [dataFilter]);

  const filterStaffs = (value) => {
    if (value) {        
      let arrStaffs = filter(
        get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.provinceId === value;
        },
      )
      form.setFieldsValue({staffId: undefined});
      setFilteredStaffs(arrStaffs);
    } else {
      setFilteredStaffs([...get(dataFilter, 'arrStaffs')]);
    }
  }

  return (
    <Card size="small" className="orderManagementPage">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]} align="bottom">
        <Col lg={4} xl={4}>
            <Form.Item name="provinceId" label="Tỉnh">
              <Select
                style={{ width: "100%" }}
                placeholder="Tỉnh"
                allowClear
                onChange={(e) => {
                  filterStaffs(e);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {map(get(dataFilter, "arrProvinces"), (item, index) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="staffId" label="Nhân viên">
              <Select
                style={{ width: "100%" }}
                placeholder="Nhân viên"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {map(filteredStaffs, (item, index) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} xxl={4}>
            <Form.Item name="orderDate" label="Ngày đặt hàng">
              <DatePicker style={{ width: "100%" }} placeholder="Chọn" />
            </Form.Item>
          </Col>
          <Col lg={4} xxl={4}>
            <Form.Item name="keyword" label="Tìm theo từ khóa">
              <Input placeholder="Tên KH/ Mã ĐH" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" align="center" gutter={[8]}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              disabled={fetchingListShippingOrder}
            >
              <span className="d-flex align-items-center">
                <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        size="small"
        scroll={{ x: 1920 }}
        rowKey="id"
        className="shippingOrderList"
        columns={columns}
        dataSource={listShippingOrder}
        onChange={handleChangeTable}
        loading={fetchingListShippingOrder}
        pagination={{
          total: total ?? 0,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
      />
      <AddEditShippingOrderModal
        config={shippingOrderModalConfig}
        onCancel={handleCancelAddEditShippingOrderModal}
      />
    </Card>
  );
};

export default AllOrderManagementPage;
