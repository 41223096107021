/**
 * Copyright 2021-present, Hoàng An.
 * All rights reserved.
 * @author tangan2215@gmail.com on 07/06/2021.
 * @description Màn hình Nhóm nhãn hiệu. Route `/productManagement/productBrand`
 **/
 import { Alert } from "antd";
 
 export default function TargetMonitoring() { 
   return (     
      <>
       <Alert
        message="Thông Báo!"
        description="Màn hình này sẽ sớm được hoàn thiện!"
        type="success"
        showIcon
      />
      </>
   );
 }
 