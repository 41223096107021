/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 31/05/2021.
 * @description Modal Thêm/Sửa nhà cung cấp
 * */

import { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Select } from "antd";
import PropTypes from "prop-types";
import services from "services";
import TextArea from "antd/lib/input/TextArea";

const ProviderModal = function ({
  visible,
  mode,
  onCancel,
  dataItem,
  onReloadPage,
}) {
  const [form] = Form.useForm();
  const [isContinue, setIsContinue] = useState(false);
  const [saveCreate, setSave] = useState(false);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const handleConfirm = useCallback(() => {
    setIsContinue(false);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleConfirmAndContinue = useCallback(() => {
    setIsContinue(true);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleSubmit = useCallback(
    (items) => {
      if (mode === "edit") {
        services.generalService
          .providerInformation_Update(items)
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onReloadPage();
            onCancel();
            form.resetFields();
          })
          .catch((err) => {
            message.error("Lưu không thành công, hãy thử lại.");
          });
      } else if (mode === "add") {
        services.generalService
          .providerInformation_Create(items)
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onReloadPage();
            if (isContinue) {
              form.resetFields();
            } else {
              onCancel();
              form.resetFields();
            }
          })
          .catch((err) => {
            message.error("Lưu không thành công, hãy thử lại.");
          });
      }
    },
    [mode, onReloadPage, onCancel, form, isContinue]
  );

  const renderTitle = () =>
    mode === "add" ? `Thêm mới nhà cung cấp` : ` Chỉnh sửa nhà cung cấp`;

  useEffect(() => {
    const initValues = {
      id: dataItem.id,
      currentState: dataItem.currentState,
      providerCode: dataItem.providerCode,
      providerName: dataItem.providerName,
      status: dataItem.status,
      representative: dataItem.representative,
      taxCode: dataItem.taxCode,
      phonenumber: dataItem.phonenumber,
      address: dataItem.address,
      notes: dataItem.notes,
    };
    form.setFieldsValue(initValues);
  }, [dataItem, form]);

  const handleCloseModal = useCallback(() => {
    onCancel && onCancel();
    form.resetFields();
  }, [form, onCancel]);

  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      onCancel={onCancel}
      footer={[
        <Form.Item>
          <Button onClick={handleConfirm} type="primary">
            Lưu
          </Button>
          {mode === "add" ? (
            <Button
              title="Lưu và tiếp tục"
              key="submitAndContinute"
              type="primary"
              onClick={handleConfirmAndContinue}
            >
              Lưu và tiếp tục
            </Button>
          ) : (
            ""
          )}
          <Button type="default" onClick={handleCloseModal}>
            Quay lại
          </Button>
        </Form.Item>,
      ]}
    >
      <Form initialValues={dataItem} onFinish={handleSubmit} form={form} {...layout} labelAlign='left'>
        <Form.Item
          label="Mã nhà cung cấp"
          required
          name="providerCode"
          rules={[
            { required: true, message: "Mã nhà cung cấp không được trống" },
          ]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập mã nhà cung cấp" />
        </Form.Item>
        <Form.Item
          label="Tên nhà cung cấp"
          name="providerName"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập tên nhà cung cấp" />
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="status"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 7 }}
        >
          <Select placeholder="Chọn trạng thái">
            <Select.Option value={1}>Hoạt động</Select.Option>
            <Select.Option value={0}>Không hoạt động</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Người đại diện"
          name="representative"
          rules={[{ require: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Vui lòng nhập" />
        </Form.Item>
        <Form.Item
          label="Mã số thuế"
          name="taxCode"
          // rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Vui lòng nhập" />
        </Form.Item>
        <Form.Item
          name="phonenumber"
          label="Số điện thoại"
          // rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>
        <Form.Item
          name="address"
          label="Địa chỉ"
          // rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập địa chỉ" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          // rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập email" />
        </Form.Item>
        <Form.Item label="Ghi chú" name="notes" labelCol={{ span: 7 }}>
          <TextArea placeholder="Nhập ghi chú" rows={5} />
        </Form.Item>
        <Form.Item hidden name="id">
          <Input disabled></Input>
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
ProviderModal.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
};

export default ProviderModal;
