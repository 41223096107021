import { message, Table } from 'antd';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import services from 'services';
import utils from 'utils';

function DocumentTypeManagement({ params }) {
  const [listProductOutbound, setListProductOutbound] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({
    pageNumber: 1,
    pageSize: 20,
  });

  useEffect(() => {
    if (params?.load) {
      setLoading(true);
      const pageNumber = get(paging, 'pageNumber', 1);
      const pageSize = get(paging, 'pageSize', 20);
      const fromDate = get(params, 'date[0]');
      const toDate = get(params, 'date[1]');
      const documentType = get(params, 'documentType');
      const customerId = get(params, 'customerIdObj?.value');

      services.satatisticReportService
        .productOutbound_ProductOutboundByTypeReport({
          pageNumber,
          pageSize,
          fromDate,
          toDate,
          documentType,
          customerId,
        })
        .then((res) => {
          const items = get(res, 'items', []);
          const total = get(res, 'totalCount', 0);
          setListProductOutbound(items);
          setTotal(total);
        })
        .catch((err) => {
          message.error('Không tìm thấy thông tin xuất hàng');
          setListProductOutbound([]);
        })
        .finally(() => setLoading(false));
    }
  }, [paging]);

  useEffect(() => {
    setPaging({
      ...paging,
      pageNumber: 1,
    });
  }, [params]);

  const columns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'documentType',
      key: 'documentType',
      title: 'Hình thức xuất',
      align: 'center',
      width: 150,
      render: (value) => {
        return utils.$const.OUTBOUND.DOCUMENT_TYPE.find(
          (document) => value === document?.value
        )?.name;
      },
    },
    {
      dataIndex: 'fromWarehouseCode',
      key: 'fromWarehouseCode',
      title: 'Mã kho xuất',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'fromWarehouseName',
      key: 'fromWarehouseName',
      title: 'Tên kho xuất',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'productCode',
      key: 'productCode',
      title: 'Mã sản phẩm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: 'Tên sản phẩm',
      align: 'left',
      width: 150,
    },
    {
      dataIndex: 'quantity',
      key: 'quantity',
      title: 'Số lượng',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'price',
      key: 'price',
      title: 'Đơn giá',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'unitTypeName',
      key: 'unitTypeName',
      title: 'Đơn vị tính',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: 'Thành tiền',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'vat',
      key: 'vat',
      title: 'Tiền VAT',
      align: 'right',
      width: 150,
      render: (value) =>
        value ? `${utils.$number.numberFormatter(value)}%` : '0%',
    },
    {
      dataIndex: 'vatAmount',
      key: 'vatAmount',
      title: 'Tổng tiền VAT',
      align: 'right',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'discount',
      key: 'discount',
      title: 'Tiền khuyến mãi',
      align: 'right',
      width: 150,
      render: (value) =>
        value ? `${utils.$number.numberFormatter(value)}%` : 0,
    },
    {
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      title: 'Tổng tiền khuyến mãi',
      align: 'right',
      width: 200,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
  ];

  const handleChangeTable = useCallback((pagination) => {
    setPaging({
      pageNumber: pagination?.current,
      pageSize: pagination?.pageSize,
    });
  }, []);

  return (
    <Table
      rowKey='id'
      className='productInboundList'
      columns={columns}
      dataSource={listProductOutbound}
      locale={{
        emptyText: 'Không có đơn hàng nào',
      }}
      pagination={{
        current: paging?.pageNumber,
        pageSize: paging?.pageSize,
        total,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
      }}
      onChange={handleChangeTable}
      loading={loading}
      bordered
      scroll={{ x: 3000, y: 680 }}
    />
  );
}

export default DocumentTypeManagement;
