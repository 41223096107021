import { useCallback, useState } from 'react';
import {
  Card,
  Row,
  Form,
  Col,
  Divider,
  Table,
  DatePicker,
  message,
  Button,
  Tabs,
  Select,
} from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { map } from 'lodash';
import services from 'services';
import utils from 'utils';
import moment from 'moment';
import BaseSelect from 'components/selects/baseSelect';
export default function StatisticProductInbound() {
  const [form] = Form.useForm();
  //const [fetchingListInbound, setFetchingListInbound] = useState(false);
  const [listProductInbound, setListProductInbound] = useState([]);
  //const [listDocumentTypeInbound, setListDocumentTypeInbound] = useState([]);
  const listDocumentTypeInbound = [];
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  // const [listInboundPagination, setListInboundPagination] = useState({
  //   total: 0,
  //   pageSize: 20,
  // });
  const [fromToDate, setFromToDate] = useState({
    fromDate: utils.$datetime.currentFirstDate(),
    toDate: utils.$datetime.currentLastDate(),
  });
  const [selectTab, setSelectTab] = useState(0);
  const { TabPane } = Tabs;
  const { RangePicker } = DatePicker;

  const handleConfirmfilter = useCallback(() => {
    form.submit();
  }, [form]);

  const onSubmit = (values) => {
    setIsLoading(true);
    let fromDate = form.getFieldValue('date')[0];
    let toDate = form.getFieldValue('date')[1];
    let warehouseObj = form.getFieldValue('warehouseIDObj');
    let documentTypeObj = form.getFieldValue('documentTypeObj');

    let params = {
      documentType: documentTypeObj?.key,
      warehouseId: warehouseObj?.key,
      fromDate: fromDate,
      toDate: toDate,
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize,
    };

    if (selectTab === '0') {
      // Thống kê theo phiếu nhập
      services.satatisticReportService
        .productInbound_ProductInboundByTypeReport(params)
        .then((res) => {
          setIsLoading(false);
          setTotal(res?.totalCount ?? 0);
          setListProductInbound(res.items);
          if (res.items.length === 0) {
            message.error('Không tìm thấy sản phẩm');
          } else {
          }
        })
        .catch((err) => {
          setTotal(0);
          setListProductInbound([]);
          setIsLoading(false);
        });
    } else if (selectTab === '1') {
      // Thống kê theo hình thức nhập
      // services.satatisticReportService.order_OrderByCustomerReport().then(res =>{
      //   setListOrderByCustomer(res.items)
      //   setIsLoading(false)
      //   if(res.items.length === 0){
      //     message.error("Không tìm thấy sản phẩm")
      //   }
      //   else{
      //     message.success("Tìm kiếm thành công")
      //   }
      // })
    }
  };

  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    },
    [paging?.pageSize]
  );

  const inboundColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      fixed: 'left',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    // {
    //   dataIndex: 'documentCode',
    //   key: 'documentCode',
    //   title: 'Mã phiếu',
    //   fixed: 'left',
    //   align: 'center',
    //   width: 150,
    // },
    {
      dataIndex: 'inDate',
      key: 'inDate',
      title: 'Ngày tạo',
      // fixed: 'left',
      align: 'center',
      width: 150,
      render: (value) => utils.$datetime.formatDatetime(value),
    },
    {
      dataIndex: 'documentType',
      key: 'documentType',
      title: 'Hình thức nhập',
      // fixed: 'left',
      align: 'center',
      width: 250,
      render: (value) => {
        return utils.$const.INBOUND.DOCUMENT_TYPE.find(
          (document) => value === document?.value
        )?.name;
      },
    },
    // {
    //   dataIndex: 'customerCode',
    //   key: 'customerCode',
    //   title: 'Mã khách hàng',
    //   align: 'center',
    //   width: 150,
    // },
    // {
    //   dataIndex: 'customerName',
    //   key: 'customerName',
    //   title: 'Tên khách hàng',
    //   width: 150,
    //   align: 'center',
    // },
    // {
    //   dataIndex: 'providerCode',
    //   key: 'providerCode',
    //   title: 'Mã nhà cung cấp',
    //   align: 'center',
    //   width: 150,
    // },
    // {
    //   dataIndex: 'providerName',
    //   key: 'providerName',
    //   title: 'Tên nhà cung cấp',
    //   align: 'center',
    //   width: 150,
    // },
    {
      dataIndex: 'toWarehouseCode',
      key: 'toWarehouseCode',
      title: 'Mã kho',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'toWarehouseName',
      key: 'toWarehouseName',
      title: 'Tên kho',
      align: 'center',
      width: 150,
    },
    // {
    //   dataIndex: 'toWarehouseCode',
    //   key: 'toWarehouseCode',
    //   title: 'Mã kho nhập',
    //   align: 'center',
    //   width: 150,
    // },
    // {
    //   dataIndex: 'toWarehouseName',
    //   key: 'toWarehouseName',
    //   title: 'Tên kho nhập',
    //   align: 'center',
    //   width: 150,
    // },
    {
      dataIndex: 'productCode',
      key: 'productCode',
      title: 'Mã sản phẩm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'productName',
      key: 'productName',
      title: 'Tên sản phẩm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'quantity',
      key: 'quantity',
      title: 'Số lượng',
      align: 'center',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'price',
      key: 'price',
      title: 'Đơn giá',
      align: 'center',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'unitTypeName',
      key: 'unitTypeName',
      title: 'Đơn vị tính',
      align: 'center',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'currencyName',
      key: 'currencyName',
      title: 'Đơn vị tiền tệ',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      title: 'DVT Thành tiền',
      align: 'center',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'vat',
      key: 'vat',
      title: 'VAT',
      align: 'center',
      width: 150,
      render: (value) =>
        value ? `${utils.$number.numberFormatter(value)}%` : 0,
    },
    {
      dataIndex: 'vatAmount',
      key: 'vatAmount',
      title: 'Tiền VAT',
      align: 'center',
      width: 150,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    {
      dataIndex: 'discount',
      key: 'discount',
      title: 'Giảm giá',
      align: 'center',
      width: 150,
      render: (value) =>
        value ? `${utils.$number.numberFormatter(value)}%` : 0,
    },
    {
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      title: 'Tiền giảm giá',
      align: 'center',
      width: 200,
      render: (value) => (value ? utils.$number.numberFormatter(value) : 0),
    },
    // {
    //   dataIndex: "status",
    //   key: "status",
    //   title: "Trạng thái",
    //   align: "center",
    //   width: 150,
    // },
  ];

  const documentTypeColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      fixed: 'left',
      // width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Hình thức nhập',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Mã kho nhập',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tên kho nhập',
      align: 'center',
      width: 250,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Mã sản phẩm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tên sản phẩm',
      align: 'center',
      width: 250,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tổng số lượng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tổng tiền',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tổng tiền VAT',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tổng tiền khuyến mại',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tổng tiền (có VAT)',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tổng tiền (không VAT)',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Hình thức nhập',
      align: 'center',
      width: 150,
    },
  ];

  // const handleBrowseProductInbound = useCallback((param) => {
  //   setFetchingListInbound(true);
  //   const pageNumber = get(param, 'pageNumber', 1);
  //   const pageSize = get(param, 'pageSize', 20);
  //   const fromDate = utils.$datetime.stringToDateTime(param.fromDate);
  //   const toDate = utils.$datetime.stringToDateTime(param.toDate);
  //   services.satatisticReportService
  //     .productInbound_ProductInboundReport(pageNumber, pageSize, fromDate, toDate)
  //     .then((res) => {
  //       const items = get(res, 'items');
  //       const total = get(res, 'totalCount');
  //       setListProductInbound(items);
  //       setListInboundPagination((prev) => ({
  //         ...prev,
  //         total,
  //         pageSize,
  //       }));
  //     })
  //     .catch((err) => {
  //       message.error('Không tìm thấy thông tin nhập hàng');
  //       setListProductInbound([]);
  //     })
  //     .finally(() => setFetchingListInbound(false));
  // }, []);

  // const handleChangeTable = useCallback(
  //   (pagination, filters, sorter, extra) => () => {
  //     const pageNumber = get(pagination, 'pageNumber', 1);
  //     const pageSize = get(pagination, 'pageSize', 20);
  //     handleBrowseProductInbound({ pageNumber, pageSize });
  //   },
  //   [handleBrowseProductInbound]
  // );

  // const handleBrowseCustomerInformation = useCallback((search) => {
  //   return services.generalService.customerInformation_GetListCustomer();
  // }, []);

  const handleSelectTab = useCallback(
    (value) => {
      setSelectTab(value);
    },
    [setSelectTab]
  );

  const handleChangeRangePicker = useCallback((_, dateString) => {
    setFromToDate({
      fromDate: dateString?.[0],
      toDate: dateString?.[1],
    });
  }, []);

  const handleBrowseWarehouseInformation = useCallback((search) => {
    return services.generalService.warehouses_GetAllWarehouse();
  }, []);

  // useEffect(() => {
  //   handleBrowseProductInbound({});
  // }, [handleBrowseProductInbound]);

  return (
    <Card>
      <Form
        initialValues={{
          date: [moment(fromToDate.fromDate), moment(fromToDate.toDate)],
        }}
        form={form}
        labelCol={{ span: 24 }}
        onFinish={onSubmit}
      >
        <Row gutter={[8]}>
          <Col lg={8} xl={6}>
            {/* <Form.Item name='purchaseId' label='Mã đơn hàng'>
                <Input placeholder='Nhập mã đơn hàng' />
              </Form.Item> */}
            <Form.Item name='documentTypeObj' label='Hình thức nhập'>
              <Select placeholder='Hình thức nhập' labelInValue>
                {map(utils.$const.INBOUND.DOCUMENT_TYPE, (stat) => (
                  <Select.Option key={stat.id} value={stat.value}>
                    {stat.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={6}>
            <Form.Item name='warehouseIDObj' label='Kho hàng'>
              <BaseSelect
                searchName='warehouseIDObj'
                itemValue='id'
                itemLabel='warehouseName'
                placeholder='Từ kho'
                searchOnMount
                labelInValue
                onSearch={handleBrowseWarehouseInformation}
              />
            </Form.Item>
          </Col>
          <Col lg={8} xl={6}>
            <Form.Item name='status' label='Trạng thái'>
              <Select allowClear placeholder='Chọn'>
                {map(utils.$const.INBOUND.STATUS, (stat) => (
                  <Select.Option key={stat.id} value={stat.id}>
                    {stat.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={6}>
            <Form.Item name='date' label='Từ ngày - đến ngày'>
              <RangePicker
                format={utils.$const.COMMON.DATE_TIME_FORMAT['YYYY-MM-DD']}
                onChange={handleChangeRangePicker}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' gutter={[8]}>
          <Col>
            <Button
              type='primary'
              onClick={handleConfirmfilter}
              disabled={isLoading}
            >
              <span className='d-flex align-items-center'>
                <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button type='dashed'>
              <span className='d-flex align-items-center'>
                <DownloadOutlined />{' '}
                <span className='ml-1'>Xuất file Excel</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Tabs defaultActiveKey='0' type='card' onChange={handleSelectTab}>
        <TabPane tab='Thống kê theo phiếu nhập' key='0'>
          <Table
            className='productInboundList'
            columns={inboundColumns}
            dataSource={listProductInbound}
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: paging?.pageNumber ?? 1,
              pageSize: paging?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
            }}
            loading={isLoading}
            bordered
            scroll={{ x: 3000, y: 680 }}
          />
        </TabPane>
        <TabPane tab='Thống kê theo hình thức nhập' key='1'>
          <Table
            className='documentTypeInboundList'
            columns={documentTypeColumns}
            dataSource={listDocumentTypeInbound}
            loading={isLoading}
            bordered
            scroll={{ x: 3000, y: 680 }}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
}
