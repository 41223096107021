/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 20/09/2021.
 * @description Màn hình `Danh sách menu`. Permission `/access-management/menu-control`
 * */

import { useCallback, useState, useEffect } from "react";
import { Card, Button, Table, message, Typography, Modal, Input, Divider, Row, Col, Form } from "antd";
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteFilled } from "@ant-design/icons";
import services from "services";
import MenuControlModal from "components/modals/MenuControlModal";
import utils from "utils";

import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { get } from "lodash";
import SubMenuControlModal from "components/modals/SubMenuControlModal";
import Text from "antd/lib/typography/Text";
import { render } from "react-dom";

const AreaNestedTableRender = ({ mainMenu, setSubMenuControlModalConfig }) => {
  // Modal Function
  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa menu <b className="text-danger">{row.subMenuName}</b>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.accessManagementService
          .subMenu_Delete([{
            id: row.id,
            currentState: row.currentState,
            status: row.status,
          }])
          .then(() => message.success("Xóa thành công."))
          .catch(() =>
            message.error("Xóa không thành công.Vui lòng thử lại")
          );
      },
      onCancel() { },
    });
  }, []);
  const columns = [
    {
      title: "Sub menu",
      dataIndex: "subMenuName",
      key: "subMenuName",
      width: 150,
      align: "left",
    },
    {
      title: "Link truy cập",
      dataIndex: "subMenuPath",
      key: "subMenuPath",
      width: 300,
      align: "left",
    },
    {
      title: "Quyền truy cập",
      dataIndex: "permissionId",
      key: "permissionId",
      width: 300,
      render: () => {
        return mainMenu.menuName !== '' ? mainMenu.menuName : '';
      }
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
      ellipsis: true,
      render: (value) => (
        <div className={`status--${value ? "active" : "inactive"}`}>
          {
            utils.$const.MENU_CONTROL.STATUS.find(
              (status) => status?.id === value
            )?.name
          }
        </div>
      ),
    },
    {
      title: "#",
      dataIndex: "action",
      width: 100,
      align: "center",
      key: "action",
      render: (_, record) => {
        return (
          <div className="warehouseInfo-card__button">
            <Button
              type="primary"
              size="small"
              onClick={() => setSubMenuControlModalConfig({
                dataSource: { ...record },
                visible: true,
                mode: utils.$const.COMMON.MODAL_MODE.EDIT,
              })
              }
            >
              <span className="d-flex align-items-center">
                <EditOutlined /> <span className="ml-1"></span>
              </span>
            </Button>
            <Button
              className="warehouseInfo-card__button--right"
              type="danger"
              size="small"
              onClick={() => handleShowConfirmDelete(record)}
            >
              <span className="d-flex align-items-center">
                <DeleteFilled /> <span className="ml-1"></span>
              </span>
            </Button>
          </div>
        );
      },
    },
  ];
  
  return (
    <Table
      className="expand-table"
      bordered
      rowKey="id"
      pagination={false}
      columns={columns}
      dataSource={mainMenu.subMenuDefinitions}
    />
  );
};

const MenuControlPage = function () {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [paging, setPaging] = useState({
    total: 0,
    pageSize: 20,
  });
  const [total, setTotal] = useState(0);
  const [isReloadPage, setIsReloadPage] = useState(false);

  const [subMenuControlModalConfig, setSubMenuControlModalConfig] = useState({
    mode: utils.$const.COMMON.MODAL_MODE.ADD,
    visible: false,
    dataSource: {},
  });
  const [menuControlModalConfig, setMenuControlModalConfig] = useState({
    mode: utils.$const.COMMON.MODAL_MODE.ADD,
    visible: false,
    dataSource: {},
  });

  const handleBrowseMenuControl = useCallback((param) => {
    setIsLoading(true);
    const pageNumber = get(param, "pageNumber", 1);
    const pageSize = get(param, "pageSize", 20);
    services.accessManagementService
      .mainMenu_GetBsWithPagination(pageNumber, pageSize)
      .then((res) => {
        setIsLoading(false);
        setTotal(res?.totalCount ?? 0);
        setData(res.items);
      })
      .catch((error) => {
        message.error("Có lỗi khi lấy danh sách menu.");
        setTotal(0);
        setData([]);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleCreateSubMenuControlModal = useCallback(() => {
    setSubMenuControlModalConfig({
      dataSource: {},
      visible: true,
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
  }, []);
  const handleCreateMenuControlModal = useCallback(() => {
    setMenuControlModalConfig({
      dataSource: {},
      visible: true,
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
  }, []);

  const handleEditMenuControl = useCallback(
    (dataSource) => {
      setMenuControlModalConfig({
        dataSource,
        visible: true,
        mode: utils.$const.COMMON.MODAL_MODE.EDIT,
      });
    },
    [setMenuControlModalConfig]
  );

  const handleCancelMenuControlModal = useCallback(() => {
    setMenuControlModalConfig({
      dataSource: {},
      visible: false,
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    setTimeout(() => {
      handleBrowseMenuControl({});
    }, 300);
  }, [handleBrowseMenuControl]);
  const handleCancelSubMenuControlModal = useCallback(() => {
    setSubMenuControlModalConfig({
      dataSource: {},
      visible: false,
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    setTimeout(() => {
      handleBrowseMenuControl({});
    }, 300);
  }, [handleBrowseMenuControl]);

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setMenuControlModalConfig({
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
      visible: false,
      dataSource: {},
    });
  }, [setIsReloadPage, isReloadPage]);

  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    },
    [paging]
  );

  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa Menu <b className="text-danger">{row.menuName}</b>{" "}
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.accessManagementService
          .mainMenu_Delete([
            {
              id: row.id,
              currentState: row.currentState,
            },
          ])
          .then(() => {
            message.success("Xóa Menu thành công.");
            handleBrowseMenuControl({});
          })
          .catch(() =>
            message.error("Xóa Menu không thành công.Vui lòng thử lại")
          );
      },
      onCancel() { },
    });
  }, [handleBrowseMenuControl]);

  const columns = [
    {
      title: "Tên màn hình",
      dataIndex: "menuName",
      key: "menuName",
      width: 150,
      align: "left",
    },
    {
      title: "Link truy cập",
      dataIndex: "menuPath",
      key: "menuPath",
      width: 300,
      align: "left",
    },
    {
      title: "Quyền truy cập",
      dataIndex: "permissionDefinition",
      key: "permissionDefinition",
      width: 300,
      align: "left",
      render: (value) => value ? value.permissionName : "",
    },
    {
      title: "Số màn hình phụ",
      dataIndex: "subMenuCount",
      key: "subMenuCount",
      width: 150,
      align: "center",
      render(text, record) {
        let countItem = record?.subMenuDefinitions;
        return {
          props: {
            style: { background: "rgba(158, 159, 161, 0.83)" }
          },
          children: <Text strong>{countItem.length !== 0 ? countItem.length : 0}</Text>
        };
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 100,
      ellipsis: true,
      render: (value) => (
        <div className={`status--${value ? "active" : "inactive"}`}>
          {
            utils.$const.MENU_CONTROL.STATUS.find(
              (status) => status?.id === value
            )?.name
          }
        </div>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Button
            type="primary"
            size="small"
            onClick={() => handleEditMenuControl(row)}
          >
            <span className="d-flex align-items-center">
              <EditOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            className="warehouseInfo-card__button--right"
            type="danger"
            size="small"
            onClick={() => handleShowConfirmDelete(row)}
          >
            <span className="d-flex align-items-center">
              <DeleteFilled /> <span className="ml-1"></span>
            </span>
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    handleBrowseMenuControl({});
  }, [handleBrowseMenuControl]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    let filter = {
      ...form.getFieldsValue(),
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize
    };
    services.accessManagementService.mainMenu_GetBsWithPagination(filter)
      .then(res => {
        setIsLoading(false);
        setData(res.items);
        setTotal(res?.totalCount ?? 0);
        if (res.items.length === 0) {
          message.error("Không tìm thấy thông tin");
        }
        else {
          message.success("Tìm kiếm thành công");
        }
      })
      .catch(err => {
        setTotal(0);
        setIsLoading(false);
      })
  };

  return (
    <Card className="MenuControlPage">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={10}>
            <Form.Item name='menuName' label='Tìm theo từ khóa'>
              <Input placeholder='Nhập từ khóa' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button
                type='primary'
                htmlType='submit'
                disabled={isLoading}
              >
                <span className='d-flex align-items-center'>
                  <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
          </Col>
          <Col lg={24} xl={3}>
            <Form.Item className='pl-5 create-menu__left general-button--height' label=' '>
              <Button className='' onClick={handleCreateMenuControlModal}>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Thêm menu</span>
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={3}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='' onClick={handleCreateSubMenuControlModal}>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Thêm sub-menu</span>
                </span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        rowKey="id"
        columns={columns}
        dataSource={data}
        size="small"
        scroll={{ x: 1200 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        }}
        loading={isLoading}
        locale={{ emptyText: "Không có menu" }}
        expandable={{
          expandedRowRender: (mainMenu) => (
            <AreaNestedTableRender
              mainMenu={mainMenu}
              setSubMenuControlModalConfig={setSubMenuControlModalConfig}
            />
          )
        }}
      />
      <MenuControlModal
        config={menuControlModalConfig}
        onCancel={handleCancelMenuControlModal}
        onReloadPage={handleReloadPage}
      />
      <SubMenuControlModal
        config={subMenuControlModalConfig}
        onCancel={handleCancelSubMenuControlModal}
        onReloadPage={handleReloadPage}
      />
    </Card>
  );
};
export default MenuControlPage;
