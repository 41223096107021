/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 04/06/2021.
 * @description Modal Thêm/Sửa vùng miền
 * */

import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, message, Row, Col } from "antd";
import PropTypes from "prop-types";
import { map } from "lodash";
import utils from "utils";
import services from "services";

const BusinessRegionModal = function ({ config, onCancel, onReloadPage,dataHuman }) {
  const { visible, mode, dataSource } = config;
  const [form] = Form.useForm();
  const [isContinue, setIsContinue] = useState(false);
  const disabled = mode === utils.$const.COMMON.MODAL_MODE.ADD === false;
  const handleConfirm = useCallback(() => {
    setIsContinue(false);
    setTimeout(() => form.submit(), 400);
  },[form]);

  const handleConfirmAndContinue = useCallback(() => {
    setIsContinue(true);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleSubmit = useCallback(
    (values) => {
      if (mode === utils.$const.COMMON.MODAL_MODE.ADD) {
        services.organization
          .businessRegion_Create({...values,status:1})
          .then((res) => {
            setTimeout(() => {
              message.success("Tạo mới thành công.");
            }, 300);
            if(isContinue){
              form.resetFields();
            }else{
              onCancel();
              form.resetFields();
            }
          })
          .catch((error) =>
            message.error("Tạo mới không thành công.")
          );
      } else {
        services.organization
          .businessRegion_Update(values)
          .then((res) => {
            setTimeout(() => {
              message.success("Chỉnh sửa thành công.");
            }, 300);
            onCancel();
            form.resetFields();
          })
          .catch((error) =>
            message.error("Chỉnh sửa không thành công.")
          );
      }
    },
    [mode, isContinue, form, onCancel]
  );
  const handleCancelModal = () => {
    form.resetFields();
    onCancel();
  }
  useEffect(() => {
    if (visible && mode === utils.$const.COMMON.MODAL_MODE.EDIT) {
      // set dữ liệu trong dataSource vào form
      form.setFieldsValue({
        id: dataSource.id,
        currentState: dataSource.currentState,
        regionCode: dataSource.regionCode,
        regionName: dataSource.regionName,
        description: dataSource.description,
        //status: dataSource.status,
        managerUserId: dataSource.managerUserId,
        //accountingUserId: dataSource.accountingUserId
      });
    }
  }, [dataSource, visible, mode, form]);

  const renderTitle = () =>
    mode === utils.$const.COMMON.MODAL_MODE.ADD
      // ? `Thêm mới vùng miền`
      // : "Chỉnh sửa vùng miền";
      ? `THÔNG TIN VÙNG MIỀN/ KHU VỰC`
      : "THÔNG TIN VÙNG MIỀN/ KHU VỰC";
  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      centered
      onCancel={handleCancelModal}
      width="40%"
      footer={[
        <Form.Item>
            <Button onClick={handleConfirm} type="primary">
              {dataSource?.id ? "Cập nhật" : "Thêm"}
            </Button>
            {mode === utils.$const.COMMON.MODAL_MODE.ADD ?
              <Button
                title='Thêm và tiếp tục'
                key='submitAndContinute'
                type="primary"
                onClick={handleConfirmAndContinue}
              >
                Thêm và tiếp tục
              </Button> : ''
            }
            <Button type='default' onClick={handleCancelModal}>
              Quay lại
            </Button>
          </Form.Item>
      ]}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        labelAlign="left"
        initialValues={{
          status: 1,
        }}
      >
        <Form.Item
          label="Mã vùng miền"
          name="regionCode"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Input placeholder="Nhập mã vùng miền" />
        </Form.Item>
        <Form.Item
          label="Tên vùng miền"
          name="regionName"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Input placeholder="Nhập tên vùng miền" />
        </Form.Item>
        <Form.Item
          label="Mô tả"
          name="description"
        >
          <Input.TextArea rows={2} placeholder="Nhập mô tả vùng miền" />
        </Form.Item>
         
        <Form.Item
          name="managerUserId"
          label="Người quản lý"
        >
          <Select placeholder="Chọn người quản lý">
              {map(dataHuman?.arrManager, (item) => (
                <Select.Option key={item.userId} value={item.userId}>
                  {item.fullName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="accountingUserId"
          label="Kế toán"
        >
          <Select 
            placeholder="Chọn Kế toán"
            allowClear>
              {map(dataHuman?.arrAccounting, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.fullName}
                </Select.Option>
              ))}
          </Select>
        </Form.Item> */}
        {/* <Form.Item
          name="status"
          label="Trạng thái"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Select placeholder="Chọn trạng thái">
            {map(utils.$const.ORGANIZATION.STATUS, (item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item hidden name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};
BusinessRegionModal.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
};
export default BusinessRegionModal;
