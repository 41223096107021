import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  message,
  Row,
  Col,
  Upload,
  Spin,
  InputNumber,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import React, {
  useEffect,
  useState,
  useCallback,
} from "react";
import services from "services";
import _ from "lodash";

const ProductInfoModal = ({
  visible,
  onCancel,
  selectedProductInfo,
  onReload,
  mode,
  ...rest
}) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const [form] = Form.useForm();

  const [listUnitType, setlistUnitType] = useState([]);
  //const [unitType, setUnitType] = useState({});
  const [saveCreate, setSave] = useState(false);

  // const [listPackageUnit, setlistPackingUnit] = useState([]);
  // const [packageUnit, setPackageUnit] = useState({});

  const [listBrand, setlistBrand] = useState([]);
  //const [brand, setBrand] = useState({});

  const [listIndustry, setlistIndustry] = useState([]);
  //const [industry, setIndustry] = useState({});

  //const [listDistributor, setlistDistributor] = useState([]);
 //const [distributor, setDistributor] = useState({});

  const [listProductType, setlistProductType] = useState([]);
  //const [productType, setProductType] = useState({});

  const [variableFlie, setVariableFlie] = useState();
  const [listVariableFile, setListVariableFile] = useState();
  const [listImgSub, setListImgSub] = useState([]);

  const listStatus = [
    {
      id: 1,
      status: 1,
      statusName: "Hoạt động",
    },
    {
      id: 2,
      status: 0,
      statusName: "Không hoạt động",
    },
  ];
  const listPackageType = [
    {
      id: "Gói cứng",
      name: "Gói cứng",
    },
    {
      id: "Gói mềm",
      name: "Gói mềm",
    },
  ];

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const [isShowSpiner, setShowSpiner] = useState(true);
  const [title, setTitle] = useState("");

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64List(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChangeList = ({ fileList }) => {
    setFileList(fileList);

    if (fileList.length > 0) {
      setListVariableFile([]);
      let listFileUpload = [];
      fileList.forEach((element) => {
        const fileUpload = {
          data: element.originFileObj,
          fileName: element.originFileObj.name,
        };
        listFileUpload.push(fileUpload);
      });
      setListVariableFile(listFileUpload);
    }

    // const fileToUpload = {
    //   data: file,
    //   fileName: file.name,
    // };
    // listVariableFile.push(fileToUpload)
  };

  const handleCancel = () => setPreviewVisible(false);

  function getBase64List(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
    const fileToUpload = {
      data: img,
      fileName: img.name,
    };
    // variableFlie = fileToUpload;
    setVariableFlie(fileToUpload);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   message.error('Image must smaller than 2MB!');
    // }
    // return isJpgOrPng && isLt2M;

    //Accept for upload quality Images
    const isAccept = true;
    return isJpgOrPng && isAccept;
  }

  useEffect(() => {
    const initValues = {
      id: selectedProductInfo.id,
      productCode: selectedProductInfo?.productCode,
      productName: selectedProductInfo.productName,
      packageType: selectedProductInfo.packageType,
      unitTypeId: _.get(
        selectedProductInfo,
        "productUnitDetails[0].unitTypeId"
      ),
      packageUnitId: _.get(
        selectedProductInfo,
        "productUnitDetails[1].unitTypeId"
      ),
      qtyPerPackage: selectedProductInfo.qtyPerPackage,
      evenSalePrice: _.get(
        selectedProductInfo,
        "productUnitDetails[1].salePrice"
      ),
      oddSalePrice: _.get(
        selectedProductInfo,
        "productUnitDetails[0].salePrice"
      ),
      brandId: selectedProductInfo.productBrand?.id,
      industryId: selectedProductInfo.industry?.id,
      productTypeId: selectedProductInfo.productType?.id,
      // distributorId: selectedProductInfo.distributor?.id,
      notes: selectedProductInfo.notes,
      activeStatus: selectedProductInfo.activeStatus,
      currentState: selectedProductInfo.currentState,
      mainImagesUrl: selectedProductInfo.mainImagesUrl,
      listImagesSub: selectedProductInfo.listImagesSub,
    };
    form.setFieldsValue(initValues);
    setlistUnitType([]);
    //setlistPackingUnit([]);
    setlistBrand([]);
    setlistIndustry([]);
    //setlistDistributor([]);
    setlistProductType([]);
    // services.generalService.unitTypes_GetAllUnitType().then((res) => {
    //   setlistUnitType(res);
    //   let unitType = res.find(
    //     (x) =>
    //       x.id ===
    //       _.get(selectedProductInfo, "productUnitDetails[0].unitTypeId")
    //   );
    //   if (unitType != undefined && unitType != null) {
    //     setUnitType(unitType);
    //   }

    //   let packageUnit = res.find(
    //     (x) =>
    //       x.id ===
    //       _.get(selectedProductInfo, "productUnitDetails[1].unitTypeId")
    //   );
    //   if (packageUnit != undefined && packageUnit != null) {
    //     setPackageUnit(packageUnit);
    //   }
    // });
    // services.generalService.packageUnits_GetAllPackageUnit().then((res) => {
    //   setlistPackingUnit(res);
    //   let packageUnit = res.find(
    //     (x) => x.id === _.get(selectedProductInfo, "productUnitDetails[1].unitTypeId")
    //   );
    //   if (packageUnit != undefined && packageUnit != null) {
    //     setPackageUnit(packageUnit);
    //   }
    // });
    services.generalService.productBrand_GetAllBrand().then((res) => {
      setlistBrand(res);
      // let brand = res.find(
      //   (x) => x.id === selectedProductInfo.productBrand?.id
      // );
      // if (brand != undefined && brand != null) {
      //   setBrand(brand);
      // }
    });
    services.generalService
      .productIndustry_GetAllProductIndustry()
      .then((res) => {
        setlistIndustry(res);
        // let industry = res.find(
        //   (x) => x.id === selectedProductInfo.industry?.id
        // );
        // if (industry != undefined && industry != null) {
        //   setIndustry(industry);
        // }
      });
    // services.generalService.distributor_GetAllDistributorsWithPermission().then((res) => {
    //   setlistDistributor(res);
    //   let distributor = res.find(
    //     (x) => x.id === selectedProductInfo.distributor?.id
    //   );
    //   if (distributor != undefined && distributor != null) {
    //     setDistributor(distributor);
    //   }
    // });
    services.generalService.productType_GetAllProductType().then((res) => {
      setlistProductType(res);
      // let productType = res.find(
      //   (x) => x.id === selectedProductInfo.productType?.id
      // );
      // if (productType != undefined && productType != null) {
      //   setProductType(productType);
      // }
    });
    if (selectedProductInfo?.mainImagesUrl !== "") {
      setImageUrl(selectedProductInfo.mainImagesUrl);
    }
    setFileList([]);

    if (selectedProductInfo.listImagesSub?.length > 0) {
      setListImgSub(selectedProductInfo.listImagesSub);
      setIsShow(false);
    } else {
      setListImgSub([]);
      setIsShow(true);
    }

    if (mode === "Edit") {
      setTitle("Chỉnh Sửa Sản Phẩm");
    }
    if (mode === "Create") {
      setTitle("Thêm Mới Sản Phẩm");
    }
    if (mode === "View") {
      setTitle("Chi Tiết Sản Phẩm");
    }
    setShowSpiner(false);
  }, [selectedProductInfo, form, mode]);

  const handleConfirm = useCallback(
    (state) => {
      if (state) {
        setSave(true);
      } else {
        setSave(false);
      }
      form.submit();
    },
    [form]
  );

  const onSubmit = (values) => {
    setShowSpiner(true);
    let dataSubmit = form.getFieldValue();
    Object.assign(dataSubmit, {
      productUnitDetails: [
        {
          unitTypeId: form.getFieldValue().unitTypeId,
          isDefault: true,
          salePrice: form.getFieldValue().oddSalePrice,
        },
        {
          unitTypeId: form.getFieldValue().packageUnitId,
          isDefault: false,
          salePrice: form.getFieldValue().evenSalePrice,
        },
      ],
    });
    // delete dataSubmit["unitTypeId"];
    // delete dataSubmit["packageUnitId"];

    //Update Item
    if (selectedProductInfo.id !== undefined) {
      if (
        variableFlie !== null &&
        variableFlie !== undefined &&
        variableFlie !== ""
      ) {
        services.fileManagement
          .photo_PostPhotoAttachment(variableFlie, "Photo", "Products")
          .then((res) => {
            if (res.succeeded) {
              dataSubmit.mainImagesUrl = res.data;
              let Data = dataSubmit;
              if (listVariableFile !== null && listVariableFile !== undefined) {
                Data.listImagesSub = [];
                services.fileManagement
                  .photo_PostMultiplePhotoAttachment(
                    listVariableFile,
                    "Photo",
                    "Products"
                  )
                  .then((res) => {
                    if (res.length > 0) {
                      let imgList = [];
                      res.forEach((element) => {
                        let objectAssign = {
                          productId: Data.id,
                          imagesUrl: element,
                        };
                        imgList.push(objectAssign);
                      });
                      Data.listImagesSub = imgList;

                      services.generalService
                        .products_Update(Data)
                        .then((res) => {
                          if (res.succeeded) {
                            setShowSpiner(false);
                            message.success("chỉnh sửa thành công.");
                            onCancel();
                            onReload();
                          } else {
                            setShowSpiner(false);
                            message.error("Mã sản phẩm đã tồn tại.");
                          }
                        })
                        .catch((erro) => {
                          setShowSpiner(false);
                          message.error("Có lỗi trong quá trình chỉnh sữa.");
                        });
                    }
                  })
                  .catch();
              } else {
                services.generalService
                  .products_Update(dataSubmit)
                  .then((res) => {
                    if (res.succeeded) {
                      setShowSpiner(false);
                      message.success("chỉnh sửa thành công.");
                      onCancel();
                      onReload();
                    } else {
                      setShowSpiner(false);
                      message.error("Mã sản phẩm đã tồn tại.");
                    }
                  })
                  .catch((erro) => {
                    setShowSpiner(false);
                    message.error("Có lỗi trong quá trình chỉnh sữa.");
                  });
              }
            }

            // services.generalService.products_Update(form.getFieldValue()).then(res => {
            //   if (res.succeeded == true) {
            //     message.success("chỉnh sửa thành công.")
            //     onReload();
            //     onCancel();
            //   }
            //   else {
            //     message.error("Mã sản phẩm đã tồn tại.")
            //   }
            // }).catch((erro) => {
            //   message.error("Có lỗi trong quá trình chỉnh sữa.")
            // });
          });
      } else {
        let Data = dataSubmit;
        if (listVariableFile?.length > 0) {
          Data.listImagesSub = [];
          services.fileManagement
            .photo_PostMultiplePhotoAttachment(
              listVariableFile,
              "Photo",
              "Products"
            )
            .then((res) => {
              if (res.length > 0) {
                let imgList = [];
                res.forEach((element) => {
                  let objectAssign = {
                    productId: Data.id,
                    imagesUrl: element,
                  };
                  imgList.push(objectAssign);
                });
                Data.listImagesSub = imgList;
                services.generalService
                  .products_Update(Data)
                  .then((res) => {
                    if (res.succeeded) {
                      message.success("chỉnh sửa thành công.");
                      onReload();
                      onCancel();
                    } else {
                      message.error("Mã sản phẩm đã tồn tại.");
                    }
                  })
                  .catch((erro) => {
                    message.error("Có lỗi trong quá trình chỉnh sữa.");
                  });
              }
            });
        } else {
          services.generalService
            .products_Update(dataSubmit)
            .then((res) => {
              if (res.succeeded) {
                message.success("chỉnh sửa thành công.");
                onCancel();
                onReload();
              } else {
                message.error("Mã sản phẩm đã tồn tại.");
              }
            })
            .catch((erro) => {
              message.error("Có lỗi trong quá trình chỉnh sữa.");
            });
        }
      }

      //ADD Items
    } else {
      let dataClone = {
        productCode: form.getFieldValue().productCode,
        productName: form.getFieldValue().productName,
        packageType: form.getFieldValue().packageType,
        qtyPerPackage: parseInt(form.getFieldValue().qtyPerPackage),
        packageUnitId: form.getFieldValue().packageUnitId,
        unitTypeId: form.getFieldValue().unitTypeId,
        // evenSalePrice: parseFloat(form.getFieldValue().evenSalePrice),
        // oddSalePrice: parseFloat(form.getFieldValue().oddSalePrice),
        notes: form.getFieldValue().notes,
        brandId: form.getFieldValue().brandId,
        industryId: form.getFieldValue().industryId,
        productTypeId: form.getFieldValue().productTypeId,
        // distributorId: form.getFieldValue().distributorId ? form.getFieldValue().distributorId : null,
        mainImagesUrl: "",
        listImagesSub: [],
        activeStatus: form.getFieldValue().activeStatus,
        productUnitDetails: [
          {
            unitTypeId: form.getFieldValue().unitTypeId,
            isDefault: true,
            salePrice: form.getFieldValue().oddSalePrice,
          },
          {
            unitTypeId: form.getFieldValue().packageUnitId,
            isDefault: false,
            salePrice: form.getFieldValue().evenSalePrice,
          },
        ],
      };
      if (
        variableFlie !== undefined &&
        variableFlie !== null &&
        variableFlie !== ""
      ) {
        services.fileManagement
          .photo_PostPhotoAttachment(variableFlie, "Photo", "Products")
          .then((res) => {
            if (res.succeeded === true) {
              dataClone.mainImagesUrl = res.data;
            }
            let Data = dataClone;
            if (listVariableFile !== null && listVariableFile !== undefined) {
              Data.listImagesSub = [];
              services.fileManagement
                .photo_PostMultiplePhotoAttachment(
                  listVariableFile,
                  "Photo",
                  "Products"
                )
                .then((res) => {
                  if (res.length > 0) {
                    let imgList = [];
                    res.forEach((element) => {
                      let objectAssign = {
                        productId: Data.id,
                        imagesUrl: element,
                      };
                      imgList.push(objectAssign);
                    });
                    Data.listImagesSub = imgList;
                    services.generalService
                      .products_Create(Data)
                      .then((res) => {
                        if (res.succeeded) {
                          message.success("Thêm sản phẩm thành công");
                          setShowSpiner(false);
                          // khi click thêm và tiếp tục thì lưu xong sẽ reset from
                          if (saveCreate) {
                            form.resetFields();
                          } else {
                            onCancel();
                          }
                          onReload();
                        } else {
                          message.error("Mã sản phẩm đã tồn tại.");
                          setShowSpiner(false);
                        }
                      })
                      .catch((erro) => {
                        message.error("Có lỗi trong quá trình thêm mới");
                        setShowSpiner(false);
                      });
                  }
                });
            } else {
              services.generalService
                .products_Create(dataClone)
                .then((res) => {
                  if (res.succeeded) {
                    message.success("Thêm sản phẩm thành công");
                    setShowSpiner(false);
                    // khi click thêm và tiếp tục thì lưu xong sẽ reset from
                    if (saveCreate) {
                      form.resetFields();
                    } else {
                      onCancel();
                    }
                    onReload();
                  } else {
                    message.error("Mã sản phẩm đã tồn tại.");
                    setShowSpiner(false);
                  }
                })
                .catch((erro) => {
                  message.error("Có lỗi trong quá trình thêm mới");
                  setShowSpiner(false);
                });
            }
          });
      } else {
        let Data = dataClone;
        if (listVariableFile !== null && listVariableFile !== undefined) {
          Data.listImagesSub = [];
          services.fileManagement
            .photo_PostMultiplePhotoAttachment(
              listVariableFile,
              "Photo",
              "Products"
            )
            .then((res) => {
              if (res.length > 0) {
                let imgList = [];
                res.forEach((element) => {
                  let objectAssign = {
                    productId: Data.id,
                    imagesUrl: element,
                  };
                  imgList.push(objectAssign);
                });
                Data.listImagesSub = imgList;
                services.generalService
                  .products_Create(Data)
                  .then((res) => {
                    if (res.succeeded) {
                      message.success("Thêm sản phẩm thành công");
                      setShowSpiner(false);
                      // khi click thêm và tiếp tục thì lưu xong sẽ reset from
                      if (saveCreate) {
                        form.resetFields();
                      } else {
                        onCancel();
                      }
                      onReload();
                    } else {
                      message.error("Mã sản phẩm đã tồn tại.");
                      setShowSpiner(false);
                    }
                  })
                  .catch((erro) => {
                    message.error("Có lỗi trong quá trình thêm mới");
                    setShowSpiner(false);
                  });
              }
            });
        } else {
          services.generalService
            .products_Create(dataClone)
            .then((res) => {
              if (res.succeeded) {
                message.success("Thêm sản phẩm thành công");
                setShowSpiner(false);
                // khi click thêm và tiếp tục thì lưu xong sẽ reset from
                if (saveCreate) {
                  form.resetFields();
                } else {
                  onCancel();
                }
                onReload();
              } else {
                message.error("Mã sản phẩm đã tồn tại.");
                setShowSpiner(false);
              }
            })
            .catch((erro) => {
              message.error("Có lỗi trong quá trình thêm mới");
              setShowSpiner(false);
            });
        }
      }
    }

    // onCancel();
  };

  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (info) => {
    // setLoading(true)
    // // Get this url from response in real world.
    setLoading(true);
    getBase64(info.file.originFileObj, (imageUrl) => setImageUrl(imageUrl));
    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const [isShow, setIsShow] = useState(false);

  const test = () => {
    setIsShow(!isShow);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      {...rest}
      title={title}
      loading={true}
      width={mode === "Create" || mode === "Edit" ? "70%" : "70%"}
      footer={[
        <Button
          style={{ display: mode !== "View" ? "" : "none" }}
          type="primary"
          onClick={() => handleConfirm(false)}
        >
          Lưu
        </Button>,
        // khi thêm mới nó nút này
        <Button
          key="save"
          style={{ display: mode === "Create" ? "" : "none" }}
          type="primary"
          onClick={() => handleConfirm(true)}
        >
          Lưu và tiếp tục
        </Button>,

        <Button key="back" onClick={onCancel}>
          Quay lại
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} labelAlign="left" {...layout}>
        <Row>
          <Col span={13}>
            <Form.Item
              label="Mã sản phẩm"
              required
              name="productCode"
              rules={[
                { required: true, message: "Mã sản phẩm không được trống" },
              ]}
              labelCol={{ span: 6 }}
            >
              <Input placeholder="Nhập mã sản phẩm" />
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              label="Giá Sỉ"
              required
              name="evenSalePrice"
              rules={[
                { required: true, message: "Vui lòng nhập giá" },
                // { max:99999999999999999, message: "Số lượng max là 10000"}
              ]}
              labelCol={{ span: 6 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập giá bán sỉ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={13}>
            <Form.Item
              label="Tên sản phẩm"
              required
              name="productName"
              rules={[
                { required: true, message: "Tên sản phẩm không được trống" },
              ]}
              labelCol={{ span: 6 }}
            >
              <Input placeholder="Nhập tên sản phẩm" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Giá Bán Lẻ"
              required
              name="oddSalePrice"
              rules={[{ required: true, message: "Vui lòng nhập giá" }]}
              labelCol={{ span: 6 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập giá bán sỉ"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={13}>
            <Form.Item
              label="Quy Cách Đóng"
              required
              name="qtyPerPackage"
              rules={[{ required: true, message: "Vui lòng nhập giá trị" }]}
              labelCol={{ span: 6 }}
            >
              <Input type="number" placeholder="Nhập quy cách đóng gói" />
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              label="Dạng đóng gói"
              required
              name="packageType"
              rules={[{ required: true, message: "Vui lòng nhập giá trị" }]}
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn dạng đóng gói" allowClear>
                {listPackageType.map((item, i) => (
                  <Select.Option key={i} value={item?.id}>
                    {item?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="ĐVT Lẻ "
              required
              name="unitTypeId"
              rules={[{ required: true, message: "Đơn vị không được trống" }]}
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn đơn vị tính" allowClear>
                {listUnitType.map((item, i) => (
                  <Select.Option key={i} value={item?.id}>
                    {item?.unitName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={13}>
            <Form.Item
              label="Nhãn hiệu"
              required
              name="brandId"
              rules={[
                { required: true, message: "Nhãn hiệu không được trống" },
              ]}
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn nhãn hiệu" allowClear>
                {listBrand.map((item, i) => (
                  <Select.Option key={i} value={item?.id}>
                    {item?.brandName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              label="ĐVT Chẵn"
              required
              name="packageUnitId"
              rules={[{ required: true, message: "Đơn vị không được trống" }]}
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn đơn vị tính" allowClear>
                {listUnitType.map((item, i) => (
                  <Select.Option key={i} value={item?.id}>
                    {item?.unitName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={13}>
            <Form.Item
              label="Nghành hàng"
              required
              name="industryId"
              rules={[
                { required: true, message: "Nghành hàng không được trống" },
              ]}
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn nghành hàng" allowClear>
                {listIndustry.map((item, i) => (
                  <Select.Option key={i} value={item?.id}>
                    {item?.productIndustryName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              label="Trạng thái"
              name="activeStatus"
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn trạng thái">
                {listStatus.map((item, i) => (
                  <Select.Option key={i} value={item?.status}>
                    {item?.statusName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={13}>
            <Form.Item
              label="Loại sản phẩm"
              required
              name="productTypeId"
              rules={[
                { required: true, message: "Loại sản phẩm không được trống" },
              ]}
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn loại sản phẩm" allowClear>
                {listProductType.map((item, i) => (
                  <Select.Option key={i} value={item?.id}>
                    {item?.productTypeName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}></Col>
        </Row>
        {/* <Row>
          <Col span={13}>
            <Form.Item
              label="Nhà Phân Phối"
              // name="distributorId"
              // rules={[
              //   { required: true, message: "Nhà phân phối không được trống" },
              // ]}
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn loại sản phẩm" allowClear>
                {listDistributor.map((item, i) => (
                  <Select.Option key={i} value={item?.id}>
                    {item?.distributorName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row> */}

        <Row>
          <Col span={13}>
            <Form.Item label="Ghi chú" name="notes" labelCol={{ span: 6 }}>
              <TextArea placeholder="Nhập ghi chú" rows={5} />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Ảnh Đại Diện" labelCol={{ span: 8 }}>
              <Upload
                accept="image/png, image/gif, image/jpeg"
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                      width: "100%",
                      height: "200px",
                      position: "absolute",
                      bottom: "0",
                      right: "0",
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <label
            onClick={test}
            style={{
              backgroundColor: "blue",
              color: "white",
              padding: "5px 10px 5px 10px",
              cursor: "pointer",
              position: "absolute",
              bottom: "15%",
              left: "5%",
              zIndex: "99999999999999999999",
            }}
          >
            {isShow ? "Hủy" : "Chỉnh Sửa"}
          </label>
          <Col span={24} style={{ display: isShow ? "block" : "none" }}>
            <Form.Item label="Các Ảnh Khác" labelCol={{ span: 3 }}>
              {/* <img src="http://api-filemanagement-service-dms.danhhuytech.com//photos/products/23_6_202118_1260085135redbull.jpg" alt="" style={{width:'150px',height:'150px'}} /> */}

              <Upload
                accept="image/png, image/gif, image/jpeg"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChangeList}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>

              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
          </Col>

          <Col span={24} style={{ display: isShow ? "none" : "block" }}>
            <Form.Item
              label="Các Ảnh Khác"
              labelCol={{ span: mode === "Create" || mode === "Edit" ? 3 : 5 }}
            >
              {listImgSub.map((item, i) => (
                <img
                  key={i}
                  alt=""
                  src={item.imagesUrl}
                  style={{
                    width: "150px",
                    height: "150px",
                    marginLeft: "20px",
                  }}
                />
              ))}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Spin
        style={{
          position: "absolute",
          left: "50%",
          right: "50%",
          top: "40%",
          display: isShowSpiner ? "" : "none",
        }}
        size="large"
      />
    </Modal>
  );
};

export default ProductInfoModal;
