import {
  Card,
  Col,
  DatePicker,
  Divider,
  Row,
  Table,
  Typography,
  Form,
  Select,
} from "antd";
import React from "react";
import ChartCustomer from "./ChartCustomer";
function SaleSummaryReport() {
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "NO",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mã nhân viên",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Tên nhân viên  ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Tổng số đơn hàng ",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tổng só lượng sản phẩm ",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Tổng doanh só",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Tổng doanh thu",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tổng công nợ",
      dataIndex: "age",
      key: "age",
    },
  ];

  // ******** TO DO *********
  const listEmployees = [];
  for (let i = 10; i < 36; i++) {
    listEmployees.push(
      <Select.Option key={i.toString(36) + i}>
        {i.toString(36) + i}
      </Select.Option>
    );
  }
  // ******** END TO DO *********

  function onChange(date, dateString) {
    setSelectedMonth(dateString);
  }
  const [selectedMonth, setSelectedMonth] = React.useState("");
  return (
    <>
      <Card className="CustomerInfor-card">
        <Row gutter={[12]} className="space_div">
          <Col lg={8} xl={6}>
            <Form.Item name="CustomerCode" label="Chọn nhân viên">
              <Select placeholder="Chọn nhân viên" allowClear>
                {listEmployees}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={6}>
            <Form.Item name="reportMonth" label={`Chọn tháng:`}>
              <DatePicker onChange={onChange} picker="month" />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      {<ChartCustomer selectedMonth={selectedMonth} />}

      <br></br>
      <Card className="CustomerInfor-card">
        <div className="CustomerInfor-card__header">
          <Typography.Text>Báo Cáo Doanh Số Của Sale</Typography.Text>
        </div>
        <Divider />

        <Table dataSource={dataSource} columns={columns} />
      </Card>
      <br></br>
    </>
  );
}

export default SaleSummaryReport;
