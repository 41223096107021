import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Menu, message } from "antd";
import utils from "utils";
import { get, map, size } from "lodash";
import AuthSlice from "redux/auth/authSlice";
import { useSelector } from "react-redux";
import services from "services";

const MenuSider = function ({ defaultOpenKeys }) {
  const history = useHistory();
  const [menu, setMenu] = useState([]);
  const user = useSelector(AuthSlice.seletors.makeSelectUser);

  const handleSelectMenu = useCallback(
    (payload) => {
      utils.$localStorage.pls.setObject("menu", { keyPath: payload.keyPath });
      history.push(payload.key);
    },
    [history]
  );
  const handleBrowseSaleRoute = () => {
    services.accessManagementService
      .accessManagement_GetMenuByUserRoleId(get(user, "userId"))
      .then((res) => {
        setMenu(res);
      })
      .catch((error) => {
        message.error("Có lỗi khi lấy danh sách menu.");
      });
  };
  useEffect(() => {
    handleBrowseSaleRoute({});
  }, []);
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={get(defaultOpenKeys, 1, [])}
      defaultOpenKeys={defaultOpenKeys}
      onSelect={handleSelectMenu}
    >
      {map(menu, (item, index) => {
        return size(item.subMenuDefinitions) > 0 ? (
          <Menu.SubMenu
            key={item.id}
            title={<i className={item.menuIcon}><span className="ml_10 font_15" style={{fontFamily:'Roboto'}}>{item.menuName}</span></i>}
          >
            {map(item.subMenuDefinitions, (value, key) => {
              return (
                <Menu.Item key={value.subMenuPath}>
                  {value.subMenuName}
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            key={item.menuPath}
            title={item.menuPath}
          >
          <i className={item.menuIcon}><span className="ml_10 font_15" style={{fontFamily:'Roboto'}}>{item.menuName}</span></i>
          </Menu.Item>
        );
      })}

      {/* <Menu.Item
        icon={<DashboardOutlined />}
        key='/dashboard'
        title='Dashboard'
      >
        Dashboard
      </Menu.Item>
      <Menu.SubMenu
        icon={<OrderedListOutlined />}
        key='/organization'
        title='Tổ Chức'
      >
        <Menu.Item key='/organization/organizeChart'>Sơ đồ tổ chức</Menu.Item>
        <Menu.Item key='/organization/title-management'>
          Quản lý chức danh
        </Menu.Item>
        <Menu.Item key='/organization/distributor-information'>
          Nhà phân phối
        </Menu.Item>
        <Menu.Item key='/organization/user-account'>Quản lý nhân viên</Menu.Item>
        <Menu.Item key='/organization/organize-definition'>
          Quản lý tổ chức
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        icon={<CreditCardOutlined />}
        key='/sale-management'
        title='Phiếu bán hàng'
      >
        <Menu.Item key='/sale-management/purchase-order'>
          Phiếu đặt hàng
        </Menu.Item>
        <Menu.Item key='/sale-management/sell-order'>Đơn bán hàng</Menu.Item>
        <Menu.Item key='/sale-management/list-order'>
          Danh sách đơn hàng
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        icon={<UserOutlined />}
        key='/customer-management'
        title='Quản lý khách hàng'
      >
        <Menu.Item key='/customer-management/sale-channel'>
          Kênh bán hàng
        </Menu.Item>
        <Menu.Item key='/customer-management/business-type'>
          Hình thức kinh doanh
        </Menu.Item>
        <Menu.Item key='/customer-management/customer-groups'>
          Nhóm khách hàng
        </Menu.Item>
        <Menu.Item key='/customer-management/customer-information'>
          Thông tin khách hàng
        </Menu.Item>
        {/* <Menu.Item key='/customer-management/sale-route'>
          Tuyến bán hàng
        </Menu.Item> */}
      {/* <Menu.Item key='/customer-management/sale-route-management-new'>
          Quản lý tuyến bán hàng
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        icon={<SlidersOutlined />}
        key='/product-management'
        title='Quản lý sản phẩm'
      > */}
      {/* <Menu.Item key='/product-management/product-industry'>
          Ngành hàng
        </Menu.Item>
        <Menu.Item key='/product-management/product-brand'>Nhãn hiệu</Menu.Item>
        <Menu.Item key='/product-management/provider-information'>
          Nhà cung cấp
        </Menu.Item>
        <Menu.Item key='/product-management/product-type'>
          Loại sản phẩm
        </Menu.Item>
        <Menu.Item key='/product-management/product-info'>
          Thông tin sản phẩm
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        icon={<ShopOutlined />}
        key='/warehouse'
        title='Quản lý kho'
      >
        <Menu.Item key='/warehouse/warehouse-information'>
          Thông tin kho
        </Menu.Item>
        <Menu.Item key='/warehouse/product-inbound'>
          Danh sách phiếu nhập
        </Menu.Item>
        <Menu.Item key='/warehouse/product-outbound'>
          Danh sách phiếu xuất
        </Menu.Item>
        <Menu.Item key='/warehouse/productInventory'>Quản lý tồn</Menu.Item>
      </Menu.SubMenu>
      
      <Menu.SubMenu
        icon={<TableOutlined />}
        key='/general-management'
        title='Danh mục quản lý'
      >
        <Menu.Item key='/general-management/payment-method'>
          Hình thức thanh toán
        </Menu.Item>
        <Menu.Item key='/general-management/unit-type'>Đơn vị tính</Menu.Item>
        <Menu.Item key='/general-management/currency-unit'>
          Đơn vị tiền tệ
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        icon={<BarChartOutlined />}
        key='/statistic-management'
        title='Thống kê'
      // 4 tab ( khách hàng mới, trùng thông tin, thiếu thông tin, viếng thăm lần đầu)
      >
        <Menu.Item key='/statistic-management/customer-summary'>
          Thông kê khách hàng
        </Menu.Item>
        {/* 2 tab */}
      {/* <Menu.Item key='/statistic-management/inventory-statistic'>
          Thống kê xuất nhập tồn
        </Menu.Item>
        <Menu.Item key='/statistic-management/statistic-shipping-order'>
          Thông kê đơn hàng
        </Menu.Item>
        <Menu.Item key='/statistic-management/statistic-product-inbound'>
          Thống kê nhập hàng
        </Menu.Item>
        <Menu.Item key='/statistic-management/statistic-product-outbound'>
          Thống kê xuất hàng
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu icon={<RiseOutlined />} key='/report-management' title='Báo cáo'> */}
      {/* <Menu.Item key='/report-management/sale-summary-report'>
          Báo cáo doanh số của Sale
        </Menu.Item>
        <Menu.Item key='/report-management/customer-summary-report'>
          Báo cáo bán hàng khách hàng
        </Menu.Item> */}
      {/* <Menu.Item key='/report-management/attendance-summary'>
          Báo cáo viếng thăm khách hàng
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        icon={<ShareAltOutlined />}
        key='/access-management'
        title='Tài khoản và phân quyền'
      >
        <Menu.Item key='/access-management/action-control'>
          Danh sách chức năng
        </Menu.Item>
        <Menu.Item key='/access-management/permission'>
          Danh sách quyền truy cập
        </Menu.Item>
        <Menu.Item key='/access-management/role'>
          Quản lý nhóm tài khoản
        </Menu.Item>
        <Menu.Item key='/access-management/menu-control'>
          Danh sách màn hình
        </Menu.Item>
        <Menu.Item key='/access-management/authorize-access'>
          Phân quyền
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item icon={<SettingOutlined />} key='caidat'>
        Cài đặt hệ thống
      </Menu.Item> */}
    </Menu>
  );
};

export default MenuSider;
