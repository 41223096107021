/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Màn hình `Sơ đồ tổ chức`. Route `/organization/OraganizeChart`
 * */

import React, { useCallback, useEffect, useState } from "react";
import { Card } from "antd";
import { forEach } from "lodash";
import services from "services";
import { Chart } from "react-google-charts";

// [
//   ["Name", "Manager", "ToolTip"],
//   [
//     {
//       v: "Mike",
//       f: 'Mike<div style="color:red; font-style:italic">President</div>',
//     },
//     "",
//     "The President",
//   ],
//   [
//     {
//       v: "Jim",
//       f: 'Jim<div style="color:red; font-style:italic">Vice President</div>',
//     },
//     "Mike",
//     "VP",
//   ],
//   ["Alice", "Mike", ""],
//   ["Bob", "Jim", "Bob Sponge"],
//   ["Carol", "Bob", ""],
//   ["T.Duc", "Bob", ""],
// ]

const OraganizeChartPage = function () {
  // const [listRegion, setListRegion] = useState([]);
  // const [fetchingListRegion, setfetchingListRegion] = useState([]);
  const [organizations, setOrganizations] = useState([
    ["Name", "Manager", "ToolTip"],
  ]);

  const handleBrowseRegion = useCallback((search) => {
    //setfetchingListRegion(true);
    services.organization
      .organization_GetOrganizationDefinition()
      .then((res) => {
        setOrganizations((prev) => {
          let next = [...prev];
          let item = [
            {
              v: res.levelName,
              f: `<div className="organization__node">${res.levelName}</div>`,
            },
            "",
            "Doanh nghiệp",
          ];
          next.push(item);
          // Add data vùng miền
          forEach(res.businessRegions, (region) => {
            item = [
              {
                v: region.regionName,
                f: `<div className="region__node">${region.regionName}</div>`,
              },
              res.levelName,
              "Vùng miền",
            ];
            next.push(item);
            forEach(region.businessProvinces, (province) => {
              item = [
                {
                  v: province.provinceName,
                  f: `<div className="${
                    province.status === 1 ? "area--active" : "area--inactive"
                  }">${province.provinceName}</div>`,
                },
                region.regionName,
                "Tỉnh",
              ];
              next.push(item);
              forEach(province.businessDistricts, (district) => {
                item = [
                  {
                    v: district.districtName,
                    f: district.districtName,
                  },
                  province.provinceName,
                  "Quận/Huyện",
                ];
                next.push(item);
                forEach(district.distributors, (distributor) => {
                  item = [
                    {
                      v: distributor.distributorName,
                      f: distributor.distributorName,
                    },
                    district.districtName,
                    "NPP",
                  ];
                  next.push(item);
                  forEach(distributor.staffOfDistributors, (staff) => {
                    item = [
                      {
                        v: staff.fullName,
                        f: staff.fullName,
                      },
                      distributor.distributorName,
                      "NV",
                    ];
                    next.push(item);
                  });
                });
              });
            });
          });
          return next;
        });
      });
      //.finally(() => setfetchingListRegion(false));
  }, []);
  useEffect(() => {
    handleBrowseRegion();
  }, [handleBrowseRegion]);
  return (
    <div id="oraganizeChartPage"  className="h-100">
      <Card className="oraganizeChartPage__wrapper dms__card--rounded h-100" size="small">
        <div
          style={{
            overflow: "auto",
            minHeight: "calc(100vh - 180px)",
            maxHeight: "calc(100vh - 180px)",
          }}
        >
          <Chart
            width="100%"
            height="100%"
            chartType="OrgChart"
            loader={<div>Đang lấy dữ liệu...</div>}
            data={organizations}
            options={{
              allowHtml: true,
              allowCollapse: true,
              nodeClass: "chart__node",
              selectedNodeClass: "chart__node--selected",
            }}
            rootProps={{ className: "organization__chart" }}
          />
        </div>
      </Card>
    </div>
  );
};
export default OraganizeChartPage;
