import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table
} from "antd";
import {
  DeleteOutlined
} from "@ant-design/icons";
import React, {
  useEffect,
  useState,
} from "react";
import services from "services";import {
  find,
  map,
  trim,
  size,
  filter,
  toLower,
  includes
} from "lodash";
import utils from 'utils';

const KpiModal = ({
  visible,
  onCancel,
  onSave,
  selectedKpi,
  mode,
  filteredStaffs,
  ...rest
}) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const [form] = Form.useForm();

  const [isShowSpiner, setShowSpiner] = useState(true);
  const [title, setTitle] = useState("");

  const [listProduct, setListProduct] = useState([]);
  const [listFilteredProduct, setListFilteredProduct] = useState([]);
  const [selectedListKpiProducts, setSelectedListKpiProducts] = useState([]);  

  useEffect(() => {
    let initValues = {
      staffId: selectedKpi?.staffId,
      newCustomerCount: selectedKpi?.newCustomerCount ?? 0,
      visitedCustomerCount: selectedKpi?.visitedCustomerCount ?? 0,
      purchaseOrderCount: selectedKpi?.purchaseOrderCount ?? 0,
      turnover: selectedKpi?.turnover ?? 0,
      workdays: selectedKpi?.workdays,
      month: selectedKpi?.month,
      year: selectedKpi?.year,
      activeStatus: 1
    };
    var originalKpiProducts = selectedKpi?.kpiProducts ? [...selectedKpi.kpiProducts] : [];
    setSelectedListKpiProducts(originalKpiProducts);
    form.setFieldsValue(initValues);

    if (mode === "Edit") {
      setTitle("Chỉnh Sửa KPI");
    }
    if (mode === "Create") {
      setTitle("Thêm Mới KPI");
    }
    if (mode === "View") {
      setTitle("Chi Tiết KPI");
    }
    setShowSpiner(false);
  }, [selectedKpi, form, mode]);

  const onSubmit = (values) => {
    setShowSpiner(true);
    const dataSubmit = form.getFieldValue();
    const dataClone = {
      id: selectedKpi?.id,
      currentState: selectedKpi?.currentState,
      staffId: dataSubmit.staffId,
      newCustomerCount: parseInt(dataSubmit.newCustomerCount),
      visitedCustomerCount: parseInt(dataSubmit.visitedCustomerCount),
      purchaseOrderCount: parseInt(dataSubmit.purchaseOrderCount),
      turnover: dataSubmit.turnover,
      workdays: dataSubmit.workdays,
      month: dataSubmit.month,
      year: dataSubmit.year,
      activeStatus: 1,
      kpiProducts: selectedListKpiProducts
    };    

    if (mode === "Create") {
      services.shippingOrderService
      .kpi_Create(dataClone)
      .then((res) => {
          message.success("Thêm kpi thành công");        
          onSave();
      })
      .catch((error) => {
        message.error("Có lỗi trong quá trình thêm mới");
        onCancel();
      })
      .finally(() => setShowSpiner(false));
    } else {
      services.shippingOrderService
      .kpi_Update(dataClone)
      .then((res) => {
        if(res.succeeded) {
          message.success("Cập nhật kpi thành công");        
          onSave();
        } else {
          message.error(res.errors.length > 0 && res.errors[0]);
          onCancel();
        }
      })
      .catch((error) => {
        message.error("Có lỗi trong quá trình cập nhật");
        onCancel();
      })
      .finally(() => setShowSpiner(false));
    }
  };

  useEffect(() => {
    services.generalService.products_GetAllProducts().then((res) => {
      setListProduct(res?.map((product) => {
        return {
          productId: product.id,
          productCode: product.productCode,
          productName: product.productName
        }
      }))
    });
  }, [setListProduct])

  //Start Kpi products
  const handleBrowseKpiProduct = (search) => {
    if (size(trim(search)) === 0) {
      setListFilteredProduct(listProduct);
      return;
    }
    let filtered = filter(listProduct, (x) => {
      return (
        includes(
          utils.$common.converVietNamese(toLower(x.productName)),
          utils.$common.converVietNamese(toLower(search))
        ) ||
        includes(
          utils.$common.converVietNamese(toLower(x.productCode)),
          utils.$common.converVietNamese(toLower(search))
        )
      );
    });
    setListFilteredProduct(filtered);
  };

  const handleAddKpiProduct = (productId) => {
    if (!productId) return;
    if (
      find(
        selectedListKpiProducts,
        (x) => x.productId === productId
      )
    ) {
      message.warning("Đã có sản phẩm này trong danh sách.");
      return;
    }
    let findedProduct = find(
      listProduct,
      (x) => x.productId === productId
    );
    if (findedProduct) {
      message.info("Thêm sản phẩm vào danh sách");
      setSelectedListKpiProducts((prev) => {
        let next = [...prev];
        next.push({
          productId: findedProduct.productId,
          productName: findedProduct.productName,
          quantity: 0,
          turnover: 0
        });
        return next;
      });
    }
  };

  const handleUpdateKpiProduct = ({index, name, value}) => {
    let cloneKpiProducts = [...selectedListKpiProducts];
    let updatedKpiProduct = cloneKpiProducts[index];
    updatedKpiProduct[name] = value;
    setSelectedListKpiProducts(cloneKpiProducts);
  };

  const handleRemoveKpiProduct = ({ index, item }) => {
    setSelectedListKpiProducts((prev) => {
      let next = [...prev];
      next.splice(index, 1);
      return next;
    });
  };

  const productOptions = map(listFilteredProduct, (product) => {
    const productKey = `${product.productId}`;
    const title = `${product.productCode} - ${product.productName}`;
    return (
      <Select.Option key={productKey} value={productKey} title={title}>
        <span>{product.productCode}</span>
        {product.productCode && product.productName ? (
          <span className="mx-1">-</span>
        ) : null}
        <span>{product.productName}</span>
      </Select.Option>
    );
  });

  const kpiProductColumns = [
    {
      key: "productId",
      dataIndex: "productId",
      title: "Sản phẩm",
      render: (productId) => (listProduct.find((product) => (product.productId === productId))?.productName)
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: "Sản lượng",
      align: "center",
      render: (value, item, index) => (
        <InputNumber
          placeholder="Nhập"
          min={0}
          value={value}
          controls={false}
          className="text-center"
          onChange={(quantity) =>
            handleUpdateKpiProduct({
              index,
              name: "quantity",
              value: quantity
            })
          }
        />
      )
    },
    {
      key: "price",
      dataIndex: "price",
      title: "Giá",
      align: "center",
      render: (value, item, index) => (
        <InputNumber
          placeholder="Nhập"
          min={0}
          value={value}
          controls={false}
          className="text-center"
          onChange={(price) =>
            handleUpdateKpiProduct({
              index,
              name: "price",
              value: price
            })
          }
        />
      )
    },
    {
      key: "turnover",
      dataIndex: "turnover",
      title: "Doanh số",
      align: "center",
      render: (value, item, index) => (
        <InputNumber
          placeholder="Nhập"
          min={0}
          value={value}
          controls={false}
          className="text-center"
          onChange={(turnover) =>
            handleUpdateKpiProduct({
              index,
              name: "turnover",
              value: turnover
            })
          }
        />
      )
    },
    {
      key: "notes",
      dataIndex: "notes",
      title: "Ghi chú",
      align: "center",
      render: (value, item, index) => (
        <Input 
          placeholder="Nhập" 
          value={value}
          onChange={(event) =>
            handleUpdateKpiProduct({
              index,
              name: "notes",
              value: event.target.value
            })
          }
        />
      )
    },
    {
      key: "action",
      dataIndex: "action",
      title: "",
      width: 50,
      fixed: "right",
      render: (value, item, index) => {
        return (
          <span className="d-flex align-items-center justify-content-center text-danger">
            <DeleteOutlined
              onClick={() => handleRemoveKpiProduct({ item, index })}
            />
          </span>
        );
      },
    },
  ];
  //End Kpi Products

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      {...rest}
      title={title}
      loading={true}
      width="70%"
      footer={[
        <Button
          key="save"
          style={{ display: mode !== "View" ? "" : "none" }}
          type="primary"
          onClick={() => form.submit()}
        >
          Lưu
        </Button>,

        <Button
          key="back"
          onClick={onCancel}
        >
          Huỷ
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} labelAlign="left" {...layout}>
        <Row>
          <Col span={13}>
            <Form.Item 
              label='Nhân viên' 
              name='staffId'
              rules={[
                { required: true, message: "Vui lòng chọn nhân viên" }
              ]}
              labelCol={{ span: 7 }}>
                <Select placeholder='Chọn nhân viên'
                        disabled={mode === "Create" ? false: true}
                        allowClear>
                  {filteredStaffs.map((pv, id) => (
                    <Select.Option key={pv?.userId} value={pv?.userId}>
                      {pv?.fullName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Số ngày công"
              required
              min={0}
              name="workdays"
              rules={[
                { required: true, message: "Vui lòng nhập số ngày công" },
              ]}
              labelCol={{ span: 7 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập số ngày công"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={13}>
            <Form.Item
              label="Khách hàng mới"
              required
              name="newCustomerCount"
              rules={[
                { required: true, message: "Vui lòng nhập số lượng khách hàng mới" },
              ]}
              labelCol={{ span: 7 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập số lượng khách hàng mới"
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Tháng"
              required
              min={0}
              name="month"
              rules={[
                { required: true, message: "Vui lòng nhập tháng" },
              ]}
              labelCol={{ span: 7 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập tháng"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={13}>
            <Form.Item
              label="Khách viếng thăm"
              required
              min={0}
              name="visitedCustomerCount"
              rules={[
                { required: true, message: "Vui lòng nhập số lượng khách hàng viếng thăm" },
              ]}
              labelCol={{ span: 7 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập số lượng khách hàng viếng thăm"
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Năm"
              required
              min={0}
              name="year"
              rules={[
                { required: true, message: "Vui lòng nhập năm" },
              ]}
              labelCol={{ span: 7 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập năm"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={13}>
            <Form.Item
              label="Số lượng đơn"
              required
              min={0}
              name="purchaseOrderCount"
              rules={[
                { required: true, message: "Vui lòng nhập số lượng đơn hàng" },
              ]}
              labelCol={{ span: 7 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập số lượng đơn hàng"
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Doanh số"
              required
              name="turnover"
              rules={[{ required: true, message: "Vui lòng nhập doanh số" }]}
              labelCol={{ span: 7 }}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập doanh số"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        
        <Row>
          <Card 
            className="w-100"
            size="small"
          >
            <div className="d-flex align-items-center">
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Nhập từ khóa (2 ký tự trở lên)"
                optionFilterProp="children"
                notFoundContent={null}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                value={null}
                onSelect={handleAddKpiProduct}
                onSearch={handleBrowseKpiProduct}
              >
                {productOptions}
              </Select>
            </div>
            <Table
              className="mt-3"
              size="small"
              rowKey="productId"
              bordered
              dataSource={selectedListKpiProducts}
              columns={kpiProductColumns}
              pagination={false}
              locale={{ emptyText: "Chưa có KPI sản phẩm" }}
            />
          </Card>
        </Row>
      </Form>

      <Spin
        style={{
          position: "absolute",
          left: "50%",
          right: "50%",
          top: "40%",
          display: isShowSpiner ? "" : "none",
        }}
        size="large"
      />
    </Modal>
  );
};

export default KpiModal;
