import $localStorage from "./localstorage";

const $oauth = {
  setOauth(auth) {
    $localStorage.sls.setObject("auth", auth);
  },
  clearOauth() {
    $localStorage.sls.remove("auth");
  },
  getOauth() {
    const auth = $localStorage.sls.getObject("auth");
    let isAuthenticated = false;
    let accessToken = "";
    let user = {};
    if (auth && auth.accessToken && auth.user) {
      isAuthenticated = true;
      accessToken = auth.accessToken;
      user = auth.user;
    }
    return {
      isAuthenticated,
      accessToken,
      user,
    };
  },
};
export default $oauth;
