/**
 * Copyright 2021-present, Hoàng An.
 * All rights reserved.
 * @author tangan2215@gmail.com on 07/06/2021.
 * @description Màn hình Nhóm nhãn hiệu. Route `/productManagement/ProductType`
 **/
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  message,
  Divider,
  Col,
  Row,
  Input,
  Form,
  Select
} from "antd";
import {
  DeleteOutlined,
  EditFilled,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ProductTypeModal from "components/modals/ProductTypeModal";
import services from "services";
import Pagination from "components/Paginate/index";

export default function ProductType() {
  const [listProductType, setlistProductType] = useState([]);
  const [showProductTypeModal, setShowProductTypeModal] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState({});

  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  ///const [isReloadPage, setIsReloadPage] = useState(false);

  const handleGetlistProductType = useCallback(() => {
    setLoading(true);
    services.generalService
      .productType_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res) => {
        setlistProductType(res.items);
        setLoading(false);
        setTotal(res?.totalCount ?? 0);
      })
      .catch((error) => {
        setTotal(0);
        setLoading(false);
      });
  }, [paging]);

  const [form] = Form.useForm();

  const listStatus = [
    {
      id: 1,
      status: 1,
      statusName: "Hoạt động",
    },
    {
      id: 2,
      status: 0,
      statusName: "Không hoạt động",
    },
  ]

  useEffect(() => {
    handleGetlistProductType();
  }, [handleGetlistProductType]);

  // const handleChangePaging = useCallback(
  //   (page, pageSize) => {
  //     const currentPage = pageSize !== paging?.pageSize ? 1 : page;
  //     setPaging({ page: currentPage, pageSize });
  //   },
  //   [paging]
  // );

  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa loại sản phẩm{" "}
          <b className="text-danger">{row.productTypeCode}</b>{" "}
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.generalService
          .productType_Delete({
            id: row.id,
            currentState: row.currentState,
          })
          .then((res) => {
            message.success("Xóa loại sản phẩm thành công.");
            handleGetlistProductType();
          })
          .catch((erro) => {
            message.error("Tồn tại sản phẩm có chứa loại sản phẩm.");
          });
      },
      onCancel() {},
    });
  }, []);

  const columns = [
    {
      dataIndex: "productTypeCode",
      key: "productTypeCode",
      title: "Mã loại",
      //  align: "center",
      width: 150,
    },
    {
      dataIndex: "productTypeName",
      key: "productTypeName",
      title: "Tên loại",
      width: 200,
      //  align: "center"
    },
    {
      dataIndex: "notes",
      key: "notes",
      title: "Ghi chú",
      // align: "center",
    },
    {
      dataIndex: "activeStatus",
      key: "activeStatus",
      title: "Trạng Thái",
      align: "center",
      width: 180,
      fixed: "right",
      render: (value) => (
        <div className={`status--${value ? "active" : "inactive"}`}>
          {value === 1 ? "Hoạt Động" : "Không Hoạt Động"}
        </div>
      ),
    },
    {
      dataIndex: "#",
      key: "action",
      title: "#",
      align: "center",
      width: 150,
      fixed: "right",
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Button
            icon={<EditFilled className="warehouseInfo-card__icon" />}
            type="primary"
            onClick={handleEditProductType(row)}
          ></Button>
          <Button
            className="warehouseInfo-card__button--right"
            icon={<DeleteOutlined className="warehouseInfo-card__icon" />}
            type="danger"
            onClick={() => handleShowConfirmDelete(row)}
          ></Button>
        </div>
      ),
    },
  ];

  const handleCreateProductType = useCallback(() => {
    setShowProductTypeModal(true);
  }, []);

  const handleEditProductType = useCallback(
    (ProductTypeInfor) => (e) => {
      setShowProductTypeModal(true);
      setSelectedProductType(ProductTypeInfor);
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setShowProductTypeModal(false);
    setSelectedProductType({});
  }, []);

  const handleConfirmfilter = useCallback(() => {
    form.submit();
  }, [form]);

  const onSubmit = (values) => {
    setLoading(true);

    let dataSubmit = {
      ...form.getFieldValue(),
      pageNumber: 1,
      pageSize: 10,
    };

    services.generalService
      .productType_FilterProductType(dataSubmit)
      .then((res) => {
        setlistProductType(res.items);
        setLoading(false);
        // if (res.items.length == 0) {
        //   message.error("Không tìm thấy nhãn hiệu");
        // } else {
        //   message.success("Tìm kiếm thành công");
        // }
      });
  };

  return (
    <>
      
      <Card className="ProductType-card">
        <Divider style={{margin:10}}/>
        <Form form={form} onFinish={onSubmit} labelCol={{ span: 24 }}>
          <Row gutter={[15]}>
            <Col lg={6} xl={6}>
              <Form.Item name="productTypeCode" label="Mã loại sản phẩm">
                <Input placeholder="Nhập mã loại" />
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              <Form.Item name="productTypeName" label="Tên loại sản phẩm">
                <Input placeholder="Nhập tên loại" />
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              <Form.Item name="activeStatus" label="Trạng thái">
                <Select allowClear placeholder="Chọn trạng thái">
                  {listStatus.map((item, i) => (
                    <Select.Option key={i} value={item?.status}>
                      {item?.statusName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleConfirmfilter}
                  size="middle"
                  disabled={loading}
                >
                  <span className="d-flex align-items-center">
                    <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                  </span>
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={[8]}>
            <Col>
              <Button size="middle" onClick={handleCreateProductType}>
                <span className="d-flex align-items-center">
                  <PlusOutlined />
                  <span className="ml-1">Thêm mới</span>
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
        <Divider style={{margin:10}}/>
        <div className="CustomerInfor-card__header">
          {/* <Typography.Text>DANH SÁCH LOẠI SẢN PHẨM</Typography.Text> */}
        </div>
        <Table
          rowKey="id"
          className="ProductType-card__table"
          columns={columns}
          dataSource={listProductType}
          bordered
          loading={loading}
          scroll={{ x: 1200 }}
          size="small"
          // pagination={{
          //   total,
          //   pageSizeOptions: [10, 20, 50],
          //   current: paging?.page ?? 1,
          //   pageSize: paging?.pageSize ?? 10,
          //   onChange: handleChangePaging,
          //   showSizeChanger: true,
          // }}
          pagination={false}
          locale={{ emptyText: "Không có loại sản phẩm nào" }}
        />
        <Pagination total={total} setPaging={setPaging} paging={paging} />

        <ProductTypeModal
          visible={showProductTypeModal}
          onCancel={handleCloseModal}
          selectedProductType={selectedProductType}
          onReload={handleGetlistProductType}
        />
      </Card>
    </>
  );
}
