import React, { memo } from "react";
import { Pagination } from "antd";

const paginate = memo(({ total, grid, setPaging, paging ,setParams,params}) => {
  const onPageSize = (e) => {
    if(paging?.page){
      setPaging((preState) => {
        let nextState = { ...preState };
        nextState.page = e;
        return nextState;
      });
    }else{
      setParams((preState) => {
        let nextState = { ...preState };
        nextState.page = e;
        return nextState;
      });
    }
    
  };
  const onSizeChange = (current, pageSize) => {
    if(paging?.page){
      setPaging((preState) => {
        let nextState = { ...preState };
        nextState.pageSize = pageSize;
        return nextState;
      });
    }else{
      setParams((preState) => {
        let nextState = { ...preState };
        nextState.pageSize = pageSize;
        return nextState;
      });
    }
  };
  return (
    <Pagination
      className="mt_10 d-flex justify-content-end"
      current={paging?.page ? paging.page : params?.pageNumber}
      total={total}
      onChange={(e) => onPageSize(e)}
      showTotal={(total, range) => `${range[0]}-${range[1]} / ${total} items`}
      pageSizeOptions={['5','10','15', '30', '50','100']}
      onShowSizeChange={(current, pageSize) => onSizeChange(current, pageSize)}
      showSizeChanger
    />
  );
});
export default paginate;
