import React, { useState, memo } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import styled from "styled-components";
import _ from "lodash";
import Map2 from "../../../../images/map2.png";
import {
  UserOutlined,
  FieldTimeOutlined,
  FlagOutlined,
  MobileOutlined,
} from "@ant-design/icons";
const customerMarker = memo(({ item }) => {
  const google = window.google;
  const [isShow, setIsShow] = useState({
    image: "",
    click: false,
  });
  const onMarker = (e) => {
    setIsShow((preState) => {
      let nextState = { ...preState };
      nextState.click = !isShow.click;
      return nextState;
    });
  };

  return (
    <div>
      <Marker
        position={{ lat: item.latitude, lng: item.longitude }}
        onClick={onMarker}
        icon={{
          url: Map2,
          scaledSize: new window.google.maps.Size(50, 50),
        }}
      />
      
      {isShow.click && (
        <div>
          <InfoWindow
            className="test"
            onCloseClick={() => {
              setIsShow((preState) => {
                let nextState = { ...preState };
                nextState.click = false;
                return nextState;
              });
            }}
            position={{
              lat: item.latitude,
              lng: item.longitude,
            }}
            onClose={isShow.click}
            options={{pixelOffset: new google.maps.Size(0, -30), maxWidth : 300}}
          >            
            <div style={{ border: "1px solid 1px" }}>
              <div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                <UserOutlined /> NV: {_.get(item, "staffName", "")}
              </div>
              <div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                <span>
                  <UserOutlined /> KH: {_.get(item, "customerName", "")}
                </span>
                <span style={{marginLeft: 5}}>
                  <MobileOutlined /> {_.get(item, "bateryPercent", "")}%
                </span>
              </div>
              <div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                  <FieldTimeOutlined /> Check in: {_.get(item, "startTime", "")}
              </div>
              {_.get(item, "endTime", "") !== "" &&
              <div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                  <FieldTimeOutlined /> Check out: {_.get(item, "endTime", "")}
              </div>}
              <span style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                <FlagOutlined style={{ color: "blue" }} />{" "}
                {_.get(item, "address", "")}
              </span>
              {item.prevCustomerName !== '' && (<div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                <UserOutlined /> Cách KH {item.prevCustomerName}: {item.distance}m
              </div>)}
              {item.visitImageUrl &&
                <img
                  key={item.id}
                  alt=""
                  src={item.visitImageUrl}
                  style={{
                    width: "100%",
                    maxHeight: "226px",
                    marginTop: "5px",
                  }}
                />                
              }
            </div>
          </InfoWindow>
        </div>
      )}
    </div>
  );
});
export default styled(customerMarker)``;
