import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Card,Spin,message } from "antd";
import styled from "styled-components";
import _ from "lodash";
import TotalSales from "./totalSales";
import services from "services";
import Filter from "./totalSales/Filter";
import moment from "moment";
let time;
const Dashboard = ({ className, profile }) => {
  const ggMapKey = process?.env?.REACT_APP_GG_MAP_KEY;
  const [data, setData] = useState();
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dataFilter, setDataFilter] = useState({
    arrNpp: [],
    arrCustomer: [
      {
        id: "CI",
        name: "Check in",
      },
      {
        id: "CO",
        name: "Check out",
      },
    ],
    arrUser: [],
    arrUserProvice: [],
  });
  const [params, setParams] = useState({
    type: "CI",
    staffId: undefined,
    checkDate: moment(),
  });

  const getProducts = useCallback(() => {
    setLoading(true)
    services.customerService
      .visitAttendance_GetVisitAttendanceCheckinOrCheckOut({
        type: _.get(params, "type"),
        checkDate:_.get(params, "checkDate")&& `${moment(_.get(params, "checkDate")).format('YYYY-MM-DD')}T00:00:00.000Z`,
        staffId: _.get(params, "staffId"),
      })
      .then((res) => {
        let arrData = _.map(res, (item, index) => {
          item.endTime =
            item.endTime && moment(item.endTime).format("DD-MM-YYYY HH:mm");
          return item;
        });
        setData(arrData);
        setLoading(false)
      })
      .catch((error) => {setLoading(false);message.error('Không tìm thấy kết quả')});

      // api filter province
    services.organization
    .province_GetAllProvincesWithPermission()
    .then((res) => {
      let arrData = _.map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrUserProvice = arrData;
        return nextState;
      });
    })
    .catch((error) => {});
    // // api filter npp
    // services.generalService
    //   .distributor_GetAllDistributor()
    //   .then((res) => {
    //     let arrData = _.map(res, (item, index) => {
    //       item.name = item.distributorName;
    //       return item;
    //     });
    //     setDataFilter((preState) => {
    //       let nextState = { ...preState };
    //       nextState.arrNpp = arrData;
    //       return nextState;
    //     });
    //   })
    //   .catch((error) => {});

    // // khi ko chọn npp thì lấy all nhân viên
    // if (!_.get(params, "distributorId")) {
    //   // danh sach nv
    //   services.organization
    //     .humanResource_GetAll()
    //     .then((res) => {
    //       let arrData = _.map(res?.items, (item, index) => {
    //         item.index = index + 1;
    //         item.id = _.get(item, "userId");
    //         item.name = _.get(item, "fullName");
    //         return item;
    //       });
    //       setDataFilter((preState) => {
    //         let nextState = { ...preState };
    //         nextState.arrUser = arrData;
    //         return nextState;
    //       });
    //     })
    //     .catch((error) => {});
    // }
  }, [filter]);

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(getProducts, 800);
  }, [getProducts]);

  useEffect(() => {
    
  }, []);

  return (
    <Card>
      <Row className={className} gutter={16}>
        <Col xs={24} style={{ marginBottom: 10 }}>
          <Filter
            params={params}
            setParams={setParams}
            dataFilter={dataFilter}
            setFilter={setFilter}
            filter={filter}
            setDataFilter={setDataFilter}
          />
        </Col>

        <Col xs={24}>
        <Spin spinning={loading} tip="Đang lấy dữ liệu...">
          <TotalSales 
            data={data}
            ggMapKey={ggMapKey} />
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default styled(Dashboard)``;
