import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Spin } from "antd";
import styled from "styled-components";
import _ from "lodash";
import ChartDay from "./chartDay";
import ChartWeek from "./chartWeek";
import ChartTotalSales from "./chartTotalSales";
import TotalSales from "./totalSales";
import services from "services";

let time;
const Dashboard = ({ className, profile }) => {
  const [data, setData] = useState([
    {
      monthlyDetail: [],
      specificWeeklySales: [],
      weeklySaleOrder: [],
      weeklySaleRevenue: [],
      summary: [],
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const getProducts = useCallback(() => {
    setIsLoading(true);
    services.satatisticReportService
      .dashboard_SummaryReport()
      .then((res) => {
        setIsLoading(false);
        let arrMonthlyDetail = [];
        let arrSpecificWeeklySales = [];
        let arrWeeklySaleOrder = [];
        let arrWeeklySaleRevenue = [];
        let arrSummary = [];
        _.map(_.get(res, "monthlyDetailReport.dailySales"), (item, index) => {
          arrMonthlyDetail.push([
            item.reportDate,
            item.saleOrder,
            item.saleRevenue,
            item.newCustomer,
            item.visitAttendance,
            item.totalOrder,
            item.totalSKU,
          ]);
        });
        _.map(
          _.get(res, "weeklySaleReport.dailySales"),
          (item, index) => {
            arrSpecificWeeklySales.push([
              item.reportDate,
              item.saleOrder,
              item.saleRevenue,
            ]);
          }
        );
        arrSpecificWeeklySales.unshift(["Month", "Doanh số", "Doanh thu"]);
        _.map(_.get(res, "weeklySaleReport.dailySales"), (item, index) => {
          arrWeeklySaleOrder.push([
            item.reportDate,
            item.saleOrder,
          ]);
          arrWeeklySaleRevenue.push([
            item.reportDate,
            item.saleRevenue,
          ]);
        });
        arrWeeklySaleOrder.unshift(["Doanh số","Doanh thu"]);
        arrWeeklySaleRevenue.unshift(["Doanh thu","Doanh số"]);
        arrMonthlyDetail.unshift([
          "Month",
          "Doanh số",
          "Doanh thu",
          "Khách hàng mới",
          "Tổng lần viếng thăm",
          "Tổng đơn hàng",
          "Tổng số SKU",
        ]);
        arrSummary = [
          {
            total: _.get(res, "summaryReport.saleOrder"),
            name: "Doanh thu",
            color: "red",
          },
          {
            total: _.get(res, "summaryReport.saleRevenue"),
            name: "Doanh số",
            color: "#ffcc00",
          },
          {
            total: _.get(res, "summaryReport.newCustomer"),
            name: "Khách hàng mới",
            color: "#007fff",
          },
          {
            total: _.get(res, "summaryReport.visitAttendance"),
            name: "Lần viếng thăm",
            color: "#ff7f00",
          },
          {
            total: _.get(res, "summaryReport.totalOrder"),
            name: "Đơn hàng",
            color: "#7f3f00",
          },
          {
            total: _.get(res, "summaryReport.totalSKU"),
            name: "Tổng số SKU",
            color: "#00bf5f",
          },
        ];
        setData((preState) => {
          let nextState = { ...preState };
          nextState.monthlyDetail = arrMonthlyDetail;
          nextState.specificWeeklySales = arrSpecificWeeklySales;
          nextState.weeklySaleOrder = arrWeeklySaleOrder;
          nextState.weeklySaleRevenue = arrWeeklySaleRevenue;
          nextState.summary = arrSummary;
          return nextState;
        });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(getProducts, 800);
  }, [getProducts]);

  return (
    <Spin spinning={isLoading}>
      <Row className={className} gutter={16}>
        <Col xs={24}>
          <TotalSales data={data.summary} />
        </Col>
        <Col xs={24}>
          <ChartDay data={data.specificWeeklySales} />
        </Col>
        <Col xs={24}>
          <ChartWeek dataOrder={data.weeklySaleOrder} dataRevenue={data.weeklySaleRevenue}/>
        </Col>
        <Col xs={24}>
          <ChartTotalSales data={data.monthlyDetail} />
        </Col>
      </Row>
    </Spin>
  );
};

export default styled(Dashboard)``;
