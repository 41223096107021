import React from "react";
import styled from "styled-components";
import Chart from "react-google-charts";

const Dashboard = ({ className, profile,data }) => {
 
  return (
    <div style={{ background: "white" }}>
      <Chart
        width="100%"
        height={500}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          // title: "Tổng doanh số, tổng doanh thu, tổng khách hàng mới, tổng lần viếng thăm, tổng số đơn hàng, Tổng số SKU",
          seriesType: "bars",
          chartArea: { width: "70%" },
          hAxis: {
            title:
              "Biểu Đồ Chi Tiết Hoạt Động Trong Tháng",
            minValue: 0,
          },
          // vAxis: {
          //   title: 'City',
          // },
          colors: ["#5656ff", "#f70000", "#ffcc00", "#00bf5f", "#bf00bf", "#005fbf"],
        }}
      />
    </div>
  );
};

export default styled(Dashboard)``;
