import { useCallback, useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  Divider,
  Select,
  message
} from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import services from "services";
import GeneralSummary from "components/customerTurnoverByMonth/GeneralSummary";
import _ from "lodash";
import moment from 'moment';
import {saveFileAs} from 'utils/excel';
const { Option } = Select;

const CustomerTurnoverByMonth = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [paging, setPaging] = useState({pageNumber: 1, pageSize: 10});
  const [dataFilter, setDataFilter] = useState({
    arrStaffs: [],
    arrProvinces: [],
  });

  const [selectedRegionId, setSelectedRegionId] = useState(undefined);
  const [selectedProvinceId, setSelectedProvinceId] = useState();
  const [selectedStaffId, setSelectedStaffId] = useState();
  const [filteredStaffs, setFilteredStaffs] = useState([]);
  const [filteredProvinces, setFilteredProvinces] = useState([
    ..._.get(dataFilter, 'arrProvinces'),
  ]);

  const handleSubmit = (values) => {
    setParams(values);
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize });
  };

  useEffect(() => {
    // api nhân viên        
    services.userManagementService.resource_GetAllSalersWithPermission().then((res) => {
      let arrData = _.map(res, (item, index) => {
        item.index = index + 1;
        item.id = _.get(item, "userId");
        item.name = _.get(item, "fullName");
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrStaffs = arrData;
        return nextState;
      });
    });

    // api tỉnh
    services.organization
    .province_GetAllProvincesWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });

      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrProvinces = array;
        return nextState;
      });
    })
    .catch((error) => {});
    
    // api khu vực
    services.organization
    .businessRegion_GetAllRegionsWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.regionName;
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrBusinessRegions = array;
        return nextState;
      });
    })
    .catch((error) => {});

  }, [setDataFilter]);

  useEffect(() => {
    setFilteredProvinces([..._.get(dataFilter, 'arrProvinces')]);
    setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
  }, [dataFilter]);

  const filterProvinces = (value) => {
    if (value) {
      let arrProvinces = _.filter(
        _.get(dataFilter, 'arrProvinces'),
        (item, index) => {
          return item?.businessRegionId === value;
        },
      )
      setSelectedProvinceId(null);
      setFilteredProvinces(arrProvinces);
      
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.regionId === value;
        },
      )
      setSelectedStaffId(null);
      setFilteredStaffs(arrStaffs);
    } else {
      setFilteredProvinces([..._.get(dataFilter, 'arrProvinces')])
      setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')])
    }
    setSelectedRegionId(value);
  }

  const filterStaffs = (value) => {
    if (value) {        
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.provinceId === value;
        },
      )
      setSelectedStaffId(null);
      setFilteredStaffs(arrStaffs);
    } else {
      if (selectedRegionId) {
        let arrStaffs = _.filter(
          _.get(dataFilter, 'arrStaffs'),
          (item, index) => {
            return item?.regionId === selectedRegionId;
          },
        )
        setFilteredStaffs(arrStaffs);
      } else {
        setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
      }
    }
    setSelectedProvinceId(value);
  }

  const exportExcel = () => {
    setLoading(true);
    services.generalService
      .customerInformation_ExportReportCustomerTurnoverByMonth({
        customerName: params.customerName,
        staffId: params.staffId,
        provinceId: params.provinceId,
        regionId: params.regionId,
        pageNumber: paging.pageNumber,
        pageSize: paging.pageSize,
        month: params.month && moment(params.month).format('MM'),
        year: params.month && moment(params.month).format('YYYY'),
      })
      .then((response) => {
        saveFileAs(response.data, 'Sản lượng của KH theo tháng '
             + (params.month && moment(params.month).format('MM')) + '/'
             + (params.month && moment(params.month).format('YYYY')) + '.xlsx');
      })
      .catch((err) => {
        message.error('Có lỗi khi xuất excel');
      })
      .finally(() => {
        setLoading(false);
      });
  }; 
  
  const getQuery = useCallback(
    (value, name) => {
      setParams((preState) => {
        let nextState = { ...preState }
        nextState[name] = value
        return nextState
      })
    },
    [setParams],
  )

  return (
    <Card className="orderManagementPage">
      <Divider style={{ margin: 10 }} />
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
        <Col lg={4} xl={4}>
          <Form.Item name="regionId" label="Khu vực">
              <Select
                value={selectedRegionId}
                style={{ width: "100%" }}
                placeholder="Khu vực"
                allowClear
                onChange={(e) => {
                  getQuery(e, 'regionId');
                  filterProvinces(e);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {_.map(_.get(dataFilter, "arrBusinessRegions"), (item, index) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="provinceId" label="Tỉnh">
              <Select
                style={{ width: "100%" }}
                placeholder="Tỉnh"
                allowClear
                value={selectedProvinceId}
                onChange={(value) => {
                  getQuery(value, "provinceId");
                  filterStaffs(value);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {_.map(filteredProvinces, (item, index) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="staffId" label="Nhân viên">
              <Select
                style={{ width: "100%" }}
                placeholder="Nhân viên"
                allowClear
                value={selectedStaffId}
                onChange={(value) => { 
                  getQuery(value, "staffId");
                  setSelectedStaffId(value);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {_.map(filteredStaffs, (item, index) => {
                  return (
                    // <Option value={item.id} key={item.id}>
                    //   {item.name}
                    // </Option>
                    <Option value={item.userId} key={item.userId}>
                      {item.fullName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="customerName" label="Khách hàng">
              <Input placeholder="Tên khách hàng"/>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="month" label="Tháng xem">
              <DatePicker
                placeholder="Tháng xem"
                style={{ width: '100%'}}
                value={params.month}
                format={'MM-YYYY'}
                onChange={(e) => {
                  getQuery(e, 'month')
                }}
                picker="month"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 5,marginBottom:10 }}>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
              >
                <span className="d-flex align-items-center">
                  <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col className='ml-2'>
            <Button
              type='dashed'
              disabled={loading}
              onClick={exportExcel}
            >
              <span className='d-flex align-items-center'>
                <DownloadOutlined /> <span className='ml-1'>Xuất file excel</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <GeneralSummary
        params={params}
        paging={paging}
        setPaging={setPaging}
        loading={loading}
        setLoading={setLoading}
      />
    </Card>
  );
};

export default CustomerTurnoverByMonth;
