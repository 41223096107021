/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 04/06/2021.
 * @description Modal Thêm/Sửa tỉnh/xã/huyện/thôn
 * */

import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import PropTypes from "prop-types";
import { map,get } from "lodash";
import utils from "utils";
import services from "services";
import BaseSelect from "components/selects/baseSelect";

const BusinessDistrictModal = function ({ config, onCancel,dataHuman ,setLoading,isLoading}) {
  const { visible, mode, dataSource } = config;
  const [form] = Form.useForm();
  const [isContinue, setIsContinue] = useState(false);
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const disabled = mode === utils.$const.COMMON.MODAL_MODE.EDIT

  const handleConfirm = useCallback(() => {
    setIsContinue(false);
    setTimeout(() => form.submit(), 400);
  },[form]);

  const handleConfirmAndContinue = useCallback(() => {
    setIsContinue(true);
    setTimeout(() => form.submit(), 400);
  }, [form]);
  const handleBrowseArea = useCallback((search) => {
    return services.organization.businessDistrict_GetPaging();
  }, []);
  const handleSubmit = useCallback(
    (values) => {
      if (mode === utils.$const.COMMON.MODAL_MODE.ADD) {
        services.organization
          .businessDistrict_Create({
            businessProvinceId : values.provinceObj?.key,
            districtId : values.districtObj?.key,
            districtCode : values.districtObj?.label,
            managerUserId : values.managerUserIdObj,
            accountingUserId : values.accountingUserIdObj,
            description: values.description,
            status: 1,
          })
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            if(isContinue){
              form.resetFields();
            }else{
              onCancel();
              form.resetFields();
            }
          })
          .catch((error) =>
            message.error("Tạo mới địa điểm không thành công.")
          );
      } else {
        services.organization
          .businessDistrict_Update({
            // ...values,
            businessProvinceId : values.provinceObj?.key,
            districtId : values.districtObj?.key,
            id : values.id,
            districtCode : values.districtObj?.label,
            managerUserId : values.managerUserIdObj,
            accountingUserId : values.accountingUserIdObj,
            currentState : values.currentState,
            status : 1,
          })
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onCancel();
            form.resetFields();
          })
          .catch((error) =>
            message.error("Chỉnh sửa địa điểm không thành công.")
          );
      }
    },
    [mode, isContinue, form, onCancel]
  );
  const handleCancelModal = () => {
    form.resetFields();
    onCancel();
  }
  useEffect(async() => {
    if (mode === utils.$const.COMMON.MODAL_MODE.EDIT) {
    await  form.setFieldsValue({
        id: dataSource.id,
        currentState: dataSource.currentState,
        locationCode: dataSource.locationCode,
        provinceObj: {
          key: dataSource.districtRegion?.id,
          label: dataSource.districtRegion?.provinceName,
        },
        districtObj: {
          key: dataSource?.id,
          label: dataSource?.districtName,
        },
        // managerUserIdObj: get(dataSource,'managerUserInformation.fullName') ?{
        //   key: '6f39cf98-9cee-4eb9-0b5f-08d9818c4b7c',
        //   label: 'Nghĩa Huỳnh',
        // } : undefined,
        
        accountingUserIdObj:dataSource?.accountingUserInformation ? {
          key: dataSource?.accountingUserId,
          label: dataSource?.accountingUserInformation,
        } : undefined,
        description: dataSource.description,
        status: 1,
      });
    }
  }, [dataSource, visible, mode, form]);
  const renderTitle = () =>
    mode === utils.$const.COMMON.MODAL_MODE.ADD
      // ? `Thêm mới địa điểm`
      // : "Chỉnh sửa địa điểm";
      ? `THÔNG TIN QUẬN/ HUYỆN`
      : "THÔNG TIN QUẬN/ HUYỆN";

  const handleChangeBusinessProvince = useCallback((obj)=> {
    services.organization.district_GetDistrictByBusinessProvince(obj.key)
    .then((res)=>{
      setDistrict(res);
    })
    form.getFieldValue().districtObj = "";
  }, [form]);

  useEffect(()=>{
    services.organization.businessProvince_GetListBusinessProvince()
    .then((res)=>{
      setProvince(res)
    })
  },[setProvince])
  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      centered
      onCancel={handleCancelModal}
      width="45%"
      footer={[
          <Form.Item>
            <Button onClick={handleConfirm} type="primary">
              {dataSource?.id ? "Sửa" : "Thêm"}
            </Button>
            {mode === utils.$const.COMMON.MODAL_MODE.ADD ?
              <Button
                title='Thêm và tiếp tục'
                key='submitAndContinute'
                type="primary"
                onClick={handleConfirmAndContinue}
              >
                Thêm và tiếp tục
              </Button> : ''
            }
            <Button type='default' onClick={handleCancelModal}>
              Quay lại
            </Button>
          </Form.Item>
      ]}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        labelAlign="left"
        initialValues={{
          status: 1,
        }}
      >
        <Form.Item
          label="Thuộc tỉnh"
          name="provinceObj"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Select placeholder='Chọn tỉnh' 
            showSearch
            disabled={disabled}
            showArrow={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            labelInValue
            allowClear
            onChange={handleChangeBusinessProvince}
            >
            {province.map((pv, id) => (
              <Select.Option key={id} value={pv?.id}>
                {pv?.provinceName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Mã Quận/ Huyện"
          name="districtObj"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Select placeholder='Chọn quận/ Huyện' 
            showSearch
            disabled={disabled}
            showArrow={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            labelInValue
            allowClear
            >
            {district.map((dt, id) => (
              <Select.Option key={id} value={dt?.id}>
                {dt?.districtName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

            <Form.Item
              name="managerUserIdObj"
              label="Người quản lý"
              // rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <Select placeholder="Chọn người quản lý">
                {map(dataHuman?.arrManager, (item) => (
                  <Select.Option key={item.userId} value={item.userId}>
                    {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="accountingUserIdObj"
              label="Kế toán"
              // rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <Select placeholder="Chọn Kế toán" disabled={disabled}>
                {map(dataHuman?.arrAccounting, (item) => (
                  <Select.Option key={item.userId} value={item.userId}>
                    {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
        {/* <Form.Item
          name="statusObj"
          label="Trạng thái"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
        >
          <Select placeholder="Chọn trạng thái">
            {map(utils.$const.ORGANIZATION.STATUS, (item) => (
              <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item
          label="Mô tả"
          name="description"
          disabled={disabled}
        >
          <Input.TextArea rows={2} placeholder="Nhập mô tả khu vực" disabled={disabled}/>
        </Form.Item>
        <Form.Item hidden name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};
BusinessDistrictModal.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
};
export default BusinessDistrictModal;
