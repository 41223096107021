/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 31/05/2021.
 * @description Màn hình `Thông tin nhà cung cấp`. Route `/generalManagement/providerInformation`
 * */

import { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  message,
  Form,
  Row,
  Col,
  Input,
  Divider
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import ProviderModal from "components/modals/ProviderModal";
import services from "services";
import APP_CONST from "utils/constants";
import ConfirmModal from "components/modals/confirm";

const ProviderInformationPage = function () {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [filterCriteria, setFilterCriteria] = useState({providerCode: null, providerName: null});
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const loadProviders = useCallback(() => {
    setIsLoading(true);
    let filter = {
      providerCode: filterCriteria?.providerCode,
      providerName: filterCriteria?.providerName,
      pageNumber: paging?.pageNumber ?? 1,
      pageSize: paging?.pageSize ?? 10,
    };
    services.generalService
      .providerInformation_FilterProviderInformation(filter)
      .then((res) => {
        setIsLoading(false);
        setData(res.items);
        setTotal(res?.totalCount ?? 0);
      })
      .catch((err) => {
        setTotal(0);
        setIsLoading(false);
      });
    },
    [paging]
  );

  useEffect(() => {
    loadProviders();
  }, [loadProviders]);

  const columns = [
    {
      title: "Mã nhà cung cấp",
      dataIndex: "providerCode",
      key: "providerCode",
      width: 200
    },
    {
      title: "Tên nhà cung cấp",
      dataIndex: "providerName",
      key: "providerName",
      width: 400
    },
    {
      title: "Người đại diện",
      dataIndex: "representative",
      key: "representative",
      width: 150
    },
    {
      title: "Mã số thuế",
      dataIndex: "taxCode",
      key: "taxCode",
      width: 150,
      align: "right",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phonenumber",
      key: "phonenumber",
      width: 150,
      align: "right",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      width: 400
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
      width: 250
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
      width: 500
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      render: (value) => (
        <div className={`status--${value ? "active" : "inactive"}`}>
          {
            APP_CONST?.PROVIDER_INFO?.STATUS?.find(
              (status) => status?.id === value
            )?.name
          }
        </div>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Button type="primary" size="small" onClick={handleEditProvider(row)}>
            <span className="d-flex align-items-center">
              <EditOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            className="warehouseInfo-card__button--right"
            type="danger"
            size="small"
            onClick={handleDeleteProvider(row)}
          >
            <span className="d-flex align-items-center">
              <DeleteFilled /> <span className="ml-1"></span>
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const [selectedProvider, setSelectedProvider] = useState({});
  const [isOpenProviderModal, setIsOpenProviderModal] = useState(false);
  const [providerModalMode, setProviderModalMode] = useState("add");
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const handleCreateProviderModal = useCallback(() => {
    setProviderModalMode("add");
    setIsOpenProviderModal(true);
    setSelectedProvider([]);
  }, []);

  const handleEditProvider = useCallback(
    (provider) => (e) => {
      setProviderModalMode("edit");
      setIsOpenProviderModal(true);
      setSelectedProvider(provider);
    },
    [setSelectedProvider, setProviderModalMode, setIsOpenProviderModal]
  );

  const handleCancelProviderModal = useCallback(() => {
    setIsOpenProviderModal(false);
    setSelectedProvider({});
  }, [setIsOpenProviderModal, setSelectedProvider]);

  const handleReloadPage = useCallback(() => {
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
    setSelectedProvider({});
  }, [setSelectedProvider]);

  const handleChangePaging = (page, pageSize) => {
    const currentPage = pageSize !== paging?.pageSize ? 1 : page;
    setPaging({ page: currentPage, pageSize });
  }

  const handleDeleteProvider = useCallback(
    (provider) => () => {
      setSelectedProvider(provider);
      setIsShowConfirmModal(true);
    },
    [setSelectedProvider, setIsShowConfirmModal]
  );

  const handleApproveToDeleteProvider = useCallback(() => {
    services.generalService
      .providerInformation_Delete([
        {
          id: selectedProvider?.id,
          currentState: selectedProvider?.currentState,
        },
      ])
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedProvider({});
        handleReloadPage();
      })
      .catch(() => {
        message.error("Xóa nhà cung cấp thất bại");
      });
  }, [handleReloadPage, selectedProvider]);

  const handleRejectToDeleteProvider = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedProvider({});
  }, []);

  const handleSubmit = (values) => {
    setFilterCriteria({ ...values });
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
  };

  return (
    <>
      
      <Card className="ProviderInformationPage">
        <Divider style={{margin:10}}/>
        <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
          <Row gutter={15}>
            <Col lg={24} xl={6}>
              <Form.Item name="providerCode" label="Mã nhà cung cấp">
                <Input placeholder="Nhập mã cung cấp" />
              </Form.Item>
            </Col>
            <Col lg={24} xl={6}>
              <Form.Item name="providerName" label="Tên nhà cung cấp">
                <Input placeholder="Nhập tên nhà cung cấp" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={15} justify="end">
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading}
              >
                <span className="d-flex align-items-center">
                  <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                </span>
              </Button>
            </Col>
            <Col>
              <Button onClick={handleCreateProviderModal}>
                <span className="d-flex align-items-center">
                  <PlusOutlined />
                  <span className="ml-1">Thêm mới</span>
                </span>
              </Button>
            </Col>
          </Row>{" "}
        </Form>
        <Divider style={{margin:10}}/>

        {/* <Divider/> */}
        <Table
          bordered
          columns={columns}
          dataSource={data}
          scroll={{ x: 1200 }}
          size="small"
          pagination={{
            total,
            pageSizeOptions: [10, 20, 50],
            current: paging?.pageNumber ?? 1,
            pageSize: paging?.pageSize ?? 10,
            onChange: handleChangePaging,
            showSizeChanger: true,
          }}
          loading={isLoading}
          locale={{ emptyText: "Không có nhà cung cấp" }}
        />

        <ProviderModal
          visible={isOpenProviderModal}
          mode={providerModalMode}
          onCancel={handleCancelProviderModal}
          dataItem={selectedProvider}
          onReloadPage={handleReloadPage}
        />
        <ConfirmModal
          title="Xóa nhà cung cấp"
          visible={isShowConfirmModal}
          onCancel={handleRejectToDeleteProvider}
        >
          <div className="confirm-modal__content">
            Bạn có chắc muốn xóa{" "}
            <strong>{selectedProvider?.providerName}</strong> không?
          </div>
          <div className="confirm-modal__footer">
            <Button type="danger" onClick={handleRejectToDeleteProvider}>
              Không
            </Button>
            <Button type="primary" onClick={handleApproveToDeleteProvider}>
              Xác nhận
            </Button>
          </div>
        </ConfirmModal>
      </Card>
    </>
  );
};
export default ProviderInformationPage;
