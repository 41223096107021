/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Modal Thêm/Sửa nhân viên
 * */

import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Divider,
  Drawer,
  Typography,
  message,
} from "antd";
import { MailOutlined, ProfileOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import services from "services";
import TempUserImgage from "images/anh1.jpeg";
import classNames from "classnames";
import AuthSlice from "redux/auth/authSlice";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const { Text, Link, Title } = Typography;

const QuickUser = function ({ visible, onCancel }) {
  const user = useSelector(AuthSlice.seletors.makeSelectUser);
  const [changePasswordForm] = Form.useForm();
  const [profileForm] = Form.useForm();
  const [profile, setProfile] = useState({
    email: "",
    lockoutEnabled: false,
    personalInformation: null,
    roleName: "",
    userName: "",
  });
  const [config, setConfig] = useState({
    width: 375,
    action: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    services.userManagementService
      .user_Logout()
      .then((res) => {
        dispatch(
          AuthSlice.actions.setAuthenticate({
            isAuthenticated: false,
          })
        );
        history.push("/auth/login");
      })
      .catch((err) => {
        message.error("Có lỗi khi đăng xuất.Vui lòng thử lại.");
      });
  };
  const handleSubmitChangePassword = (values) => {
    services.userManagementService
      .profile_ChangePassword(values)
      .then((res) => {
        message.success(
          "Thay đổi mật khẩu thành công. Hệ thống sẽ đăng xuất, vui lòng đăng nhập lại."
        );
        changePasswordForm.resetFields();
        setTimeout(() => {
          handleLogout();
        }, 3000);
      })
      .catch((err) => {
        message.error("Thay đổi mật khẩu không thành công");
      });
  };
  const handleShowExtraForm = (action) => {
    if (action === "changePassword") {
      changePasswordForm.resetFields();
    }
    setConfig((prev) => ({
      ...prev,
      action: prev.width === 375 ? action : "",
    }));
  };

  const handleGetUserProfile = useCallback(() => {
    services.userManagementService
      .profile_UserInfo(user.userName)
      .then((res) => {
        let profile = {
          userName: res.userName,
          fullName: res.personalInformation?.fullName,
          address: res.personalInformation.address,
          email: res.email,
          status: res.personalInformation?.status,
          genderType: res.personalInformation?.genderType,
        };
        changePasswordForm.setFieldsValue({
          userName: profile.userName,
        });
        profileForm.setFieldsValue(profile);
        setProfile(profile);
      })
      .catch((err) => {});
  }, [user, profileForm, changePasswordForm]);

  useEffect(() => {
    if (visible) {
      handleGetUserProfile();
    } else if (config.action === 'changePassword') {
      setConfig((prev) => ({ ...prev, action: "" }));
      changePasswordForm.resetFields();
    }
  }, [visible, handleGetUserProfile, changePasswordForm]);
  
  return (
    <Drawer
      visible={visible}
      title="Thông tin nhân viên"
      width={config.width}
      onClose={onCancel}
    >
      <div id="quick-user" className="mt-2">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-125 mr-4">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${TempUserImgage})`,
              }}
            ></div>
          </div>
          <div className="d-flex flex-column">
            <Text level={5} type="success" strong>
              {profile.fullName || "Chưa có"}
            </Text>
            <div className="text-muted mt-1">{user.roles}</div>
            <div className="mt-2">
              <Link href="mailto://jm@softplus.com">
                <span className="d-flex align-items-center">
                  <span className="mr-1 d-flex align-items-center">
                    <MailOutlined />
                  </span>
                  <Text className="text-muted d-flex align-items-center">
                    {profile.email || "Chưa có"}
                  </Text>
                </span>
              </Link>
              <Button type="primary" className="mt-2" onClick={handleLogout}>
                Đăng xuất
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="navi">
            <Button
              className="w-100 d-flex h-auto pl-0 border-0"
              onClick={() => handleShowExtraForm("userInfo")}
            >
              <div className="navi-link">
                <div className="symbol symbol-40 mr-2">
                  <div className="symbol-label">
                    <ProfileOutlined />
                  </div>
                </div>
                <div className="navi-text">
                  <Text
                    className={classNames("text-title", {
                      "text-title--active": config.action === "userInfo",
                    })}
                    strong
                  >
                    Thông tin nhân viên
                  </Text>
                  <div className="text-muted">Xem thông tin tài khoản...</div>
                </div>
              </div>
            </Button>
          </div>
          <div className="navi">
            <Button
              className="w-100 d-flex h-auto pl-0 border-0"
              onClick={() => handleShowExtraForm("changePassword")}
            >
              <div className="navi-link">
                <div className="symbol symbol-40 mr-2">
                  <div className="symbol-label">
                    <ProfileOutlined />
                  </div>
                </div>
                <div className="navi-text">
                  <Text
                    className={classNames("text-title", {
                      "text-title--active": config.action === "changePassword",
                    })}
                    strong
                  >
                    Đổi mật khẩu
                  </Text>
                  <div className="text-muted">Thay đổi mật khẩu</div>
                </div>
              </div>
            </Button>
          </div>
        </div>
        {config.action === "changePassword" && (
          <>
            <Divider />
            <div className="changePassword__wrapper mt-4">
              <Form
                initialValues={{ userName: user.userName }}
                form={changePasswordForm}
                onFinish={handleSubmitChangePassword}
              >
                <Title level={5}>Thay đổi mật khẩu</Title>
                <Form.Item name="oldPassword">
                  <Input.Password
                    placeholder="Mật khẩu hiện tại"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="newPassword">
                  <Input.Password
                    placeholder="Mật khẩu mới"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item name="confirmPassword">
                  <Input.Password
                    placeholder="Xác nhận mật khẩu mới"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Mật khẩu mới chưa trùng khớp")
                          );
                        },
                      }),
                    ]}
                  />
                </Form.Item>
                <Form.Item name="userName" hidden noStyle>
                  <Input disabled />
                </Form.Item>
                <Button htmlType="submit" type="primary" className="w-100">
                  Đổi mật khẩu
                </Button>
              </Form>
            </div>
          </>
        )}
        {config.action === "userInfo" && (
          <>
            <Divider />
            <div className="userInfo__wrapper mt-4">
              <Form form={profileForm} labelCol={{ span: 6 }} labelAlign="left">
                <Title level={5}>Chi tiết tài khoản</Title>
                <Form.Item name="userName" label="Tài khoản">
                  <Input type="text" readOnly />
                </Form.Item>
                <Form.Item name="fullName" label="Họ tên">
                  <Input type="text" readOnly />
                </Form.Item>
                <Form.Item name="address" label="Địa chỉ">
                  <Input type="text" readOnly />
                </Form.Item>
              </Form>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};
QuickUser.propTypes = {
  config: PropTypes.any,
};
export default QuickUser;
