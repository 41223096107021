/**
 * Copyright 2021-present, TRUNGHIEU.
 * All rights reserved.
 * @author
 * @description Màn hình Nhóm thương hiệu. Route `/customer-management/business-type`
 **/
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Space,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import BrandGroupsModal from "components/modals/BrandGroupsModal";
import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import services from "services";
import _ from "lodash";
import utils from "utils";
export default function BrandGroups() {
  // =================================================================
  // STATE     STATE     STATE     STATE     STATE     STATE     STATE
  // =================================================================
  const [itemsGR, setitemsGR] = useState([]);
  const [showBrandGroupsModal, setShowBrandGroupsModal] = useState(false);
  const [selectedBrandGroups, setSelectedBrandGroups] = useState({});
  const [listBrandGroups, setListBrandGroups] = useState([]);
  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  const [isReloadPage, setIsReloadPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  // const [listBrandGroupsPagination, setlistBrandGroupsPagination] =
  //   useState({
  //     size: "small",
  //     total: 0,
  //     pageSize: 20,
  //     showTotal: (total, range) =>
  //       `${range[0]}-${range[1]} trong ${total} dòng`,
  //   });
  const columns = [
    {
      dataIndex: "customerGroupCode",
      key: "customerGroupCode",
      title: "Mã nhóm",
      // align: "center",
    },
    {
      dataIndex: "customerGroupName",
      key: "customerGroupName",
      title: "Tên nhóm",
      // align: "center",
    },
    {
      dataIndex: "notes",
      key: "notes",
      title: "Ghi chú",
      // align: "left",
    },
    {
      dataIndex: "status",
      key: "status",
      title: "Trạng thái",
      align: "center",
      width: 150,
      render: (value) => {
        var findedStatus = utils.$const.CUSTOMER_STATUS_INFO.STATUS.find(
          (x) => x.id === value
        );
        return (
          <span style={{ color: findedStatus?.color }}>
            {findedStatus?.name}
          </span>
        );
      },
      filters: [
        {
          text: "Hoạt động",
          value: 1,
        },
        {
          text: "Không hoạt động",
          value: 0,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },

    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      fixed: "right",
      width: 50,
      render: (_, row) => (
        <div className="d-flex align-items-center justify-content-center">
          <Space>
            <EditOutlined
              className="text-primary"
              onClick={handleEditBrandGroups(row)}
            />

            <DeleteOutlined
              className="text-danger"
              onClick={() => handleShowConfirmDelete(row)}
            />
          </Space>
        </div>
      ),
    },
  ];
  // =================================================================
  // efect    efect    efect    efect    efect    efect    efect
  // =================================================================
  const handleGetListBrandGroups = useCallback(async () => {
    await setLoading(true);
    try {
      services.generalService
        .customerGroup_GetBsWithPagination(paging?.page, paging?.pageSize)
        .then((res) => {
          setTotal(res?.totalCount ?? 0);
          setListBrandGroups(res?.items ?? []);
          setitemsGR(res?.items ?? []);
          setLoading(false);
        })
        .catch(() => {
          setTotal(0);
          setListBrandGroups([]);
          setitemsGR([]);
          setLoading(false);
        });
    } catch (error) {
      setTotal(0);
      setListBrandGroups([]);
      setitemsGR([]);
      setLoading(false);
    }
  }, [paging, isReloadPage]);

  useEffect(() => {
    handleGetListBrandGroups();
  }, [handleGetListBrandGroups]);
  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedBrandGroups({});
  }, [setSelectedBrandGroups, setIsReloadPage, isReloadPage]);

  const handleShowConfirmDelete = useCallback(
    (row) => {
      Modal.confirm({
        title: (
          <span>
            Xác nhận xóa <b className="text-danger">{row?.customerGroupName}</b>{" "}
          </span>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: "Xác nhận",
        okType: "danger",
        cancelText: "Hủy",
        content: "",
        onOk() {
          services.generalService
            .customerGroup_Delete([
              {
                id: row.id,
                currentState: row.currentState,
              },
            ])
            .then(() => {
              handleReloadPage();
              message.success("Xóa thành công.");
              handleGetListBrandGroups();
            })
            .catch(() =>
              message.error("Xóa không thành công.Vui lòng thử lại")
            );
        },
        onCancel() {},
      });
    },
    [handleGetListBrandGroups, handleReloadPage]
  );

  // =================================================================
  // handle    handle     handle     handle     handle     handle
  // =================================================================

  const handleCreateBusinessType = useCallback(() => {
    setShowBrandGroupsModal(true);
  }, []);

  const handleEditBrandGroups = useCallback(
    (BrandGroups) => (e) => {
      setShowBrandGroupsModal(true);
      setSelectedBrandGroups(BrandGroups);
    },
    [setShowBrandGroupsModal, setSelectedBrandGroups]
  );

  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ page: currentPage, pageSize });
    },
    [setPaging, paging?.pageSize]
  );
  const handleCloseModal = useCallback(() => {
    setShowBrandGroupsModal(false);
    setSelectedBrandGroups({});
  }, []);
  const handleSearch = (values) => {
    let param = {
      pageNumber: 1,
      pageSize: 20,
      CustomerGroupCode: values.customerGroupCode,
      CustomerGroupName: values.customerGroupName,
      Status: values.status,
    };
    funcGetallCustomerTypefilter(param);
  };

  const funcGetallCustomerTypefilter = useCallback(
    (param) => {
      try {
        services.generalService
          .customerGroup_FilterCustomerGroup({
            pageNumber: paging.page,
            pageSize: paging.pageSize,
            customerGroupCode: param.CustomerGroupCode,
            customerGroupName: param.CustomerGroupName,
            status: param.Status,
          })
          .then((res) => {
            setTotal(res?.totalCount ?? 0);
            setitemsGR(res?.items ?? []);
            setLoading(false);
          })
          .catch(() => {
            setTotal(0);
            setitemsGR([]);
            setLoading(false);
          });
      } catch (error) {
        setTotal(0);
        setitemsGR([]);
        setLoading(false);
      }
    },
    [itemsGR]
  );
  return (
    <Card className="CustomerInfor-card" size="small">
      <Form labelCol={{ span: 24 }} onFinish={handleSearch}>
        <Row gutter={[8]} align="bottom">
          <Col lg={24} xl={16} xxl={8}>
            <Form.Item
              name="customerGroupCode"
              label="Mã nhóm"
              className="mb-0"
            >
              <Input placeholder="Nhập mã nhóm KH" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={16} xxl={8}>
            <Form.Item
              name="customerGroupName"
              label="Tên nhóm"
              className="mb-0"
            >
              <Input placeholder="Tên nhóm" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={16} xxl={8}>
            <Form.Item name="status" label="Trạng thái" className="mb-0">
              <Select placeholder="Chọn trạng thái" allowClear>
                <Select.Option value={1}>Hoạt động </Select.Option>
                <Select.Option value={0}>Không hoạt động</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8]} className="my-3" justify="end">
          <Col>
            <Button type="primary" htmlType="submit">
              <span className="d-flex align-items-center">
                <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button onClick={handleCreateBusinessType}>
              <span className="d-flex align-items-center">
                <PlusOutlined /> <span className="ml-1">Tạo mới</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        size="small"
        className="CustomerInfor-card__table"
        columns={columns}
        // dataSource={itemsGR.length > 0 ? itemsGR : listBrandGroups}
        dataSource={itemsGR}
        total={listBrandGroups?.length ?? 0}
        bordered
        loading={loading}
        locale={{ emptyText: "Không có bản ghi nào" }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.page ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
      />

      <BrandGroupsModal
        visible={showBrandGroupsModal}
        selectedBrandGroups={selectedBrandGroups}
        listBrandGroups={listBrandGroups}
        onCancel={handleCloseModal}
        onReloadPage={handleReloadPage}
      />
    </Card>
  );
}
