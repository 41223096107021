import React, { memo } from "react";

import _ from "lodash";
import { Table } from "antd";
import Pagination from "components/Paginate/index";

const List = memo(({ data, total, setPaging, paging, columns }) => {
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        size="small"
        scroll={{ x: "calc(700px + 50%)", y: 400 }}
        pagination={false}
      />
      <Pagination total={total} setPaging={setPaging} paging={paging} />
    </div>
  );
});

export default List;
