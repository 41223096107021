import React, { useCallback, useState, useEffect } from 'react';
import {
  Card,
  Form,
  Table,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  message,
  Modal,
} from 'antd';
import {
  DeleteFilled,
  SearchOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { get } from 'lodash';
import utils from 'utils';
import services from 'services';
import BaseSelect from 'components/selects/baseSelect';

const PurchaseOrderManagementPage = () => {
  const [form] = Form.useForm();
  const [fetchingListShippingOrder, setFetchingListShippingOrder] =
    useState(false);

  const [isReloadPage, setIsReloadPage] = useState(false);
  const [params, setParams] = useState({});

  const [listShippingOrder, setListShippingOrder] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({
    pageSize: 10,
    page: 1,
  });

  const handleSubmit = (values) => {
    setParams(values);
  };
  // Modal Function
  const handleShowConfirmCancel = useCallback(
    (row) => {
      Modal.confirm({
        title: (
          <span>
            Xác nhận hủy đơn hàng{' '}
            <b className='text-danger'>{row.purchaseId}</b>{' '}
          </span>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: 'Xác nhận',
        okType: 'danger',
        cancelText: 'Hủy',
        content: '',
        onOk() {
          services.shippingOrderService
            .purchaseOrders_ChangePurchaseOrderStatus({
              id: row.id,
              status: 4,
            })
            .then(() => {
              setIsReloadPage(!isReloadPage);
              message.success('Hủy đơn hàng thành công.');
            })
            .catch(() => {
              setIsReloadPage(!isReloadPage);
              message.error('Hủy đơn hàng không thành công.Vui lòng thử lại');
            });
        },
        onCancel() {},
      });
    },
    [isReloadPage]
  );

  const handleShowConfirmApprove = useCallback(
    (row) => {
      Modal.confirm({
        title: (
          <span>
            Xác nhận duyệt đơn hàng{' '}
            <b className='text-danger'>{row.purchaseId}</b>{' '}
          </span>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: 'Xác nhận',
        okType: 'primary',
        cancelText: 'Hủy',
        content: '',
        onOk() {
          services.shippingOrderService
            .purchaseOrders_ChangePurchaseOrderStatus({
              id: row.id,
              status: 2,
            })
            .then(() => {
              setIsReloadPage(!isReloadPage);
              message.success('Duyệt đơn hàng thành công.');
            })
            .catch(() => {
              setIsReloadPage(!isReloadPage);
              message.error('Duyệt đơn hàng không thành công.Vui lòng thử lại');
            });
        },
        onCancel() {},
      });
    },
    [isReloadPage]
  );

  const columns = [
    {
      title: 'Mã ĐH',
      dataIndex: 'purchaseId',
      key: 'purchaseId',
      width: '150px',
      align: 'center',
    },
    {
      title: 'Tên KH',
      dataIndex: 'customer',
      key: 'customer',
      render: (value) => value.customerName,
      width: '200px',
    },
    {
      title: 'Ngày đặt hàng',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (value) => utils.$datetime.formatDatetime(value),
      width: '150px',
      align: 'center',
    },
    {
      title: 'Ngày giao dự kiến',
      dataIndex: 'shippingEstDate',
      key: 'shippingEstDate',
      render: (value) => utils.$datetime.formatDatetime(value),
      width: '150px',
      align: 'center',
    },
    {
      title: 'Người bán',
      dataIndex: 'saler',
      key: 'saler',
      width: '150px',
      render: (value, row) => row?.saler?.fullName,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'notes',
      key: 'notes',
      width: '200px',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '200px',
      align: 'center',
      render: (value) => {
        return (
          <div
            className={`text-center status--${
              utils.$const.SHIPPING_ORDER.STATUS.find((x) => x.id === value)
                ?.color
            }`}
          >
            {
              utils.$const.SHIPPING_ORDER.STATUS.find((x) => x.id === value)
                ?.name
            }
          </div>
        );
      },
    },
    {
      title: 'Hành động',
      width: 150,
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => (
        <div className='warehouseInfo-card__button'>
          {row?.status !== 2 && (
            <Button
              icon={<EditOutlined className='warehouseInfo-card__icon' />}
              type='primary'
              onClick={() => handleShowConfirmApprove(row)}
            >
              Duyệt
            </Button>
          )}
          {row?.status !== 4 && (
            <Button
              className='warehouseInfo-card__button--right'
              icon={<DeleteFilled className='warehouseInfo-card__icon' />}
              type='danger'
              onClick={() => handleShowConfirmCancel(row)}
            >
              Hủy
            </Button>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (Object.keys(params)?.length > 0) {
      setFetchingListShippingOrder(true);
      const filterParams = {
        purcharseId: params?.purcharseId,
        customerId: params?.customerId?.value,
        saler: params?.saler,
        orderDate: params?.orderDate,
        shippingEstDate: params?.shippingEstDate,
        pageNumber: paging?.page || 1,
        pageSize: paging?.pageSize || 10,
      };
      services.shippingOrderService
        .purchaseOrders_PurchaseOrderFilterFullOpt(filterParams)
        .then((res) => {
          const items = get(res, 'items');
          const total = get(res, 'totalCount');
          setTotal(total);
          setListShippingOrder(items);
        })
        .catch((err) => {
          message.error('Có lỗi khi lấy danh sách đơn hàng.');
          setListShippingOrder([]);
        })
        .finally(() => setFetchingListShippingOrder(false));
    }
  }, [params, paging, isReloadPage]);

  const handleBrowseShippingOrder = useCallback((params) => {
    setParams(params);
    setFetchingListShippingOrder(true);
    const pageNumber = get(paging, 'page', 1);
    const pageSize = get(paging, 'pageSize', 20);
    services.shippingOrderService
      .purchaseOrders_PurchaseOrderFilterFullOpt({ pageNumber, pageSize })
      .then((res) => {
        const items = get(res, 'items');
        const total = get(res, 'totalCount');
        setListShippingOrder(items);
        setTotal(total);
      })
      .catch((error) => {
        message.error('Có lỗi khi lấy danh sách đơn hàng.');
        setListShippingOrder([]);
      })
      .finally(() => setFetchingListShippingOrder(false));
  }, []);

  const handleChangeTable = useCallback(
    (page, pageSize) => () => {
      setPaging({
        ...paging,
        page,
        pageSize,
      });
    },
    []
  );

  const handleBrowseCustomerInformation = useCallback((search) => {
    return services.generalService.customerInformation_GetListCustomer();
  }, []);

  useEffect(() => {
    if (Object.keys(params)?.length === 0) {
      handleBrowseShippingOrder({});
    }
  }, [handleBrowseShippingOrder, isReloadPage, paging]);

  return (
    <Card className='orderManagementPage'>
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={8} xl={4}>
            <Form.Item name='purcharseId' label='Mã đơn hàng'>
              <Input placeholder='Nhập mã đơn hàng' />
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='customerId' label='Khách hàng'>
              <BaseSelect
                allowClear
                searchName='CustomerId'
                itemValue='id'
                itemLabel='customerName'
                placeholder='Chọn'
                searchOnMount
                labelInValue
                onSearch={handleBrowseCustomerInformation}
              />
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='saler' label='Người bán'>
              <Input placeholder='Nhập tên người bán' />
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='orderDate' label='Ngày đặt hàng'>
              <DatePicker style={{ width: '100%' }} placeholder='Chọn' />
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='shippingEstDate' label='Ngày giao hàng'>
              <DatePicker style={{ width: '100%' }} placeholder='Chọn' />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' gutter={[8]}>
          <Col>
            <Button
              type='primary'
              htmlType='submit'
              disabled={fetchingListShippingOrder}
            >
              <span className='d-flex align-items-center'>
                <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        rowKey='id'
        className='shippingOrderList'
        columns={columns}
        dataSource={listShippingOrder}
        pagination={{
          current: paging.page,
          pageSize: paging.pageSize,
          total: total,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onChange: handleChangeTable(),
        }}
        loading={fetchingListShippingOrder}
        scroll={{ x: 1200 }}
        bordered
      />
    </Card>
  );
};

export default PurchaseOrderManagementPage;
