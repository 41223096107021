/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 20/09/2021.
 * @description Màn hình `Danh sách phân quyền truy cập màn hình`. Permission `/access-management/authorize-access`
 * */

import { useCallback, useState, useEffect } from "react";
import { Card, Button, Table, message, Checkbox, Row, Col, Form, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import services from "services";
import ConfirmModal from "components/modals/confirm";
// import ScreenPermissionModal from "components/modals/ScreenPermissionModal";

const AuthorizeAccessPage = function (){
    const [form] = Form.useForm();
    //onst [data, setData] = useState([]);

    const [paging, setPaging] = useState({ page: 1, pageSize: 20 });
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [isReloadPage, setIsReloadPage] = useState(false);

    useEffect(() => {
      setLoading(true);
      services.accessManagementService.actionDefinition_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res)=>{
        setLoading(false);
        setTotal(res?.totalCount ?? 0);
        //setData(res.items);
      })
      .catch((error)=>{
        setTotal(0);
        setLoading(false);
      })
    }, [paging,isReloadPage]);

    const columns = [
        {
            title: "Menu",
            dataIndex: "screenName",
            key:"screenName",
            width: 300,
            align: "left",
        },
        {
            title: "Sub menu",
            dataIndex: "screenName",
            key:"screenName",
            width: 300,
            align: "left",
        },
        {
            dataIndex: "actionSearch",
            key: "actionSearch",
            title: "Search",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionSearch")}
                />
            )
        },
        {
            dataIndex: "actionCreate",
            key: "actionCreate",
            title: "Create",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionCreate")}
                />
            )
        },
        {
            dataIndex: "actionEdit",
            key: "actionEdit",
            title: "Edit",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionEdit")}
                />
            )
        },
        {
            dataIndex: "actionDelete",
            key: "actionDelete",
            title: "Delete",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionDelete")}
                />
            )
        },
        {
            dataIndex: "actionApprove",
            key: "actionApprove",
            title: "Approve",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionApprove")}
                />
            )
        },
        {
            dataIndex: "actionReject",
            key: "actionReject",
            title: "Reject",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionReject")}
                />
            )
        },
        {
            dataIndex: "actionDownload",
            key: "actionDownload",
            title: "Download",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionDownload")}
                />
            )
        },
        {
            dataIndex: "actionExport",
            key: "actionExport",
            title: "Export",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionExport")}
                />
            )
        },
        {
            dataIndex: "actionImport",
            key: "actionImport",
            title: "Import",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionImport")}
                />
            )
        },
        {
            dataIndex: "actionFilter",
            key: "actionFilter",
            title: "Filter",
            align: "center",
            width:  100,
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(rowIndex, "actionFilter")}
                />
            )
        },
    ];

    const handleCheckboxChangeFactory = (rowIndex, columnKey) => event => {
        const newState = [...testData];
        newState[rowIndex][columnKey] = event.target.checked;
        setTestData(newState);
    };

  const [testData, setTestData] = useState([
    {
      key: 1,
      screenName: "Màn hình 1",
      notes: "Kho xuất",
      status:"Hoạt động",
      actionSearch: true,
      actionCreate: true,
      actionEdit: true,
      actionDelete: true,
      actionApprove: true,
      actionReject: true,
      actionDownload: true,
      actionImport: true,
      actionExport: true,
      actionFilter: true,

    },
    {
      key: 2,
      screenName: "Màn hình 2",
      notes: "Kho nhập",
      status:"Không hoạt động",
      actionSearch: false,
      actionCreate: false,
      actionEdit: false,
      actionDelete: false,
      actionApprove: false,
      actionReject: false,
      actionDownload: false,
      actionImport: false,
      actionExport: false,
      actionFilter: false,
    }
  ]);
    
    const [selectedScreenPermission, setSelectedScreenPermission] = useState({});
    //const [isOpenScreenPermissionModal, setIsOpenScreenPermissionModal] = useState(false);
    //const [actionControlModalMode, setScreenPermissionModalMode] = useState("add");
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  //   const handleCreateScreenPermissionModal = useCallback(() => {
  //   setScreenPermissionModalMode("add");
  //   setIsOpenScreenPermissionModal(true);
  //   setSelectedScreenPermission([]);
  // }, []);
  
  // const handleEditScreenPermission = useCallback(
  //   (currencyUnit) => (e) => {
  //     setScreenPermissionModalMode("edit")
  //     setIsOpenScreenPermissionModal(true);
  //     setSelectedScreenPermission(currencyUnit);
  //   },
  //   [setSelectedScreenPermission,setScreenPermissionModalMode,setIsOpenScreenPermissionModal]
  // );

  // const handleCancelScreenPermissionModal = useCallback(() => {
  //   setIsOpenScreenPermissionModal(false);
  //   setSelectedScreenPermission({});
  // }, [setIsOpenScreenPermissionModal, setSelectedScreenPermission]);

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedScreenPermission({});
  }, [setSelectedScreenPermission, setIsReloadPage, isReloadPage]);
  
  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ page: currentPage, pageSize });
    },
    [paging]
  );

  // const handleDeleteScreenPermission = useCallback(
  //   (currencyUnit) => () => {
  //     setSelectedScreenPermission(currencyUnit);
  //     setIsShowConfirmModal(true);
  //   },
  //   [setSelectedScreenPermission, setIsShowConfirmModal]
  // );

  const handleApproveToDeleteScreenPermission = useCallback(() => {
    services.accessManagementService
      .actionDefinition_Delete([{
        id: selectedScreenPermission?.id,
        currentState: selectedScreenPermission?.currentState
      }])
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedScreenPermission({});
        handleReloadPage();
      })
      .catch((err) => {
        message.error(err?.message || "Xóa kho thất bại");
      });
  }, [selectedScreenPermission,handleReloadPage]);

  const handleRejectToDeleteScreenPermission = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedScreenPermission({});
  }, [setSelectedScreenPermission]);

  // const renderTitle = () => {
  //   return (
  //     <Typography.Text>PHÂN QUYỀN TRUY CẬP</Typography.Text>
  //   );
  // };
  // const renderActions = () => {
  //   return (
  //     <div className="provider__actions">
  //       <Button onClick={handleCreateScreenPermissionModal} type="primary">
  //         Thêm mới
  //       </Button>
  //     </div>
  //   );
  // };
  const [roleDefinition, setRoleDefinition] =useState([]);
  useEffect(() => {
    services.accessManagementService.userRole_GetBsWithPagination(paging?.page, paging?.pageSize)
    .then((res)=>{
      setRoleDefinition(res.items);
    })
  }, [paging?.page, paging?.pageSize, setRoleDefinition])

  return (
    <Card className="ScreenPermissionPage">
      <Form form={form} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={6}>
            <Form.Item name='role' label='Nhóm tài khoản'>
              <Select placeholder="Nhập nhóm tài khoản" >
                {roleDefinition.map((item) =>(
                  <Select.Option value= {item.id}>
                      {item.roleName}
                  </Select.Option>
                ))}
            </Select>
            </Form.Item>
            
          </Col>
          <Col lg={24} xl={6}>
          </Col>
          <Col lg={24} xl={6}>
          </Col>
          <Col lg={24} xl={2}>
          </Col>
          <Col lg={24} xl={2}>
          </Col>
          <Col flex lg={24} xl={2}>
            <Form.Item className='general-button--height pl-4' label=' '>
              <Button
              //  onClick={handleCreateActionControlModal}
              >
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Cập nhật</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        columns={columns}
      //   dataSource={data}
        dataSource={testData}
        size="small"
        scroll={{ x: 1200 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.page ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        }}
        loading={loading}
        locale={{ emptyText: "Không có nhà cung cấp" }}
      />
      <ConfirmModal
        title="Xóa chức năng, quyền"
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeleteScreenPermission}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa {" "}
          <strong>{selectedScreenPermission?.actionName}</strong> không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleRejectToDeleteScreenPermission}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeleteScreenPermission}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Card>
  );
};
export default AuthorizeAccessPage;