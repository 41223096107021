import { Card, Col, Divider, Row } from "antd";
// import React from "react";
// import Logo from "images/logo-dms.svg";
import "./style.css";
function DetailModal(props) {
  const unknowValue = (
    <>
      <span className="n_a">N/A</span>
    </>
  );
  return (
    <div>
      <Row gutter={[16, 48]}>
        <Col span={6}>
          <div
            style={{
              border: "16px",
              width: "100%",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
              overflow: "hidden",
            }}
          >
            {/* <img src={Logo} alt="Danh Huy Soft" height="64" width="200" /> */}
            {props?.selectedCustomerInfor?.avatar ? (
              <img
                src={props?.selectedCustomerInfor?.avatar}
                alt="Lỗi ảnh đại diện"
                width="100%"
                style={{
                  objeccFit: "cover",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </Col>
        <Col span={18}>
          <Divider orientation="center" plain>
            <div className="nameTitle">Họ và tên:</div>{" "}
            <h2>{props?.selectedCustomerInfor?.customerName}</h2>
          </Divider>
        </Col>
      </Row>
      <div style={{ marginTop: "20px" }}></div>
      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Khách hàng Mới/Cũ :</div>{" "}
          {props?.selectedCustomerInfor?.customerStatus == 1 ? (
            <span style={{ color: "#52C41A" }}>Khách hàng mới</span>
          ) : (
            <span style={{ color: "#FF4D4F" }}>Khách hàng cũ</span>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Mã Khách Hàng:</div>{" "}
          {props?.selectedCustomerInfor?.customerCode ?? unknowValue}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Số điện thoại:</div>
          {props?.selectedCustomerInfor?.phonenumber ?? unknowValue}
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Mã số thuế:</div>
          {props?.selectedCustomerInfor?.taxCode ?? unknowValue}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Mã bưu điện:</div>
          {props?.selectedCustomerInfor?.faxCode ?? unknowValue}
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Người đại diện:</div>
          {props?.selectedCustomerInfor?.representative ?? unknowValue}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Địa chỉ:</div>
          {props?.selectedCustomerInfor?.address ?? unknowValue}
        </Col>
      </Row>
      <Divider orientation="left" plain>
        Thông tin thêm
      </Divider>
      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Mail:</div>
          {props?.selectedCustomerInfor?.email ?? unknowValue}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Trạng thái:</div>
          {props?.selectedCustomerInfor?.status == 1 ? (
            <span style={{ color: "#52C41A" }}>Hoạt động</span>
          ) : (
            <span style={{ color: "#FF4D4F" }}>Ngưng hoạt động</span>
          )}
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Nhóm khách hàng :</div>
          {props?.selectedCustomerInfor?.customerGroup?.customerGroupName ??
            unknowValue}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Tên cửa hàng: </div>
          {props?.selectedCustomerInfor?.storeName ?? unknowValue}
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Hình Thức kinh doanh: </div>
          {props?.selectedCustomerInfor?.businessType?.businessName ??
            unknowValue}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Kênh bán hàng: </div>
          {props?.selectedCustomerInfor?.saleChannel?.saleChannelName ??
            unknowValue}
        </Col>
      </Row>

      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Người nhận hàng:</div>
          {props?.selectedCustomerInfor?.shipTo ?? unknowValue}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Địa chỉ nhận hàng:</div>{" "}
          {props?.selectedCustomerInfor?.shipToAddress ?? unknowValue}
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Số fax:</div>
          {props?.selectedCustomerInfor?.faxCode ?? unknowValue}{" "}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Địa chỉ xuất hóa đơn:</div>
          {props?.selectedCustomerInfor?.billToAddress ?? unknowValue}
        </Col>
      </Row>

      <Row gutter={[16, 48]}>
        <Col span={12}>
          <div className="nameTitle">Người nhận hóa đơn:</div>
          {props?.selectedCustomerInfor?.billTo ?? unknowValue}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Tỉnh :</div>
          {props?.selectedCustomerInfor?.province?.provinceName ?? unknowValue}
        </Col>
      </Row>
      <Row gutter={[16, 48]}>

        <Col span={12}>
          <div className="nameTitle"> Phường :</div>
          {props?.selectedCustomerInfor?.ward?.wardName ?? unknowValue}
        </Col>
        <Col span={12}>
          <div className="nameTitle">Huyện </div>
          {props?.selectedCustomerInfor?.district?.districtName ?? unknowValue}
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={24}>
          <div className="nameTitle">Ghi chú: </div>
          <div className="site-card-border-less-wrapper">
            <Card bordered={true} style={{ width: "100%" }}>
              <p>{props?.selectedCustomerInfor?.notes ?? unknowValue}</p>
            </Card>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 48]}>
        {/* <Col span={12}>
          <div className="nameTitle">Ngày viếng thăm :</div>
          {props?.selectedCustomerInfor?.dateOfVisitings?.map((item, i) => {
            return <div>{item.date.toString()}</div>;
          })}
        </Col> */}
        {/* <Col span={12}>
          <div className="nameTitle"> Thứ tự viếng thăm :</div>
          {props?.selectedCustomerInfor?.visitAttendanceIndex ?? unknowValue}
        </Col> */}
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={12}></Col>
        <Col span={12}></Col>
      </Row>
      <Row gutter={[16, 48]}>
        <Col span={12}></Col>
        <Col span={12}></Col>
      </Row>
      <Row
        gutter={[16, 48]}
        style={{
          display: "fixed",
          bottom: "8px",
        }}
      >
        <Col span={24}>
          Vui lòng liên hệ với số điện thoại :{" "}
          <span
            style={{
              color: "red",
            }}
          >
            {props?.selectedCustomerInfor?.phonenumber}
          </span>{" "}
          để biết thêm thông tin !
        </Col>
      </Row>
    </div>
  );
}

export default DetailModal;
