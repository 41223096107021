import React from "react";
import styled from "styled-components";
import Chart from "react-google-charts";

const Dashboard = ({ className, profile ,data}) => {

  return (
    <div style={{ background: "white" }}>
      <Chart
        width="100%"
        height={500}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          // title: "Doanh thu + doanh số theo ngày",
          seriesType: "bars",
          chartArea: { width: "70%" },
          hAxis: {
            title: "Doanh Thu và Doanh Số Trong Tuần",
            minValue: 0,
            // titleTextStyle: { color: 'red' }
          },
          // vAxis: {
          //   title: 'City',
          // },
          colors: ["#5656ff", "#f70000"],
        }}
      />
    </div>
  );
};

export default styled(Dashboard)``;
