import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  message,
  InputNumber,
  Checkbox,
} from "antd";
import React, { useCallback, useEffect } from "react";
import services from "services";
import utils from "utils";

const DAY_OF_WEEKS = {
  Monday: "Thứ 2",
  Tuesday: "Thứ 3",
  Wednesday: "Thứ 4",
  Thursday: "Thứ 5",
  Friday: "Thứ 6",
  Saturday: "Thứ 7",
  Sunday: "Chủ nhật",
};

const CustomermanagementModal = ({ config, onCancel }) => {
  const { visible, dataSource, mode } = config;
  const checkedWeeks = dataSource?.visitFrequency?.map((t) => t.weekIndex);
  const [form] = Form.useForm();

  const onSubmitEdit = useCallback(
    (values) => {
      const payload = {
        currentState: dataSource?.currentState,
        saleRouteId: dataSource?.saleRouteId,
        saleRouteDetails: [
          {
            customerInformationId: dataSource?.customerInformation?.id,
            visitAttendanceIndex: values.visitAttendanceIndex,
            dateOfVisitings: values.dateOfVisitings.map((wDay) => ({
              date: wDay,
            })),
            visitFrequency: values.visitFrequency.map((wIndex) => ({
              weekIndex: wIndex,
            })),
          },
        ],
      };


      services.generalService
        .saleRoute_UpdateCustomerInSaleRoute(payload)
        .then((res) => {
          if (res?.succeeded) {
            message.success("Sửa thông tin viếng thăm thành công");
            form.resetFields();
            onCancel();
          }
        })
        .catch((err) =>
          message.error(
            err?.message ?? "Sửa thông tin viếng thăm không thành công"
          )
        );
    },
    [dataSource, form, onCancel]
  );

  const handleConfirm = useCallback(() => {
    form.submit();
  }, [form]);

  useEffect(() => {
    if (visible && dataSource.saleRouteId) {
      form.setFieldsValue({
        id: dataSource?.id,
        status: dataSource?.status,
        currentState: dataSource?.currentState,
        saleRouteId: dataSource?.saleRouteId,
        visitAttendanceIndex: dataSource?.visitAttendanceIndex,
        dateOfVisitings: dataSource?.dateOfVisitings?.map((x) => x.date) || [],
        visitFrequency: checkedWeeks,
      });
    }
  }, [visible, dataSource, form, checkedWeeks]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={600}
      title={
        mode === utils.$const.COMMON.MODAL_MODE.EDIT
          ? "Sửa khách hàng trong tuyến"
          : "Thêm khách hàng trong tuyến"
      }
      destroyOnClose
      footer={[
        <Button key="submit" type="primary" onClick={handleConfirm}>
          Xác nhận
        </Button>,
        <Button key="back" onClick={onCancel}>
          Quay lại
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onSubmitEdit}
        labelAlign="left"
        labelCol={{ span: 8 }}
        preserve={false}
      >
        <Form.Item
          label="Thứ tự viếng thăm"
          required
          name="visitAttendanceIndex"
          rules={[
            { required: true, message: "Thiếu Thứ tự viếng thăm" },
            {
              type: "number",
              min: 0,
              message: "Thứ tự viếng thăm không được nhỏ hơn 0",
            },
            {
              type: "number",
              max: 99,
              message: "Thứ tự viếng thăm nhỏ hơn 100",
            },
          ]}
        >
          <InputNumber
            style={{ minWidth: "100%" }}
            placeholder="Thứ tự viếng thăm"
          />
        </Form.Item>
        <Form.Item label="Ngày viếng thăm" name="dateOfVisitings" required>
          <Select mode="multiple" allowClear placeholder="Chọn ngày viếng thăm">
            {Object.entries(DAY_OF_WEEKS).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item required name="visitFrequency" label="Tần suất viếng thăm">
          <Checkbox.Group>
            <Row>
              <Col span={6}>
                <Checkbox value={1} style={{ lineHeight: "32px" }}>
                  W1
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={2} style={{ lineHeight: "32px" }}>
                  W2
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={3} style={{ lineHeight: "32px" }}>
                  W3
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox value={4} style={{ lineHeight: "32px" }}>
                  W4
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item hidden name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled />
        </Form.Item>
        <Form.Item hidden name="saleRouteId">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomermanagementModal;
