/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 04/06/2021.
 * @description Modal Thêm/Sửa khu vực
 * */

import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  message,
} from "antd";
import PropTypes from "prop-types";
import { map } from "lodash";
import utils from "utils";
import services from "services";
import _ from "lodash";

const BusinessAreaModal = function ({
  config,
  onCancel,
  dataHuman,
}) {
  const { visible, mode, dataSource } = config;
  const [form] = Form.useForm();
  const isAdd = mode === utils.$const.COMMON.MODAL_MODE.ADD;
  const [region, setRegion] = useState([]);
  const [province, setProvince] = useState([]);
  const [isContinue, setIsContinue] = useState(false);
  const disabled = mode === utils.$const.COMMON.MODAL_MODE.ADD === false

  const handleConfirm = useCallback(() => {
    setIsContinue(false);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleConfirmAndContinue = useCallback(() => {
    setIsContinue(true);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleSubmit = useCallback(
    (values) => {
      if (isAdd) {
        services.organization
          .businessProvince_Create({
            provinceId: values.areaObj?.value,
            provinceCode: values.areaObj?.label,
            provinceName: values.areaObj?.label,
            businessRegionId: values.businessRegionIdObj?.key,
            description: values.description,
            managerUserId: values.managerUserIdObj?.value,
            //accountingUserId: values.accountingUserIdObj?.value,
            // unused
            status: 1,
          })
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            if (isContinue) {
              form.resetFields();
            } else {
              onCancel();
              form.resetFields();
            }
          })
          .catch((error) => message.error("Tạo mới tỉnh không thành công."));
      } else {
        services.organization
          .businessProvince_Update({
            // ...values,
            id: values.id,
            description: values.description,
            provinceId: form.getFieldValue()?.provinceId,
            provinceCode: form.getFieldValue()?.provinceCode,
            provinceName: form.getFieldValue()?.provinceName,
            currentState : values.currentState,
            businessRegionId: values.businessRegionIdObj?.key,
            managerUserId: values.managerUserIdObj?.key ? values.managerUserIdObj?.key : values.managerUserIdObj,
            status: 1,
            //accountingUserId: values.accountingUserIdObj?.key,

            // unused
          })
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onCancel();
            form.resetFields();
          })
          .catch((error) =>
            message.error("Chỉnh sửa tỉnh không thành công.")
          );
      }
    },
    [isAdd, isContinue, form, onCancel]
  );

  useEffect(() => {
    services.organization.businessRegion_GetBsWithPagination().then((res) => {
      setRegion(res.items);
    });
  }, [setRegion]);

  const handleCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  const handleChangebusinessRegion = useCallback(
    (obj) => {
      services.organization
        .province_GetAllProvincesWithPermission()
        .then((res) => {
          setProvince(res);
        });
      form.getFieldValue().areaCode = "";
    },
    [form]
  );
  useEffect(() => {
    if (visible && mode === utils.$const.COMMON.MODAL_MODE.EDIT) {
      // set dữ liệu trong dataSource vào form
      form.setFieldsValue({
        id: dataSource.id,
        provinceCode: dataSource.provinceCode,
        currentState : dataSource.currentState,
        provinceId : dataSource.provinceId,
        businessRegionId: dataSource.businessRegionId,
        provinceName: dataSource.provinceName,
        areaObj: {
          key: dataSource.provinceCode,
          label: dataSource.provinceName,
        },
        businessRegionIdObj: {
          key: dataSource.businessRegion?.id,
          label: dataSource.businessRegion?.regionName,
        },
        managerUserIdObj: dataSource.businessRegion?.managerUserInformation ? {
          key: dataSource.managerUserId,
          label: dataSource.managerUserInformation?.fullName,
        } : undefined,
        // accountingUserIdObj:dataSource.businessRegion?.accountingUserInformation? {
        //   key: dataSource.accountingUserId,
        //   label: dataSource.accountingUserInformation?.fullName,
        // } : undefined,
        description: dataSource.description,
        status: dataSource.status,
      });
    }
  }, [dataSource, visible, mode, form]);

  const renderTitle = () =>
    mode === utils.$const.COMMON.MODAL_MODE.ADD
      ? // ? `Thêm mới khu vực`
        // : "Chỉnh sửa khu vực";
        `THÔNG TIN TỈNH/ THÀNH`
      : "THÔNG TIN TỈNH/ THÀNH";
  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      width={isAdd && visible ? "40%" : "60%"}
      onCancel={handleCancelModal}
      centered
      footer={[
        <Form.Item>
          <Button onClick={handleConfirm} type="primary">
            {dataSource?.id ? "Cập nhật" : "Thêm"}
          </Button>
          {mode === utils.$const.COMMON.MODAL_MODE.ADD ? (
            <Button
              title="Thêm và tiếp tục"
              key="submitAndContinute"
              type="primary"
              onClick={handleConfirmAndContinue}
            >
              Thêm và tiếp tục
            </Button>
          ) : (
            ""
          )}
          <Button type="default" onClick={handleCancelModal}>
            Quay lại
          </Button>
        </Form.Item>,
      ]}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        labelAlign="left"
        initialValues={{
          status: 1,
        }}
      >
        <Row gutter={[8, 8]}>
          <Col span={isAdd ? 24 : 24}>
            <Form.Item
              label="Vùng/ Miền"
              name="businessRegionIdObj"
              rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <Select
                placeholder="Chọn vùng/ Miền"
                showSearch
                showArrow={true}
                disabled={disabled}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue
                allowClear
                onChange={handleChangebusinessRegion}
              >
                {region.map((pv, id) => (
                  <Select.Option key={id} value={pv?.id}>
                    {pv?.regionName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Mã Tỉnh/ Thành"
              name="areaObj"
              rules={[{ required: true, message: "Vui lòng nhập" }]}
            >
              <Select
                placeholder="Chọn tỉnh thành"
                showSearch
                showArrow={true}
                disabled={disabled}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue
                allowClear
              >
                {province.map((pv, id) => (
                  <Select.Option key={id} value={pv?.id}>
                    {pv?.provinceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="managerUserIdObj"
              label="Người quản lý"
            >
              <Select placeholder="Chọn người quản lý"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue>
                {map(dataHuman?.arrManager, (item) => (
                  <Select.Option key={item.userId} value={item.userId}>
                    {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item
              name="accountingUserIdObj"
              label="Kế toán"
            >
              <Select placeholder="Chọn Kế toán" disabled={disabled}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue>
                {map(dataHuman?.arrAccounting, (item) => (
                  <Select.Option key={item.userId} value={item.userId}>
                    {item.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item label="Mô tả" name="description">
              <Input.TextArea rows={2} placeholder="Nhập mô tả khu vực" disabled={disabled}/>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item hidden name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};
BusinessAreaModal.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
};
export default BusinessAreaModal;
