/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 20/09/2021.
 * @description Màn hình `Thông tin đơn vị tiền tệ`. CurrencyUnit `/generalManagement/CurrencyUnit`
 * */

import { useCallback, useState, useEffect } from "react";
import { Card, Button, Table, message, Typography, Row, Col, Form, Input } from "antd";
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteFilled } from "@ant-design/icons";
import CurrencyUnitModal from "components/modals/CurrencyUnitModal";
import services from "services";
import APP_CONST from "utils/constants";
import ConfirmModal from "components/modals/confirm";

const CurrencyUnitPage = function (){
    const [form] = Form.useForm();
    const [data, setData] = useState([]);

    const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [isReloadPage, setIsReloadPage] = useState(false);

    useEffect(() => {
      setIsLoading(true);
      services.generalService.currencyUnit_GetBsWithPagination(paging?.pageNumber, paging?.pageSize)
      .then((res)=>{
        setIsLoading(false);
        setTotal(res?.totalCount ?? 0);
        setData(res.items);
      })
      .catch((error)=>{
        setTotal(0);
        setIsLoading(false);
      })
    }, [paging,isReloadPage]);

    const columns = [
        {
            title: "Mã đơn vị tiền tệ",
            dataIndex: "code",
            width: 400,
            key:"code",
        },
        {
            title: "Đơn vị tiền tệ",
            dataIndex: "name",
            width: 700,
            key:"name",
        },
        {
          title: "Trạng thái",
          dataIndex: "status",
          key:"status",
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value) => (
            <div className={`status--${value ? "active" : "inactive"}`}>
              {
                APP_CONST?.CURRENCY_UNIT?.STATUS?.find(
                  (status) => status?.id === value
                )?.name
              }
            </div>
          ),
        },
        {
            dataIndex: "action",
            key: "action",
            title: "#",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_, row) => (
              <div className="warehouseInfo-card__button">
                <Button
                    type="primary"
                    size="small"
                    onClick={handleEditCurrencyUnit(row)}
                >
                    <span className="d-flex align-items-center">
                      <EditOutlined /> <span className="ml-1"></span>
                    </span>
                </Button>
                <Button
                    className="warehouseInfo-card__button--right"
                    type="danger"
                    size="small"
                    onClick={handleDeleteCurrencyUnit(row)}
                >
                    <span className="d-flex align-items-center">
                    <DeleteFilled /> <span className="ml-1"></span>
                  </span>
                </Button>
                </div>
            ),
        },
    ];
    
    const [selectedCurrencyUnit, setSelectedCurrencyUnit] = useState({});
    const [isOpenCurrencyUnitModal, setIsOpenCurrencyUnitModal] = useState(false);
    const [currencyUnitModalMode, setCurrencyUnitModalMode] = useState("add");
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    const handleCreateCurrencyUnitModal = useCallback(() => {
    setCurrencyUnitModalMode("add");
    setIsOpenCurrencyUnitModal(true);
    setSelectedCurrencyUnit([]);
  }, []);
  
  const handleEditCurrencyUnit = useCallback(
    (currencyUnit) => (e) => {
      setCurrencyUnitModalMode("edit")
      setIsOpenCurrencyUnitModal(true);
      setSelectedCurrencyUnit(currencyUnit);
    },
    [setSelectedCurrencyUnit,setCurrencyUnitModalMode,setIsOpenCurrencyUnitModal]
  );

  const handleCancelCurrencyUnitModal = useCallback(() => {
    setIsOpenCurrencyUnitModal(false);
    setSelectedCurrencyUnit({});
  }, [setIsOpenCurrencyUnitModal, setSelectedCurrencyUnit]);

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedCurrencyUnit({});
  }, [setSelectedCurrencyUnit, setIsReloadPage, isReloadPage]);
  
  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    },
    [paging]
  );

  const handleDeleteCurrencyUnit = useCallback(
    (currencyUnit) => () => {
      setSelectedCurrencyUnit(currencyUnit);
      setIsShowConfirmModal(true);
    },
    [setSelectedCurrencyUnit, setIsShowConfirmModal]
  );

  const handleApproveToDeleteCurrencyUnit = useCallback(() => {
    services.generalService
      .currencyUnit_Delete([{
        id: selectedCurrencyUnit?.id,
        currentState: selectedCurrencyUnit?.currentState
      }])
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedCurrencyUnit({});
        handleReloadPage();
      })
      .catch((err) => {
        message.error(err?.message || "Xóa kho thất bại");
      });
  }, [handleReloadPage, selectedCurrencyUnit]);

  const handleRejectToDeleteCurrencyUnit = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedCurrencyUnit({});
  }, [setSelectedCurrencyUnit]);

  // const renderTitle = () => {
  //   return (
  //     <Typography.Text>THÔNG TIN ĐƠN VỊ TIỀN TỆ</Typography.Text>
  //   );
  // };
  
  const handleSubmit = (values) => {
    setIsLoading(true);
    let filter = {
      ...form.getFieldsValue(),
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize
    };
    services.generalService.currencyUnit_filterCurrencyUnit(filter)
    .then(res =>{
      setIsLoading(false);
      setData(res.items);
      setTotal(res?.totalCount ?? 0);
      if (res.items.length === 0) {
        message.error("Không tìm thấy thông tin");
      }
      else {
        message.success("Tìm kiếm thành công");
      }
    })
    .catch(err =>{
      setTotal(0);
      setIsLoading(false);
    })
  };

  return (
    <Card className="CurrencyUnitPage">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={6}>
            <Form.Item name='code' label='Mã đơn vị tiền tệ'>
              <Input placeholder='Nhập mã đơn vị tiền tệ' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name='name' label='Đơn vị tiền tệ'>
              <Input placeholder='Nhập đơn vị tiền tệ' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item className='general-button--height' label=' '>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                >
                  <span className='d-flex align-items-center'>
                    <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
                  </span>
                </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height ' label=' '>
              <Button onClick={handleCreateCurrencyUnitModal}>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Tạo mới</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Export excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Import excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      
      <Table
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 1200 }}
        size="small"
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        }}
        loading={isLoading}
        locale={{ emptyText: "Không có đơn vị tiền tệ" }}
      />
      <CurrencyUnitModal
        visible={isOpenCurrencyUnitModal}
        mode = {currencyUnitModalMode}
        onCancel = {handleCancelCurrencyUnitModal}
        dataItem = {selectedCurrencyUnit}
        onReloadPage={handleReloadPage}
      />
      <ConfirmModal
        title="Xóa nhà cung cấp"
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeleteCurrencyUnit}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa{" "}
          <strong>{selectedCurrencyUnit?.name}</strong> không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleRejectToDeleteCurrencyUnit}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeleteCurrencyUnit}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Card>
  );
};
export default CurrencyUnitPage;