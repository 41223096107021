import React, { memo, useCallback } from "react";
import { Row, Col, Select, Button } from "antd";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import classNames from "classnames";
import {
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import services from "services";

const { Option } = Select;

const Fillter = memo(
  ({
    params,
    setParams,
    visible,
    setVisible,
    setRow,
    row,
    className,
    totalLength,
    setTotalLength,
    setData,
    setLoading,
    filter,
    setFilter,
    id,
    dataFilter,
    setDataFilter,
  }) => {
    const getQuery = useCallback(
      (value, name) => {
        setFilter((preState) => {
          let nextState = { ...preState };
          nextState[name] = value;
          return nextState;
        });
      },
      [filter]
    );
    const onCreate = () => {
      setVisible((preState) => {
        let nextState = { ...preState };
        nextState.isShowCreate = true;
        return nextState;
      });
    };
    const getFilter = (() => {
      // khi tìm kiếm mới call api
      if (_.get(filter, "customerId") || _.get(filter, "problemTypeId") || _.get(params, "pageNumber") || _.get(params, "pageSize")) {
        setLoading(true);
        services.customerService
          .visitComment_GetBsWithPagination({
            customerId: _.get(filter, "customerId"),
            problemTypeId: _.get(filter, "problemTypeId"),
            pageNumber: _.get(params, "pageNumber"),
            pageSize: _.get(params, "pageSize"),
          })
          .then((res) => {
            setLoading(false);
            let arrData = _.map(_.get(res, "items"), (item, index) => {
              item.key = index + 1;
              item.comments = _.get(item, "problemTypeName");
              item.createTimeCommet =
                item.createTime &&
                moment(item.createTime).format("DD-MM-YYYY HH:mm");
              return item;
            });
            setData(arrData);
            setTotalLength(_.get(res, "totalCount"));
            // setParams((preState) => {
            //   let nextState = { ...preState };
            //   nextState = nextState;
            //   return nextState;
            // });
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    });

    // const getProducts = useCallback(() => {

    // }, []);

    // useEffect(() => {
     
    // }, [ params]);
    return (
      <>
        {id === null ? (
          <>
            <Row
              gutter={15}
              className={classNames({
                [className]: true,
              })}
              style={{ marginBottom: 10 }}
            >
              <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                <span>Khách hàng</span>
                <Select
                  value={_.get(filter, "arrCustomer")}
                  style={{ width: "100%", marginTop: 10 }}
                  placeholder="Khách hàng"
                  allowClear
                  onChange={(e) => {
                    getQuery(e, "customerId");
                  }}
                >
                  {_.map(_.get(dataFilter, "arrCustomer"), (item, index) => {
                    return (
                      <Option value={item.id} key={item.index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                <span>Vấn đề</span>
                <Select
                  value={_.get(filter, "arrProblem")}
                  style={{ width: "100%", marginTop: 10 }}
                  placeholder="Vấn đề"
                  allowClear
                  onChange={(e) => {
                    getQuery(e, "problemTypeId");
                  }}
                >
                  {_.map(_.get(dataFilter, "arrProblem"), (item, index) => {
                    return (
                      <Option value={item.id} key={item.index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row gutter={15} justify="end" style={{ marginBottom: 15 }}>
              {/* <Col>
            <Button onClick={onCreate} icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Col> */}
              <Col>
                <Button
                  type="primary"
                  onClick={getFilter}
                  icon={<SearchOutlined />}
                >
                  Tìm kiếm
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Row gutter={15} justify="end" style={{ marginBottom: 15 }}>
            <Col>
              <Button onClick={onCreate} icon={<PlusOutlined />}>
                Thêm mới
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  }
);
export default styled(Fillter)`
  .ant-picker {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .clearParams {
    text-align: right;
  }
`;
