import {
  LoadingOutlined,
  PlusOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import GoogleMap from "google-map-react";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Steps,
  Upload,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
//import Maps from "images/maps.jpg";
import React, { useCallback } from "react";
import { filter } from "lodash";
import services from "services";
//import users from "images/user.png"
import "./style.scss";

const { Step } = Steps;
const steps = [
  {
    title: "Thông tin chính",
    content: "First-content",
    icon: <UserOutlined />,
    loadIcon: <LoadingOutlined />,
    index: 1,
  },
  {
    title: "Thông tin chi tiêt",
    content: "First-content",
    icon: <UserOutlined />,
    loadIcon: <LoadingOutlined />,
    index: 2,
  },
  {
    title: "Thông tin bổ sung",
    icon: <SolutionOutlined />,
    loadIcon: <LoadingOutlined />,
    content: "Second-content",
    index: 3,
  },
  {
    title: "Thông tin vị trí",
    icon: <SmileOutlined />,
    loadIcon: <LoadingOutlined />,
    content: "Last-content",
    index: 4,
  },
];
const StepsFormModal = ({
  visible,
  onCancel,
  selectedCustomerInfor = {},
  listCustomerInformation = [],
  onReloadPage,
  ...props
}) => {
  // ----------------------------------------------------
  // -----------------------STATE-----------------
  // ----------------------------------------------------
  // const includedIds = form.getFieldValue();
  // const [currentCusId, setcurrentCusId] = React.useState("");
  const [form] = Form.useForm();
  const [fetchingRegister, setFetchingRegister] = React.useState(false);
  //const [loading, setLoading] = React.useState(false);
  const loading = false;
  const [current, setCurrent] = React.useState(0);
  //const [doneStepItem, setDoneStepItem] = React.useState(false);
  const [fail, setFail] = React.useState(true);
  const [variableFlie, setVariableFlie] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();
  //const [AvtImg, setAvtImg] = React.useState("");
  const [location, setLocation] = React.useState({
    lat: 59.95,
    lng: 30.33,
  });
  const [province, setProvince] = React.useState(props?.listProvince || []);
  const [districts, setDistricts] = React.useState(props?.listDistrict || []);
  const [wards, setWards] = React.useState([]);
  //const [zoom, setZoom] = React.useState(11);
  // ----------------------------------------------------
  // --------------------useEffect------------------------
  // ----------------------------------------------------
  React.useEffect(() => {
    setDistricts(props?.listDistrict || []);
  }, [props?.listDistrict]);

  React.useEffect(() => {
    setWards(props?.listWard || []);
  }, [props?.listWard]);

  React.useEffect(() => {
    if (Object.keys(selectedCustomerInfor).length > 0) {
      form.setFieldsValue(selectedCustomerInfor);
    } else {
      form.resetFields();
    }
  }, [selectedCustomerInfor, form]);
  // const handleSearch = useCallback((values) => {
  //   if (!values) {
  //     openWarningPopUp();
  //   }
  // });
  // ----------------------------------------------------
  // --------------------Submit------------------------
  // ----------------------------------------------------
  const handleCloseModal = () => {
    Modal.confirm({
      title: (
        <span>
          <b className="text-danger">Thông tin chưa được lưu !</b>
        </span>
      ),
      cancelText: "Quay lại",
      okText: "Tiếp tục và không lưu",
      content: "",
      onOk() {
        onCancel();
        form.resetFields();
        setImageUrl(null);
        setCurrent(0);
      },
      onCancel() {},
    });
  };

  // const openWarningPopUp = () => {
  //   Modal.warning({
  //     title: (
  //       <span>
  //         <b className="text-danger">
  //           Thông tin khởi tạo khách hàng chưa chính xác .Vui lòng kiểm tra lại
  //           thông tin bạn vừa nhập !
  //         </b>
  //       </span>
  //     ),
  //     width: "500px",
  //     content: "",
  //     onOk() {},
  //   });
  // };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isAccept = true;
    return isJpgOrPng && isAccept;
  }
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
    const fileToUpload = {
      data: img,
      fileName: img.name,
    };
    setVariableFlie(fileToUpload);
    if (
      variableFlie !== undefined &&
      variableFlie !== null &&
      variableFlie !== ""
    ) {
      services.fileManagement
        .photo_PostPhotoAttachment(variableFlie, "Photo", "Products")
        .then((res) => {
          // if (res.succeeded == true) {
          //   setAvtImg(res.data);
          // }
        });
    }
  }

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => setImageUrl(imageUrl));
  };
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  //const [dateOfVisit, setdateOfVisit] = React.useState([]);
  const dateOfVisit = [];
  // function handleChangeDate(value) {
  //   // eslint-disable-next-line no-lone-blocks
  //   const dateOfVisit = value?.map((item, key) => {
  //     return { date: item };
  //   });
  //   setdateOfVisit(dateOfVisit);
  // }
  const children = [];
  children.push(
    <>
      <Select key={"Monday"} value="Monday">
        Thứ hai
      </Select>
      <Select key={"Tuesday"} value="Tuesday">
        Thứ ba
      </Select>
      <Select key={"Wednesday"} value="Wednesday">
        Thứ tư
      </Select>
      <Select key={"Thursday"} value="Thursday">
        Thứ năm
      </Select>
      <Select key={"Friday"} value="Friday">
        Thứ sáu
      </Select>
      <Select key={"Saturday"} value="Saturday">
        Thứ bảy
      </Select>
      <Select key={"Sunday"} value="Sunday">
        Chủ nhật
      </Select>
    </>
  );
  const K_WIDTH = 40;
  const K_HEIGHT = 40;

  const greatPlaceStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: "absolute",
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,
    // width:40,
    // height:40,
    border: "5px solid #f44336",
    borderRadius: K_HEIGHT,
    backgroundColor: "white",
    textAlign: "center",
    color: "#3f51b5",
    fontSize: 16,
    fontWeight: "bold",
    padding: 4,
  };
  const MyGreatPlace = ({ text }) => {
    return <div style={greatPlaceStyle}>{text}</div>;
  };
  const handleApiLoaded = (map, maps) => {
  };
  function createMapOptions(maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
    };
  }
  const _onChildMouseEnter = (key, childProps) => {
  };
  const ggmapKey = process?.env?.REACT_APP_GG_MAP_KEY;
  const handleConfirm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleSubmit = (values) => {
    let dataNew = {
      ...values,
      customerStatus: values?.customerStatus ?? 1,
      status: values?.status ?? 1,
      debtLimit: Number(values?.debtLimit ?? 0),
      longitude: location.lng,
      latitude: location.lat,
    };
    setFetchingRegister(true);
    if (
      variableFlie !== null &&
      variableFlie !== undefined &&
      variableFlie !== ""
    ) {
      services.fileManagement
        .photo_PostPhotoAttachment(variableFlie, "Photo", "Products")
        .then((res) => {
          if (res.succeeded === true) {
            dataNew.avatar = res.data;
          }
          let Data = dataNew;
          services.generalService
            .customerInformation_Create({
              ...Data,
              dateOfVisitings: dateOfVisit,
            })
            .then((res) => {
              if (res?.succeeded) {
                message.success("Thêm khách hàng thành công");
                onReloadPage();
                form.resetFields();
                onCancel();
                setImageUrl(null);
                setCurrent(0);
              }
              setFetchingRegister(false);
            })
            .catch((err) => {
              message.error(
                err?.message ?? "Thêm thất bại, mã khách hàng có thể đã tồn tại"
              );
              setFetchingRegister(false);
            });
        });
    } else {
      services.generalService
        .customerInformation_Create({
          ...dataNew,
          dateOfVisitings: dateOfVisit,
        })
        .then((res) => {
          if (res?.succeeded) {
            message.success("Thêm khách hàng thành công");
            onReloadPage();
            form.resetFields();
            onCancel();
            setImageUrl(null);
            setCurrent(0);
          }
          setFetchingRegister(false);
        })
        .catch((err) => {
          message.error(
            err?.message ?? "Thêm thất bại, mã khách hàng có thể đã tồn tại"
          );
          setFetchingRegister(false);
        });
    }
  };
  //const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const handleChangeRegion = useCallback(
    (regionId) => {
      let regionItem = filter(props?.listRegion || [], function (item) {
        if (item.id === regionId) return item?.id;
      });

      if (regionItem[0]) {
        form.setFieldsValue({provinceId: "" , districtId: "", wardId: "" });

        services.organization
          .province_GetProvinceByRegion(regionItem[0]?.id)
          .then((res) => {
            setProvince(res);
          });
      }
    },
    [form, props]
  );

  const handleChangeProvince = useCallback(
    (provinceId) => {
      let provinceItem = filter(props?.listProvince || [], function (item) {
        if (item.id === provinceId) return item?.id;
      });

      if (provinceItem[0]) {
        form.setFieldsValue({ districtId: "", wardId: "" });

        services.organization
          .district_GetDistrictByProvince(provinceItem[0]?.id)
          .then((res) => {
            setDistricts(res);
          });
      }
    },
    [form, props]
  );

  const handleChangeDistrict = useCallback(
    (districtId) => {
      // return;
      // GET phường theo huyện
      let districtItem = filter(districts, function (item) {
        if (item.id === districtId) return item?.id;
      });

      if (districtItem[0]) {
        form.setFieldsValue({ wardId: "" });

        services.organization
          .ward_GetWardByDistrict(districtItem[0]?.id)
          .then((res) => {
            setWards(res);
          });
      }
    },
    [form, districts]
  );

  const handleClickMap = (e) => {
    setLocation({ lat: e.lat, lng: e.lng });
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCloseModal}
      // onOk={handleConfirm}
      {...props}
      title={selectedCustomerInfor?.id ? "Sửa khách hàng" : "Thêm khách hàng"}
      footer={false}
      width="80%"
    >
      <Form form={form} onFinish={handleSubmit} {...layout}>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} icon={item.icon} />
          ))}
        </Steps>
        <div className="steps-content">
          <div className={steps[current].index === 1 ? "show" : "hide"}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Mã KH"
                  required
                  name="customerCode"
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (
                          listCustomerInformation
                            ?.map((item) => item?.customerCode)
                            .includes(value)
                        ) {
                          setFail(true);
                          return Promise.reject(new Error("Mã KH đã tồn tại"));
                        } else {
                          setFail(false);
                          Promise.resolve();
                        }
                      },
                    },
                    {
                      required: true,
                      whitespace: true,
                      message: "Mã KH không được trống",
                    },
                  ]}
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập khách hàng" />
                </Form.Item>
                {/* ---------------------- */}
                <Form.Item
                  label="Tên khách hàng"
                  required
                  name="customerName"
                  rules={[
                    {
                      required: true,
                      message: "Tên khách hàng không được trống",
                    },
                    {
                      validator: async (_, value) => {
                        if (value) {
                          setFail(false);
                          Promise.resolve();
                        } else {
                          setFail(true);
                          new Error("Tên khách hàng không được trống");
                        }
                      },
                    },
                  ]}
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập Tên khách hàng" />
                </Form.Item>
                {/* ---------------------- */}

                <Form.Item
                  label="Sô điện thoại"
                  required
                  name="phonenumber"
                  rules={[
                    {
                      required: true,
                      message: "Sô điện thoại không được trống",
                    },
                    // {
                    //   type: "number",
                    //   message: "Dữ liệu nhập vào chưa đúng định dạng",
                    // },
                    {
                      max: 11,
                      message: "Sai định dạnh số điện thoại",
                    },
                  ]}
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập Sô điện thoại" />
                </Form.Item>
                {/* ---------------------- */}

                <Form.Item
                  label="Địa chỉ"
                  required
                  name="address"
                  rules={[
                    { required: true, message: "Địa chỉ không được trống" },
                  ]}
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập dịa chỉ" />
                </Form.Item>
                {/* ---------------------- */}
              </Col>
              <Col span={12}>
                <div className="uploadImg">
                  <Form.Item
                    label="Ảnh Đại Diện"
                    labelCol={{ span: 8 }}
                    className="uploadImg"
                  >
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img
                          className="avtCustom"
                          src={imageUrl}
                          alt="avatar"
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          {/* -------------------------------------------------------- */}
          <div className={steps[current].index === 2 ? "show" : "hide"}>
            <Row gutter={24}>
              <Col span={12}>
                {/* <Form.Item
                  label="Tuyến bán hàng"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Tuyến bán hàng không được trống",
                    },
                  ]}
                  name="saleRouteId"
                  labelCol={{ span: 8 }}
                >
                  <Select placeholder="Chọn tuyến bán hàng" allowClear>
                    {props?.listsaleRoute?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.saleRouteName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}
                {/* ========================================= */}
                <Form.Item
                  label="Khu vực"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Khu vực không được trống",
                    },
                  ]}
                  name="areaId"
                  labelCol={{ span: 8 }}
                >
                  <Select
                    placeholder="Chọn khu vực"
                    showSearch
                    showArrow={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleChangeRegion}
                    allowClear
                  >
                    {props?.listRegion?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.regionName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Tỉnh"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Tỉnh không được trống",
                    },
                  ]}
                  name="provinceId"
                  labelCol={{ span: 8 }}
                >
                  <Select
                    placeholder="Chọn Tỉnh"
                    showSearch
                    showArrow={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleChangeProvince}
                    allowClear
                  >
                    {province?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.provinceName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Huyện"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Huyện không được trống",
                    },
                  ]}
                  name="districtId"
                  labelCol={{ span: 8 }}
                >
                  <Select
                    placeholder="Chọn Huyện"
                    showSearch
                    showArrow={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleChangeDistrict}
                    allowClear
                  >
                    {districts?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.districtName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Phường"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Phường không được trống",
                    },
                  ]}
                  name="wardId"
                  labelCol={{ span: 8 }}
                >
                  <Select placeholder="Chọn Phường" allowClear>
                    {wards?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.wardName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Kênh bán hàng"
                  // required
                  // rules={[
                  //   {
                  //     required: true.valueOf,
                  //     message: "Kênh sale không được trống",
                  //   },
                  // ]}
                  name="saleChannelId"
                  labelCol={{ span: 8 }}
                >
                  <Select placeholder="Chọn kênh bán hàng" allowClear>
                    {props?.listsaleChannel?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.saleChannelName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Nhóm khách hàng"
                  // required
                  // rules={[
                  //   {
                  //     required: true.valueOf,
                  //     message: "Kênh sale không được trống",
                  //   },
                  // ]}
                  name="customerGroupId"
                  labelCol={{ span: 8 }}
                >
                  <Select placeholder="Chọn nhóm khách hàng" allowClear>
                    {props?.listcustomerGroup?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.customerGroupName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Hình thức kinh doanh"
                  // required
                  // rules={[
                  //   {
                  //     required: true.valueOf,
                  //     message: "Hình thức kinh doanh không được trống",
                  //   },
                  // ]}
                  name="businessTypeId"
                  labelCol={{ span: 8 }}
                >
                  <Select placeholder="Chọn hình thức kinh doanh" allowClear>
                    {props?.listBusinessType?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.businessName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* -------------------------------------------------------- */}
              {/* -------------------------------------------------------- */}
              {/* -------------------------------------------------------- */}
              {/* -------------------------------------------------------- */}
              <Col span={12}>
                <Form.Item
                  label="Tên cửa hàng"
                  name="storeName"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Tên cửa hàng sale không được trống",
                    },
                  ]}
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập tên cửa hàng" />
                </Form.Item>

                {/* <Form.Item
                  label="Khu vực - vùng miền"
                  name=""
                  labelCol={{ span: 8 }}
                  required
                >
                  <Select placeholder="Chọn khu vực kinh doanh" allowClear>
                    {props?.listBusinessRegions?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.regionName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}
                <Form.Item
                  label="Trạng thái "
                  name="status"
                  labelCol={{ span: 8 }}
                >
                  <Select defaultValue={1} placeholder="Chọn Trạng thái">
                    <Select.Option value={1}>Hoạt động</Select.Option>
                    <Select.Option value={0}>Ngừng hoạt động</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Khách hàng Mới/Cũ"
                  name="customerStatus"
                  // required
                  // rules={[
                  //   { required: true, message: "Trạng thái không được trống" },
                  // ]}
                  labelCol={{ span: 8 }}
                >
                  <Select defaultValue={1} placeholder="Chọn Trạng thái">
                    <Select.Option value={1}>Khách hàng mới</Select.Option>
                    <Select.Option value={2}>Khách hàng cũ</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Hạn mức nợ"
                  name="debtLimit"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập hạn mức nợ" />
                </Form.Item>
                <Form.Item label="Email" name="email" labelCol={{ span: 8 }}>
                  <Input placeholder="Nhập mail khách hàng" />
                </Form.Item>
                <Form.Item
                  label="Người đại diện"
                  name="representative"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập người đại diện" />
                </Form.Item>

                <Form.Item label="Ghi chú" name="notes" labelCol={{ span: 8 }}>
                  <TextArea placeholder="Nhập ghi chú" rows={4} />
                </Form.Item>
              </Col>
            </Row>
            {/* -------------------------------------------------------- */}
            {/* -------------------------------------------------------- */}
            {/* -------------------------------------------------------- */}
          </div>
          {/* -------------------------------------------------------- */}
          <div className={steps[current].index === 3 ? "show" : "hide"}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Người nhận hóa đơn"
                  name="billTo"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập người nhận hóa đơn" />
                </Form.Item>
                <Form.Item
                  label="Địa chỉ xuất hóa đơn"
                  name="billToAddress"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập địa chỉ xuất hóa đơn" />
                </Form.Item>

                <Form.Item
                  label="Người nhận hàng"
                  name="shipTo"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập người nhận hàng" />
                </Form.Item>
                <Form.Item
                  label="Địa chỉ nhận hàng"
                  name="shipToAddress"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập địa chỉ nhận hàng" />
                </Form.Item>

                <Form.Item
                  label="Mã bưu điện"
                  name="zipCode"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập mã bưu điện" />
                </Form.Item>
              </Col>
              {/* -------------------------------------------------------- */}
              {/* -------------------------------------------------------- */}
              {/* -------------------------------------------------------- */}
              <Col span={12}>
                <Form.Item
                  label="Mã số thuế"
                  name="taxCode"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập mã số thuế" />
                </Form.Item>
                <Form.Item
                  label="hotline"
                  name="hotline"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập hotline" />
                </Form.Item>
                <Form.Item label="Số fax" name="faxCode" labelCol={{ span: 8 }}>
                  <Input placeholder="Nhập số fax" />
                </Form.Item>
                {/* <Form.Item
                  label="Tên Khách Hàng (EN)"
                  name="customerEnName"
                  labelCol={{ span: 8 }}
                >
                  <Input placeholder="Nhập customerEnName" />
                </Form.Item> */}
                {/* <Form.Item label="Ngày viếng thăm" labelCol={{ span: 8 }}>
                  <Select
                    mode="tags"
                    placeholder="Please select"
                    required
                    onChange={handleChangeDate}
                  >
                    {children}
                  </Select>
                </Form.Item> */}
              </Col>
            </Row>
          </div>
          {/* -------------------------------------------------------- */}
          <div className={steps[current].index === 4 ? "show" : "hide"}>
            {/* <img
              src={Maps}
              alt="GGF maps hiện không hoạt động"
              height="100%"
              width="100%"
            /> */}
            <div style={{ height: "100vh", width: "100%" }}>
              {/* <GoogleMapReact
                bootstrapURLKeys={{ key: ggmapKey }}
                defaultCenter={center}
                defaultZoom={zoom}
                yesIWantToUseGoogleMapApiInternals
              >
                <AnyReactComponent
                  lat={10.762622}
                  lng={106.660172}
                  text="My Marker"
                />
              </GoogleMapReact> */}
              <GoogleMap
                apiKey={ggmapKey} // set if you need stats etc ...
                center={{
                  lat: 15.818135592909831,
                  lng: 108.2856855718962,
                }}
                // zoom={zoom}
                defaultZoom={6}
                options={createMapOptions}
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
                onChildMouseEnter={_onChildMouseEnter()}
                onClick={handleClickMap}
              >
                <MyGreatPlace
                  lat={location.lat}
                  lng={location.lng}
                  text={"A"}
                />
              </GoogleMap>
            </div>
          </div>
        </div>

        <div className="steps-action btn_cf">
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Về trước
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => {
                next();
                // if (doneStepItem) {
                //   next();
                // } else {
                //   openWarningPopUp();
                // }
              }}
              disabled={fail}
            >
              Tiếp theo
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button
              type="primary"
              key="submit"
              onClick={() => {
                // handleSearch();
                handleConfirm();
              }}
              disabled={steps[current].index === 4 ? false : true && !fail}
              loading={fetchingRegister}
            >
              Tạo khách hàng
            </Button>
          )}{" "}
        </div>
      </Form>
    </Modal>
  );
};

export default StepsFormModal;
