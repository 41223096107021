import React, { memo } from "react";

import _ from "lodash";
import { Table } from "antd";

const List = memo(({ data, total, paging, setPaging, dataFilter }) => {

  const handleChangePaging = (pageNumber, pageSize) => {
    const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
    setPaging({ pageNumber: currentPage, pageSize });
  };
  
  const kpiProductColumns = [
    {
      dataIndex: "no",
      key: "no",
      title: "STT",
      align: "center",
      width: 50,
      render: (value, row, index) => ((paging.pageNumber - 1) * paging.pageSize) + index + 1,
    },
    {
      title: "Vùng",
      dataIndex: "regionName",
      key: "regionName",
      width: 150,
      render: (value, row) => {
        let name = _.get(dataFilter, "arrBusinessRegions").find(
          (BusinessRegions) => BusinessRegions?.id === row.regionId
        )?.regionName;
        return <span>{name}</span>;
      },
    },
    {
      title: "Tỉnh",
      dataIndex: "provinceName",
      key: "provinceName",
      width: 120,

      render: (value, row) => {
        let name = _.get(dataFilter, "arrProvinces").find(
          (Province) => Province?.id === row.provinceId
        )?.provinceName;
        return <span>{name}</span>;
      },
    },
    {
      title: "Mã NV",
      dataIndex: "staffCode",
      key: "staffCode",
      width: 100,
    },
    {
      title: "Tên NV",
      dataIndex: "staffName",
      key: "staffName",
      width: 130,      
    },
    {
      title: "Mã SP",
      dataIndex: "productCode",
      key: "productCode",
      width: 120,      
    },
    {
      title: "Tên SP",
      dataIndex: "productName",
      key: "productName",
      width: 150      
    },
    {
      title: "Kế Hoạch Trong Tháng",
      dataIndex: "plannedQuantity",
      key: "plannedQuantity",
      align: "right",
      width: 100,
      render: (value) => {
        return <span>{value ? value.toLocaleString() : 0}</span>;
      },
    },
    {
      title: "Thực Hiện",
      dataIndex: "actualQuantity",
      key: "actualQuantity",
      align: "right",
      width: 100,
      render: (value) => {
        return <span>{value ? value.toLocaleString() : 0}</span>;
      },
    },
    {
      title: "% Đạt Được",
      dataIndex: "percentQuantity",
      key: "percentQuantity",
      align: "right",
      width: 100
    },
    {
      title: "Tổng Doanh Thu",
      dataIndex: "turnoverFormatted",
      key: "turnoverFormatted",
      align: "right",
      width: 130
    },
  ];

  return (
    <div>
      <Table
        columns={kpiProductColumns}
        dataSource={data}
        bordered
        size="small"
        scroll={{ x: "calc(700px + 50%)", y: 400 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
      />
    </div>
  );
});

export default List;
