import {
  createSlice
} from "@reduxjs/toolkit";
import utils from "utils";

const getDefaultState = () => {
  return utils.$oauth.getOauth();
};

const slice = createSlice({
  name: "auth",
  initialState: getDefaultState(),
  reducers: {
    setAuthenticate: (state, action) => {
      if (action.payload.isAuthenticated) {
        state.isAuthenticated = true;
        state.accessToken = action.payload.accessToken;
        state.user = action.payload.user;
        utils.$oauth.setOauth({
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          user: action.payload.user
        })
      } else {
        state.isAuthenticated = false;
        state.user = {};
        utils.$oauth.clearOauth();
      }
    },
  },
});
const slices = {
  actions: slice.actions,
  reducer: slice.reducer,
  seletors: {
    makeSelectIsAuthenticated: (state) => state.auth.isAuthenticated,
    makeSelectUser: (state) => state.auth.user,
  },
};
export default slices;