import { useCallback, useState } from 'react';
import {
  Card,
  Row,
  Form,
  Col,
  Divider,
  Table,
  DatePicker,
  Button,
  Tabs,
  Input,
  Select,
} from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { map } from 'lodash';
import services from 'services';
import utils from 'utils';
import moment from 'moment';
import BaseSelect from 'components/selects/baseSelect';
export default function StatisticCustomerSummary() {
  const [form] = Form.useForm();
  //const [newCustomerSummary, setListCustomerSummary] = useState([]);
  const newCustomerSummary = [];
  const [isLoading, setIsLoading] = useState(false);
  //const [total, setTotal] = useState(0);
  const total = 0;
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [fromToDate, setFromToDate] = useState({
    fromDate: utils.$datetime.currentFirstDate(),
    toDate: utils.$datetime.currentLastDate(),
  });
  const [selectTab, setSelectTab] = useState(0);
  const { TabPane } = Tabs;
  const { RangePicker } = DatePicker;

  const handleConfirmFilter = useCallback(() => {
    form.submit();
  }, [form]);

  const onSubmit = (values) => {
    setIsLoading(true);
    // let fromDate = form.getFieldValue('date')[0];
    // let toDate = form.getFieldValue('date')[1];

    if (selectTab === '0') {
      // Tổng hợp khách hàng mơi
      //   services.satatisticReportService.productInbound_ProductInboundReport(paging.pageNumber, paging.pageSize, fromDate, toDate).then(res =>{
      //     setIsLoading(false);
      //     setTotal(res?.totalCount ?? 0);
      //     setListCustomerSummary(res.items);
      //     if(res.items.length === 0){
      //       message.error("Không tìm thấy sản phẩm")
      //     }
      //     else{
      //       message.success("Tìm kiếm thành công")
      //     }
      //   }).catch(err =>{
      //     setTotal(0);
      //     setListCustomerSummary([]);
      //     setIsLoading(false);
      //   })
    } else if (selectTab === '1') {
      //Tổng hợp khách hàng viếng thăm lần đầu
    } else if (selectTab === '2') {
      //Tổng hợp khách hàng trùng thông tin
    } else {
      //Tổng hợp khách hàng thiếu thông tin
    }
  };

  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    },
    [paging?.pageSize]
  );

  const newCustomerColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      fixed: 'left',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'customerCode',
      key: 'customerCode',
      title: 'Mã khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'customerName',
      key: 'customerName',
      title: 'Tên khách hàng',
      width: 150,
      align: 'center',
    },
    {
      dataIndex: '',
      key: '',
      title: 'Mã tuyến',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tên tuyến',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Khu vực',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Địa chỉ khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Số điện thoại',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tống số đơn hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Ngày đăng ký',
      align: 'center',
      width: 150,
    },
  ];

  const firstTimeVisitColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      fixed: 'left',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'customerCode',
      key: 'customerCode',
      title: 'Mã khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'customerName',
      key: 'customerName',
      title: 'Tên khách hàng',
      width: 150,
      align: 'center',
    },
    {
      dataIndex: '',
      key: '',
      title: 'Mã tuyến',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tên tuyến',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Khu vực',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Địa chỉ khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Số điện thoại',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Thứ tự viếng thăm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Ngày viếng thăm',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Ngày viếng thăm gần nhất',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tổng thời gian viếng thăm',
      align: 'center',
      width: 150,
    },
  ];
  const duplicateCustomerColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      fixed: 'left',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'customerCode',
      key: 'customerCode',
      title: 'Mã khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'customerName',
      key: 'customerName',
      title: 'Tên khách hàng',
      width: 150,
      align: 'center',
    },
    {
      dataIndex: '',
      key: '',
      title: 'Số điện thoại',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Địa chỉ khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Khu vực',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Mã tuyến',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tên tuyến',
      align: 'center',
      width: 150,
    },
  ];
  const missingCustomerColumns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      fixed: 'left',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'customerCode',
      key: 'customerCode',
      title: 'Mã khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: 'customerName',
      key: 'customerName',
      title: 'Tên khách hàng',
      width: 150,
      align: 'center',
    },
    {
      dataIndex: '',
      key: '',
      title: 'Số điện thoại',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Email',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Địa chỉ khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Khu vực',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Mã tuyến',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tên tuyến',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Nhóm khách hàng',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Hình thức kinh doanh',
      align: 'center',
      width: 150,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Kênh bán hàng',
      align: 'center',
      width: 150,
    },
  ];

  const handleBrowseCustomerInformation = useCallback((search) => {
    return services.generalService.customerInformation_GetListCustomer();
  }, []);

  const handleSelectTab = useCallback(
    (value) => {
      setSelectTab(value);
    },
    [setSelectTab]
  );

  const handleChangeRangePicker = useCallback((_, dateString) => {
    setFromToDate({
      fromDate: dateString?.[0],
      toDate: dateString?.[1],
    });
  }, []);

  return (
    <Card>
      <Form
        initialValues={{
          date: [moment(fromToDate.fromDate), moment(fromToDate.toDate)],
        }}
        form={form}
        labelCol={{ span: 24 }}
        onFinish={onSubmit}
      >
        <Row gutter={[8]}>
          <Col lg={8} xl={4}>
            <Form.Item name='purchaseId' label='Mã đơn hàng'>
              <Input placeholder='Nhập mã đơn hàng' />
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='customerID' label='Khách hàng'>
              <BaseSelect
                allowClear
                searchName='CustomerId'
                itemValue='id'
                itemLabel='customerName'
                placeholder='Chọn'
                searchOnMount
                labelInValue
                onSearch={handleBrowseCustomerInformation}
              />
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='saler' label='Người bán'>
              <Input placeholder='Nhập tên người bán' />
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='status' label='Trạng thái'>
              <Select allowClear placeholder='Chọn'>
                {map(utils.$const.SHIPPING_ORDER.STATUS, (stat) => (
                  <Select.Option key={stat.id} value={stat.id}>
                    {stat.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='date' label='Từ ngày - đến ngày'>
              <RangePicker
                format={utils.$const.COMMON.DATE_TIME_FORMAT['YYYY-MM-DD']}
                onChange={handleChangeRangePicker}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' gutter={[8]}>
          <Col>
            <Button
              type='primary'
              onClick={handleConfirmFilter}
              disabled={isLoading}
            >
              <span className='d-flex align-items-center'>
                <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button type='dashed'>
              <span className='d-flex align-items-center'>
                <DownloadOutlined />{' '}
                <span className='ml-1'>Xuất file Excel</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Tabs defaultActiveKey='0' type='card' onChange={handleSelectTab}>
        <TabPane tab='Tổng hợp khách hàng mới' key='0'>
          <Table
            className='newCustomerSummary'
            columns={newCustomerColumns}
            dataSource={newCustomerSummary}
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: paging?.pageNumber ?? 1,
              pageSize: paging?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
            }}
            loading={isLoading}
            bordered
            scroll={{ x: 2200, y: 680 }}
          ></Table>
        </TabPane>
        <TabPane tab='Tổng hợp khách hàng viếng thăm lần đầu' key='1'>
          <Table
            className='firstTimeVisitSummary'
            columns={firstTimeVisitColumns}
            //   dataSource={newCustomerSummary}
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: paging?.pageNumber ?? 1,
              pageSize: paging?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
            }}
            loading={isLoading}
            bordered
            scroll={{ x: 2500, y: 680 }}
          ></Table>
        </TabPane>
        <TabPane tab='Tổng hợp khách hàng trùng thông tin' key='2'>
          <Table
            className='duplicateCustomerSummary'
            columns={duplicateCustomerColumns}
            //   dataSource={newCustomerSummary}
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: paging?.pageNumber ?? 1,
              pageSize: paging?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
            }}
            loading={isLoading}
            bordered
            scroll={{ x: 1500, y: 680 }}
          ></Table>
        </TabPane>
        <TabPane tab='Tổng hợp khách hàng thiếu thông tin' key='3'>
          <Table
            className='missingCustomerSummary'
            columns={missingCustomerColumns}
            //   dataSource={newCustomerSummary}
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: paging?.pageNumber ?? 1,
              pageSize: paging?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
            }}
            loading={isLoading}
            bordered
            scroll={{ x: 2500, y: 680 }}
          ></Table>
        </TabPane>
      </Tabs>
    </Card>
  );
}
