import React, { memo, useCallback, useState, useEffect } from 'react'
import { Row, Col, Select, DatePicker, Button } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'
import classNames from 'classnames'
import { SearchOutlined } from '@ant-design/icons'
import services from "services";

const { Option } = Select

const Fillter = memo(({ className, params, setParams, setFilter, filter }) => {
  const [selectedDistributorId, setSelectedDistributorId] = useState();
  const [filteredDistributors, setFilteredDistributors] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    arrProvinces: [],
    arrDistributors: [],
  });

  const types = [
    {
      id: "CI",
      name: "Check in",
    },
    {
      id: "CO",
      name: "Check out",
    },
  ];

  const getFilter = () => {
    setFilter(!filter)
  }

  const getQuery = useCallback(
    (value, name) => {
      setParams((preState) => {
        let nextState = { ...preState };
        nextState[name] = value;
        return nextState;
      })
    },
    [params],
  ) 

  useEffect(() => {
    // api filter province
    services.organization
    .province_GetAllProvincesWithPermission()
    .then((res) => {
      let arrData = _.map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrProvinces = arrData;
        return nextState;
      });
    })
    .catch((error) => {});

    // api filter distributors
    services.generalService
      .distributor_GetAllDistributorsWithPermission()
      .then((res) => {
        let arrData = _.map(res, (item, index) => {
          item.index = index + 1;
          item.name = item.distributorName;
          return item;
        });
        setDataFilter((preState) => {
          let nextState = { ...preState };
          nextState.arrDistributors = arrData;
          return nextState;
        });
        setFilteredDistributors(arrData);
      })
      .catch((error) => {});
  }, [setDataFilter]);

  const filterDistributors = (value) => {
    if (value) {
      let arr = _.filter(
        _.get(dataFilter, 'arrDistributors'),
        (item, index) => {
          return item?.provinceId === value;
        }
      )
      setFilteredDistributors(arr);
      setSelectedDistributorId(null);
      setParams((preState) => {
        let nextState = { ...preState };
        nextState["distributorId"] = undefined;
        return nextState;
      })
    } else {
      setFilteredDistributors([..._.get(dataFilter, 'arrDistributors')])
    }
  }

  return (
    <div>
      <Row
        gutter={15}
        className={classNames({
          [className]: true,
        })}
      >
        <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Tỉnh</span>
            <Select
              style={{ width: "100%", marginTop: 10 }}
              placeholder="Tỉnh"
              allowClear
              onChange={(e) => {
                getQuery(e, 'provinceId');
                filterDistributors(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(_.get(dataFilter, "arrProvinces"), (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
          <span>Nhà phân phối</span>
          <Select
            value={selectedDistributorId}
            style={{ width: '100%', marginTop: 10 }}
            placeholder="Nhà phân phối"
            allowClear
            onChange={(e) => {
              getQuery(e, 'distributorId');
              setSelectedDistributorId(e);
            }}
          >
            {_.map(filteredDistributors, (item, index) => {
              return (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              )
            })}
          </Select>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
          <span>Nhân viên check</span>
          <Select
            value={_.get(params, 'type')}
            style={{ width: '100%', marginTop: 10 }}
            placeholder="Nhân viên check"
            allowClear
            onChange={(e) => {
              getQuery(e, 'type')
            }}
          >
            {_.map(types, (item, index) => {
              return (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              )
            })}
          </Select>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Ngày xem</span>
            <DatePicker
              placeholder="Ngày xem"
              style={{ width: "100%",marginTop: 10 }}
              value={params.checkDate}
              format={"DD-MM-YYYY"}
              onChange={(e) => {
                getQuery(e, "checkDate");
              }}
            />
          </Col>
      </Row>
      <Row justify="end" style={{marginTop:5}}>
        <Col>
          <Button type="primary" onClick={getFilter} icon={<SearchOutlined />}>
            Tìm kiếm
          </Button>
        </Col>
      </Row>
    </div>
  )
})
export default styled(Fillter)`
  .ant-picker {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .clearParams {
    text-align: right;
  }
`
