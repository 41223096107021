import React, { useCallback, useEffect, useState } from "react";
import { Card, Button, Form, Col, Row, Select, Pagination, Spin, message, DatePicker, Input } from "antd";
import services from "services";
import _ from 'lodash';
import { SearchOutlined, FieldTimeOutlined, UserOutlined, ShopOutlined } from "@ant-design/icons";
import NoImg from './../../images/NoImg.jpg';
import moment from "moment";

export default function VisitImages() {
  const { Option } = Select;

  const [form] = Form.useForm();
  const [params, setParams] = useState({
    customerName: null,
    provinceId: null,
    staffId: null,
    visitTime: null
  });
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({pageNumber: 1, pageSize: 12});
  const [dataFilter, setDataFilter] = useState({
    arrStaffs: [],
    arrProvinces: [],
  });
  const [filteredStaffs, setFilteredStaffs] = useState([]);
  const [listCustomerVisit, setListCustomerVisit] = useState([]);
  const [isShowSpiner, setShowSpiner] = useState(true);

  useEffect(() => {
    setShowSpiner(true);
    services.fileManagement.photo_GetReportImages({      
      customerName: params.customerName,
      provinceId: params.provinceId,
      staffId: params.staffId,
      visitTime: params.visitTime && `${moment(params.visitTime).format('YYYY-MM-DD')}T00:00:00.000Z`,
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize
    }).then(res => {
      setListCustomerVisit(res.items);
      setTotal(res.totalCount);      
    }).catch(error=>{
      message.error("Có lỗi trong quá trình lấy khách hàng ")
    }).finally(() => setShowSpiner(false));
  }, [paging]);

  const handleSubmit = (values) => {
    setParams(values);
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize });
  };
  
  const handleChangePaging = useCallback(
    (pageNumber, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
      setPaging({ pageNumber: currentPage, pageSize });
    }
  ); 

  useEffect(() => {
    // api nhân viên        
    services.userManagementService.resource_GetAllSalersWithPermission().then((res) => {
      let arrData = _.map(res, (item, index) => {
        item.index = index + 1;
        item.id = _.get(item, "userId");
        item.name = _.get(item, "fullName");
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrStaffs = arrData;
        return nextState;
      });
    });

    // api tỉnh
    services.organization
    .province_GetAllProvincesWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });

      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrProvinces = array;
        return nextState;
      });
    })
    .catch((error) => {});
  }, [setDataFilter]);

  const filterStaffs = (value) => {
    if (value) {        
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.provinceId === value;
        },
      )
      form.setFieldsValue({staffId: undefined});
      setFilteredStaffs(arrStaffs);
    } else {
      setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
    }
  }

  useEffect(() => {
    if(dataFilter.arrStaffs != null && dataFilter.arrStaffs.length > 0) {
    setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
    }
  }, [dataFilter]);

  const style = {
    cardImg__height: { height: "250px", with: "100%" }
  }

  return (
    <Card >
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={4} xl={4}>
            <Form.Item name="provinceId" label="Tỉnh">
              <Select
                style={{ width: "100%" }}
                placeholder="Tỉnh"
                allowClear
                onChange={(e) => {
                  filterStaffs(e);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {_.map(_.get(dataFilter, "arrProvinces"), (item, index) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="staffId" label="Nhân viên">
              <Select
                style={{ width: "100%" }}
                placeholder="Nhân viên"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
              >
                {_.map(filteredStaffs, (item, index) => {
                  return (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="customerName" label="Khách hàng">
              <Input placeholder="Tên khách hàng"/>
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item name="visitTime" label="Ngày viếng thăm">
                <DatePicker 
                  style={{ width: "100%" }}                  
                  defaultValue={moment()}
                  placeholder="Chọn"
                />
              </Form.Item>
          </Col>
          <Col style={{ display: "flex", alignItems: "end" }}>
            <Form.Item>
              <Button
                disabled={isShowSpiner}
                type="primary"
                htmlType="submit">
                <span className="d-flex align-items-center">
                  <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                </span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row gutter={16}>
        {listCustomerVisit.map((item, i) =>
        (
          <Col className="gutter-row pb-3" span={6} key={i}>
            <Card
              hoverable
              className="card-image"
              style={style.card}
              cover={
                <img style={style.cardImg__height} alt="Cửa hàng" src={item.fileUrl === null ? NoImg : item.fileUrl} />}
            >
                <div className="d-flex align-items-center">
                  <ShopOutlined /> {" " + item.customerCode + " - " + item.customerName}
                </div>
                <div className="d-flex align-items-center">
                  <FieldTimeOutlined /> {" " + moment(item.visitTime).format("DD-MM-YYYY HH:mm")}
                </div>
                <div className="d-flex align-items-center">
                  <UserOutlined /> {" " + item.staffName}
                </div>
              </Card>
          </Col>
        ))}
      </Row>

      <Row style={{ float: "right", marginTop: "1.5em" }}>
        <Pagination
          total={total ?? 0}
          pageSize={paging.pageSize}
          current={paging.pageNumber}
          onChange={handleChangePaging}
          showSizeChanger= {false}
          showTotal={(total, range) => `${range[0]}-${range[1]} trong ${total} dòng`}
        />
      </Row>
      <Spin style={{ position: "absolute", left: "50%", right: "50%", top: "50%", display: isShowSpiner ? "" : "none" }} size="large" />
    </Card>

  );
}
