import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { map } from 'lodash'
import { useSelector } from 'react-redux'

import ProviderInformationPage from './generalmanagement/ProviderInformation'
import SaleRoutePage from './generalmanagement/SaleRoute'
import SaleChannelPage from './generalmanagement/SaleChannel'
import CustomerInformation from 'pages/customermanagement/CustomerInformation'
import BrandGroups from 'pages/customermanagement/BrandGroups'
import BusinessType from 'pages/customermanagement/BusinessType'
import PaymentMethodPage from './generalmanagement/PaymentMethod'
import UnitTypePage from './generalmanagement/UnitType'
import CurrencyUnitPage from './generalmanagement/CurrencyUnit'
import LoginPage from 'pages/auth/Login'
import UserAccount from 'pages/organization/UserAccount'
import OrganizeDefinition from 'pages/organization/OrganizeDefinition'
import OrganizeChart from 'pages/organization/OrganizeChart'
import RoleManagement from 'pages/organization/TitleManagement'
import Layouts from 'components/shared/layout'
import AppRoute from 'components/shared/route'
import WarehouseInfomation from 'pages/warehouse/warehouseInfo'
import ProductInventory from 'pages/warehouse/productInventory'
import ProductOutbound from './warehouse/productOutbound'
import ProductBrand from './productmanagement/ProductBrand'
import ProductIndustry from './productmanagement/ProductIndustry'
import AuthSlice from 'redux/auth/authSlice'
import ProductType from './productmanagement/ProductType'
import ProductInfo from './productmanagement/ProductInfo'
import ProductInbound from './warehouse/productInbound'
import OrderManagement from './shippingOrder/OrderManagement'
import SellOrderManagement from './shippingOrder/SellOrderManagement'
import AllOrderManagement from './shippingOrder/AllOrderManagement'
import Kpi from './shippingOrder/Kpi'
import Promotion from './shippingOrder/Promotion'
import DistributorInformationPage from './productmanagement/DistributorInformation'
import ImagesDisplayManagement from './monitoringmanagement/ImagesDisplayManagement'
import VisitImages from './monitoringmanagement/VisitImages'
import ProblemMonitoring from './monitoringmanagement/ProblemMonitoring'
import VisitComments from './monitoringmanagement/visitComments'
import SalesRouteManagement from './monitoringmanagement/SalesRouteManagement'
import TargetMonitoring from './monitoringmanagement/TargetMonitoring'
import VisitMonitoring from './monitoringmanagement/VisitMonitoring'
import SaleRouteMonitoring from './monitoringmanagement/SaleRouteMonitoring'
import StatisticCustomerSummaryPage from './statisticmanagement/StatisticCustomerSummary'
import StatisticProductInboundPage from './statisticmanagement/StatisticProductInbound'
import StatisticProductOutboundPage from './statisticmanagement/StatisticProductOutbound'
import StatisticShippingOrderPage from './statisticmanagement/StatisticShippingOrder'
import StatisticPurchaseOrderPage from './statisticmanagement/StaticPurchaseOrder'
import PurchaseOrderManagementPage from './shippingOrder/PurchaseOrderManagement'
import AddUpdateOrder from './shippingOrder/AddUpdateOrder'
import PermissionPage from './accessmanagement/Permission'
import RolePage from './accessmanagement/Role'
import MenuControlPage from './accessmanagement/MenuControl'
import RouteCustomer from './generalmanagement/RouteCustomer'
import Dashboard from 'components/dashboard'
import MapStore from 'components/map'
import MapCheckIn from 'components/mapCheckin'
import MapCheckinAll from 'components/mapCheckinAll'
import MapCheckinStaff from 'components/mapCheckinStaff'
import ProductMap from 'components/productMap'
import ProductMapWard from 'components/productMapWard'
import Themxa from 'components/themxa'
import ActionControlPage from './accessmanagement/ActionControl'
import SaleSummaryReport from './report/saleSummaryReport'
import ReportKpi from './report/kpi'
import ReportKpiDaily from './report/kpi-daily'
import ReportTimekeeping from './report/timeKeepingMonth'
import ReportVisitMonitoring from './report/visit-monitoring'
import Customer from './report/customer'
import CustomerSummaryReport from './report/customerSummaryReport'
import AttendanceSummary from './report/AttendanceSummary'
import PromotionReport from './report/promotionReport'
import StaticInventoryPage from './statisticmanagement/StaticInventory'
import AuthorizeAccessPage from './accessmanagement/AuthorizeAccess'
import OutboundModal from 'components/modals/OutboundModal'
import InboundModal from 'components/modals/InboundModal'
import AreaMonitoringPage from './organization/AreaMonitoring'
import CustomerTurnoverByMonth from './report/CustomerTurnoverByMonth'

const routesConfig = [
  // Unauthenticated Routes
  {
    path: '/auth/login',
    exact: true,
    component: LoginPage,
    layout: Layouts.PublicLayout,
  },
  // Authenticated Routes
  {
    path: '/organization/user-account',
    component: UserAccount,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/organization/organize-definition',
    component: OrganizeDefinition,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/organization/organizeChart',
    component: OrganizeChart,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/organization/title-management',
    component: RoleManagement,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/warehouse/warehouse-information',
    component: WarehouseInfomation,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/warehouse/productInventory',
    component: ProductInventory,
    layout: Layouts.PrivateLayout,
  },
  // Customer management
  {
    path: '/customer-management/customer-information',
    component: CustomerInformation,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/customer-management/customer-groups',
    component: BrandGroups,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/customer-management/business-type',
    component: BusinessType,
    layout: Layouts.PrivateLayout,
  },
  // Statistic Management
  {
    path: '/statistic-management/customer-summary',
    component: StatisticCustomerSummaryPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/statistic-management/statistic-product-inbound',
    component: StatisticProductInboundPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/statistic-management/statistic-product-outbound',
    component: StatisticProductOutboundPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/statistic-management/inventory-statistic',
    component: StaticInventoryPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/statistic-management/statistic-shipping-order',
    component: StatisticShippingOrderPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/statistic-management/statistic-purchase-order',
    component: StatisticPurchaseOrderPage,
    layout: Layouts.PrivateLayout,
  },
  // Access Management
  {
    path: '/access-management/permission',
    component: PermissionPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/access-management/role',
    component: RolePage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/access-management/menu-control',
    component: MenuControlPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/access-management/authorize-access',
    component: AuthorizeAccessPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/access-management/action-control',
    component: ActionControlPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/sale-route-management',
    component: RouteCustomer,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/customer-management/sale-route-management-new',
    component: SaleRouteMonitoring,
    layout: Layouts.PrivateLayout,
  },
  // General Management
  {
    path: '/product-management/provider-information',
    component: ProviderInformationPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/customer-management/sale-route',
    component: SaleRoutePage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/customer-management/sale-channel',
    component: SaleChannelPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/general-management/payment-method',
    component: PaymentMethodPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/general-management/unit-type',
    component: UnitTypePage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/general-management/currency-unit',
    component: CurrencyUnitPage,
    layout: Layouts.PrivateLayout,
  },
  //product management
  {
    path: '/organization/distributor-information',
    component: DistributorInformationPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/product-management/product-brand',
    component: ProductBrand,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/product-management/product-industry',
    component: ProductIndustry,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/product-management/product-type',
    component: ProductType,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/product-management/product-info',
    component: ProductInfo,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/warehouse/product-outbound/create',
    component: OutboundModal,
    layout: Layouts.PrivateLayout,
    exact: true,
  },
  {
    path: '/warehouse/product-outbound/edit/:id',
    component: OutboundModal,
    layout: Layouts.PrivateLayout,
    exact: true,
  },
  {
    path: '/warehouse/product-outbound/detail/:id',
    component: OutboundModal,
    layout: Layouts.PrivateLayout,
    exact: true,
  },
  {
    path: '/warehouse/product-outbound',
    component: ProductOutbound,
    layout: Layouts.PrivateLayout,
    exact: true,
  },

  {
    path: '/warehouse/product-inbound/create',
    component: InboundModal,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/warehouse/product-inbound/edit/:id',
    component: InboundModal,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/warehouse/product-inbound/detail/:id',
    component: InboundModal,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/warehouse/product-inbound',
    component: ProductInbound,
    layout: Layouts.PrivateLayout,
  },

  // ShippingOrder
  {
    path: '/sale-management/purchase-order',
    exact: true,
    component: OrderManagement,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/sale-management/purchase-order/new',
    component: AddUpdateOrder,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/sale-management/sell-order/',
    component: SellOrderManagement,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/sale-management/list-order/',
    component: AllOrderManagement,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/sale-management/purchase-order-manangement',
    component: PurchaseOrderManagementPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/sale-management/kpi',
    component: Kpi,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/sale-management/promotion',
    component: Promotion,
    layout: Layouts.PrivateLayout,
  },

  //Monitoring
  // {
  //   path: '/monitoring/customer-location',
  //   component: LocationMonitoring,
  //   layout: Layouts.PrivateLayout,
  // },
  {
    path: '/monitoring/sale-target',
    component: TargetMonitoring,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/visit-control',
    component: VisitMonitoring,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/customer-problem',
    component: ProblemMonitoring,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/visit-comments',
    component: VisitComments,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/images-control',
    component: VisitImages,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/images-gallery',
    component: ImagesDisplayManagement,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/ql-giamsat/ql-tuyenbanhang',
    component: SalesRouteManagement,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/dashboard/area-monitoring',
    component: AreaMonitoringPage,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/customer-location',
    component: MapStore,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/location-checkin',
    component: MapCheckIn,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/location-checkin-all',
    component: MapCheckinAll,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/location-checkin-staff',
    component: MapCheckinStaff,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/product-map',
    component: ProductMap,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/monitoring/product-map-ward',
    component: ProductMapWard,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/themxa',
    component: Themxa,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/sale-summary-report',
    component: SaleSummaryReport,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/kpi',
    component: ReportKpi,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/kpi-daily',
    component: ReportKpiDaily,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/timekeeping',
    component: ReportTimekeeping,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/visit-monitoring',
    component: ReportVisitMonitoring,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/customer',
    component: Customer,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/customer-summary-report',
    component: CustomerSummaryReport,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/attendance-summary',
    component: AttendanceSummary,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/customer-turnoverbymonth',
    component: CustomerTurnoverByMonth,
    layout: Layouts.PrivateLayout,
  },
  {
    path: '/report-management/promotion-report',
    component: PromotionReport,
    layout: Layouts.PrivateLayout,
  }
  // {
  //   path: "/",
  //   component: Dashboard,
  //   layout: Layouts.PrivateLayout,
  // },
]

const AppRoutes = function () {
  const isAuthenticated = useSelector(
    AuthSlice.seletors.makeSelectIsAuthenticated
  )
  return (
    <BrowserRouter>
      <Switch>
        {map(routesConfig, (route) => (
          <AppRoute
            key={route.path}
            isAuthenticated={isAuthenticated}
            {...route}
          />
        ))}
        <Route exact path='/'>
          <Redirect to='/dashboard' />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
export default AppRoutes
