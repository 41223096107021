import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "redux/store";
import AppRoutes from "pages/routes";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';
import 'path/to/font-awesome/css/font-awesome.min.css';

import "styles/themes.less";
import "styles/index.scss";
import "styles/app.less";


ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={viVN}>
        <AppRoutes />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
