/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 01/06/2021.
 * @description Modal Thêm/Sửa kênh bán hàng
 * */

import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import PropTypes from 'prop-types';
import services from 'services';
import TextArea from 'antd/lib/input/TextArea';


const ChannelModal = function ({ visible, mode, onCancel, dataItem, onReloadPage }) {
  const [form] = Form.useForm();

  const [isContinue, setIsContinue] = useState(false);

  const handleConfirm = useCallback(() => {
    setIsContinue(false);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleConfirmAndContinue = useCallback(() => {
    setIsContinue(true);
    setTimeout(() => form.submit(), 400);
  }, [form]);


  const handleSubmit = useCallback((items) => {
    if (mode === "edit") {
      services.generalService.saleChannel_Update(items)
        .then((res) => {
          setTimeout(() => {
            message.success("Lưu thành công.");
          }, 300);
          onReloadPage();
          onCancel();
          form.resetFields();
        }).catch((err) => {
          message.error("Lưu không thành công, hãy thử lại.");
        })
    } else if (mode === "add") {
      services.generalService.saleChannel_Create(items)
        .then((res) => {
          setTimeout(() => {
            message.success("Lưu thành công.");
          }, 300);
          onReloadPage();
          if (isContinue) {
            form.resetFields();
          } else {
            onCancel();
            form.resetFields();
          }
        }).catch((err) => {
          message.error("Lưu không thành công, hãy thử lại.");
        })
    }
  }, [mode, onReloadPage, onCancel, form, isContinue]);

  const renderTitle = () =>
    mode === "add" ? `Thêm mới kênh bán hàng` : ` Chỉnh sửa kênh bán hàng`;

  useEffect(() => {
    const initValues = {
      id: dataItem.id,
      currentState: dataItem.currentState,
      saleChannelCode: dataItem.saleChannelCode,
      saleChannelName: dataItem.saleChannelName,
      status: dataItem.status,
      notes: dataItem.notes,
    };
    form.setFieldsValue(initValues);
  }, [dataItem, form]);

  const handleCloseModal = useCallback(() => {
    onCancel && onCancel();
    form.resetFields();
  }, [form, onCancel]);

  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      onCancel={onCancel}
      footer={[
        <Form.Item>
          <Button onClick={handleConfirm} type="primary">
            {dataItem?.id ? "Sửa" : "Thêm"}
          </Button>
          {mode === "add" ?
            <Button
              title='Thêm và tiếp tục'
              key='submitAndContinute'
              type="primary"
              onClick={handleConfirmAndContinue}
            >
              Thêm và tiếp tục
            </Button> : ''
          }
          <Button type='default' onClick={handleCloseModal}>
            Quay lại
          </Button>
        </Form.Item>
      ]}>
      <Form initialValues={dataItem} onFinish={handleSubmit} form={form}>
        <Form.Item
          label="Mã kênh"
          required
          name="saleChannelCode"
          rules={[{ required: true, message: "Mã kênh không được trống" }]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Nhập mã kênh" />
        </Form.Item>
        <Form.Item
          label="Tên kênh"
          name="saleChannelName"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Nhập tên kênh" />
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="status"
          labelCol={{ span: 6 }}
        >
          <Select placeholder="Chọn trạng thái">
            <Select.Option value={1}>Hoạt động</Select.Option>
            <Select.Option value={0}>Không hoạt động</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Ghi chú"
          name="notes"
          labelCol={{ span: 6 }}
        >
          <TextArea placeholder="Nhập ghi chú" rows={5} />
        </Form.Item>
        <Form.Item hidden name="id">
          <Input disabled></Input>
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled></Input>
        </Form.Item>
      </Form>
    </Modal>

  );
};
ChannelModal.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string
};

export default ChannelModal;