/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 20/09/2021.
 * @description Màn hình `Thông tin hình thức thanh toán`. Route `/generalManagement/PaymentMethod`
 * */

import { useCallback, useState, useEffect } from "react";
import { Card, Button, Table, message, Row, Col, Input, Form } from "antd";
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteFilled } from "@ant-design/icons";
import PaymentMethodModal from "components/modals/PaymentMethodModal";
import services from "services";
import APP_CONST from "utils/constants";
import ConfirmModal from "components/modals/confirm";

const PaymentMethodPage = function (){
    const [form] = Form.useForm();
    const [data, setData] = useState([]);

    const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [isReloadPage, setIsReloadPage] = useState(false);

    useEffect(() => {
      setIsLoading(true);
      services.generalService.paymentMethod_GetBsWithPagination(paging?.pageNumber, paging?.pageSize)
      .then((res)=>{
        setIsLoading(false);
        setTotal(res?.totalCount ?? 0);
        setData(res.items);
      })
      .catch((error)=>{
        setTotal(0);
        setIsLoading(false);
      })
    }, [paging,isReloadPage]);

    const columns = [
        {
            title: "Mã thanh toán",
            dataIndex: "paymentCode",
            key:"code",
            width: 200,
        },
        {
            title: "Hình thức thanh toán",
            dataIndex: "paymentName",
            key:"name",
            width: 400,
        },
        {
            title: "Mô tả",
            dataIndex: "notes",
            key:"notes",
            width: 500,
        },
        {
          title: "Trạng thái",
          dataIndex: "status",
          key:"status",
          align: "center",
          width: 150,
          ellipsis: true,
          render: (value) => (
            <div className={`status--${value ? "active" : "inactive"}`}>
              {
                APP_CONST?.PAYMENT_METHOD?.STATUS?.find(
                  (status) => status?.id === value
                )?.name
              }
            </div>
          ),
        },
        {
            dataIndex: "action",
            key: "action",
            title: "#",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_, row) => (
              <div className="warehouseInfo-card__button">
                <Button
                    type="primary"
                    size="small"
                    onClick={handleEditPaymentMethod(row)}
                >
                    <span className="d-flex align-items-center">
                      <EditOutlined /> <span className="ml-1"></span>
                    </span>
                </Button>
                <Button
                    className="warehouseInfo-card__button--right"
                    type="danger"
                    size="small"
                    onClick={handleDeletePaymentMethod(row)}
                >
                    <span className="d-flex align-items-center">
                      <DeleteFilled /> <span className="ml-1"></span>
                    </span>
                </Button>
                </div>
            ),
        },
    ];
    
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
    const [isOpenPaymentMethodModal, setIsOpenPaymentMethodModal] = useState(false);
    const [paymentMethodModalMode, setPaymentMethodModalMode] = useState("add");
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    const handleCreatePayment = useCallback(() => {
    setPaymentMethodModalMode("add");
    setIsOpenPaymentMethodModal(true);
    setSelectedPaymentMethod([]);
  }, []);
  
  const handleEditPaymentMethod = useCallback(
    (provider) => (e) => {
      setPaymentMethodModalMode("edit");
      setIsOpenPaymentMethodModal(true);
      setSelectedPaymentMethod(provider);
    },
    [setSelectedPaymentMethod, setPaymentMethodModalMode, setIsOpenPaymentMethodModal]
  );

  const handleCancelPaymentMethod = useCallback(() => {
    setIsOpenPaymentMethodModal(false);
    setPaymentMethodModalMode({});
  }, [setPaymentMethodModalMode, setIsOpenPaymentMethodModal]);

const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedPaymentMethod({});
  }, [setSelectedPaymentMethod, setIsReloadPage, isReloadPage]);
  
  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    },
    [paging]
  );

  const handleDeletePaymentMethod = useCallback(
    (payment) => () => {
      setSelectedPaymentMethod(payment);
      setIsShowConfirmModal(true);
    },
    [setSelectedPaymentMethod, setIsShowConfirmModal]
  );

  const handleApproveToDeletePayment = useCallback(() => {
    services.generalService
      .paymentMethod_Delete([{
        id: selectedPaymentMethod?.id,
        currentState: selectedPaymentMethod?.currentState
      }])
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedPaymentMethod({});
        handleReloadPage();
      })
      .catch((err) => {
        message.error(err?.message || "Xóa kho thất bại");
      });
  }, [handleReloadPage, selectedPaymentMethod]);

  const handleRejectToDeletePayment = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedPaymentMethod({});
  }, [setSelectedPaymentMethod]);
  
  const handleSubmit = (values) => {
    setIsLoading(true);
    let filter = {
      ...form.getFieldsValue(),
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize
    };
    services.generalService.paymentMethod_FilterPaymentMethod(filter)
    .then(res =>{
      setIsLoading(false);
      setData(res.items);
      setTotal(res?.totalCount ?? 0);
      if (res.items.length === 0) {
        message.error("Không tìm thấy thông tin");
      }
      else {
        message.success("Tìm kiếm thành công");
      }
    })
    .catch(err =>{
      setTotal(0);
      setIsLoading(false);
    })
  };

  return (
    <Card className="PaymentMethodPage">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={6}>
            <Form.Item name='paymentCode' label='Mã thanh toán'>
              <Input placeholder='Nhập mã thanh toán' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name='paymentName' label='Tên thanh toán'>
              <Input placeholder='Nhập tên thanh toán' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item className='general-button--height' label=' '>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                >
                  <span className='d-flex align-items-center'>
                    <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
                  </span>
                </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height ' label=' '>
              <Button onClick={handleCreatePayment}>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Tạo mới</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Export excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Import excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>         
        </Row>
      </Form>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 1200 }}
        size="small"
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        }}
        loading={isLoading}
        locale={{ emptyText: "Không có hình thức thanh toán" }}
      />
      <PaymentMethodModal
        visible={isOpenPaymentMethodModal}
        mode = {paymentMethodModalMode}
        onCancel = {handleCancelPaymentMethod}
        dataItem = {selectedPaymentMethod}
        onReloadPage={handleReloadPage}
      />
      <ConfirmModal
        title="Xóa kênh thanh toán"
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeletePayment}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa{" "}
          <strong>{selectedPaymentMethod?.paymentName}</strong> không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleRejectToDeletePayment}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeletePayment}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Card>
  );
};
export default PaymentMethodPage;