import { useEffect, useState, useCallback } from "react";
import { message, Table } from "antd";
import services from "services";
import moment from "moment";
import get from 'lodash';

function GeneralSummary({ params, paging, setPaging, loading, setLoading }) {
  const [listCustomer, setListCustomer] = useState([]);
  const [total, setTotal] = useState(0);

  const handleChangePaging = useCallback(
    (pageNumber, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
      setPaging({ pageNumber: currentPage, pageSize });
    }
  ); 
  
  const handleChangeTable = (pagination) => () => {
    const pageNumber = get(pagination, "pageNumber", 1);
    const pageSize = get(pagination, "pageSize", 10);
    setPaging({ pageNumber, pageSize });
  }

  useEffect(() => {
    setLoading(true);
    services.generalService
      .customerInformation_ReportCustomerTurnoverByMonth({
        customerName: params.customerName,
        staffId: params.staffId,
        provinceId: params.provinceId,
        regionId: params.regionId,
        pageNumber: paging.pageNumber,
        pageSize: paging.pageSize,
        month: params.month && moment(params.month).format('MM'),
        year: params.month && moment(params.month).format('YYYY'),
      })
      .then((res) => {
        setListCustomer(res?.items || []);
        setTotal(res?.totalCount);
      })
      .catch((err) => {
        message.error("Lấy sản lượng của khách hàng theo tháng thất bại");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paging]);

  const summaryColumns = [
    {
      dataIndex: "no",
      key: "no",
      title: "STT",
      align: "center",
      fixed: "left",
      width: 18,
      render: (value, row, index) => ((paging.pageNumber - 1) * paging.pageSize) + index + 1,
    },
    {
      dataIndex: "staffCode",
      key: "staffCode",
      title: "Mã NV",
      width: 30,
    },
    {
      dataIndex: "staffName",
      key: "staffName",
      title: "Nhân viên",
      width: 60,
    },
    {
      dataIndex: "customerCode",
      key: "customerCode",
      title: "Mã KH",
      width: 50,
    },
    {
      dataIndex: "customerName",
      key: "customerName",
      title: "Tên KH",
      width: 60,
    },
    {
      dataIndex: "turnoverFormatted",
      key: "turnoverFormatted",
      title: "Sản lượng",
      width: 40,
    },
    {
      dataIndex: "phonenumber",
      key: "phonenumber",
      title: "SĐT",
      align: "right",
      width: 40,
    },
    {
      dataIndex: "address",
      key: "address",
      title: "Địa chỉ",
      width: 100,
    },
    {
      dataIndex: "customerCreatedDate",
      key: "customerCreatedDate",
      title: "Ngày tạo KH",
      align: "right",
      width: 35,
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : "")
    }
  ];

  return (
    <Table
      columns={summaryColumns}
      dataSource={listCustomer}
      onChange={handleChangeTable}
      loading={loading}
      pagination={{
        total: total ?? 0,
        pageSizeOptions: [10, 20, 50],
        current: paging?.pageNumber ?? 1,
        pageSize: paging?.pageSize ?? 10,
        onChange: handleChangePaging,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} trong ${total} dòng`,
      }}
      locale={{ emptyText: "Không có khách hàng nào" }}
      bordered
      size="small"
      scroll={{ x: 1200, y: 680 }}
    />
  );
}

export default GeneralSummary;
