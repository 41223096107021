/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Màn hình `Danh sách nhân viên`. Route `/organization/user-account`
 * */

import React, { useCallback, useState, useEffect } from "react";
import {
  Card,
  Button,
  Input,
  Table,
  Row,
  Col,
  Form,
  Select,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import UserModal from "components/modals/UserModal";
import utils from "utils";
import services from "services";

const UserAccountPage = function () {
  const [form] = Form.useForm();
  const [fetchingListRegion, setfetchingListRegion] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [total, setTotal] = useState(0);
  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  const [filterCriteria, setFilterCriteria] = useState({
    keyword: null,
    distributorId: null,
    status: null});
  const [addUserModalConfig, setAddUserModalConfig] = useState({
    visible: false,
    mode: utils.$const.COMMON.MODAL_MODE.ADD,
    dataSource: {},
  });

  const [listDistributor, setListdistributor] = useState([]);

  const handleBrowseUsers = useCallback((param) => {
    setfetchingListRegion(true);
    let filter = {
      keyword: filterCriteria?.keyword,
      distributorId: filterCriteria?.distributorId,
      status: filterCriteria?.status,
      pageNumber: paging.page,
      pageSize: paging.pageSize,
    };
    services.organization
      .humanResource_FindUserWithFilter(filter)
      .then((res) => {
        setfetchingListRegion(false);
        setAccounts(res.items);
        setTotal(res?.totalCount ?? 0);
      })
      .catch((err) => {
        setTotal(0);
        setfetchingListRegion(false);
      })
      .finally(() => setfetchingListRegion(false));
  }, [paging]);

  const handleOpenAddUserModal = useCallback((config) => {
    setAddUserModalConfig((prev) => ({ ...prev, ...config }));
  }, []);

  const handleCancelAddUserModal = useCallback(() => {
    setAddUserModalConfig({
      visible: false,
      dataSource: {},
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    handleBrowseUsers({});
  }, [handleBrowseUsers]);

  useEffect(() => {
    handleBrowseUsers({});
  }, [handleBrowseUsers]);

  const handleChangePaging = (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    };

  const columns = [
    {
      title: "Mã nhân viên",
      dataIndex: "userCode",
      key: "userCode",
    },
    {
      title: "Tên nhân viên",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_, record) =>
        record.identifier ? record.identifier.phoneNumber : "",
    },
    {
      title: "Nhà phân phối",
      dataIndex: "distributorName",
      key: "distributorName",
    },
    {
      title: "Chức danh",
      dataIndex: "titleReferenceName",
      key: "titleReferenceName",
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "userName",
      key: "userName",
      render: (_, record) =>
        record.identifier ? record.identifier.userName : "",
    },
    {
      title: "Giới tính",
      dataIndex: "genderType",
      key: "genderType",
      align: "center",
      render: (value, record) => (value === 0 ? "Nam" : "Nữ"),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      render: (value) => {
        var findedStatus = utils.$const.USER.STATUS.find((x) => x.id === value);
        return (
          <span style={{ color: findedStatus?.color }}>
            {findedStatus?.name}
          </span>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      width: 50,
      render: (_, row) => (
        <EditOutlined
          className="text-primary"
          onClick={() =>
            handleOpenAddUserModal({
              dataSource: row,
              visible: true,
              mode: utils.$const.COMMON.MODAL_MODE.EDIT,
            })
          }
        />
      ),
    },
  ];

  const handleSubmit = (values) => {
    setFilterCriteria({
      keyword: values?.keyword,
      distributorId: values?.distributorObj?.key,
      status: values?.status
    });
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
  };

  useEffect(() => {
    services.generalService
      .distributor_GetBsWithPagination(1, 100)
      .then((res) => setListdistributor(res.items));
  }, [setListdistributor]);

  const listStatus = [
    {
      id: 1,
      status: 1,
      statusName: "Hoạt động",
    },
    {
      id: 2,
      status: 0,
      statusName: "Không hoạt động",
    },
  ];

  return (
    <Card size="small" className="userAccountPage h-100">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }} initialValues={{status: 1}}>
        <Row gutter={15}>
          <Col lg={24} xl={6}>
            <Form.Item name="distributorObj" label="Nhà phân phối">
              <Select
                placeholder="Chọn nhà phân phối"
                showSearch
                showArrow={true}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue
                allowClear
              >
                {listDistributor.map((distributor, id) => (
                  <Select.Option key={id} value={distributor?.id}>
                    {distributor?.distributorName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name="keyword" label="Nhập từ khóa">
              <Input placeholder="Nhập từ khóa" />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name="status" label="Trạng thái">
              <Select defaultValue={1} allowClear placeholder="Chọn trạng thái">
                {listStatus.map((item, i) => (
                  <Select.Option key={i} value={item?.status}>
                    {item?.statusName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15} justify="end" style={{marginBottom:5}}>
        <Col style={{ flexGrow: 1 }} >
            Cây đơn vị ({total} thành viên)
        </Col>
        <Col >
            {/* <Form.Item className="general-button--height" label=" "> */}
              <Button
                type="primary"
                htmlType="submit"
                disabled={fetchingListRegion}
              >
                <span className="d-flex align-items-center">
                  <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                </span>
              </Button>
            {/* </Form.Item> */}
          </Col>
          <Col >
            {/* <Form.Item className="general-button--height " label=" "> */}
              <Button
                className="create-button"
                onClick={() =>
                  handleOpenAddUserModal({
                    visible: true,
                    mode: utils.$const.COMMON.MODAL_MODE.ADD,
                    dataSource: {},
                  })
                }
              >
                <span className="d-flex align-items-center">
                  <PlusOutlined className="mr-1" /> Thêm mới
                </span>
              </Button>
            {/* </Form.Item> */}
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        columns={columns}
        dataSource={accounts}
        scroll={{ x: 1200 }}
        size="small"
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`
        }}
        loading={fetchingListRegion}
        locale={{ emptyText: "Không có dữ liệu" }}
      />
      <UserModal
        config={addUserModalConfig}
        onCancel={handleCancelAddUserModal}
        listDistributor={listDistributor}
      />
    </Card>
  );
};
export default UserAccountPage;
