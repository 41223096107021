import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  Row,
  Form,
  Button,
  Input,
  Col,
  Divider,
  Table,
  Select,
  DatePicker,
  message,
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  UnorderedListOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useEffect } from 'react';
import services from 'services';
import utils from 'utils';
import { map, get, omit } from 'lodash';

const actions = {
  APPROVE: 'approve',
  CANCEL: 'cancel',
};

export default function ProductInbound() {
  const [form] = Form.useForm();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isReloadPage, setIsReloadPage] = useState(false);
  const [total, setTotal] = useState(0);

  const [listWarehouse, setListWarehouse] = useState([]);
  const [listCustomer, setListCustomer] = useState([]);
  const [listProvider, setListProvider] = useState([]);

  const [listProductInbound, setListProductInbound] = useState([]);

  const [params, setParams] = useState({ page: 1, pageSize: 10 });

  useEffect(() => {
    const getListCustomer = () => {
      services.generalService
        .customerInformation_GetListCustomer()
        .then((res) => setListCustomer(res))
        .catch(() => setListCustomer([]));
    };
    getListCustomer();
  }, []);

  useEffect(() => {
    const getListWarehouse = () => {
      services.generalService
        .warehouses_GetAllWarehouse()
        .then((res) => setListWarehouse(res))
        .catch(() => setListWarehouse([]));
    };
    getListWarehouse();
  }, []);

  useEffect(() => {
    const getListProvider = () => {
      services.generalService
        .providerInformation_GetAllProvider()
        .then((res) => setListProvider(res))
        .catch(() => setListProvider([]));
    };
    getListProvider();
  }, []);

  const handleSubmit = (values) => {
    setParams({ ...params, ...values });
  };

  useEffect(() => {
    const newParams = omit(params, ['page', 'pageSize']);
    if (Object.values(newParams).length === 0) {
      handleGetListProductInbound();
    } else {
      handleFilterListInbound();
    }
  }, [params, isReloadPage]);

  const handleFilterListInbound = () => {
    setLoading(true);
    services.warehouseService
      .productInbound_GetProductInboundFilterPaging({
        pageNumber: 1,
        pageSize: params?.pageSize,
        fromDate: params?.fromDate,
        documentCode: params?.documentCode,
        documentType: params?.documentType,
        status: params?.status,
        customerId: params?.customerId,
        toDate: params?.toDate,
        toWarehouseId: params?.toWarehouseId,
      })
      .then((res) => {
        setTotal(res?.totalCount ?? 0);
        setListProductInbound(res?.items ?? []);
      })
      .catch(() => message.error('Lấy thông tin phiếu xuất thất bại'))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetListProductInbound = () => {
    setLoading(true);
    const pageNumber = get(params, 'page', 1);
    const pageSize = get(params, 'pageSize', 20);
    services.warehouseService
      .productInbound_GetBsWithPagination(pageNumber, pageSize)
      .then((res) => {
        const items = get(res, 'items');
        const total = get(res, 'totalCount');
        setListProductInbound(items);
        setTotal(total);
      })
      .catch((err) => {
        message.error('Không tìm thấy thông tin nhập hàng');
        setListProductInbound([]);
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'documentCode',
      key: 'documentCode',
      title: 'Mã phiếu',
      width: 200,
      render: (value, row) => (
        <div className='d-flex align-items-center justify-content-between'>
          <span className='d-flex align-items-center'>{value}</span>
        </div>
      ),
    },
    {
      dataIndex: 'documentType',
      key: 'documentType',
      title: 'Hình thức nhập',
      align: 'center',
      width: 250,
      render: (text, row, index) => renderDocumentType(text),
    },
    {
      dataIndex: 'inDate',
      key: 'inDate',
      title: 'Ngày nhập',
      width: 200,
      align: 'center',
      render: (value) => utils.$datetime.formatDatetime(value),
    },
    // {
    //   dataIndex: 'providerIDObj',
    //   key: 'providerIDObj',
    //   title: 'Nhà cung cấp',
    //   align: 'center',
    //   width: 250,
    //   render: (text, row, index) => row?.provider?.providerName,
    // },
    {
      dataIndex: 'customer',
      key: 'customer',
      title: 'Khách hàng',
      width: 250,
      render: (text, row, index) => row.customer?.customerName,
    },
    {
      dataIndex: 'fromWarehouse',
      key: 'fromWarehouse',
      title: 'Từ kho',
      width: 250,
      render: (text, row, index) => {
        return text?.warehouseName;
      },
    },
    {
      dataIndex: 'toWarehouse',
      key: 'toWarehouse',
      title: 'Đến kho',
      width: 250,
      render: (text, row, index) => {
        return text?.warehouseName;
      },
    },
    // {
    //   dataIndex: 'notes',
    //   key: 'notes',
    //   title: 'Ghi chú',
    //   width: 500,
    // },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Trạng thái',
      align: 'center',
      fixed: 'right',
      width: 200,
      render: (value, row) => {
        const curStatus = utils.$const.INBOUND.STATUS.find(
          (x) => x.id === value
        );
        return curStatus ? (
          <div className={`status__outbound status--${curStatus?.color}`}>
            {curStatus?.name}
          </div>
        ) : null;
      },
    },
    {
      dataIndex: 'action',
      key: 'action',
      title: '#',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (_, row) => (
        <div className='inbound-card__button'>
          {row?.status === utils.$const.INBOUND.STATUS[0].id && (
            <Button
              onClick={() =>
                history.push(`/warehouse/product-inbound/edit/${row?.id}`)
              }
              type='primary'
              icon={<EditOutlined className='inbound-card__icon' />}
            />
          )}
          <Button
            icon={
              <UnorderedListOutlined className='inbound-card__button--right' />
            }
            type='info'
            onClick={() =>
              history.push(`/warehouse/product-inbound/detail/${row?.id}`)
            }
          />
        </div>
      ),
    },
  ];

  const renderDocumentType = (text) => {
    if (text === 'CustomerReturn') {
      return 'Nhập theo đơn hàng';
    } else if (text === 'WarehouseTransferring') {
      return 'Nhập chuyển kho';
    } else if (text === 'EditQuantity') {
      return 'Nhập điều chỉnh';
    }
  };

  const handleChangePaging = (page, pageSize) => {
    const currentPage = pageSize !== params?.pageSize ? 1 : page;
    setParams({ ...params, page: currentPage, pageSize });
  };

  return (
    <Card className='ProductInboundPage'>
      <Form onFinish={handleSubmit} form={form} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='documentCode' label='Mã phiếu nhập'>
              <Input placeholder='Nhập mã'></Input>
            </Form.Item>
          </Col>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='customerId' label='Mã khách hàng'>
              <Select placeholder='Chọn khách hàng' allowClear>
                {map(listCustomer, (customer) => (
                  <Select.Option key={customer?.id} value={customer?.id}>
                    {customer?.customerName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='documentType' label='Hình thức nhập'>
              <Select placeholder='Hình thức nhập'>
                {map(utils.$const.INBOUND.DOCUMENT_TYPE, (stat) => (
                  <Select.Option key={stat.id} value={stat.value}>
                    {stat.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='providerID' label='Nhà cung cấp'>
              <Select placeholder='Chọn nhà cung cấp' allowClear>
                {map(listProvider, (provider) => (
                  <Select.Option key={provider?.id} value={provider?.id}>
                    {provider?.providerName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='fromDate' label='Từ ngày'>
              <DatePicker style={{ width: '100%' }} placeholder='Chọn ngày' />
            </Form.Item>
          </Col>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='toDate' label='Đến ngày'>
              <DatePicker style={{ width: '100%' }} placeholder='Chọn ngày' />
            </Form.Item>
          </Col>

          <Col span={6} lg={8} xl={6}>
            <Form.Item name='toWarehouseId' label='Đến kho'>
              <Select placeholder='Chọn kho' allowClear>
                {map(listWarehouse, (warehouse) => (
                  <Select.Option key={warehouse?.id} value={warehouse?.id}>
                    {warehouse?.warehouseName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6} lg={8} xl={6}>
            <Form.Item name='status' label='Trạng thái'>
              <Select placeholder='Nhập trạng thái'>
                {map(utils.$const.INBOUND.STATUS, (stat) => (
                  <Select.Option key={stat.id} value={stat.id}>
                    {stat.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' gutter={[8]}>
          <Col>
            <Button
              onClick={() => history.push('/warehouse/product-inbound/create')}
            >
              <span className='d-flex align-items-center'>
                <PlusOutlined /> <span className='ml-1'>Tạo phiếu</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button type='primary' htmlType='submit' disabled={loading}>
              <span className='d-flex align-items-center'>
                <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Table
        rowKey='id'
        className='productInboundList'
        columns={columns}
        dataSource={listProductInbound}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: params?.page ?? 1,
          pageSize: params?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
        locale={{ emptyText: 'Không có phiếu xuất nào' }}
        loading={loading}
        bordered
      />
    </Card>
  );
}
