import React, { memo, useCallback, useState } from "react";
import { Row, Col, Select, DatePicker, Button } from "antd";
import _ from "lodash";
import styled from "styled-components";
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons'
import moment from 'moment'
import {SheetParams, ExportExcel} from 'utils/excel'

const { Option } = Select;

const Fillter = memo(
  ({ params, setParams, columns, data, dataFilter, setFilter, filter }) => {
    const [selectedProvinceId, setSelectedProvinceId] = useState();
    const [filteredProvinces, setFilteredProvinces] = useState([..._.get(dataFilter, "arrProvince")]);

    const getQuery = useCallback(
      (value, name) => {
        setParams((preState) => {
          let nextState = { ...preState };
          nextState[name] = value;
          return nextState;
        });
      },
      [setParams]
    );

    const getFilter = () => {
      setFilter(!filter);
    }; 
    
    const filterProvinces = (value) => {
      if (value) {
        let arrData = _.filter(_.get(dataFilter, "arrProvince"), (item, index) => {
          return item?.businessRegionId === value
        });
        setSelectedProvinceId(null);
        setFilteredProvinces(arrData);
      } else {
        setFilteredProvinces([..._.get(dataFilter, "arrProvince")]);
      }
    }

    const exportExcel = () => {
      var sheet = new SheetParams(columns, data, 'Viếng thăm theo KH');
      let SheetArr = [sheet];
      ExportExcel(SheetArr, "Viếng thăm theo KH " + moment().format("DD-MM-YYYY"));
    };

    return (
      <div>
        <Row gutter={15}>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Khu vực</span>
            <Select
              style={{ width: "100%", marginTop: 10 }}
              placeholder="Khu vực"
              allowClear
              onChange={(e) => {
                getQuery(e, "regionId");
                filterProvinces(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(_.get(dataFilter, "arrBusinessRegions"), (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Tỉnh</span>
            <Select
              style={{ width: "100%", marginTop: 10 }}
              placeholder="Tỉnh"
              allowClear
              value={selectedProvinceId}
              onChange={(e) => {
                getQuery(e, "provinceId");
                setSelectedProvinceId(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(filteredProvinces, (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Tháng xem</span>
            <DatePicker
              placeholder="Tháng xem"
              style={{ width: "100%",marginTop: 10 }}
              value={params.month}
              format={"MM-YYYY"}
              onChange={(e) => {
                getQuery(e, "month");
              }}
              picker="month"
            />
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 5 }}>
          <Col>
            <Button
              type="primary"
              onClick={getFilter}
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </Col>
          <Col className='ml-2'>
            <Button
              type='dashed'
              onClick={exportExcel}
            >
              <span className='d-flex align-items-center'>
                <DownloadOutlined /> <span className='ml-1'>Xuất file excel</span>
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
);
export default styled(Fillter)`
  .ant-picker {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .clearParams {
    text-align: right;
  }
`;
