/**
 * Copyright 2021-present, TRUNGHIEU.
 * All rights reserved.
 * @author
 * @description Màn hình Nhóm thương hiệu. Route `/customer-management/customer-information`
 **/
import {
  DeleteFilled,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  MessageOutlined,
  LoadingOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Upload
} from "antd";
import CustomerInfoModal from "components/modals/CustomerInfoModal";
import StepsFormModal from "components/modals/stepsForm/StepsFormModal";
import React, { useCallback, useEffect, useState } from "react";
import services from "services";
import DetailModal from "./DetailModal";
import "./style.css";
import { get } from "lodash";
import _ from "lodash";
import { Link } from "react-router-dom";
import utils from "utils";
import moment from 'moment';
import {saveFileAs} from 'utils/excel';

const { Option } = Select

export default function CustomerInformation() {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  const [ShowCustomerStepsInforModal, setShowCustomerStepsInforModal] =
    useState(false);
  const [ShowCustomerInforModal, setShowCustomerInforModal] = useState(false);
  const [selectedCustomerInfor, setSelectedCustomerInfor] = useState({});
  const [isReloadPage, setIsReloadPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listCustomerInformation, setListCustomerInformation] = useState([]);
  const [total, setTotal] = useState(0);
  const [listRegion, setListRegion] = React.useState([]);
  const [listBusinessType, setListBusinessType] = React.useState([]);
  const [listsaleChannel, setListsaleChannel] = React.useState([]);
  const [listsaleRoute, setListsaleRoute] = React.useState([]);
  const [listcustomerGroup, setListcustomerGroup] = React.useState([]);
  // const [filterCriteria, setFilterCriteria] = React.useState({});
  // Import
  const [importLoading, setImportLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  // ================
  const [listProvince, setListProvince] = React.useState([]);
  const [listWard, setListWard] = React.useState([]);
  const [listDistrict, setListDistrict] = React.useState([]);
  const [listBusinessRegions, setListBusinessRegions] = React.useState([]);

  //#region filter vùng, tỉnh, nhân viên
  const [dataFilter, setDataFilter] = useState({
    arrBusinessRegions: [],
    arrProvinces: [],
    arrStaffs: [],
  });
  
  const [selectedRegionId, setSelectedRegionId] = useState(undefined);
  const [selectedProvinceId, setSelectedProvinceId] = useState();
  const [selectedStaffId, setSelectedStaffId] = useState();
  const [filteredProvinces, setFilteredProvinces] = useState([
    ..._.get(dataFilter, 'arrProvinces'),
  ]);
  const [filteredStaffs, setFilteredStaffs] = useState([
    ..._.get(dataFilter, 'arrStaffs')
  ]);

  const [params, setParams] = useState({
    regionId: undefined,
    provinceId: undefined,
    staffId: undefined,
    customerCode: undefined,
    customerName: undefined,
  });

  const getQuery = useCallback(
    (value, name) => {
      setParams((preState) => {
        let nextState = { ...preState }
        nextState[name] = value
        return nextState
      })
    },
    [setParams],
  )

  useEffect(() => {
    // api tỉnh
    services.organization
    .province_GetAllProvincesWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });

      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrProvinces = array;
        return nextState;
      });
    })
    .catch((error) => {});

    // api nhân viên        
    services.userManagementService.resource_GetAllSalersWithPermission().then((res) => {
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrStaffs = res;
        return nextState;
      });
    });

    // api khu vực
    services.organization
    .businessRegion_GetAllRegionsWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.regionName;
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrBusinessRegions = array;
        return nextState;
      });
    })
    .catch((error) => {});
  }, [setDataFilter]);

  useEffect(() => {
    var provinces = _.get(dataFilter, 'arrProvinces');
    var staffs = _.get(dataFilter, 'arrStaffs');
    if(provinces && provinces.length > 0 && staffs && staffs.length > 0){
      filterProvinces();
    }
  }, [dataFilter]);

  const filterProvinces = (value) => {
    if (value) {
      let arrProvinces = _.filter(
        _.get(dataFilter, 'arrProvinces'),
        (item, index) => {
          return item?.businessRegionId === value;
        },
      )
      setSelectedProvinceId(null);
      setFilteredProvinces(arrProvinces);
      
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.regionId === value;
        },
      )
      setSelectedStaffId(null);
      setFilteredStaffs(arrStaffs);
      
      setParams((preState) => {
        let nextState = { ...preState };
        nextState['provinceId'] = undefined;
        nextState['staffId'] = undefined;
        return nextState
      });
    } else {
      setFilteredProvinces([..._.get(dataFilter, 'arrProvinces')])
      setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')])
    }
    setSelectedRegionId(value);
  }

  const filterStaffs = (value) => {
    if (value) {        
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.provinceId === value;
        },
      )
      setSelectedStaffId(null);
      setFilteredStaffs(arrStaffs);
      
      setParams((preState) => {
        let nextState = { ...preState };
        nextState['staffId'] = undefined;
        return nextState
      });
    } else {
      if (selectedRegionId) {
        let arrStaffs = _.filter(
          _.get(dataFilter, 'arrStaffs'),
          (item, index) => {
            return item?.regionId === selectedRegionId;
          },
        )
        setFilteredStaffs(arrStaffs);
      } else {
        setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
      }
    }
    setSelectedProvinceId(value);
  }
  //#endregion

  const onClose = () => {
    setVisibleDrawer(false);
    setIsLoad(!isLoad);
    setSelectedCustomerInfor({});
  };

  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ page: currentPage, pageSize });
    }
  );

  const handleSearch = (values) => {
    // handleReloadPage();
    // let param = {
    //   ProvinceId: values.ProvinceId,
    //   CustomerCode: values.CustomerCode,
    //   CustomerName: values.CustomerName
    // };
    // setFilterCriteria(() => {
    //   const newFilterCriteria = Object.assign({}, param);
    //   setFilterCriteria({...newFilterCriteria});
    // });
    setPaging({ page: 1, pageSize: paging?.pageSize});
  };

  const funcGetallcustomerfilter = useCallback(async () => {
    await setLoading(true);
    try {
      services.generalService
        .customerInformation_GetPagingByFilteringWithPermission(
          params?.regionId,
          params?.provinceId,
          params?.staffId,
          params?.customerCode,
          params?.customerName,
          paging?.page, //PageNumber
          paging?.pageSize
        )
        .then((res) => {
          const items = get(res, "items");
          setTotal(res?.totalCount ?? 0);
          setListCustomerInformation(items);
          setLoading(false);
        })
        .catch(() => {
          setTotal(0);
          setListCustomerInformation([]);
          setLoading(false);
        });
    } catch (error) {
      setTotal(0);
      setListCustomerInformation([]);
      setLoading(false);
    };
  }, [paging, isLoad, isReloadPage]);

  

  const exportCustomers = () => {
    setLoading(true);
    services.generalService
    .customerInformation_GetExportPagingByFilteringWithPermission(
      params?.regionId,
      params?.provinceId,
      params?.staffId,
      params?.customerCode,
      params?.customerName,
      paging?.page, //PageNumber
      paging?.pageSize
    )
    .then((response) => {
      saveFileAs(response.data, 'Danh sách khách hàng '+ moment().format("DD-MM-YYYY") + '.xlsx');
    })
    .catch((err) => {
      message.error('Có lỗi khi xuất excel');
    })
    .finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    funcGetallcustomerfilter();
  }, [funcGetallcustomerfilter]);
  useEffect(() => {
    setLoading(true);
    services.organization
      .businessRegion_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res) => setListRegion(res.items))
      .catch(() => setListRegion([]));
    services.generalService
      .businessType_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res) => setListBusinessType(res.items))
      .catch(() => setListBusinessType([]));
    services.generalService
      .saleChannel_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res) => setListsaleChannel(res.items))
      .catch(() => setListsaleChannel([]));
    services.generalService
      .saleRoute_GetPagingSaleRoute(paging?.page, paging?.pageSize)
      .then((res) => setListsaleRoute(res.items))
      .catch(() => setListsaleRoute([]));
    services.generalService
      .customerGroup_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res) => setListcustomerGroup(res?.items))
      .catch(() => setListcustomerGroup([]));
    // -------------------
    services.organization
      .province_GetAllProvincesWithPermission()
      .then((res) => setListProvince(res))
      .catch(() => setListProvince([]));
    services.organization
      .ward_GetBsWithPagination(paging?.page, 1000)
      .then((res) => setListWard(res?.items))
      .catch(() => setListWard([]));
    services.organization
      .district_GetBsWithPagination(paging?.page, 1000)
      .then((res) => setListDistrict(res?.items))
      .catch(() => setListDistrict([]));
    services.organization
      .businessRegion_GetBsWithPagination(paging?.page, paging?.pageSize)
      .then((res) => setListBusinessRegions(res?.items))
      .catch(() => setListBusinessRegions([]));
  }, []);

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedCustomerInfor({});
  }, [setSelectedCustomerInfor, setIsReloadPage, isReloadPage]);
  const reload = () => {
    setIsReloadPage(!isReloadPage);
  };
  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa <b className="text-danger">{row.customerName}</b>{" "}
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.generalService
          .customerInformation_Delete([
            {
              id: row.id,
              currentState: row.currentState,
            },
          ])
          .then((res) => {
            if (res?.succeeded) {
              reload();
            }
            message.success("Xóa thành công.");
            funcGetallcustomerfilter();
          })
          .catch(() => message.error("Xóa không thành công.Vui lòng thử lại"));
      },
      // onCancel() {
      //   reload();
      // },
    });
  }, []);
  const columns = [
    {
      dataIndex: "staffCode",
      key: "staffCode",
      title: "Mã NV",
      width: 80,
    },
    {
      dataIndex: "staffName",
      key: "staffName",
      title: "Tên NV",
      width: 130,
    },
    {
      dataIndex: "saleRouteCode",
      key: "saleRouteCode",
      title: "Mã tuyến",
      width: 100,
    },
    {
      dataIndex: "saleRouteName",
      key: "saleRouteName",
      title: "Tên tuyến",
      width: 130,
    },
    {
      dataIndex: "customerCode",
      key: "customerCode",
      title: "Mã khách hàng",
      // align: "center",
      width: 120,
    },
    {
      dataIndex: "customerName",
      key: "customerName",
      title: "Tên khách hàng",
      // align: "left",
      // fixed: "left",
      width: 130,
    },
    {
      dataIndex: "businessType",
      key: "businessType",
      title: "Hình thức KD",
      // align: "center",
      width: 80,
      render: (businessType) => <>{businessType?.businessName}</>,
    },
    {
      dataIndex: "province",
      key: "provinceId",
      title: "Tỉnh",
      width: 100,
      render: (province) => <>{province?.provinceName}</>,
    },
    {
      dataIndex: "phonenumber",
      key: "phonenumber",
      title: "Số điện thoại",
      // align: "center",
      width: 100,
    },
    {
      dataIndex: "address",
      key: "address",
      title: "Địa chỉ",
      width: 200,
      // ellipsis: true,
    },
    {
      dataIndex: "latitude",
      key: "latitude",
      title: "Vĩ độ",
      width: 100
    },
    {
      dataIndex: "longitude",
      key: "longitude",
      title: "Kinh độ",
      width: 100
    },
    {
      dataIndex: "status",
      key: "status",
      title: "Trạng thái",
      align: "center",
      width: 100,
      filters: [
        {
          text: "Hoạt động",
          value: 1,
        },
        {
          text: "Ngừng hoạt động",
          value: 0,
        },
      ],
      // fixed: "right",
      onFilter: (value, record) => record.status === value,
      render: (value) => {
        var findedStatus = utils.$const.CUSTOMER_STATUS_INFO.STATUS.find(
          (x) => x.id === value
        );
        return (
          <div className={`status--${value ? "active" : "inactive"}`}>
            {value == 1 ? "Hoạt Động" : "Không Hoạt Động"}
          </div>
          // {/* </div>
          //   <div style={{ color: '#fff',background:findedStatus?.color,padding:5 ,width:'100%',borderRadius:5}}>
          //     {findedStatus?.name}
          //   </div> */}
        );
      },
    },

    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      fixed: "right",
      width: 150,
      render: (text, row) => {
        let id = _.get(row, "id");
        return (
          <div className="d-flex align-items-center justify-content-between">
            <Button
              onClick={handleEditCustomerInfor(row)}
              type="primary"
              size="small"
            >
              <span className="d-flex align-items-center">
                <EditOutlined />
                {/* <span className="ml-1">Sửa</span> */}
              </span>
            </Button>
            <Button
              type="danger"
              size="small"
              onClick={() => handleShowConfirmDelete(row)}
            >
              <span className="d-flex align-items-center">
                <DeleteFilled />
                {/* <span className="ml-1">Xóa</span> */}
              </span>
            </Button>
            <Button
              icon={
                <UnorderedListOutlined className="CustomerInfor-card__icon" />
              }
              info
              size="small"
              onClick={showDrawer(row)}
            />
            <Link
              to={`/customer-management/visit-comments?id=${id}`}
              target="_blank"
            >
              <Button
                onClick={() => {
                }}
                style={{ marginRight: 8 }}
                icon={
                  <MessageOutlined style={{ fontSize: 25, color: "green" }} />
                }
                type="link"
                title="Danh sách vấn đề"
                size="small"
              ></Button>
            </Link>
          </div>
        );
      },
    },
  ];
  const showDrawer = useCallback(
    (CustomerInfor) => (e) => {
      setVisibleDrawer(true);
      setSelectedCustomerInfor(CustomerInfor);
    },
    [setVisibleDrawer, setSelectedCustomerInfor]
  );

  const handleCreateCustomerInforV1 = useCallback(() => {
    setShowCustomerStepsInforModal(true);
  }, []);

  const handleEditCustomerInfor = useCallback(
    (CustomerInfor) => (e) => {
      setShowCustomerInforModal(true);
      setSelectedCustomerInfor(CustomerInfor);
    },
    [setShowCustomerInforModal, setSelectedCustomerInfor]
  );
  const handleCloseModal = useCallback(() => {
    setShowCustomerStepsInforModal(false);
    setShowCustomerInforModal(false);
    setSelectedCustomerInfor({});
  }, [
    setShowCustomerInforModal,
    setSelectedCustomerInfor,
    setShowCustomerStepsInforModal,
  ]);

  function beforeUpload(file) {
    const isExcelFile = file.type === "application/vnd.ms-excel" ||
     file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcelFile) {
      message.error("You can only upload excel file!");
    }
    return isExcelFile ? true : Upload.LIST_IGNORE;;
  }

  const importCustomers = (data) => {
    if (data.fileList.length > 0) {
      setImportLoading(true);
      const fileToUpload = {
        data: data.fileList[0].originFileObj,
        fileName: data.fileList[0].name
      };

      services.generalService
          .customerInformation_ImportCustomerData(fileToUpload)
          .then((res) => {
            message.success('Import danh sách khách hàng thành công.');
          })
          .catch((error) => {
            message.error('Có lỗi khi import danh sách khách hàng.');
          }).
          finally(() => {
            setImportLoading(false);
            setFileList([]);
          });
    }
  }
  return (
    <Card className="CustomerInfor-card" size="small">
      {/* <Typography.Title level={5}>THÔNG TIN KHÁCH HÀNG</Typography.Title>
        <Divider /> */}
      {/* <Form labelCol={{ span: 24 }} onFinish={handleSearch}> */}
        <Row gutter={[8]}>
          {/* <Col lg={8} xxl={4}>
            <Form.Item label="Tỉnh" name="ProvinceId" className="mb-0">
            <Select
                    placeholder="Chọn tỉnh"
                    showSearch
                    showArrow={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                  >
                    {listProvince?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.provinceName}
                      </Select.Option>
                    ))}
                  </Select>
            </Form.Item>
          </Col> */}
          <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
            <span>Khu vực</span>
            <Select
              value={selectedRegionId}
              style={{ width: '100%', marginTop: 10 }}
              placeholder="Khu vực"
              allowClear
              onChange={(e) => {
                getQuery(e, 'regionId');
                filterProvinces(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(_.get(dataFilter, 'arrBusinessRegions'), (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
            <span>Tỉnh</span>
            <Select
              style={{ width: '100%', marginTop: 10 }}
              placeholder="Tỉnh"
              allowClear
              value={selectedProvinceId}
              onChange={(e) => {
                getQuery(e, 'provinceId');
                filterStaffs(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(filteredProvinces, (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
            <span>Nhân viên</span>
            <Select
              style={{ width: '100%', marginTop: 10 }}
              placeholder="Nhân viên"
              allowClear
              value={selectedStaffId}
              onChange={(e) => {
                getQuery(e, 'staffId');
                setSelectedStaffId(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(filteredStaffs, (item, index) => {
                return (
                  <Option value={item.userId} key={item.userId}>
                    {item.fullName}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
            {/* <Form.Item name="CustomerCode" label="Mã khách hàng" className="mb-0">
              <Input placeholder="Nhập mã khách hàng " />
            </Form.Item> */}
              <span>Mã Khách Hàng</span>
              <Input
                style={{ width: '100%', marginTop: 10 }}
               placeholder="Nhập mã khách hàng"
               onChange={(e) => {
                getQuery(e.target.value, 'customerCode');
              }}
              />
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
            {/* <Form.Item name="CustomerName" label="Tên khách hàng" className="mb-0">
              <Input placeholder="Nhập tên khách hàng" />
            </Form.Item> */}
              <span>Tên Khách Hàng</span>
              <Input
                style={{ width: '100%', marginTop: 10 }}
               placeholder="Nhập tên khách hàng"
               onChange={(e) => {
                getQuery(e.target.value, 'customerName');
              }}
              />
          </Col>
          {/* <Col lg={8} xxl={4}>
            <Form.Item name="Keyword" label="Tìm kiếm theo từ khóa" className="mb-0">
              <Input placeholder="Nhập từ khóa" />
            </Form.Item>
          </Col> */}
          {/* <Col lg={8} xxl={4}>
            <Form.Item name="StoreName" label="Tìm kiếm tên cửa hàng" className="mb-0">
              <Input placeholder="Nhập tên cửa hàng" />
            </Form.Item>
          </Col> */}
        </Row>
        <Row justify="end" align="center" gutter={[8]} className="my-3">
          <Col>
            {/* <Button type="primary" htmlType="submit">
              <span className="d-flex align-items-center">
                <SearchOutlined />{" "}
                <span className="ml-1" htmlType="submit">
                  Tìm kiếm
                </span>
              </span>
            </Button> */}
            <Button
              disabled={loading}
              type="primary"
              onClick={handleSearch}
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </Col>
          <Col className='ml-2'>
            <Button
              disabled={loading}
              type='primary'
              onClick={exportCustomers}
            >
              <span className='d-flex align-items-center'>
                <DownloadOutlined /> <span className='ml-1'>Xuất file excel</span>
              </span>
            </Button>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button onClick={handleCreateCustomerInforV1}>
              <span className="d-flex align-items-center">
                <PlusOutlined /> <span className="ml-1">Thêm khách hàng </span>
              </span>
            </Button>
          </Col>
          <Col>
            <Upload
              className='import-export ant-btn'
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              showUploadList={false}
              fileList={fileList}
              beforeUpload={beforeUpload}
              onChange={importCustomers}
            >
              <span className='d-flex align-items-center text-white'>
                {importLoading ? <LoadingOutlined /> : <DownloadOutlined />}
                <span className='ml-1'>Import khách hàng</span> 
              </span>
            </Upload>
          </Col>
          {/* <Col>
            <Button className="ml-2 btn-success">
              <span className="d-flex align-items-center">
                <DownloadOutlined /> <span className="ml-1">Xuất Excel</span>
              </span>
            </Button>
          </Col> */}
        </Row>
      {/* </Form> */}
      <Table
        size="small"
        className="CustomerInfor-card__table"
        columns={columns}
        dataSource={listCustomerInformation}
        total={listCustomerInformation?.length ?? 0}
        bordered
        locale={{ emptyText: "Không có thông tin khách hàng !" }}
        loading={loading}
        //scroll={{ x: 3000, y: 680 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.page ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
      />

      <CustomerInfoModal
        visible={ShowCustomerInforModal}
        setIsLoad={setIsLoad}
        isLoad={isLoad}
        onCancel={handleCloseModal}
        listCustomerInformation={listCustomerInformation}
        onReloadPage={handleReloadPage}
        listBusinessType={listBusinessType}
        listsaleChannel={listsaleChannel}
        listsaleRoute={listsaleRoute}
        listcustomerGroup={listcustomerGroup}
        selectedCustomerInfor={selectedCustomerInfor}
        listProvince={listProvince}
        listWard={listWard}
        listDistrict={listDistrict}
        listBusinessRegions={listBusinessRegions}
        listRegion={listRegion}
      />

      {/* ____________________________________ */}
      <StepsFormModal
        visible={ShowCustomerStepsInforModal}
        onCancel={handleCloseModal}
        listCustomerInformation={listCustomerInformation}
        onReloadPage={handleReloadPage}
        listRegion={listRegion}
        listBusinessType={listBusinessType}
        listsaleChannel={listsaleChannel}
        listsaleRoute={listsaleRoute}
        listcustomerGroup={listcustomerGroup}
        selectedCustomerInfor={selectedCustomerInfor}
        listProvince={listProvince}
        listWard={listWard}
        listDistrict={listDistrict}
        listBusinessRegions={listBusinessRegions}
      />
      {/* ____________________________________ */}
      {/* ____________________________________ */}
      <Drawer
        width={800}
        title="Chi tiết khách hàng"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visibleDrawer}
        selectedCustomerInfor={selectedCustomerInfor}
        footer={
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Button
              onClick={() => {
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Quay lại
            </Button>
          </div>
        }
      >
        <DetailModal selectedCustomerInfor={selectedCustomerInfor} />
      </Drawer>
    </Card>
  );
}
