import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Upload,
  Tabs,
} from "antd";
import { filter }from "lodash";
import GoogleMap from "google-map-react";

import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect, useState } from "react";
import services from "services";
import "./stepsForm/style.scss";
const { TabPane } = Tabs;
const CustomerInfoModal = ({
  visible,
  onCancel,
  selectedCustomerInfor = {},
  listCustomerInformation = {},
  listsaleChannel = {},
  listsaleRoute = {},
  listBusinessType = {},
  onReloadPage,
  isLoad,
  setIsLoad,
  ...props
}) => {
  const [form] = Form.useForm();
  const ggmapKey = process?.env?.REACT_APP_GG_MAP_KEY;

  const [dateOfVisit, setdateOfVisit] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [variableFlie, setVariableFlie] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [location, setLocation] = React.useState({
    lat: null,
    lng: null,
  });
  const [province, setProvince] = React.useState([]);

  useEffect(() => {
    setDistricts(props?.listDistrict || []);
    setProvince(props?.listProvince || []);
    
  }, [props?.listDistrict,props?.listProvince]);

  useEffect(() => {
    setWards(props?.listWard || []);
  }, [props?.listWard]);

  // function handleChangeDate(value) {
  //   // eslint-disable-next-line no-lone-blocks
  //   const dateOfVisit = value?.map((item, key) => {
  //     return { date: item };
  //   });
  //   setdateOfVisit(dateOfVisit);
  // }
  const children = [];
  children.push(
    <>
      <Select key={"Monday"} value="Monday">
        Thứ hai
      </Select>
      <Select key={"Tuesday"} value="Tuesday">
        Thứ ba
      </Select>
      <Select key={"Wednesday"} value="Wednesday">
        Thứ tư
      </Select>
      <Select key={"Thursday"} value="Thursday">
        Thứ năm
      </Select>
      <Select key={"Friday"} value="Friday">
        Thứ sáu
      </Select>
      <Select key={"Saturday"} value="Saturday">
        Thứ bảy
      </Select>
      <Select key={"Sunday"} value="Sunday">
        Chủ nhật
      </Select>
    </>
  );
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isAccept = true;
    return isJpgOrPng && isAccept;
  }
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
    const fileToUpload = {
      data: img,
      fileName: img.name,
    };
    setVariableFlie(fileToUpload);
  }
  const handleChange = (info) => {
    setLoading(true);
    getBase64(info.file.originFileObj, (imageUrl) => setImageUrl(imageUrl));
    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const greatPlaceStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: "absolute",
    left: -40 / 2,
    top: -40 / 2,
    width: 40,
    height: 40,
    border: "5px solid #f44336",
    borderRadius: 40,
    backgroundColor: "white",
    textAlign: "center",
    color: "#3f51b5",
    fontSize: 16,
    fontWeight: "bold",
    padding: 4,
  };
  const handleClickMap = (e) => {
    setLocation({ lat: e.lat, lng: e.lng });
  };
  const createMapOptions = (maps) => {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
        style: maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: true,
    };
  };
  const MyGreatPlace = ({ text }) => {
    return <div style={greatPlaceStyle}>{text}</div>;
  };
  useEffect(() => {
    if (selectedCustomerInfor?.avatar !== "") {
      setImageUrl(selectedCustomerInfor.avatar);
    }

    const initValues = {
      ...selectedCustomerInfor,
      avatar: selectedCustomerInfor.avatar,
      businessTypeId: selectedCustomerInfor.businessType?.id,
      // saleRouteId: String(selectedCustomerInfor.saleRoute?.id),
      saleChannelId: String(selectedCustomerInfor.saleChannel?.id),
      districtId: String(selectedCustomerInfor.district?.id),
      wardId: String(selectedCustomerInfor.ward?.id),
      provinceId: String(selectedCustomerInfor.province?.id),
      areaId: String(selectedCustomerInfor.region?.id),
      status: selectedCustomerInfor?.status,
      customerStatus: selectedCustomerInfor?.customerStatus,
      debtLimit: selectedCustomerInfor?.debtLimit || 0,
      // levelDefinitionId:String(selectedCustomerInfor.levelDefinitionId)
    };
    if (Object.keys(selectedCustomerInfor).length > 0) {
      form.setFieldsValue(initValues);
    } else {
      form.resetFields();
    }

    setLocation((preState)=>{
      let nextState = {...preState}
      nextState.lat = selectedCustomerInfor?.latitude
      nextState.lng = selectedCustomerInfor?.longitude
      return nextState
    })
  }, [selectedCustomerInfor, form]);

  const handleSubmit = (values) => {
    let dataNew = {
      ...values,
      regionId: "b3fb65d9-b3eb-4189-0247-08d939a9c524",
      debtLimit: Number(values?.debtLimit),
      latitude: location?.lat,
      longitude: location?.lng,
    };

    if (
      variableFlie !== null &&
      variableFlie !== undefined &&
      variableFlie !== ""
    ) {
      services.fileManagement
        .photo_PostPhotoAttachment(variableFlie, "Photo", "Products")
        .then((res) => {
          if (res.succeeded === true) {
            dataNew.avatar = res.data;
          }
          let Data = dataNew;
          services.generalService
            .customerInformation_Update({
              id: selectedCustomerInfor?.id,
              currentState: selectedCustomerInfor?.currentState,
             
              ...Data,
              dateOfVisitings: dateOfVisit,
            })
            .then((res) => {
              if (res?.succeeded) {
                message.success("Sửa khách hàng thành công");
                onReloadPage();
                // setIsLoad(!isLoad)
                form.resetFields();
                onCancel();
              }
            })
            .catch((err) => {
              message.success(
                err?.message ??
                  "Sửa khách không thành công vui lòng kiểm tra lại !"
              );
            });
        });
    } else {
      services.generalService
        .customerInformation_Update({
          id: selectedCustomerInfor?.id,
          currentState: selectedCustomerInfor?.currentState,
          ...dataNew,
          dateOfVisitings: dateOfVisit,
        })
        .then((res) => {
          if (res?.succeeded) {
            message.success("Sửa khách hàng thành công");
            onReloadPage();
            // setIsLoad(!isLoad)
            form.resetFields();
            onCancel();
          }
        })
        .catch((err) => {
          message.error(
            err?.message ??
              "Sửa khách không hàng thành công vui lòng kiểm tra lại !"
          );
        });
    }
  };

  const handleCloseModal = useCallback(() => {
    onCancel && onCancel();
    form.resetFields();
  }, [form, onCancel]);

  const handleConfirm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleChangeProvince = useCallback(
    (provinceId) => {
      let provinceItem = filter(props?.listProvince || [], function (item) {
        if (item.id === provinceId) return item?.id;
      });

      if (provinceItem[0]) {
        form.setFieldsValue({ districtId: "", wardId: "" });

        services.organization
          .district_GetDistrictByProvince(provinceItem[0]?.id)
          .then((res) => {
            setDistricts(res);
          });
      }
    },
    [form, props]
  );

  const handleChangeDistrict = useCallback(
    (districtId) => {
      // // GET phường theo huyện
      let districtItem = filter(districts, function (item) {
        if (item.id === districtId) return item?.id;
      });

      if (districtItem[0]) {
        form.setFieldsValue({ wardId: "" });

        services.organization
          .ward_GetWardByDistrict(districtItem[0]?.id)
          .then((res) => {
            setWards(res);
          });
      }
    },
    [form, districts]
  );
  const handleChangeRegion = useCallback(
    (regionId) => {
      let regionItem = filter(props?.listRegion || [], function (item) {
        if (item.id === regionId) return item?.id;
      });

      if (regionItem[0]) {
        form.setFieldsValue({provinceId: "" , districtId: "", wardId: "" });

        services.organization
          .province_GetProvinceByRegion(regionItem[0]?.id)
          .then((res) => {
            setProvince(res);
          });
      }
    },
    [form, props]
  );
  
  return (
    <Modal
      style={{ top: 10 }}
      visible={visible}
      onCancel={handleCloseModal}
      destroyOnClose
      {...props}
      title={selectedCustomerInfor?.id ? "Sửa khách hàng" : "Thêm khách hàng"}
      footer={[
        <Button
          type="primary"
          key="submit"
          onClick={() => {
            handleConfirm();
          }}
        >
          Lưu
        </Button>,
        <Button key="back" onClick={handleCloseModal}>
          Quay lại
        </Button>,
      ]}
      width="80%"
    >
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Thông tin khách hàng" key="1">
          <Form form={form} onFinish={handleSubmit}>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  label="Ảnh Đại Diện"
                  labelCol={{ span: 8 }}
                  className="uploadImgEdit"
                  name="avatar"
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img className="avtCustom" src={imageUrl} alt="avatar" />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
                
                <Form.Item
                  label="Mã KH"
                  required
                  name="customerCode"
                  rules={[
                    { required: true, message: "Mã KH không được trống" },
                  ]}
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập khách hàng" />
                </Form.Item>
                <Form.Item
                  label="Tên khách hàng"
                  required
                  name="customerName"
                  rules={[
                    {
                      required: true,
                      message: "Tên khách hàng không được trống",
                    },
                  ]}
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập Tên khách hàng" />
                </Form.Item>
                <Form.Item
                  label="Địa chỉ"
                  required
                  name="address"
                  rules={[
                    { required: true, message: "Địa chỉ không được trống" },
                  ]}
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập dịa chỉ" />
                </Form.Item>
                <Form.Item
                  label="Kênh bán hàng"
                  name="saleChannelId"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Kênh bán hàng không được trống",
                    },
                  ]}
                  labelCol={{ span: 6 }}
                >
                  <Select placeholder="Chọn Kênh bán hàng" allowClear>
                    {listsaleChannel.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.saleChannelName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Trạng thái "
                  // required
                  name="status"
                  // rules={[
                  //   { required: true, message: "Trạng thái không được trống" },
                  // ]}
                  labelCol={{ span: 6 }}
                >
                  <Select placeholder="Chọn Trạng thái">
                    <Select.Option value={1}>Hoạt động</Select.Option>
                    <Select.Option value={0}>không hoạt động</Select.Option>
                  </Select>
                </Form.Item>
                {/* <Form.Item
              label="Tuyến bán hàng"
              name="saleRouteId"
              required
              rules={[
                {
                  required: true,
                  message: "Tuyến bán hàng không được trống",
                },
              ]}
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn tuyến bán hàng" allowClear>
                {listsaleRoute.map((item, i) => (
                  <Select.Option key={i} value={item?.id}>
                    {item?.saleRouteName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
                <Form.Item
                  label="Hình thức kinh doanh"
                  name="businessTypeId"
                  labelCol={{ span: 6 }}
                >
                  <Select placeholder="Chọn hình thức kinh doanh" allowClear>
                    {listBusinessType.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.businessName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Nhóm khách hàng"
                  name="customerGroupId"
                  labelCol={{ span: 6 }}
                >
                  <Select placeholder="Chọn nhóm khách hàng" allowClear>
                    {props.listcustomerGroup.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.customerGroupName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Khách hàng Mới/Cũ"
                  name="customerStatus"
                  labelCol={{ span: 6 }}
                >
                  <Select placeholder="Chọn Trạng thái">
                    <Select.Option value={1}>Khách hàng mới</Select.Option>
                    <Select.Option value={2}>Khách hàng cũ</Select.Option>
                  </Select>
                </Form.Item>

                {/* <Form.Item label="Ngày viếng thăm" labelCol={{ span: 6 }}>
              <Select
                mode="tags"
                placeholder="Please select"
                required
                onChange={handleChangeDate}
              >
                {children}
              </Select>
            </Form.Item> */}
            <Form.Item
                  label="Khu vực"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Khu vực không được trống",
                    },
                  ]}
                  name="areaId"
                  labelCol={{ span: 6 }}
                >
                  <Select
                    placeholder="Chọn khu vực"
                    showSearch
                    showArrow={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleChangeRegion}
                    allowClear
                  >
                    {props?.listRegion?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.regionName}
                      </Select.Option>
                    ))}
                  </Select>
                  </Form.Item>
                <Form.Item
                  label="Tỉnh"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Tỉnh không được trống",
                    },
                  ]}
                  name="provinceId"
                  labelCol={{ span: 6 }}
                >
                  <Select
                    placeholder="Chọn Tỉnh"
                    showSearch
                    showArrow={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleChangeProvince}
                    allowClear
                  >
                    {province.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.provinceName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Huyện"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Huyện không được trống",
                    },
                  ]}
                  name="districtId"
                  labelCol={{ span: 6 }}
                >
                  <Select
                    placeholder="Chọn Huyện"
                    showSearch
                    showArrow={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleChangeDistrict}
                    allowClear
                  >
                    {districts?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.districtName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Phường"
                  required
                  rules={[
                    {
                      required: true.valueOf,
                      message: "Phường không được trống",
                    },
                  ]}
                  name="wardId"
                  labelCol={{ span: 6 }}
                >
                  <Select placeholder="Chọn Phường" allowClear>
                    {wards?.map((type) => (
                      <Select.Option key={type?.id} value={type?.id}>
                        {type?.wardName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* <Form.Item
              label="Khu vực kinh doanh"
              name=""
              labelCol={{ span: 6 }}
            >
              <Select placeholder="Chọn khu vực kinh doanh" allowClear>
                {props?.listBusinessRegions?.map((type) => (
                  <Select.Option key={type?.id} value={type?.id}>
                    {type?.regionName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
                <Form.Item
                  label="Sô điện thoại"
                  required
                  name="phonenumber"
                  rules={[
                    {
                      required: true,
                      message: "Sô điện thoại không được trống",
                    },
                  ]}
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập Sô điện thoại" />
                </Form.Item>
                <Form.Item
                  label="Tên cửa hàng"
                  required
                  name="storeName"
                  rules={[
                    {
                      required: true,
                      message: "Tên cửa hàng sale không được trống",
                    },
                  ]}
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập tên cửa hàng" />
                </Form.Item>
                <Form.Item
                  label="Hạn mức nợ"
                  name="debtLimit"
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập hạn mức nợ" />
                </Form.Item>
                <Form.Item label="Mail" name="email" labelCol={{ span: 6 }}>
                  <Input placeholder="Nhập email" />
                </Form.Item>
                <Form.Item
                  label="Người đại diện"
                  name="representative"
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập người đại diện" />
                </Form.Item>
                <Form.Item label="Số fax" name="faxCode" labelCol={{ span: 6 }}>
                  <Input placeholder="Nhập số fax" />
                </Form.Item>
                <Form.Item
                  label="Địa chỉ nhận hàng"
                  name="shipToAddress"
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập địa chỉ nhận hàng" />
                </Form.Item>
                <Form.Item
                  label="Địa chỉ xuất hóa đơn"
                  name="billToAddress"
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập địa chỉ xuất hóa đơn" />
                </Form.Item>
                <Form.Item
                  label="hotline"
                  name="hotline"
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập hotline" />
                </Form.Item>
                <Form.Item
                  label="Mã số thuế"
                  name="taxCode"
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập mã số thuế" />
                </Form.Item>
                <Form.Item
                  label="Mã bưu điện"
                  name="zipCode"
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập mã bưu điện" />
                </Form.Item>

                <Form.Item
                  label="Người nhận hàng"
                  name="shipTo"
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập người nhận hàng" />
                </Form.Item>
                <Form.Item
                  label="Người nhận hóa đơn"
                  name="billTo"
                  labelCol={{ span: 6 }}
                >
                  <Input placeholder="Nhập người nhận hóa đơn" />
                </Form.Item>

                <Form.Item label="Ghi chú" name="notes" labelCol={{ span: 6 }}>
                  <TextArea placeholder="Nhập ghi chú" rows={5} />
                </Form.Item>
              </Col>
            </Row>
            {/* <div className="CustomerInfor-modal__footer">
          <Button type="danger" onClick={handleCloseModal}>
            Hủy
          </Button>
          <Button
            type="primary"
            key="submit"
            onClick={() => {
              handleConfirm();
            }}
          >
            {selectedCustomerInfor?.id ? "sửa khách hàng" : "Thêm khách hàng"}
          </Button>
        </div> */}
          </Form>
        </TabPane>
        <TabPane tab="Map" key="2">
          <div style={{ height: "100vh", width: "100%" }}>
            <GoogleMap
              apiKey={ggmapKey} // set if you need stats etc ...
              center={{
                lat: 15.818135592909831,
                lng: 108.2856855718962,
              }}
              // zoom={zoom}
              defaultZoom={6}
              options={createMapOptions}
              onClick={handleClickMap}
            >
              <MyGreatPlace lat={location.lat} lng={location.lng} text={"A"} />
            </GoogleMap>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default CustomerInfoModal;
