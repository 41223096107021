import React, { memo, useState, useCallback, useEffect } from "react";
import { Row, Col, Card, Spin } from "antd";
import styled from "styled-components";
import _ from "lodash";
import List from "./list";
import services from "services";
import Filter from "./Filter";
import moment from "moment";
let time;
const Index = memo(({ className, profile }) => {
  const [data, setData] = useState();
  const [dataFilter, setDataFilter] = useState({
    arrBusinessRegions: [],
    arrProvince: [],
  });
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);

  const [params, setParams] = useState({
    regionId: undefined,
    provinceId: undefined,
    month: moment(),
    year: moment(),
  });

  useEffect(() => {
    // api khu vực
    services.organization
    .businessRegion_GetAllRegionsWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.regionName;
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrBusinessRegions = array;
        return nextState;
      });
    })
    .catch((error) => {});

    // api tỉnh
    services.organization
      .province_GetAllProvincesWithPermission()
      .then(async (res) => {
        let array = _.map(res, (item, index) => {
          item.name = item.provinceName;
          return item;
        });
  
        setDataFilter((preState) => {
          let nextState = { ...preState };
          nextState.arrProvince = array;
          return nextState;
        });
      })
      .catch((error) => {});
  }, [setDataFilter])

  const getProducts = useCallback(() => {
      setLoading(true);
      services.shippingOrderService
        .kpi_KpiReport({
          regionId: params.regionId,
          provinceId: params.provinceId,
          month: params.month && moment(params.month).format('MM'),
          year: params.month && moment(params.month).format('YYYY'),
          pageNumber: paging.page,
          pageSize: paging.pageSize,
        })
        .then((res) => {
          let array = _.map(res.items, (item, index) => {
            item.regionName = _.get(dataFilter, "arrBusinessRegions").find(
              (BusinessRegions) => BusinessRegions?.id === item.regionId
            )?.regionName;
            item.provinceName = _.get(dataFilter, "arrProvince").find(
              (Province) => Province?.id === item.provinceId
            )?.provinceName;
            return item;
          });

          setLoading(false);
          setData(array);
          setTotal(res.totalCount);
        })
        .catch((error) => {
          setLoading(false);
        });
  }, [filter,paging]);

  useEffect(() => {    
    clearTimeout(time);
    time = setTimeout(getProducts, 800);
  }, [getProducts]);

  const columns = [
    {
      title: "Mã NV",
      dataIndex: "regionName",
      key: "regionName",
      width: 150,      
    },
    {
      title: "Tên Nhân Viên",
      dataIndex: "provinceName",
      key: "provinceName",
      width: 180,      
    },
    {
      title: "Ngày",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "KH Trên Tuyến",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "SL đã VT Đúng Tuyến",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "SL VT Ngoại Tuyến",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "SL KH Chưa Viếng Thăm",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "SL VT Có Ảnh",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "SL VT Không Có Ảnh",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "VT Có Kiếm Tồn",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "VT Không Có Kiểm Tồn",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "VT Có Đơn Hàng",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    },
    {
      title: "VT Không Có Đơn Hàng",
      dataIndex: "staffName",
      key: "staffName",
      width: 180,
    }
  ];

  return (
    <Card>
      <Spin spinning={loading} tip="Đang lấy dữ liệu...">
        <Row className={className} gutter={16}>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <Filter
              params={params}
              setParams={setParams}
              columns={columns}
              data={data}
              dataFilter={dataFilter}
              setFilter={setFilter}
              filter={filter}
            />
          </Col>

          <Col>
            <List
              data={data}
              total={total}
              setPaging={setPaging}
              paging={paging}
              columns={columns}
            />
          </Col>
        </Row>
      </Spin>
    </Card>
  );
});

export default styled(Index)``;
