/**
 * Copyright 2021-present, Hoàng An
 * All rights reserved.
 * @author tangan2215@gmail.com on 07/06/2021.
 * @description Màn hình sản phẩm. Route `/productManagement/ProductInfo`
 **/
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  message,
  Divider,
  Col,
  Row,
  Form,
  Select
} from "antd";
import {
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteFilled,
  UnorderedListOutlined,
} from "@ant-design/icons";
import ProductInfoModal from "components/modals/ProductInfoModal";
import services from "services";
import Pagination from "components/Paginate/index";
import _ from "lodash";
let time;
export default function ProductInfo() {
  const [listProductInfo, setlistProductInfo] = useState([]);
  const [showProductInfoModal, setShowProductInfoModal] = useState(false);
  const [selectedProductInfo, setSelectedProductInfo] = useState({});

  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  //const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  //const [isReloadPage, setIsReloadPage] = useState(false);

  const [isMode, setIsMode] = useState("Edit");

  const handleGetlistProductInfo = useCallback(() => {
    setIsLoading(true);
    services.generalService
      .products_GetProductsWithPagination(paging?.page, 123)
      .then((res) => {
        let arrNew = _.map(_.get(res, "items"), (item, index) => {
          item.unitName = _.get(item, "productUnitDetails[0].unitTypeName");
          item.packageUnitName = _.get(
            item,
            "productUnitDetails[1].unitTypeName"
          );
          item.oddSalePrice = _.get(item, "productUnitDetails[0].salePrice");
          item.evenSalePrice = _.get(
            item,
            "productUnitDetails[1].salePrice"
          );
          return item;
        });
        setlistProductInfo(arrNew);
        setIsLoading(false);
        setTotal(res?.totalCount ?? 0);
      })
      .catch((error) => {
        setTotal(0);
        //setLoading(false);
      });
  }, [paging]);

  const [form] = Form.useForm();
  const [listBrand, setlistBrand] = useState([]);
  const [listIndustry, setlistIndustry] = useState([]);
  //const [listDistributor, setlistDistributor] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(() => {
      handleGetlistProductInfo();
      services.generalService.productBrand_GetAllBrand().then((res) => {
        setlistBrand(res);
      });
      services.generalService
        .productIndustry_GetAllProductIndustry()
        .then((res) => {
          setlistIndustry(res);
        });
      // services.generalService.distributor_GetAllDistributorsWithPermission().then((res) => {
      //   setlistDistributor(res);
      // });
    }, 800);
  }, [handleGetlistProductInfo]);

  const listStatus = [
    {
      id: 1,
      status: 1,
      statusName: "Hoạt động",
    },
    {
      id: 2,
      status: 0,
      statusName: "Không hoạt động",
    },
  ];

  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa sản phẩm <b className="text-danger">{row.productCode}</b>{" "}
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.generalService
          .products_Delete({
            id: row.id,
            currentState: row.currentState,
          })
          .then((res) => {
            message.success("Xóa sản phẩm thành công.");
            handleGetlistProductInfo();
          })
          .catch((erro) => {
            message.error("Có lỗi xảy ra!");
          });
      },
      onCancel() {},
    });
  }, []);

  const handleConfirmfilter = useCallback(() => {
    form.submit();
  }, [form]);

  const onSubmit = (values) => {
    setIsLoading(true);

    let dataSubmit = {
      ...form.getFieldValue(),
      pageNumber: 1,
      pageSize: 10,
    };

    services.generalService.products_FilterProducts(dataSubmit).then((res) => {
      let arrNew = _.map(_.get(res, "items"), (item, index) => {
        item.unitName = _.get(item, "productUnitDetails[0].unitTypeName");
        item.packageUnitName = _.get(
          item,
          "productUnitDetails[1].unitTypeName"
        );
        item.oddSalePrice = _.get(item, "productUnitDetails[0].salePrice");
        item.evenSalePrice = _.get(
          item,
          "productUnitDetails[1].salePrice"
        );
        return item;
      });
      setlistProductInfo(arrNew);
      setIsLoading(false);
      // if (res.items.length == 0) {
      //   message.error("Không tìm thấy sản phẩm");
      // } else {
      //   message.success("Tìm kiếm thành công");
      // }
    });
  };
  const columns = [
    {
      dataIndex: "productCode",
      key: "productCode",
      title: "Mã Sản Phẩm",
      // align: "center",
      width: 150,
      // fixed: "left",
    },
    {
      dataIndex: "productName",
      key: "productName",
      title: "Tên Sản Phẩm",
      width: 150,

      // align: "center",
      // fixed: "left",
    },
    {
      dataIndex: "unitName",
      key: "unitName",
      title: "ĐVT Lẻ",
      width: 150,
      // align: "center",
      render: (value, row) => {
        return <p>{value}</p>;
      },
    },
    {
      dataIndex: "packageUnitName",
      key: "packageUnitName",
      title: "ĐVT Chẵn",
      width: 150,
      // align: "center",
      render: (value) => <p>{value}</p>,
    },
    {
      dataIndex: "evenSalePrice",
      key: "evenSalePrice",
      title: "Giá Thùng",
      width: 150,
      align: "right",
      render: (value) => {
        return <p>{value ? value.toLocaleString() : ""}</p>;
      },
    },
    {
      dataIndex: "oddSalePrice",
      key: "oddSalePrice",
      title: "Giá Gói",
      width: 150,
      align: "right",
      render: (value) => {
        return <p>{value ? value.toLocaleString() : ""}</p>;
      },
    },
   
    {
      dataIndex: "productBrand",
      key: "productBrand",
      title: "Nhãn Hiệu",
      width: 150,
      // align: "center",
      render: (value) => <p>{_.get(value,'brandName')}</p>,
    },
    {
      dataIndex: "industry",
      key: "industry",
      title: "Ngành Hàng",
      width: 150,
      // align: "center",
      render: (value) => <p>{_.get(value,'productIndustryName')}</p>,
    },
    // {
    //   dataIndex: "distributor",
    //   key: "distributor",
    //   title: "Nhà Phân Phối",
    //   width: 150,
    //   // align: "center",
    //   render: (value) => <p>{_.get(value, "distributorName")}</p>,
    // },
    {
      dataIndex: "productType",
      key: "productType",
      title: "Loại Sản Phẩm",
      width: 150,
      // align: "center",
      render: (value) => <p>{_.get(value, "productTypeName")}</p>,
    },
    {
      dataIndex: "packageType",
      key: "packageType",
      title: "Dạng đóng gói",
      width: 150,
      // align: "center",
    },
    {
      dataIndex: "notes",
      key: "notes",
      title: "Ghi Chú",
      width: 150,
      // align: "center",
    },
    {
      dataIndex: "activeStatus",
      key: "activeStatus",
      title: "Trạng Thái",
      align: "center",
      // fixed: "right",
      width: 180,
      render: (value) => (
        <div className={`status--${value ? "active" : "inactive"}`}>
          {value == 1 ? "Hoạt Động" : "Không Hoạt Động"}
        </div>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      width: 150,
      fixed: "right",
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Button
            onClick={handleModalProductInfo(row, "Edit")}
            type="primary"
            size="small"
          >
            <span className="d-flex align-items-center">
              <EditOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            danger
            size="small"
            onClick={() => handleShowConfirmDelete(row)}
          >
            <span className="d-flex align-items-center">
              <DeleteFilled /> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            type="ghost"
            size="small"
            className="ml-1"
            onClick={handleModalProductInfo(row, "View")}
          >
            <span className="d-flex align-items-center">
              <UnorderedListOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const handleCreateProductInfo = useCallback(
    (mode) => (e) => {
      setShowProductInfoModal(true);
      setIsMode(mode);
    },
    []
  );

  const handleModalProductInfo = useCallback(
    (ProductInfoInfor, mode) => (e) => {
      setShowProductInfoModal(true);
      setSelectedProductInfo(ProductInfoInfor);
      setIsMode(mode);
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setShowProductInfoModal(false);
    setSelectedProductInfo({});
    handleGetlistProductInfo()
  }, []);

  // const handleChangePaging = useCallback(
  //   (page, pageSize) => {
  //     const currentPage = pageSize !== paging?.pageSize ? 1 : page;
  //     setPaging({ page: currentPage, pageSize });
  //   },
  //   [paging]
  // );

  return (
    <>
     
      <Card className="ProductInfo-card">
        <Divider style={{margin:10}}/>
        <Form form={form} onFinish={onSubmit} labelCol={{ span: 24 }}>
          <Row gutter={15}>
            <Col lg={6} xl={6}>
              <Form.Item
                label="Nhãn hiệu"
                name="brandId"
                labelCol={{ span: 24 }}
              >
                <Select placeholder="Chọn nhãn hiệu" allowClear>
                  {listBrand.map((item, i) => (
                    <Select.Option key={i} value={item?.id}>
                      {item?.brandName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              <Form.Item
                label="Ngành hàng"
                name="industryId"
                labelCol={{ span: 24 }}
              >
                <Select placeholder="Chọn ngành hàng" allowClear>
                  {listIndustry.map((item, i) => (
                    <Select.Option key={i} value={item?.id}>
                      {item?.productIndustryName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              {/* <Form.Item
                label="Nhà Phân Phối"
                name="distributorId"
                labelCol={{ span: 24 }}
              >
                <Select placeholder="Chọn nhà phân phối" allowClear>
                  {listDistributor.map((item, i) => (
                    <Select.Option key={i} value={item?.id}>
                      {item?.distributorName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
              <Form.Item name="activeStatus" label="Trạng thái">
                <Select allowClear placeholder="Chọn trạng thái">
                  {listStatus.map((item, i) => (
                    <Select.Option key={i} value={item?.status}>
                      {item?.statusName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={6} xl={6}>
            </Col>
          </Row>
          <Row justify="end" gutter={[8]}>
            <Col>
              <Button size="middle" onClick={handleCreateProductInfo("Create")}>
                <span className="d-flex align-items-center">
                  <PlusOutlined />
                  <span className="ml-1">Thêm mới</span>
                </span>
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={handleConfirmfilter}
                size="middle"
                disabled={isLoading}
              >
                <span className="d-flex align-items-center">
                  <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
        <Divider style={{margin:10}}/>
        <div className="CustomerInfor-card__header">
          {/* <Typography.Text>DANH SÁCH SẢN PHẨM</Typography.Text> */}

          {/* <div>
          <Button type="default" className="mr-2" onClick={handleConfirmfilter}>
            Tìm kiếm
          </Button>
          <Button type="primary" onClick={handleCreateProductInfo}>
            Thêm mới
          </Button>
        </div> */}
        </div>
        <Table
          rowKey="id"
          className="ProductInfo-card__table"
          columns={columns}
          dataSource={listProductInfo}
          scroll={{ x: 1200 }}
          bordered
          loading={isLoading}
          // pagination={{
          //   total,
          //   pageSizeOptions: [10, 20, 50],
          //   current: paging?.page ?? 1,
          //   pageSize: paging?.pageSize ?? 10,
          //   onChange: handleChangePaging,
          //   showSizeChanger: true,
          // }}
          size="small"
          pagination={false}
          locale={{ emptyText: "Không có sản phẩm nào" }}
        />
        <Pagination total={total} setPaging={setPaging} paging={paging} />

        <ProductInfoModal
          visible={showProductInfoModal}
          onCancel={handleCloseModal}
          selectedProductInfo={selectedProductInfo}
          mode={isMode}
          onReload={handleGetlistProductInfo}
        />
      </Card>
    </>
  );
}
