import { AxiosResponse } from "axios";
import utils from "utils";

export default class BaseService {
  accessToken = "";
  bearerAccessToken = "";
  getBaseUrl(defaultBaseUrl: string): string {
    return utils.$config.API_GATE_WAY || defaultBaseUrl;
  }
  transformResult(
    url: string,
    res: AxiosResponse,
    action: (res: AxiosResponse) => Promise<any>
  ): Promise<any> {
    return action(res);
  }
  getAccessToken(): string{
    this.accessToken = this.accessToken ? this.accessToken : utils.$oauth.getOauth().accessToken;
    if(this.accessToken){
      this.bearerAccessToken = `Bearer ${this.accessToken}`;
    }
    return this.accessToken;
  }
}
