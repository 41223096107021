import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Card,Spin,message } from "antd";
import styled from "styled-components";
import _ from "lodash";
import Detail from "./detail";
import services from "services";
import Filter from "./detail/Filter";
import moment from "moment";
let time;
const MapCheckinAll = ({ className, profile }) => {
  const ggMapKey = process?.env?.REACT_APP_GG_MAP_KEY;
  const [data, setData] = useState();
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    type: "CI",
    provinceId: undefined,
    distributorId: undefined,
    checkDate: moment(),
  });

  const getCheckinAllStaffs = useCallback(() => {
    setLoading(true);
    services.customerService
      .visitAttendance_GetCheckinOrOutVisitAttendanceAllStaff({
        type: _.get(params, "type"),
        provinceId:  _.get(params, "provinceId"),
        distributorId:  _.get(params, "distributorId"),
        checkDate:_.get(params, "checkDate") && `${moment(_.get(params, "checkDate")).format('YYYY-MM-DD')}T00:00:00.000Z`
      })
      .then((res) => {
        let arrData = _.map(res, (item, index) => {
          item.startTime = moment(item.startTime).format("DD-MM-YYYY HH:mm");
          item.endTime = item.endTime && moment(item.endTime).format("DD-MM-YYYY HH:mm");
          return item;
        });
        setData(arrData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error('Không tìm thấy kết quả')
      });
  }, [filter]);

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(getCheckinAllStaffs, 800);
  }, [getCheckinAllStaffs]);

  return (
    <Card>
      <Row className={className} gutter={16}>
        <Col xs={24} style={{ marginBottom: 10 }}>
          <Filter
            params={params}
            setParams={setParams}
            setFilter={setFilter}
            filter={filter}
          />
        </Col>

        <Col xs={24}>
        <Spin spinning={loading} tip="Đang lấy dữ liệu...">
          <Detail
            data={data}
            params={params}
            ggMapKey={ggMapKey} />
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default styled(MapCheckinAll)``;
