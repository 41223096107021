import React, { memo, useState, useCallback, useEffect } from "react";
import { Row, Col, Card, Spin } from "antd";
import styled from "styled-components";
import _ from "lodash";
import List from "./listCriteria";
import services from "services";
import Filter from "./Filter";
import moment from "moment";

let time;
const Index = memo(({ className }) => {
  const [data, setData] = useState();
  const [dataFilter, setDataFilter] = useState({
    arrBusinessRegions: [],
    arrProvinces: [],
    arrStaffs: [],
  });

  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);

  // for filter
  const [selectedRegionId, setSelectedRegionId] = useState(undefined);
  const [selectedProvinceId, setSelectedProvinceId] = useState();
  const [selectedStaffId, setSelectedStaffId] = useState();
  const [filteredProvinces, setFilteredProvinces] = useState([
    ..._.get(dataFilter, 'arrProvinces'),
  ]);
  const [filteredStaffs, setFilteredStaffs] = useState([
    ..._.get(dataFilter, 'arrStaffs')
  ]);
  //end for filter

  const [params, setParams] = useState({
    regionId: undefined,
    provinceId: undefined,
    staffId: undefined,
    fromDate: moment(),
    toDate: moment(),
  });

  useEffect(() => {
    // api tỉnh
    services.organization
    .province_GetAllProvincesWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });

      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrProvinces = array;
        return nextState;
      });
    })
    .catch((error) => {});

    // api nhân viên        
    services.userManagementService.resource_GetAllSalersWithPermission().then((res) => {
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrStaffs = res;
        return nextState;
      });
    });

    // api khu vực
    services.organization
    .businessRegion_GetAllRegionsWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.regionName;
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrBusinessRegions = array;
        return nextState;
      });
    })
    .catch((error) => {});
  }, [setDataFilter]);

  useEffect(() => {
    var provinces = _.get(dataFilter, 'arrProvinces');
    var staffs = _.get(dataFilter, 'arrStaffs');
    if(provinces && provinces.length > 0 && staffs && staffs.length > 0){
      filterProvinces();
    }
  }, [dataFilter]);

  const filterProvinces = (value) => {
    if (value) {
      let arrProvinces = _.filter(
        _.get(dataFilter, 'arrProvinces'),
        (item, index) => {
          return item?.businessRegionId === value;
        },
      )
      setSelectedProvinceId(null);
      setFilteredProvinces(arrProvinces);
      
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.regionId === value;
        },
      )
      setSelectedStaffId(null);
      setFilteredStaffs(arrStaffs);
      
      setParams((preState) => {
        let nextState = { ...preState };
        nextState['provinceId'] = undefined;
        nextState['staffId'] = undefined;
        return nextState
      });
    } else {
      setFilteredProvinces([..._.get(dataFilter, 'arrProvinces')])
      setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')])
    }
    setSelectedRegionId(value);
  }

  const filterStaffs = (value) => {
    if (value) {        
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.provinceId === value;
        },
      )
      setSelectedStaffId(null);
      setFilteredStaffs(arrStaffs);
      
      setParams((preState) => {
        let nextState = { ...preState };
        nextState['staffId'] = undefined;
        return nextState
      });
    } else {
      if (selectedRegionId) {
        let arrStaffs = _.filter(
          _.get(dataFilter, 'arrStaffs'),
          (item, index) => {
            return item?.regionId === selectedRegionId;
          },
        )
        setFilteredStaffs(arrStaffs);
      } else {
        setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
      }
    }
    setSelectedProvinceId(value);
  }

  const getData = useCallback(() => {
    setLoading(true);
    services.customerService
      .visitAttendance_ReportMonitoringVisitAttendance({
        regionId: params.regionId,
        provinceId: params.provinceId,
        staffId: params.staffId,
        fromDate: params.fromDate && params.fromDate.format(),
        toDate: params.toDate && params.toDate.format(),
        pageNumber: paging.pageNumber,
        pageSize: paging.pageSize,
      })
      .then((res) => {
        setData(res.items);
        setLoading(false);
        setTotal(res.totalCount);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [paging]);

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(getData, 800);
  }, [getData]);

  return (
    <Card>
      <Spin spinning={loading} tip="Đang lấy dữ liệu...">
        <Row className={className} gutter={16}>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <Filter
              params={params}
              setParams={setParams}
              dataFilter={dataFilter}
              filterProvinces = {filterProvinces}
              filterStaffs = {filterStaffs}
              filteredProvinces = {filteredProvinces}
              filteredStaffs = {filteredStaffs}
              selectedRegionId = {selectedRegionId}
              selectedProvinceId = {selectedProvinceId}
              selectedStaffId = {selectedStaffId}
              setSelectedStaffId = {setSelectedStaffId}
              paging = {paging}
              setPaging = {setPaging}
              loading={loading}
              setLoading={setLoading}
            />
          </Col>
          <List
            data={data}
            setPaging={setPaging}
            paging={paging}
            total={total}
          />
        </Row>
      </Spin>
    </Card>
  );
});

export default styled(Index)``;
