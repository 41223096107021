import {
  Col,
  Form,
  Button,
  Row,
  Select,
  Card,
  Table,
  Typography,
  DatePicker,
  InputNumber,
  Popconfirm,
  message,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';

import utils from 'utils';
import { map, get, filter } from 'lodash';
import services from 'services';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import ConfirmModal from './confirm';

const actions = {
  APPROVE: 'approve',
  CANCEL: 'cancel',
};

const InboundModal = function () {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { pathname } = useLocation();

  const [documentType, setDocumentType] = useState({
    provider: false,
    customer: true,
    warehouse: false,
  });
  const [listProduct, setListProduct] = useState([]);
  const [productInboundDetails, setProductInboundDetails] = useState([]);
  const [warehouseInfoDto, setWarehouseInfoDto] = useState([]);
  const [toWarehouseInfoDto, setToWarehouseInfoDto] = useState([]);

  const [loading, setLoading] = useState(false);
  const [listDistributor, setListDistributor] = useState([]);
  const [inboundDetail, setInboundDetail] = useState({});
  const [isReloadPage, setIsReloadPage] = useState(false);

  const [configModal, setConfigModal] = useState({
    visible: false,
    type: actions.APPROVE,
    data: {},
  });

  useEffect(() => {
    services.generalService
      .products_GetProductIncludeUnit()
      .then((res) => setListProduct(res))
      .catch(() => setListProduct([]));
  }, []);

  useEffect(() => {
    if (form.getFieldsValue()?.fromDistributorId) {
      const currentDistributor = listDistributor.find(
        (distributor) =>
          distributor?.id === form.getFieldsValue()?.fromDistributorId
      );
      setWarehouseInfoDto(currentDistributor?.warehouseInfoDtos || []);
    }
  }, [form.getFieldsValue().fromDistributorId, listDistributor]);

  useEffect(() => {
    if (form.getFieldsValue()?.toDistributorId) {
      const currentDistributor = listDistributor.find(
        (distributor) =>
          distributor?.id === form.getFieldsValue()?.toDistributorId
      );
      setToWarehouseInfoDto(currentDistributor?.warehouseInfoDtos || []);
    }
  }, [form.getFieldsValue().toDistributorId, listDistributor]);

  useEffect(() => {
    if (id) {
      services.warehouseService
        .productInbound_GetProductInboundByID(id)
        .then((res) => {
          setInboundDetail(res);
        });
    }
  }, [id, isReloadPage]);

  useEffect(() => {
    if (Object.values(inboundDetail).length > 0) {
      if (inboundDetail?.documentType === 'CustomerOrder') {
        setDocumentType({
          customer: true,
          warehouse: false,
          editQuantity: false,
          provider: false,
        });
        form.setFieldsValue({ toWarehouseId: null });
      } else if (inboundDetail?.documentType === 'WarehouseTransferring') {
        setDocumentType({
          customer: false,
          warehouse: true,
          editQuantity: false,
          provider: false,
        });
      } else if (inboundDetail?.documentType === 'EditQuantity') {
        setDocumentType({
          customer: false,
          warehouse: false,
          editQuantity: true,
          provider: false,
        });
      } else if (inboundDetail?.documentType === 'BuyFromProvider') {
        setDocumentType({
          customer: false,
          warehouse: false,
          editQuantity: false,
          provider: true,
        });
      }
      const currentDistributor = listDistributor.find(
        (distributor) => distributor?.id === inboundDetail?.fromDistributorId
      );
      setWarehouseInfoDto(currentDistributor?.warehouseInfoDtos || []);

      const currentToDistributor = listDistributor.find(
        (distributor) => distributor?.id === inboundDetail?.toDistributorId
      );
      setToWarehouseInfoDto(currentToDistributor?.warehouseInfoDtos || []);
      form.setFieldsValue({
        documentCode: inboundDetail?.documentCode,
        documentType: inboundDetail?.documentType,
        inDate: inboundDetail.inDate ? moment(inboundDetail?.inDate) : null,
        customerId: inboundDetail?.customerId,
        fromDistributorId: inboundDetail?.fromDistributorId,
        fromWarehouseId: inboundDetail?.fromWarehouseId,
        toWarehouseId: inboundDetail?.toWarehouseId,
        toDistributorId: inboundDetail?.toDistributorId,
        referenceId: inboundDetail?.referenceId,
        notes: inboundDetail?.notes,
        purchaseId: inboundDetail?.referenceId,
      });
      setProductInboundDetails(
        inboundDetail?.productInboundDetails.map((product) => ({
          ...product,
          id: product?.productId,
        }))
      );
    }
  }, [inboundDetail]);

  const history = useHistory();

  const checkDuplicateProduct = (productInboundDetails) => {
    for (let i = 0; i < productInboundDetails.length; i++) {
      const standardProduct = productInboundDetails[i];
      const lessProductOutbound = productInboundDetails.filter(
        (a, index) => index !== i
      );
      for (let j = 0; j < lessProductOutbound.length; j++) {
        if (
          lessProductOutbound[j]?.id === standardProduct?.id &&
          lessProductOutbound[j]?.unitTypeId === standardProduct?.unitTypeId
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSubmit = (values) => {
    const isEmptyProduct = productInboundDetails.find(
      (product) => !product?.unitTypeId || !product?.quantity
    );

    if (productInboundDetails.length === 0 || isEmptyProduct) {
      return message.error('Vui lòng chọn đầy đủ sản phẩm');
    }

    if (checkDuplicateProduct(productInboundDetails)) {
      return message.error('Vui lòng không chọn sản phẩm giống nhau');
    }

    setLoading(true);

    const payload = {
      ...values,
      referenceId: 'string',
      providerId: null,
      productInboundDetails: productInboundDetails.map((product) => ({
        unitTypeId: product?.unitTypeId,
        quantity: product?.quantity,
        productId: product?.productId || product?.id,
        productName: product?.productName,
        price: product?.price || 0,
        currencyId: product?.currencyId,
        vat: product?.vat || 0,
        vatAmount: product?.vatAmount || 0,
        discount: product?.discount || 0,
        amount: product?.amount || 0,
        amountIncludedVAT: product?.amountIncludedVAT || 0,
        amountExcludedVAT: product?.amountExcludedVAT || 0,
        notes: product?.notes,
      })),
    };

    if (!id) {
      services.warehouseService
        .productInbound_CreateProductInbound(payload)
        .then((res) => {
          if (res?.succeeded) {
            message.success('Thêm phiếu thành công');
            handleRedirectOutboundPage();
          } else {
            message.error('Thêm phiếu thất bại');
          }
        })
        .catch(() => message.error('Thêm phiếu thất bại'))
        .finally(() => setLoading(false));
    } else {
      services.warehouseService
        .productInbound_UpdateProductInbound(id, {
          ...payload,
          currentState: inboundDetail?.currentState,
        })
        .then((res) => {
          if (res?.succeeded) {
            message.success('Sửa phiếu thành công');
            handleRedirectOutboundPage();
          } else {
            message.error('Sửa phiếu thất bại');
          }
        })
        .catch(() => message.error('Sửa phiếu thất bại'))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    services.generalService.distributor_GetAllDistributorsWithPermission().then((res) => {
      setListDistributor(res);
    });
  }, []);

  const handleChangeFromDistributor = (value) => {
    let distributorItem = listDistributor.find((item) => item.id === value);
    setWarehouseInfoDto(distributorItem?.warehouseInfoDtos || []);
    form.getFieldValue().fromWarehouseId = null;
  };

  const handleChangeToDistributor = (value) => {
    let distributorItem = listDistributor.find((item) => item.id === value);
    setToWarehouseInfoDto(distributorItem?.warehouseInfoDtos || []);
    form.getFieldValue().toWarehouseId = null;
  };

  const options = warehouseInfoDto.map((item) => (
    <Select.Option key={item.id} value={item.id}>
      {item.warehouseName}
    </Select.Option>
  ));

  const optionsTo = toWarehouseInfoDto.map((item) => (
    <Select.Option key={item.id} value={item.id}>
      {item.warehouseName}
    </Select.Option>
  ));

  const handleRedirectOutboundPage = () => {
    history.push(`/warehouse/product-inbound`);
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: 75,
      render: (item, row, index) => ++index,
    },
    {
      title: 'Mã Sản phẩm',
      dataIndex: 'productCode',
      key: 'productCode',
      width: 200,
    },
    {
      title: 'Tên Sản phẩm',
      dataIndex: 'productName',
      key: 'productName',
      width: 200,
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'unitTypeName',
      key: 'unitTypeName',
      width: 200,
      render: (value, row) => {
        return value;
      },
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
      render: (itemValue, row, idx) => {
        return !isReadOnly ? (
          <InputNumber
            placeholder={0}
            min={0}
            defaultValue={row?.quantity}
            onChange={handleChangeQuatityProduct(idx)}
          />
        ) : (
          row?.quantity
        );
      },
    },
    {
      title: '#',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, row, index) => (
        <div className='warehouseInfo-card__button'>
          {!isReadOnly && (
            <Popconfirm
              placement='topRight'
              title='Xác nhận xóa sản phẩm ?'
              onConfirm={() => {
                setProductInboundDetails((prev) => {
                  let next = [...prev];
                  next = filter(next, (x, idx) => idx !== index);
                  return next;
                });
              }}
              okText='Xác nhận'
              cancelText='Quay lại'
            >
              <Button
                className='warehouseInfo-card__button--right'
                icon={<DeleteOutlined className='warehouseInfo-card__icon' />}
                type='danger'
              />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  const handleChangeInboundType = (value) => {
    if (value === 'CustomerReturn') {
      setDocumentType({
        customer: true,
        warehouse: false,
        provider: false,
      });
    } else if (value === 'EditQuantity') {
      setDocumentType({
        editQuantity: true,
        customer: false,
        warehouse: false,
        provider: false,
      });
    } else if (value === 'WarehouseTransferring') {
      setDocumentType({
        customer: false,
        warehouse: true,
        provider: false,
      });
    } else if (value === 'BuyFromProvider') {
      setDocumentType({
        customer: false,
        warehouse: false,
        provider: true,
      });
    }
  };

  const handleChangeQuatityProduct = (index) => (value) => {
    const newProductInboundDetails = productInboundDetails.map(
      (product, idx) => {
        if (idx === index) {
          return {
            ...product,
            quantity: value,
          };
        }
        return product;
      }
    );
    setProductInboundDetails(newProductInboundDetails);
  };

  const renderDocumentType = (text) => {
    if (text === 'CustomerReturn') {
      return 'Nhập theo đơn hàng';
    } else if (text === 'WarehouseTransferring') {
      return 'Nhập chuyển kho';
    } else if (text === 'EditQuantity') {
      return 'Nhập điều chỉnh';
    } else if (text === 'BuyFromProvider') {
      return 'Nhập mua từ nhà cung cấp';
    }
  };

  const handleChangeProduct = (value) => {
    if (!value) {
      return null;
    }
    const [productId, unitTypeId] = value?.split('+');
    const curProduct = listProduct.find(
      (product) =>
        product?.id === productId && product?.unitTypeId === unitTypeId
    );
    setProductInboundDetails([...productInboundDetails, curProduct]);
  };

  const handleApproveOrCancelOutbound = (row, type) => () =>
    setConfigModal({
      visible: true,
      data: row,
      type,
    });

  const handleCancelAction = () =>
    setConfigModal({
      visible: false,
      data: {},
      type: actions.APPROVE,
    });

  const handleApproveAction = () => {
    if (configModal.type === actions.APPROVE) {
      handleApproveInbound();
    } else {
      handleCancelInbound();
    }
  };

  const handleApproveInbound = () => {
    services.warehouseService
      .productInbound_UpdateStatusProductInbound({
        id: configModal.data?.id,
        fromStatus: 'pending',
        toStatus: 'approved',
        currentState: configModal.data?.currentState,
      })
      .then((res) => {
        if (res?.succeeded) {
          message.success('Duyệt phiếu thành công');
          setIsReloadPage(!isReloadPage);
        } else {
          message.error('Duyệt phiếu thất bại');
        }
      })
      .catch((err) => {
        message.error('Duyệt phiếu thất bại');
      })
      .finally(() => handleCancelAction());
  };

  const handleCancelInbound = () => {
    services.warehouseService
      .productInbound_CancelProductInbound({
        id: configModal?.data?.id,
        currentState: configModal?.data?.currentState ?? 1,
      })
      .then((res) => {
        if (res?.succeeded) {
          message.success('Hủy phiếu thành công');
          setIsReloadPage(!isReloadPage);
        } else {
          message.error('Hủy phiếu thất bại');
        }
      })
      .catch(() => message.error('Hủy phiếu thất bại'))
      .finally(() => handleCancelAction());
  };

  const isReadOnly =
    pathname.includes('detail') ||
    (warehouseInfoDto?.length > 0 &&
      id &&
      inboundDetail?.status !== utils.$const.INBOUND.STATUS[0].id);

  const customColumns = isReadOnly
    ? columns.slice(0, columns.length - 1)
    : columns;

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      labelCol={{ span: 8 }}
      labelAlign='left'
      className='InboundModalForm'
      initialValues={{
        documentType: utils.$const.INBOUND.DOCUMENT_TYPE[0]?.value,
      }}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Card title='Thông tin nhập hàng' headStyle={{ border: 'none' }}>
            <Row gutter={[16]}>
              <Col span={10}>
                <Form.Item
                  label='Mã phiếu nhập'
                  labelAlign='left'
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.documentCode !== curValues.documentCode
                  }
                >
                  {() => {
                    if (isReadOnly) {
                      return (
                        <Text italic type='success'>
                          {get(inboundDetail, 'documentCode')}
                        </Text>
                      );
                    }
                    return (
                      <Typography.Text italic type='success'>
                        {!id
                          ? 'Mã phiếu nhập được tạo tự động'
                          : get(inboundDetail, 'documentCode')}
                      </Typography.Text>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.documentType !== curValues.documentType
                  }
                  labelAlign='left'
                  label='Hình thức nhập'
                >
                  {({ getFieldValue }) => {
                    if (isReadOnly) {
                      return (
                        <Text>
                          {renderDocumentType(getFieldValue('documentType'))}
                        </Text>
                      );
                    }
                    return (
                      <Form.Item
                        name='documentType'
                        rules={[{ required: true, message: 'Vui lòng nhập' }]}
                      >
                        <Select
                          placeholder='Hình thức nhập'
                          onChange={handleChangeInboundType}
                        >
                          {map(utils.$const.INBOUND.DOCUMENT_TYPE, (stat) => (
                            <Select.Option key={stat.id} value={stat.value}>
                              {stat.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              {(form?.getFieldsValue()?.documentType ===
                utils.$const.INBOUND.DOCUMENT_TYPE[1]?.value ||
                (isReadOnly &&
                  inboundDetail?.documentType ===
                    utils.$const.INBOUND.DOCUMENT_TYPE[1]?.value)) && (
                <Col span={10}>
                  <Form.Item
                    label='Nhà phân phối đi'
                    labelAlign='left'
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.distributorId !== curValues.distributorId
                    }
                  >
                    {({ getFieldValue }) => {
                      if (isReadOnly) {
                        const documentType = (listDistributor || []).find(
                          (x) => x.id === getFieldValue('fromDistributorId')
                        );
                        return (
                          <Text>{get(documentType, 'distributorName')}</Text>
                        );
                      }
                      return (
                        <Form.Item
                          name='fromDistributorId'
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng nhập',
                            },
                          ]}
                        >
                          <Select
                            onChange={handleChangeFromDistributor}
                            placeholder='Chọn nhà phân phối'
                          >
                            {listDistributor.map((item) => (
                              <Select.Option value={item.id} key={item.id}>
                                {item.distributorName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}

              {(form?.getFieldsValue()?.documentType ===
                utils.$const.INBOUND.DOCUMENT_TYPE[1]?.value ||
                (isReadOnly &&
                  inboundDetail?.documentType ===
                    utils.$const.INBOUND.DOCUMENT_TYPE[1]?.value)) && (
                <Col span={10}>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.fromWarehouseId !== curValues.fromWarehouseId
                    }
                    labelAlign='left'
                    label='Từ kho'
                  >
                    {() => {
                      if (isReadOnly) {
                        return (
                          <Text>
                            {get(inboundDetail, 'fromWarehouse.warehouseName')}
                          </Text>
                        );
                      }
                      return (
                        <Form.Item
                          name='fromWarehouseId'
                          rules={[
                            {
                              required: documentType.warehouse,
                              message: 'Vui lòng chọn',
                            },
                          ]}
                        >
                          <Select
                            disabled={!documentType.warehouse}
                            allowClear
                            placeholder='Chọn kho'
                          >
                            {options}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}

              <Col span={10}>
                <Form.Item
                  label={
                    form?.getFieldsValue()?.documentType ===
                      utils.$const.INBOUND.DOCUMENT_TYPE[1]?.value ||
                    (isReadOnly &&
                      inboundDetail?.documentType ===
                        utils.$const.INBOUND.DOCUMENT_TYPE[1]?.value)
                      ? 'Nhà phân phối đến'
                      : 'Nhà phân phối'
                  }
                  labelAlign='left'
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.toDistributorId !== curValues.toDistributorId
                  }
                >
                  {({ getFieldValue }) => {
                    if (isReadOnly) {
                      const documentType = (listDistributor || []).find(
                        (x) => x.id === getFieldValue('toDistributorId')
                      );
                      return (
                        <Text>{get(documentType, 'distributorName')}</Text>
                      );
                    }
                    return (
                      <Form.Item
                        name='toDistributorId'
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập',
                          },
                        ]}
                      >
                        <Select
                          onChange={handleChangeToDistributor}
                          placeholder='Chọn nhà phân phối'
                        >
                          {listDistributor.map((item) => (
                            <Select.Option value={item.id} key={item.id}>
                              {item.distributorName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col span={10}>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.toWarehouseId !== curValues.toWarehouseId
                  }
                  labelAlign='left'
                  label='Đến kho'
                >
                  {() => {
                    if (isReadOnly) {
                      return (
                        <Text>
                          {get(inboundDetail, 'toWarehouse.warehouseName')}
                        </Text>
                      );
                    }
                    return (
                      <Form.Item
                        name='toWarehouseId'
                        allowClear
                        rules={[{ required: true, message: 'Vui lòng nhập' }]}
                      >
                        <Select allowClear placeholder='Chọn kho'>
                          {optionsTo}
                        </Select>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col span={10}>
                <Form.Item
                  label='Ngày nhập'
                  labelAlign='left'
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.inDate !== curValues.inDate
                  }
                >
                  {({ getFieldValue }) => {
                    if (isReadOnly) {
                      return (
                        <Text>
                          {moment(getFieldValue('inDate')).format('DD/MM/YYYY')}
                        </Text>
                      );
                    }
                    return (
                      <Form.Item
                        name='inDate'
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng chọn',
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          placeholder='Chọn ngày'
                          disabled={isReadOnly}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              {id && (
                <Col span={10}>
                  <Form.Item
                    label='Trạng thái'
                    labelAlign='left'
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.inDate !== curValues.inDate
                    }
                  >
                    {() => {
                      const curStatus = utils.$const.INBOUND.STATUS.find(
                        (x) => x.id === inboundDetail?.status
                      );
                      return curStatus ? (
                        <div className={`text--${curStatus?.color}`}>
                          {curStatus?.name}
                        </div>
                      ) : null;
                    }}
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={[16]}>
              <Col span={20}>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.notes !== curValues.notes
                  }
                  labelCol={{ span: 4 }}
                  labelAlign='left'
                  label='Ghi chú'
                >
                  {({ getFieldValue }) => {
                    if (isReadOnly) {
                      return <Text>{getFieldValue('notes')}</Text>;
                    }
                    return (
                      <Form.Item name='notes' placeholder='Nhập ghi chú'>
                        <TextArea placeholder='Nhập ghi chú' rows={5} />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <div className='d-flex align-items-center'>
            <h5 className='mb-0 mr-2'>Chi tiết nhập hàng</h5>
            {!isReadOnly && (
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder='Chọn sản phẩm'
                onSelect={handleChangeProduct}
                allowClear
              >
                {listProduct.map((product) => (
                  <Select.Option
                    key={`${product?.id}-${product?.unitTypeId}`}
                    value={`${product?.id}+${product?.unitTypeId}`}
                  >
                    {product.productName} - {product.unitTypeName}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
        </Col>
        <Col span={24}>
          <Table
            dataSource={productInboundDetails}
            columns={customColumns}
            pagination={isReadOnly ? false : true}
            style={{ maxHeight: 300, overflow: 'auto' }}
          />
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '15px',
            marginTop: '10px',
          }}
        >
          {inboundDetail?.status === utils.$const.INBOUND.STATUS[0].id &&
            !isReadOnly && (
              <Fragment>
                <Button
                  type='success'
                  onClick={handleApproveOrCancelOutbound(
                    inboundDetail,
                    actions.APPROVE
                  )}
                  style={{ backgroundColor: '#22d368' }}
                >
                  <span className='d-flex align-items-center'>Duyệt</span>
                </Button>
                <Button
                  type='danger'
                  onClick={handleApproveOrCancelOutbound(
                    inboundDetail,
                    actions.CANCEL
                  )}
                >
                  <span className='d-flex align-items-center'>Hủy</span>
                </Button>
              </Fragment>
            )}
          {!isReadOnly && (
            <Button
              htmlType='submit'
              type='primary'
              loading={loading}
              disabled={loading}
            >
              Lưu
            </Button>
          )}
          <Button onClick={handleRedirectOutboundPage} disabled={loading}>
            Quay lại
          </Button>
        </Col>
      </Row>
      <ConfirmModal
        title={
          configModal.type === actions.APPROVE
            ? 'Duyệt phiếu xuất'
            : 'Hủy phiếu xuất'
        }
        visible={configModal.visible}
        onCancel={handleCancelAction}
      >
        <div className='confirm-modal__content'>
          Bạn có chắc muốn{' '}
          <strong>
            {configModal.type === actions.APPROVE ? 'duyệt' : 'hủy'}
          </strong>{' '}
          phiếu <strong>{configModal?.data?.documentCode}</strong> không?
        </div>
        <div className='confirm-modal__footer'>
          <Button type='danger' onClick={handleCancelAction}>
            Không
          </Button>
          <Button type='primary' onClick={handleApproveAction}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Form>
  );
};

export default InboundModal;
