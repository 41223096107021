import React, { memo } from "react";
import { Table, Badge, Tag, Button } from "antd";
import styled from "styled-components";
import classNames from "classnames";
import _ from "lodash";
import {
  MessageOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ModalCreate from "../Modal/index";
import Create from "../Create/index";

const List = memo(
  ({
    className,
    data,
    visible,
    setVisible,
    setParams,
    setData,
    setFilter,
    filter,
    dataFilter,
    id,
  }) => {
    // const onEdit = (row) => {
    //   Object.assign(row, {
    //     [`inventoryClone`]: row.inventory,
    //   });
    //   setVisible((preState) => {
    //     let nextState = { ...preState };
    //     nextState.isShow = true;
    //     nextState.type = "edit";
    //     nextState.data = [row];
    //     return nextState;
    //   });
    // };
    const onShow = (row, index) => {
      let objCustomer = {
        createTime: _.get(row, "createTime"),
        comments: _.get(row, "comments"),
        staffId: _.get(row, "customerId"),
        staffName: _.get(row, "customerName"),
        status: 1,
      };
      // xử lí comment của khách hàng unshit lên hàng đầu
      let arrDetail = row.commentDetails;
      arrDetail.unshift(objCustomer);
      setVisible((preState) => {
        let nextState = { ...preState };
        nextState.isShow = true;
        nextState.type = "edit";
        nextState.data = arrDetail;
        nextState.row = row;
        nextState.index = index;
        return nextState;
      });
    };

    const columns = [
      {
        title: "No.",
        dataIndex: "key",
        width: 80,
      },
      {
        title: "Khách hàng",
        dataIndex: "customerName",
        width: 250,
        render: (text, row) => {
          return (
            <div>
              <span>{text}</span>
              <span style={{ marginLeft: 5 }}>
                <Badge count={row.sizeComments}>
                  <MessageOutlined style={{ fontSize: 25, color: "green" }} />
                </Badge>
              </span>
            </div>
          );
        },
      },
      {
        title: "Vấn đề",
        dataIndex: "comments",
        // width: 250,
        render: (text, row) => {
          return {
            children: text,
          };
        },
      },

      {
        title: "Ngày tạo",
        dataIndex: "createTime",
        width: 120,
        render: (text, row) => {
          let time = text && moment(text).format("DD-MM-YYYY");
          return {
            children: time,
          };
        },
      },
      {
        title: "Đã xử lý",
        dataIndex: "problemStatus",
        width: 120,
        align: "center",
        render: (text, row) => {
          return text === 1 ? (
            <Tag
              style={{ width: "100%", padding: 5, borderRadius: 5 }}
              color="#ec1010"
            >
              <span style={{ fontSize: 14 }}>Chưa xử lý</span>
            </Tag>
          ) : (
            <Tag
              style={{ width: "100%", padding: 5, borderRadius: 5 }}
              color="#22d368"
            >
              <span style={{ fontSize: 14 }}>Đã xử lý</span>
            </Tag>
          );
        },
      },
      {
        title: "",
        dataIndex: "success",
        width: 100,
        align: "center",
        // fixed: "right",
        render: (text, row, index) => {
          return {
            children: (
              <>
                <Button
                  type="primary"
                  icon={<EyeOutlined />}
                  onClick={() => onShow(row, index)}
                >
                  Chi tiết trao đổi
                </Button>
                {/* <Tooltip placement="topLeft" title="Sửa">
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => onEdit(row)}
                  >
                    Sửa
                  </Button>
                </Tooltip> */}
              </>
            ),
          };
        },
      },
    ];

    return (
      <div
        className={classNames({
          [className]: true,
        })}
      >
        {/* {visible.isShow && ( */}
        <ModalCreate
          visible={visible}
          setVisible={setVisible}
          setParams={setParams}
          setData={setData}
          data={data}
          setFilter={setFilter}
          filter={filter}
          dataFilter={dataFilter}
        />
        <Create
          visible={visible}
          setVisible={setVisible}
          setParams={setParams}
          setData={setData}
          data={data}
          setFilter={setFilter}
          filter={filter}
          dataFilter={dataFilter}
          id={id}
        />
        {/* )} */}
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          size="small"
        />
      </div>
    );
  }
);

export default styled(List)`
  // thead.ant-table-thead tr th.ant-table-cell {
  //   background: #ffcc00 !important;
  //   color: black;
  // }
`;
