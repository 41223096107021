/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Màn hình `Tổ chức doanh nghiệp`. Route `/organization/oraganizeDefinition`
 * */

import React, { useCallback, useEffect, useState ,memo} from "react";
import { Card, Button, Row, Col, Table, Modal, message } from "antd";
import utils from "utils";
import services from "services";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

//import BusinessAgentModal from "components/modals/organization/BusinessAgentModal";
import BusinessAreaModal from "components/modals/organization/BusinessAreaModal";
import BusinessRegionModal from "components/modals/organization/BusinessRegionModal";
import BusinessDistrictModal from "components/modals/organization/BusinessDistrictModal";
import DistributorModal from "components/modals/DistributorModal";

const AreaNestedTableRender = ({
  region,
  onSetBusinessAreaModalConfig,
  setBusinessLocationModalConfig,
  setDelete,
  isDelete,
}) => {
  // Modal Function
  const handleShowConfirmDelete = useCallback((row,isDelete,setDelete) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa Khu vực <b className="text-danger">{row.areaName}</b>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.organization
          .businessProvince_Delete([
            {
              id: row.id,
              currentState: row.currentState,
              status: row.status,
            },
          ])
          .then(
            () => message.success("Xóa Khu vực thành công."),
            setDelete(!isDelete)
          )
          .catch(() =>
            message.error("Xóa Khu vực không thành công.Vui lòng thử lại")
          );
      },
      onCancel() {},
    });
  }, []);
  const columns = [
    {
      title: "Mã tỉnh",
      dataIndex: "provinceCode",
      key: "provinceCode",
      width: 250,
    },
    {
      title: "Tên tỉnh",
      dataIndex: "provinceName",
      key: "provinceName",
    },
    // {
    //   title: "Mô tả",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "#",
      dataIndex: "",
      width: 50,
      align: "center",
      key: "action",
      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <EditOutlined
              className="mr-2 text-primary"
              onClick={() =>
                onSetBusinessAreaModalConfig({
                  visible: true,
                  dataSource: { ...record, businessRegion: region },
                  mode: utils.$const.COMMON.MODAL_MODE.EDIT,
                })
              }
            />
            <DeleteOutlined
              className="text-danger"
              onClick={() => handleShowConfirmDelete(record,isDelete,setDelete)}
            />
          </div>
        );
      },
    },
  ];
  return (
    <Table
      className="expand-table"
      bordered
      rowKey="id"
      pagination={false}
      columns={columns}
      dataSource={region.businessProvinces}
      expandable={{
        expandedRowRender: (region) => (
          <DistrictTableRender
            region={region}
            setDelete={setDelete}
            isDelete={isDelete}
            setBusinessLocationModalConfig={setBusinessLocationModalConfig}
            // onSetBusinessAreaModalConfig={setBusinessAreaModalConfig}
          />
        ),
      }}
    />
  );
};

// vùng miền cấp huyện
const DistrictTableRender = ({
  region,
  isDelete,
  setDelete,
  setBusinessLocationModalConfig,
}) => {
  // Modal Function
  const handleShowDistrictConfirmDelete = useCallback((row,isDelete,setDelete) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa Khu vực <b className="text-danger">{row.districtName}</b>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.organization
          .businessDistrict_Delete([
            {
              id: row.id,
              currentState: 0,
            },
          ])
          .then(
            () => message.success("Xóa Khu vực thành công."),
            setDelete(!isDelete)
          )
          .catch(() =>
            message.error("Xóa Khu vực không thành công.Vui lòng thử lại")
          );
      },
      onCancel() {},
    });
  }, []);
  const columns = [
    {
      title: "Mã huyện",
      dataIndex: "districtCode",
      key: "districtCode",
      width: 250,
    },
    {
      title: "Tên huyện",
      dataIndex: "districtName",
      key: "districtName",
    },
    // {
    //   title: "Mô tả",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "#",
      dataIndex: "",
      width: 50,
      align: "center",
      key: "action",
      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <EditOutlined
              className="mr-2 text-primary"
              onClick={() =>
                setBusinessLocationModalConfig({
                  visible: true,
                  dataSource: { ...record, districtRegion: region },
                  mode: utils.$const.COMMON.MODAL_MODE.EDIT,
                })
              }
            />
            <DeleteOutlined
              className="text-danger"
              onClick={() => handleShowDistrictConfirmDelete(record,isDelete,setDelete)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table
      className="expand-table"
      bordered
      rowKey="id"
      pagination={false}
      columns={columns}
      dataSource={region.businessDistricts}
      expandable={{
        expandedRowRender: (region) => (
          <BusinessTableRender
            region={region}
            setDelete={setDelete}
            isDelete={isDelete}
            // onSetBusinessAreaModalConfig={setBusinessAreaModalConfig}
          />
        ),
      }}
    />
  );
};

// vùng miền cấp xã
const BusinessTableRender = ({ region, setDelete, isDelete }) => {
  // Modal Function
  const handleShowBusinessDelete = useCallback((row,isDelete,setDelete) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa khu vực{" "}
          <b className="text-danger">{row.distributorName}</b>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.generalService
          .distributor_DeleteBusinessDistributor({
            id: row.id,
            districtId: null,
          })
          .then(
            () => message.success("Xóa khu vực thành công."),
            setDelete(!isDelete)
          )
          .catch(() =>
            message.error("Xóa khu vực không thành công.Vui lòng thử lại")
          );
      },
      onCancel() {},
    });
  }, []);
  const columns = [
    {
      title: "Mã NPP",
      dataIndex: "distributorCode",
      key: "distributorCode",
      width: 250,
    },
    {
      title: "Tên NPP",
      dataIndex: "distributorName",
      key: "distributorName",
    },
    // {
    //   title: "Mô tả",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "#",
      dataIndex: "",
      width: 50,
      align: "center",
      key: "action",
      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            {/* <EditOutlined
              className="mr-2 text-primary"
              onClick={() =>
                onSetBusinessAreaModalConfig({
                  visible: true,
                  dataSource: { ...record, businessRegion: region },
                  mode: utils.$const.COMMON.MODAL_MODE.EDIT,
                })
              }
            /> */}
            <DeleteOutlined
              className="text-danger"
              onClick={() => handleShowBusinessDelete(record,isDelete,setDelete)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table
      className="expand-table"
      bordered
      rowKey="id"
      pagination={false}
      columns={columns}
      dataSource={region.distributors}
    />
  );
};

const OraganizeDefinition = function () {
  const [listRegion, setListRegion] = useState([]);
  const [isDelete, setDelete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fetchingListRegion, setfetchingListRegion] = useState(false);
  const [province, setProvince] = useState([]);
  const [isOpenDistributor, setOpenDistributor] = useState(false);
  const [distributorMode, setDistributorMode] = useState("add");
  const [dataHuman, setDataHuman] = useState({
    arrManager: [],
    //arrAccounting: [],
  });

  const [businessAreaModalConfig, setBusinessAreaModalConfig] = useState({
    mode: utils.$const.COMMON.MODAL_MODE.ADD,
    visible: false,
    dataSource: {},
  });
  const [businessRegionModalConfig, setBusinessRegionModalConfig] = useState({
    mode: utils.$const.COMMON.MODAL_MODE.ADD,
    visible: false,
    dataSource: {},
  });
  const [businessLocationModalConfig, setBusinessLocationModalConfig] =
    useState({
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
      visible: false,
      dataSource: {},
    });
  // const [businessAgentModalConfig, setBusinessAgentModalConfig] = useState({
  //   mode: utils.$const.COMMON.MODAL_MODE.ADD,
  //   visible: false,
  //   dataSource: {},
  // });

  const handleBrowseRegion = useCallback(
    (search) => {
      setfetchingListRegion(true);
      services.organization
        // .businessRegion_GetAllRegion()
        .businessRegion_GetBsWithPagination(1, 100)
        .then((res) => {
          setListRegion(res.items);
        })
        .finally(() => setfetchingListRegion(false));

      // api người quản lý

      services.userManagementService
        .resource_GetAllResources()
        .then((res) => {
          setDataHuman((preState) => {
            let nextState = { ...preState };
            nextState.arrManager = res;
            return nextState;
          });
        })
        .catch((erro) => {
          message.error("Có lỗi xảy ra!");
        });
      // api người kế toán

      // services.organization
      //   .humanResource_GetAllAccounting()
      //   .then((res) => {
      //     setDataHuman((preState) => {
      //       let nextState = { ...preState };
      //       nextState.arrAccounting = res;
      //       return nextState;
      //     });
      //   })
      //   .catch((erro) => {
      //     message.error("Có lỗi xảy ra!");
      //   });

      services.organization.province_GetAllProvincesWithPermission().then((res) => {
        setProvince(res);
      });
    },
    [isLoading, isDelete]
  );
  /* Region */
  const handleCancelBusinessRegionModal = useCallback(() => {
    setBusinessRegionModalConfig({
      visible: false,
      dataSource: {},
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    handleBrowseRegion();
  }, [handleBrowseRegion]);

  /* Area */
  const handleCancelBusinessAreaModal = useCallback(() => {
    setBusinessAreaModalConfig({
      visible: false,
      dataSource: {},
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    handleBrowseRegion();
  }, [handleBrowseRegion]);

  /* Location */
  const handleCancelBusinessLocationModal = useCallback(() => {
    setBusinessLocationModalConfig({
      visible: false,
      dataSource: {},
      mode: utils.$const.COMMON.MODAL_MODE.ADD,
    });
    handleBrowseRegion();
  }, [handleBrowseRegion]);

  // /* Agent */
  // const handleCancelBusinessAgentModal = useCallback(() => {
  //   setBusinessAgentModalConfig({
  //     visible: false,
  //     dataSource: {},
  //     mode: utils.$const.COMMON.MODAL_MODE.ADD,
  //   });
  // }, []);

  // Modal Function
  const handleShowConfirmDelete = useCallback((row,isDelete) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa Vùng/Miền <b className="text-danger">{row.regionName}</b>
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.organization
          .businessRegion_Delete([
            {
              id: row.id,
              currentState: row.currentState,
              status: row.status,
            },
          ])
          .then(() => message.success("Xóa Vùng/Miền thành công."),setDelete(!isDelete)
          )
          .catch(() =>
            message.error("Xóa Vùng/Miền không thành công.Vui lòng thử lại")
          );
      },
      onCancel() {},
    });
  }, []);

  useEffect(() => {
    handleBrowseRegion();
  }, [handleBrowseRegion]);

  //handle reload page
  const handleReloadPage = useCallback(() => {
    setfetchingListRegion(!fetchingListRegion);
  }, [fetchingListRegion]);

  const onCancelDistributor = useCallback(() => {
    setOpenDistributor(false);
    handleBrowseRegion()
  },[handleBrowseRegion]);
  const columnsRegion = [
    {
      title: "Mã vùng/miền",
      dataIndex: "regionCode",
      key: "regionCode",
      width: 250,
    },
    {
      title: "Tên vùng/miền",
      dataIndex: "regionName",
      key: "regionName",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "#",
      dataIndex: "",
      key: "action",
      fixed: "right",
      align: "center",
      width: 50,
      render: (_, record) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <EditOutlined
              className="mr-2 text-primary"
              onClick={() =>
                setBusinessRegionModalConfig({
                  visible: true,
                  dataSource: record,
                  mode: utils.$const.COMMON.MODAL_MODE.EDIT,
                })
              }
            />
            <DeleteOutlined
              className="text-danger"
              onClick={() => handleShowConfirmDelete(record,isDelete)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div id="organizeDefinitionPage h-100">
      <Card
        size="small"
        className="organizeDefinitionPage__wrapper dms__card--rounded h-100"
      >
        <Row gutter={[0, 8]}>
          <Col span={24} className="text-right">
            <Button.Group>
              <Button
                onClick={() =>
                  setBusinessRegionModalConfig({
                    mode: utils.$const.COMMON.MODAL_MODE.ADD,
                    visible: true,
                    dataSource: {},
                  })
                }
                type="primary"
              >
                Thêm vùng miền
              </Button>
              <Button
                onClick={() =>
                  setBusinessAreaModalConfig({
                    mode: utils.$const.COMMON.MODAL_MODE.ADD,
                    visible: true,
                    dataSource: {},
                  })
                }
                type="primary"
              >
                {/* Thêm khu vực */}
                Thêm Tỉnh/ Thành
              </Button>
              <Button
                onClick={() =>
                  setBusinessLocationModalConfig({
                    mode: utils.$const.COMMON.MODAL_MODE.ADD,
                    visible: true,
                    dataSource: {},
                  })
                }
                type="primary"
              >
                {/* Thêm địa điểm */}
                Thêm Quận/ Huyện
              </Button>
              <Button
                onClick={() =>
                  // setBusinessAgentModalConfig({
                  //   mode: utils.$const.COMMON.MODAL_MODE.ADD,
                  //   visible: true,
                  //   dataSource: {},
                  // })
                  {
                    setOpenDistributor(true);
                    setDistributorMode("add");
                  }
                }
                type="primary"
              >
                {/* Thêm đại lý */}
                Thêm Nhà Phân Phối
              </Button>
            </Button.Group>
          </Col>
          <Col span={24}>
            <Table
              size="small"
              pagination={false}
              loading={fetchingListRegion}
              bordered
              rowKey="id"
              columns={columnsRegion}
              dataSource={listRegion}
              expandable={{
                expandedRowRender: (region) => (
                  <AreaNestedTableRender
                    region={region}
                    isDelete={isDelete}
                    setDelete={setDelete}
                    onSetBusinessAreaModalConfig={setBusinessAreaModalConfig}
                    setBusinessLocationModalConfig={
                      setBusinessLocationModalConfig
                    }
                  />
                ),
              }}
              locale={{ emptyText: "Không có dữ liệu" }}
            />
          </Col>
        </Row>
      </Card>

      {/* Region Modal */}
      <BusinessRegionModal
        config={businessRegionModalConfig}
        onCancel={handleCancelBusinessRegionModal}
        onReloadPage={handleReloadPage}
        dataHuman={dataHuman}
      />
      {/* Area Modal */}
      <BusinessAreaModal
        config={businessAreaModalConfig}
        onCancel={handleCancelBusinessAreaModal}
        dataHuman={dataHuman}
      />
      {/* Location Modal */}
      <BusinessDistrictModal
        config={businessLocationModalConfig}
        onCancel={handleCancelBusinessLocationModal}
        setBusinessLocationModalConfig={setBusinessLocationModalConfig}
        dataHuman={dataHuman}
        setLoading={setLoading}
        isLoading={isLoading}
      />
      {/* Agent Modal */}
      {/* <BusinessAgentModal
        config={businessAgentModalConfig}
        onCancel={handleCancelBusinessAgentModal}
      /> */}

      <DistributorModal
        visible={isOpenDistributor}
        mode={distributorMode}
        onCancel={onCancelDistributor}
        onReloadPage={handleReloadPage}
        dataHuman={dataHuman}
        listProvince={province}
        setLoading={setLoading}
        isLoading={isLoading}
      />
    </div>
  );
};
export default memo(OraganizeDefinition);
