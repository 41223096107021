import React, { useState, memo } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import styled from "styled-components";
import _ from "lodash";
import Map2 from "../../../../images/iconusser.png";
import {
  ShoppingFilled
} from "@ant-design/icons";

const customerMarker = memo(({ item}) => {
  const google = window.google;
  
  return (
    <div>
      <Marker
        position={{ lat: item.latitude, lng: item.longitude }}
        icon={{
          url: Map2,
          scaledSize: new window.google.maps.Size(50, 50),
        }}
      />

        <div>
          <InfoWindow
            className="test"
            position={{
              lat: item.latitude,
              lng: item.longitude,
            }}
            options={{pixelOffset: new google.maps.Size(0, -30)}}
          >
            <div style={{ border: "1px solid 1px" }}>
              <div style={{ color: "black", fontWeight: "bold", borderBottom: 3 }}>
                {_.get(item, "wardName", "")}
                {_.map(item.products, (product, index) => {
                  return (
                    <ShoppingFilled style={{ color: `${product}`, fontSize: '20px' }} title={index}/>
                  );
                })}
              </div>
            </div>
          </InfoWindow>
        </div>
    </div>
  );
});
export default styled(customerMarker)``;
