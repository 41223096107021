import { useEffect, useState } from 'react';
import {
  Card,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  Button,
  Table
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import services from 'services';

const StaticInventoryPage = () => {
  const [form] = Form.useForm();
  const [params, setParams] = useState({ pageNumber: 0, pageSize: 10 });
  const [province, setProvince] = useState([]);
  const [listDistributor, setListDistributor] = useState([]);
  const [listWarehouse, setListWarehouse] = useState([]);
  const [productIndustry, setProductIndustry] = useState([]);
  const [productBrand, setProductBrand] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    services.generalService
      .warehouses_GetAllWarehouse()
      .then((res) => setListWarehouse(res))
      .catch(() => setListWarehouse([]));
  }, []);

  useEffect(() => {
    services.generalService
      .distributor_GetAllDistributorsWithPermission()
      .then((res) => {
        setListDistributor(res);
      })
      .catch(() => setListDistributor([]));
  }, []);

  useEffect(() => {
    services.organization.province_GetAllProvincesWithPermission().then((res) => {
      setProvince(res);
    });

    services.generalService
      .productIndustry_GetAllProductIndustry()
      .then((res) => {
        setProductIndustry(res);
      });

    services.generalService.productBrand_GetAllBrand().then((res) => {
      setProductBrand(res);
    });
  }, []);

  useEffect(() => {
    services.satatisticReportService
      .inventoryInspection_WarehouseInventoryInspectionReport({
        pageNumber: 0,
        pageSize: 10,
      })
      .then((res) => setData(res?.items || []))
      .catch(() => setData([]));
  }, [params]);

  const handleSubmit = (values) => {
    setParams({ ...values, load: true });
  };

  const columns = [
    {
      dataIndex: '',
      key: '',
      title: 'Chu kỳ',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Mã NPP',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tên NPP',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Mã kho',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tên kho',
      width: 300,
      ellipsis: true,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Mã SP',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tên sản phẩm',
      width: 250,
      ellipsis: true,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Đơn vị tính',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tồn đầu kỳ',
      align: 'right',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Tổng nhập',
      children: [
        {
          dataIndex: '',
          key: '',
          title: 'Nhập mua hàng NCC',
          align: 'right',
          width: 200,
          ellipsis: true,
        },
        {
          dataIndex: '',
          key: '',
          title: 'Nhập chuyển kho',
          align: 'right',
          width: 150,
          ellipsis: true,
        },
        {
          dataIndex: '',
          key: '',
          title: 'Nhập điều chỉnh',
          align: 'right',
          width: 150,
          ellipsis: true,
        },
      ],
    },
    {
      title: 'Tổng xuất',
      children: [
        {
          dataIndex: '',
          key: '',
          title: 'Xuất bán',
          align: 'right',
          width: 150,
          ellipsis: true,
        },
        {
          dataIndex: '',
          key: '',
          title: 'Xuất KM',
          align: 'right',
          width: 150,
          ellipsis: true,
        },
        {
          dataIndex: '',
          key: '',
          title: 'Xuất chuyển kho',
          align: 'right',
          width: 150,
          ellipsis: true,
        },
        {
          dataIndex: '',
          key: '',
          title: 'Xuất điều chỉnh',
          align: 'right',
          width: 150,
          ellipsis: true,
        },
      ],
    },
    {
      dataIndex: '',
      key: '',
      title: 'Tồn cuối kỳ',
      align: 'right',
      width: 150,
      ellipsis: true,
    },
  ];

  return (
    <Card className='orderManagementPage'>
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={6}>
            <Form.Item label='Tỉnh thành' name='provinceId'>
              <Select placeholder='Chọn tỉnh thành' allowClear>
                {province.map((pv, id) => (
                  <Select.Option key={id} value={pv?.id}>
                    {pv?.provinceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item label='Nhà phân phối' name='distributorId'>
              <Select placeholder='Chọn nhà phân phối' allowClear>
                {listDistributor.map((distributor, id) => (
                  <Select.Option key={id} value={distributor?.id}>
                    {distributor?.distributorName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item label='Kho' name='warehouseId'>
              <Select placeholder='Chọn kho' allowClear>
                {listWarehouse.map((wh, id) => (
                  <Select.Option key={id} value={wh?.id}>
                    {wh?.warehouseName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item label='Nhóm hàng' name='productIndustryId'>
              <Select placeholder='Chọn nhóm hàng' allowClear>
                {productIndustry.map((area, id) => (
                  <Select.Option key={id} value={area?.id}>
                    {area?.productIndustryName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8]}>
          <Col lg={24} xl={6}>
            <Form.Item name='fromDate' label='Từ ngày'>
              <DatePicker style={{ width: '100%' }} placeholder='Từ ngày' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name='toDate' label='Đến ngày'>
              <DatePicker style={{ width: '100%' }} placeholder='Đến ngày' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name='inspectionDate' label='Ngày đặt hàng'>
              <DatePicker
                style={{ width: '100%' }}
                placeholder='Ngày đặt hàng'
              />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item label='Nhãn hiệu' name='producBrandId'>
              <Select placeholder='Chọn nhãn hiệu' allowClear>
                {productBrand.map((area, id) => (
                  <Select.Option key={id} value={area?.id}>
                    {area?.brandName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row
          justify='end'
          style={{ marginBottom: '12px', display: 'flex', gap: '10px' }}
        >
          <Button
            type='primary'
            htmlType='submit'
            icon={<SearchOutlined />}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Tìm kiếm
          </Button>
          <Button type='dashed'>
            <span className='d-flex align-items-center'>
              <DownloadOutlined /> <span className='ml-1'>Xuất file Excel</span>
            </span>
          </Button>
        </Row>
      </Form>

      <Table
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 1200 }}
        // pagination={{
        //   total,
        //   pageSizeOptions: [10, 20, 50],
        //   current: paging?.pageNumber ?? 1,
        //   pageSize: paging?.pageSize ?? 10,
        //   onChange: handleChangePaging,
        //   showSizeChanger: true,
        //   showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        // }}
        // loading={isLoading}
        locale={{ emptyText: 'Không có dữ liệu' }}
      />
    </Card>
  );
};

export default StaticInventoryPage;
