import { Fragment, useCallback, useEffect, useState } from 'react';
import { Card, Table, Button, message, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  EditFilled,
  UnorderedListOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { omit } from 'lodash';

import SearchForm from 'components/product/outbound/SearchForm';

import services from 'services';
import datetime from 'utils/datetime';
import utils from 'utils';
import ConfirmModal from 'components/modals/confirm';

const ProductOutbound = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [params, setParams] = useState({ page: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [listProductOutbound, setListProductOutbound] = useState([]);
  const [total, setTotal] = useState(0);

  const [isReloadPage, setIsReloadPage] = useState(false);

  useEffect(() => {
    const newParams = omit(params, ['page', 'pageSize']);
    if (Object.values(newParams).length === 0) {
      handleGetListProductOutbound();
    } else {
      handleFilterListOutbound();
    }
  }, [params, isReloadPage]);

  const handleGetListProductOutbound = () => {
    setLoading(true);
    services.warehouseService
      .productOutbound_GetBsWithPagination(params?.page, params?.pageSize)
      .then((res) => {
        setTotal(res?.totalCount ?? 0);
        setListProductOutbound(res?.items ?? []);
      })
      .catch(() => {
        setTotal(0);
        setListProductOutbound([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterListOutbound = () => {
    setLoading(true);
    const { values } = params;
    services.warehouseService
      .productOutbound_GetProductOutboundFilterPaging({
        pageNumber: 1,
        pageSize: params?.pageSize,
        fromDate: values?.fromDate,
        documentCode: values?.documentCode,
        documentType: values?.documentType,
        status: values?.status,
        customerId: values?.customerId,
        toDate: values?.toDate,
        fromWarehouseId: values?.fromWarehouseId,
      })
      .then((res) => {
        setTotal(res?.totalCount ?? 0);
        setListProductOutbound(res?.items ?? []);
      })
      .catch(() => message.error('Lấy thông tin phiếu xuất thất bại'))
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      title: 'Mã phiếu',
      dataIndex: 'documentCode',
      key: 'documentCode',
      width: 200,
      align: 'center',
    },
    {
      title: 'Hình thức xuất',
      dataIndex: 'documentType',
      key: 'documentType',
      width: 200,
      ellipsis: true,
      render: (value) => {
        return utils.$const.OUTBOUND.DOCUMENT_TYPE.find(
          (document) => value === document?.value
        )?.name;
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'outDate',
      key: 'outDate',
      width: 150,
      align: 'center',
      render: (value) => (value ? datetime.formatDatetime(value) : ''),
    },
    {
      title: 'Ngày xuất',
      dataIndex: 'outDate',
      key: 'outDate',
      width: 150,
      align: 'center',
      render: (value) => (value ? datetime.formatDatetime(value) : ''),
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customer.customerName',
      key: 'customer.customerName',
      width: 200,
      ellipsis: true,
      render: (_, row) => row?.customer?.customerName ?? '',
    },
    {
      title: 'Kho đi',
      dataIndex: 'fromWarehouse.warehouseName',
      key: 'fromWarehouse.warehouseName',
      width: 150,
      ellipsis: true,
      render: (_, row) => row?.fromWarehouse?.warehouseName ?? '',
    },
    {
      title: 'Kho đến',
      dataIndex: 'toWarehouse.warehouseName',
      key: 'toWarehouse.warehouseName',
      width: 150,
      ellipsis: true,
      render: (_, row) => row?.toWarehouse?.warehouseName ?? '',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      align: 'center',
      fixed: 'right',
      render: (value) => {
        const curStatus = utils.$const.OUTBOUND.STATUS.find(
          (x) => x.id === value
        );
        return curStatus ? (
          <div className={`status__outbound status--${curStatus?.color}`}>
            {curStatus?.name}
          </div>
        ) : null;
      },
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      fixed: 'right',
      align: 'center',
      render: (_, row) => {
        return (
          <div className='warehouseInfo-card__button'>
            {row?.status === utils.$const.OUTBOUND.STATUS[0].id && (
              <Button type='primary' onClick={handleEditOutbound(row)}>
                <span className='d-flex align-items-center'>
                  <EditFilled />
                </span>
              </Button>
            )}

            <Button
              icon={<UnorderedListOutlined />}
              onClick={handleViewOutboundDetail(row)}
            />
          </div>
        );
      },
    },
  ];

  const handleChangePaging = (page, pageSize) => {
    const currentPage = pageSize !== params?.pageSize ? 1 : page;
    setParams({ ...params, page: currentPage, pageSize });
  };

  const handleShowAddOutboundModal = () => {
    history.push('/warehouse/product-outbound/create');
  };

  const handleViewOutboundDetail = (row) => () => {
    history.push(`/warehouse/product-outbound/detail/${row?.id}`);
  };

  const handleEditOutbound = (row) => () => {
    history.push(`/warehouse/product-outbound/edit/${row?.id}`);
  };

  const handleSubmit = (values) => setParams({ ...params, values, page: 1 });

  return (
    <Fragment>
      <Card className='ProductInboundPage'>
        <div className='ProductInbound-card__header'>
          <h4>Thông tin xuất hàng</h4>
        </div>
        <Form onFinish={handleSubmit} form={form} labelCol={{ span: 24 }}>
          <SearchForm onShowAddOutboundModal={handleShowAddOutboundModal} />
        </Form>
        <div className='outbound__table'>
          <Table
            columns={columns}
            dataSource={listProductOutbound}
            loading={loading}
            bordered
            scroll={{ x: 1200 }}
            rowKey={(row) => row?.id}
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: params?.page ?? 1,
              pageSize: params?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} trong ${total} dòng`,
            }}
            locale={{ emptyText: 'Không có phiếu xuất nào' }}
          />
        </div>
      </Card>
    </Fragment>
  );
};

export default ProductOutbound;
