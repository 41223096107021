import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  DatePicker
} from "antd";
import React, {
  useEffect,
  useState,
} from "react";
import services from "services";
import moment from 'moment';

const PromotionModal = ({
  visible,
  onCancel,
  onSave,
  selectedPromotion,
  mode,
  ...rest
}) => {
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 19 },
  };
  const [form] = Form.useForm();

  const [isShowSpiner, setShowSpiner] = useState(true);
  const [title, setTitle] = useState("");

  useEffect(() => {
    let initValues = {
      name: selectedPromotion?.name,
      startDate: moment(selectedPromotion?.startDate),
      endDate: moment(selectedPromotion?.endDate),
      turnover: selectedPromotion?.turnover ?? 0,
      promotionTypeId: selectedPromotion?.promotionTypeId ?? 1,
      bonusPercentage: selectedPromotion?.bonusPercentage ?? 0,
      description: selectedPromotion?.description
    };
    form.setFieldsValue(initValues);

    if (mode === "Edit") {
      setTitle("Chỉnh Sửa Khuyến Mãi");
    }
    if (mode === "Create") {
      setTitle("Thêm Mới Khuyến Mãi");
    }
    if (mode === "View") {
      setTitle("Chi Tiết Khuyến Mãi");
    }
    setShowSpiner(false);
  }, [selectedPromotion, form, mode]);

  const onSubmit = (values) => {
    setShowSpiner(true);
    const dataSubmit = form.getFieldValue();
    const dataClone = {
      id: selectedPromotion?.id,
      currentState: selectedPromotion?.currentState,
      name: dataSubmit.name,
      startDate: `${moment(dataSubmit.startDate).format('YYYY-MM-DD')}T00:00:00.000Z`,
      endDate: `${moment(dataSubmit.endDate).format('YYYY-MM-DD')}T00:00:00.000Z`,
      turnover: dataSubmit.turnover,
      promotionTypeId: dataSubmit.promotionTypeId,
      bonusPercentage: dataSubmit.bonusPercentage,
      description: dataSubmit.description
    };    

    if (mode === "Create") {
      services.shippingOrderService
      .promotion_Create(dataClone)
      .then((res) => {
          message.success("Thêm khuyến mãi thành công");        
          onSave();
      })
      .catch((error) => {
        message.error("Có lỗi trong quá trình thêm mới");
        onCancel();
      })
      .finally(() => setShowSpiner(false));
    } else {
      services.shippingOrderService
      .promotion_Update(dataClone)
      .then((res) => {
        if(res.succeeded) {
          message.success("Cập nhật khuyến mãi thành công");        
          onSave();
        } else {
          message.error(res.errors.length > 0 && res.errors[0]);
          onCancel();
        }
      })
      .catch((error) => {
        message.error("Có lỗi trong quá trình cập nhật");
        onCancel();
      })
      .finally(() => setShowSpiner(false));
    }
  };


  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      {...rest}
      title={title}
      loading={true}
      width="70%"
      footer={[
        <Button
          key="save"
          style={{ display: mode !== "View" ? "" : "none" }}
          type="primary"
          onClick={() => form.submit()}
        >
          Lưu
        </Button>,

        <Button
          key="back"
          onClick={onCancel}
        >
          Huỷ
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} labelAlign="left" {...layout}>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Tên chương trình"
              name="name"
              rules={[{ required: true, message: "Nhập tên chương trình" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item 
              label='Loại khuyến mãi' 
              name='promotionTypeId'
              className='pl-3'
              rules={[
                { required: true, message: "Chọn loại khuyến mãi" }
              ]}
            >
              <Select placeholder='Chọn loại khuyến mãi'
                      disabled={mode === "Create" ? false: true}
                      allowClear>
                <Select.Option key={1} value={1}>
                  Đạt doanh số quý, thưởng chiết khấu %
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Ngày bắt đầu"
              rules={[{ required: true, message: "Chọn ngày bắt đầu" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Chọn ngày"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="% thưởng"
              min={0}
              name="bonusPercentage"
              className='pl-3'
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="endDate"
              label="Ngày kết thúc"
              rules={[{ required: true, message: "Chọn ngày kết thúc" }]}              
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Chọn ngày"
              />
            </Form.Item>
            
            <Form.Item
              label="Doanh số"
              required
              name="turnover"
              rules={[{ required: true, message: "Vui lòng nhập doanh số" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Nhập doanh số"
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label="Mô tả"
              className='pl-3'
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Spin
        style={{
          position: "absolute",
          left: "50%",
          right: "50%",
          top: "40%",
          display: isShowSpiner ? "" : "none",
        }}
        size="large"
      />
    </Modal>
  );
};

export default PromotionModal;
