/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 20/09/2021.
 * @description Màn hình `Thông tin đơn vị tính`. Route `/generalManagement/UnitType`
 * */

import { useCallback, useEffect, useState } from "react";
import { Card, Button, Input, Table, message, Row, Col, Form } from "antd";
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteFilled } from "@ant-design/icons";
import UnitTypeModal from "components/modals/UnitTypeModal";
import services from "services";
import APP_CONST from "utils/constants";
import ConfirmModal from "components/modals/confirm";

const UnitTypePage = function (){
    const [form] = Form.useForm();
    const [data, setData] = useState([]);

    const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [isReloadPage, setIsReloadPage] = useState(false);

    useEffect(() => {
      setIsLoading(true);
      services.generalService.unitTypes_GetUnitTypesWithPagination(paging?.pageNumber, paging?.pageSize)
      .then((res)=>{
        setIsLoading(false);
        setTotal(res?.totalCount ?? 0);
        setData(res.items);
      })
      .catch((error)=>{
        setTotal(0);
        setIsLoading(false);
      })
    }, [paging,isReloadPage]);

    const columns = [
        {
            title: "Mã đơn vị tính",
            dataIndex: "unitCode",
            key:"code",
            width: 200,
        },
        {
            title: "Tên đơn vị tính",
            dataIndex: "unitName",
            key:"name",
            width: 400,
        },
        {
            title: "Mô tả",
            dataIndex: "notes",
            key:"notes",
            width: 500,
        },
        {
            title: "Trạng thái",
            dataIndex: "activeStatus",
            key:"activeStatus",
            align: "center",
            width: 150,
            ellipsis: true,
            render: (value) => (
              <div className={`status--${value ? "active" : "inactive"}`}>
                {
                  APP_CONST?.UNIT_TYPE?.STATUS?.find(
                    (status) => status?.id === value
                  )?.name
                }
              </div>
            ),
        },
        {
            dataIndex: "action",
            key: "action",
            title: "#",
            align: "center",
            fixed: "right",
            width: 100,
            render: (_, row) => (
              <div className="warehouseInfo-card__button">
                <Button
                    type="primary"
                    size="small"
                    onClick={handleEditUnitType(row)}
                >
                  <span className="d-flex align-items-center">
                    <EditOutlined /> <span className="ml-1"></span>
                  </span>
                </Button>
                <Button
                    className="warehouseInfo-card__button--right"
                    type="danger"
                    size="small"
                    onClick={handleDeleteUnitType(row)}
                >
                  <span className="d-flex align-items-center">
                    <DeleteFilled /> <span className="ml-1"></span>
                  </span>
                </Button>
              </div>
            ),
        },
    ];
    
    const [selectedUnit, setSelectedUnit] = useState({});
    const [isOpenUnitTypeModal, setIsOpenUnitTypeModal] = useState(false);
    const [unitTypeModalMode, setUnitTypeModalMode] = useState("add");
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

    const handleCreateUnitType = useCallback(() => {
    setUnitTypeModalMode("add");
    setIsOpenUnitTypeModal(true);
    setSelectedUnit([]);
  }, []);
  
  const handleEditUnitType = useCallback(
    (provider) => (e) => {
      setUnitTypeModalMode("edit");
      setIsOpenUnitTypeModal(true);
      setSelectedUnit(provider);
    },
    [setUnitTypeModalMode, setIsOpenUnitTypeModal, setSelectedUnit]
  );

  const handleCancelUnitType = useCallback(() => {
    setIsOpenUnitTypeModal(false);
    setUnitTypeModalMode("");
  }, [setIsOpenUnitTypeModal, setUnitTypeModalMode]);

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedUnit({});
  }, [setSelectedUnit, setIsReloadPage, isReloadPage]);
  
  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    },
    [paging]
  );

  const handleDeleteUnitType = useCallback(
    (unitType) => () => {
      setSelectedUnit(unitType);
      setIsShowConfirmModal(true);
    },
    [setSelectedUnit, setIsShowConfirmModal]
  );

  const handleApproveToDeleteUnitType = useCallback(() => {
    services.generalService
      .unitTypes_Delete({
        id: selectedUnit?.id,
        currentState: selectedUnit?.currentState
      })
      .then(() => {
        message.success("Đã xóa thành công");
        setIsShowConfirmModal(false);
        setSelectedUnit({});
        handleReloadPage();
      })
      .catch((err) => {
        message.error(err?.message || "Xóa kho thất bại");
      });
  }, [handleReloadPage, selectedUnit]);

  const handleRejectToDeleteUnitType = useCallback(() => {
    setIsShowConfirmModal(false);
    setSelectedUnit({});
  }, [setSelectedUnit]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    let filter = {
      ...form.getFieldsValue(),
      pageNumber: paging.pageNumber,
      pageSize: paging.pageSize
    };
    services.generalService.unitTypes_FilterUnitType(filter)
    .then(res =>{
      setIsLoading(false);
      setData(res.items);
      setTotal(res?.totalCount ?? 0);
      if (res.items.length === 0) {
        message.error("Không tìm thấy thông tin");
      }
      else {
        message.success("Tìm kiếm thành công");
      }
    })
    .catch(err =>{
      setTotal(0);
      setIsLoading(false);
    })
  };
    
  return (
    <Card className="UnitTypePage">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={24} xl={6}>
            <Form.Item name='unitCode' label='Mã đơn vị tính'>
              <Input placeholder='Nhập mã đơn vị tính' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item name='unitName' label='Tên đơn vị tính'>
              <Input placeholder='Nhập tên đơn vị tính' />
            </Form.Item>
          </Col>
          <Col lg={24} xl={6}>
            <Form.Item className='general-button--height' label=' '>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={isLoading}
                >
                  <span className='d-flex align-items-center'>
                    <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
                  </span>
                </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height ' label=' '>
              <Button onClick={handleCreateUnitType}>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Tạo mới</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Export excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
          <Col lg={24} xl={2}>
            <Form.Item className='general-button--height' label=' '>
              <Button className='import-export'>
                <span className='d-flex align-items-center'>
                  <PlusOutlined /><span className='ml-1'>Import excel</span> 
                </span>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 1200 }}
        size="small"
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}/${range[1]} of ${total} items`
        }}
        loading={isLoading}
        locale={{ emptyText: "Không có đơn vị tính" }}
      />
      <UnitTypeModal
        visible={isOpenUnitTypeModal}
        mode = {unitTypeModalMode}
        onCancel = {handleCancelUnitType}
        dataItem = {selectedUnit}
        onReloadPage={handleReloadPage}
      />
      <ConfirmModal
        title="Xóa nhà cung cấp"
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeleteUnitType}
      >
        <div className="confirm-modal__content">
          Bạn có chắc muốn xóa{" "}
          <strong>{setSelectedUnit?.unitName}</strong> không?
        </div>
        <div className="confirm-modal__footer">
          <Button type="danger" onClick={handleRejectToDeleteUnitType}>
            Không
          </Button>
          <Button type="primary" onClick={handleApproveToDeleteUnitType}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Card>
  );
};
export default UnitTypePage;