import { Fragment, useCallback, useEffect } from 'react';
import { Button, Form, Input, Modal, Select, Row, Col, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';

import services from 'services';
import { get } from 'lodash';

const WarehouseModal = ({
  visible,
  onCancel,
  selectedWarehouse = {},
  listWarehouseType = [],
  listProvider = [],
  onReloadPage,
  isReadOnly,
  ...props
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(selectedWarehouse).length > 0) {
      form.setFieldsValue(selectedWarehouse);
    } else {
      form.resetFields();
    }
  }, [selectedWarehouse, form]);

  const handleSubmit = useCallback(
    (values) => {
      if (!selectedWarehouse?.id) {
        services.generalService
          .warehouses_Create({ ...values, activeStatus: 1 })
          .then((res) => {
            if (res?.succeeded) {
              message.success('Thêm kho thành công');
              onReloadPage();
              form.resetFields();
              onCancel();
            }
          })
          .catch((err) => {
            message.error(err?.message ?? 'Thêm kho thất bại');
            onCancel();
          });
      } else {
        services.generalService
          .warehouses_Update({
            id: selectedWarehouse?.id,
            currentState: selectedWarehouse?.currentState,
            ...values,
          })
          .then((res) => {
            if (res?.succeeded) {
              message.success('Sửa kho thành công');
              onReloadPage();
              form.resetFields();
              onCancel();
            }
          })
          .catch((err) =>
            message.success(err?.message ?? 'Sửa kho thành công')
          );
      }
    },
    [form, selectedWarehouse]
  );

  const handleCloseModal = useCallback(() => {
    onCancel && onCancel();
    form.resetFields();
  }, [form]);

  return (
    <Modal
      visible={visible}
      onCancel={handleCloseModal}
      title={
        isReadOnly
          ? 'Thông tin kho'
          : selectedWarehouse?.id
          ? 'Sửa kho'
          : 'Thêm kho'
      }
      footer={false}
      width='60%'
      {...props}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label='Mã kho'
              shouldUpdate={(prevValues, curValues) =>
                prevValues.warehouseCode !== curValues.warehouseCode
              }
              labelAlign='left'
              labelCol={{ span: 6 }}
            >
              {() => {
                if (isReadOnly) {
                  return (
                    <Text italic type='success'>
                      {get(selectedWarehouse, 'warehouseCode')}
                    </Text>
                  );
                }
                return (
                  <Form.Item
                    name='warehouseCode'
                    rules={[
                      { required: true, message: 'Mã kho không được trống' },
                    ]}
                  >
                    <Input placeholder='Nhập mã kho' />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Tên kho'
              shouldUpdate={(prevValues, curValues) =>
                prevValues.warehouseName !== curValues.warehouseName
              }
              labelAlign='left'
              labelCol={{ span: 6 }}
            >
              {() => {
                if (isReadOnly) {
                  return <Text>{get(selectedWarehouse, 'warehouseName')}</Text>;
                }
                return (
                  <Form.Item
                    name='warehouseName'
                    rules={[
                      { required: true, message: 'Tên kho không được trống' },
                    ]}
                  >
                    <Input placeholder='Nhập tên kho' />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label='Loại kho'
              shouldUpdate={(prevValues, curValues) =>
                prevValues.warehouseTypeId !== curValues.warehouseTypeId
              }
              labelAlign='left'
              labelCol={{ span: 6 }}
            >
              {() => {
                if (isReadOnly) {
                  const warehouseType = listWarehouseType?.find(
                    (type) => type?.id === selectedWarehouse?.warehouseTypeId
                  )?.warehouseTypeName;
                  return <Text>{warehouseType}</Text>;
                }
                return (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Loại kho không được trống',
                      },
                    ]}
                    name='warehouseTypeId'
                  >
                    <Select placeholder='Chọn loại kho' allowClear>
                      {listWarehouseType.map((type) => (
                        <Select.Option key={type?.id} value={type?.id}>
                          {type?.warehouseTypeName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Nhà phân phối'
              shouldUpdate={(prevValues, curValues) =>
                prevValues.distributorId !== curValues.distributorId
              }
              labelAlign='left'
              labelCol={{ span: 6 }}
            >
              {() => {
                if (isReadOnly) {
                  const warehouseType = listProvider?.find(
                    (type) => type?.id === selectedWarehouse?.distributorId
                  )?.distributorName;
                  return <Text>{warehouseType}</Text>;
                }
                return (
                  <Form.Item
                    name='distributorId'
                    rules={[
                      {
                        required: true,
                        message: 'Nhà phân phôi không được trống',
                      },
                    ]}
                  >
                    <Select placeholder='Chọn nhà phân phối' allowClear>
                      {listProvider.map((provider) => (
                        <Select.Option key={provider?.id} value={provider?.id}>
                          {provider?.distributorName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label='Kế toán'
              shouldUpdate={(prevValues, curValues) =>
                prevValues.accountancyName !== curValues.accountancyName
              }
              labelAlign='left'
              labelCol={{ span: 6 }}
            >
              {() => {
                if (isReadOnly) {
                  return (
                    <Text>{get(selectedWarehouse, 'representative')}</Text>
                  );
                }
                return (
                  <Form.Item name='accountancyName'>
                    <Input placeholder='Nhập kế toán quản lý' />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Số điện thoại'
              shouldUpdate={(prevValues, curValues) =>
                prevValues.accountancyName !== curValues.accountancyName
              }
              labelAlign='left'
              labelCol={{ span: 6 }}
            >
              {() => {
                if (isReadOnly) {
                  return <Text>{get(selectedWarehouse, 'phoneNumber')}</Text>;
                }
                return (
                  <Form.Item name='phoneNumber'>
                    <Input placeholder='Nhập số điện thoại' />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24]}>
          <Col span={12}>
            <Form.Item
              label='Người đại diện'
              shouldUpdate={(prevValues, curValues) =>
                prevValues.representative !== curValues.representative
              }
              labelAlign='left'
              labelCol={{ span: 6 }}
            >
              {() => {
                if (isReadOnly) {
                  return (
                    <Text>{get(selectedWarehouse, 'representative')}</Text>
                  );
                }
                return (
                  <Form.Item name='representative'>
                    <Input placeholder='Nhập người đại diện' />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Địa chỉ'
              shouldUpdate={(prevValues, curValues) =>
                prevValues.address !== curValues.address
              }
              labelAlign='left'
              labelCol={{ span: 6 }}
            >
              {() => {
                if (isReadOnly) {
                  return <Text>{get(selectedWarehouse, 'address')}</Text>;
                }
                return (
                  <Form.Item name='address'>
                    <Input placeholder='Nhập địa chỉ' />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label='Ghi chú'
              shouldUpdate={(prevValues, curValues) =>
                prevValues.notes !== curValues.notes
              }
              labelAlign='left'
              labelCol={{ span: 3 }}
            >
              {() => {
                if (isReadOnly) {
                  return <Text>{get(selectedWarehouse, 'notes')}</Text>;
                }
                return (
                  <Form.Item name='notes'>
                    <TextArea placeholder='Nhập ghi chú' rows={6} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <div className='warehouse-modal__footer'>
          {isReadOnly ? (
            <Button type='danger' onClick={handleCloseModal}>
              Đóng
            </Button>
          ) : (
            <Fragment>
              <Button htmlType='submit' type='primary'>
                {selectedWarehouse?.id ? 'Lưu' : 'Lưu'}
              </Button>
              <Button type='danger' onClick={handleCloseModal}>
                Hủy
              </Button>
            </Fragment>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default WarehouseModal;
