/**
 * Copyright 2021-present, Hoàng An.
 * All rights reserved.
 * @author tangan2215@gmail.com on 07/06/2021.
 * @description Màn hình Nhóm nhãn hiệu. Route `/productManagement/productBrand`
 **/
import React, { useCallback, useEffect, useState } from "react";
import { Card, Button, Typography, Modal, Col, Row, Select, Divider, Pagination, Spin, message, Form, DatePicker } from "antd";
import services from "services";
//import utils from "utils";
import VisitDateCheckin from "pages/monitoringmanagement/visitdate/VisitDateCheckin";
//import Album from "pages/monitoringmanagement/album/Album";
import { FilterOutlined } from "@ant-design/icons";
import VitsitControlModal from "./modals/VitsitControlModal";
import NoImg from './../../images/NoImg.jpg';


export default function VisitMonitoring() {
  
  let dataFilter = {
    customerId: null,
    staffId: null,
    fromInspectionTime: null,
    toInspectionTime: null,
    pageNumber: 1,
    pageSize: 8
  }

  const [form] = Form.useForm();
  const [listCustomerInfo, setListCustomerInfo] = useState([]);
  const [listStaff, setListStaff] = useState([]);
  //const [isFilterData, setIsFilterData] = useState(false);

  const [listCustomerVisit, setlistCustomerVisit] = useState([]);
  const [listVisitDateOfCustomer, setListVisitDateOfCustomer] = useState([]);
  //const [listImgOfVisit, setListImgOfVisit] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({
    avatar: "",
    customerName: "",
    hotline: "",
    dateVisit: ""
  });

  const [totalPagingCustomer, setTotalPagingCustomer] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowSpiner, setShowSpiner] = useState(true);
  const [detailCheckin, setDetailCheckin] = useState();

  const handleGetlistCustomerVisit = useCallback(() => {
    services.monitoringControlService.apiMonitorcontrolVisitattendanceGetvisitattendancebyfilter(dataFilter).then(res => {
      setlistCustomerVisit(res.items)
      setTotalPagingCustomer(res.totalCount)
      setShowSpiner(false)
    }).catch(erro => {
      message.error("Có lỗi trong quá trình lấy khách hàng ");
    });
  }, []);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowSpiner(true)
    services.generalService.customerInformation_GetListNewCustomer().then(res => {
      setListCustomerInfo(res);
    });
    services.userManagementService.resource_GetAllResources().then(res => {
      setListStaff(res)
    });
    handleGetlistCustomerVisit();
    handleGetlistCustomerVisit();
  }, [handleGetlistCustomerVisit]);


  //const { Meta } = Card;
  const listItemFilter = [
    {
      id: 1,
      name: 'Tìm tất cả nhân viên'
    },
    {
      id: 2,
      name: 'Tìm tất cả khách hàng'
    },
    {
      id: 3,
      name: 'Tìm tất cả các ngày'
    }
  ];

  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isShowCustomer, setIsShowCustomer] = useState(true);
  const [isVisitDate, setIsVisitDate] = useState(false);
  const [isShowAlbum, setIsShowAlbum] = useState(false);


  const CustomerClick = (item) => {
    let customerInfo = {
      avatar: item.customerInformation.avatar,
      customerName: item.customerInformation.customerName,
      phonenumber: item.customerInformation.phonenumber,
      storeName: item.customerInformation.storeName,
      address: item.customerInformation.address
    }
    setCustomerInfo(customerInfo);
    setListVisitDateOfCustomer(item)
    setIsShowCustomer(false)
    setIsVisitDate(true)

  }

  const HandleBackDate = () => {
    setShowSpiner(true)
    handleGetlistCustomerVisit();
    setIsShowCustomer(true)
    setIsVisitDate(false)
  }

  const HandleBackAlbum = () => {
    setIsShowAlbum(false)
    setIsVisitDate(true)
  }

  const ChooseDate = (value) => {
    let customerInfoDetail = {
      avatar: customerInfo.avatar,
      customerName: customerInfo.customerName,
      phonenumber: customerInfo.phonenumber,
      storeName: customerInfo.storeName,
      address: customerInfo.address
    }
    setCustomerInfo(customerInfoDetail);
    setDetailCheckin(value);
    setShowModal(true)
  }

  const onChange = (value) => {
    setShowSpiner(true)
    setCurrentPage(value)

    let dataFilter = {
      customerId: null,
      staffId: null,
      fromInspectionTime: null,
      toInspectionTime: null,
      pageNumber: value,
      pageSize: 8
    }
    services.monitoringControlService.apiMonitorcontrolVisitattendanceGetvisitattendancebyfilter(dataFilter).then(res => {
      setlistCustomerVisit(res.items)
      setShowSpiner(false)
    }).catch(erro => {
      message.error("Có lỗi trong quá trình lấy khách hàng ");
    });

  }

  const handleConfirmfilter = useCallback(() => {
    form.submit();
  }, [form]);

  const onSubmit = (values) => {
    //setIsFilterData(true)
    setShowSpiner(true)
    dataFilter.customerId = values.customerId;
    dataFilter.staffId = values.staffId;
    dataFilter.fromInspectionTime = values.fromInspectionTime;
    dataFilter.toInspectionTime = values.toInspectionTime;
    // let dataFilter = {
    //   customerId: values.customerId,
    //   staffId: values.staffId,
    //   fromInspectionTime: values.fromInspectionTime,
    //   toInspectionTime: values.toInspectionTime,
    //   pageNumber: 1,
    //   pageSize: 8
    // }
    services.monitoringControlService.apiMonitorcontrolVisitimageGetimagesbyfilter(dataFilter).then(res => {
      setlistCustomerVisit(res.items)
      setTotalPagingCustomer(res.totalCount)
      setShowSpiner(false)
    }).catch(erro => {
      setShowSpiner(false)
      message.error("Có lỗi trong quá trình tìm kiếm")
    });
  }



  const hideModal = () => {
    setIsShowFilter(false)
  };

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const style = {
    blue: { background: '#0092ff', padding: '8px 0' },
    card: { width: "100%", cursor: "pointer" },
    cardImg__height: { height: "250px", with: "100%" },
    back: {
      float: "right", backgroundColor: "#9898b4", padding: "5px 10px 5px 10px", color: "white",
      cursor: "pointer"
    }
  }

  return (
    <Card >
      <Form form={form} onFinish={onSubmit} labelCol={{ span: 24 }}>
        <Row gutter={[8]}>
          <Col lg={8} xl={6}>
            <Form.Item
              label="Khách Hàng"
              name="customerId"
              labelCol={{ span: 24 }}
            >
              <Select placeholder="Chọn Khách Hàng" allowClear>
                {listCustomerInfo.map((item, i) =>
                (
                  <Select.Option key={i} value={item?.id}>
                    {item?.customerName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={8}>
            <Form.Item
              label="Nhân Viên"
              name="staffId"
              labelCol={{ span: 24 }}
            >
              <Select placeholder="Chọn Nhân Viên" allowClear>
                {listStaff.map((item, i) =>
                (
                  <Select.Option key={i} value={item?.identifier.userId}>
                    {item?.fullName + '  [' + item?.identifier.userName + ']'}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name="fromInspectionTime" label="Từ Ngày">
              <DatePicker style={{ width: "100%" }} placeholder="Chọn" />
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name="toInspectionTime" label="Đến Ngày">
              <DatePicker style={{ width: "100%" }} placeholder="Chọn" />
            </Form.Item>
          </Col>
        </Row>

      </Form>
      <div className="monitoring-card__header">
        <Typography.Text>GIÁM SÁT VIẾNG THĂM</Typography.Text>

        <div>
          <Button
            type="default"
            onClick={handleConfirmfilter}
            style={{
              display: isShowCustomer ? "" : "none"
            }}>
            <FilterOutlined />
            Tìm kiếm
          </Button>
         

        </div>

      </div>
      <div className="customer" style={{ display: isShowCustomer ? "none" : "" }}>
        <img src={customerInfo.avatar === '' ? NoImg : customerInfo.avatar} alt="" />
        <p><small>Khách Hàng: </small>{customerInfo.customerName}</p>
        <p><small>SĐT: </small>{customerInfo.phonenumber}</p>
        <p><small>Tên Cửa Hàng: </small>{customerInfo.storeName}</p>
        <p><small>Địa Chỉ: </small>{customerInfo.address}</p>    
        <p style={{ display: isShowAlbum ? "" : "none" }}><small>Ngày: </small>{customerInfo.dateVisit}</p>
      </div>
      <Row gutter={[8]} className="button-back">
          <Col>
            <Button
              type="default"
              onClick={HandleBackDate}
              style={{
                display: isVisitDate ? "" : "none"
              }}>
              Quay lại
            </Button>
            <Button
              type="default"
              onClick={HandleBackAlbum}
              style={{
                display: isShowAlbum ? "" : "none"
              }}>
              Quay lại
            </Button>
          </Col>
      </Row>
      <Divider orientation="left" style={{ display: isShowCustomer ? "" : "none" }}>Chọn khách hàng</Divider>
      <Row gutter={16} style={{ display: isShowCustomer ? "" : "none" }}>

        {listCustomerVisit.map((item, i) =>
        (
          <Col className="gutter-row visitcontrol-col" style={{}} span={6} key={i}>
            <Card
              onClick={() => CustomerClick(item)}
              hoverable
              style={style.card}
              cover={
                <img style={style.cardImg__height} alt="example" src={item.customerInformation?.avatar === '' ? NoImg : item.customerInformation?.avatar} />}
            >
              <h6>Tên KH: {item.customerInformation?.customerName}</h6>
              <h6>Tên Cửa Hàng: {item.customerInformation?.storeName}</h6>
              <h6>Thứ Tự Viếng Thăm: {i + 1}</h6>

            </Card>
            <h6 className="visitcontrol-col-h6__custom">Đã Viếng Thăm</h6>
          </Col>
        ))}
      </Row>
      <Row style={{ display: isVisitDate ? "" : "none" }}>
        <VisitDateCheckin
          listDate={listVisitDateOfCustomer}
          ChooseDate={ChooseDate}
        ></VisitDateCheckin>
      </Row>

      <Row style={{ float: "right", marginTop: "1.5em" }}>
        <Pagination
          style={{ display: isShowCustomer ? "" : "none" }}
          total={totalPagingCustomer}
          showTotal={total => `Total ${total} items`}
          //defaultPageSize={1}
          //defaultCurrent={1}
          pageSize={8}
          current={currentPage}
          onChange={onChange}
        />
      </Row>

      <Modal
        width="300px"
        title="Chọn tiêu chí tìm kiếm"
        visible={isShowFilter}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Xác Nhận"
        cancelText="Hủy"
      >
        <Select style={{ width: "100%" }} placeholder="Chọn tiêu chí tìm kiếm" allowClear>
          {listItemFilter.map((item, i) =>
          (
            <Select.Option key={i} value={item?.id}>
              {item?.name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
      <VitsitControlModal
        visible={showModal}
        onCancel={handleCloseModal}
        detailCheckin={detailCheckin}
      />
      <Spin style={{ position: "absolute", left: "50%", right: "50%", top: "50%", display: isShowSpiner ? "" : "none" }} size="large" />

     

    </Card>



  );
}
