import React, { memo } from "react";

import _ from "lodash";
import { Table } from "antd";

const List = memo(({ data, total, paging, setPaging, dataFilter }) => {

  const handleChangePaging = (pageNumber, pageSize) => {
    const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
    setPaging({ pageNumber: currentPage, pageSize });
  };
  
  const kpiColumns = [
    {
      dataIndex: "no",
      key: "no",
      title: "STT",
      align: "center",
      width: 50,
      render: (value, row, index) => ((paging.pageNumber - 1) * paging.pageSize) + index + 1
    },
    {
      title: "Vùng",
      dataIndex: "regionName",
      key: "regionName",
      width: 150,
      render: (value, row) => {
        let name = _.get(dataFilter, "arrBusinessRegions").find(
          (BusinessRegions) => BusinessRegions?.id === row.regionId
        )?.regionName;
        return <span>{name}</span>;
      },
    },
    {
      title: "Tỉnh",
      dataIndex: "provinceName",
      key: "provinceName",
      width: 120,

      render: (value, row) => {
        let name = _.get(dataFilter, "arrProvinces").find(
          (Province) => Province?.id === row.provinceId
        )?.provinceName;
        return <span>{name}</span>;
      },
    },
    {
      title: "Nhân viên",
      dataIndex: "staffName",
      key: "staffName",
      width: 130,
    },
    {
      title: "Kế Hoạch Trong Tháng",
      children: [
        {
          title: "Số KH Mới",
          dataIndex: "plannedNewCustomerCount",
          key: "plannedNewCustomerCount",
          align: "right",
          width: 90,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}</span>;
          },
        },
        {
          title: "Số KH VT",
          dataIndex: "plannedVisitedCustomerCount",
          key: "plannedVisitedCustomerCount",
          align: "right",
          width: 90,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}</span>;
          },
        },
        {
          title: "SL Đơn Hàng",
          dataIndex: "plannedPurchaseOrderCount",
          key: "plannedPurchaseOrderCount",
          align: "right",
          width: 100,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}</span>;
          },
        },
        {
          title: "Doanh Số",
          dataIndex: "plannedTurnoverFormatted",
          key: "plannedTurnoverFormatted",
          align: "right",
          width: 130
        },
      ],
    },
    {
      title: "Thực Hiện",
      children: [
        {
          title: "Số KH Mới",
          dataIndex: "actualNewCustomerCount",
          key: "actualNewCustomerCount",
          align: "right",
          width: 90,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}</span>;
          },
        },
        {
          title: "Số KH VT",
          dataIndex: "actualVisitedCustomerCount",
          key: "actualVisitedCustomerCount",
          align: "right",
          width: 90,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}</span>;
          },
        },
        {
          title: "SL Đơn Hàng",
          dataIndex: "actualPurchaseOrderCount",
          key: "actualPurchaseOrderCount",
          align: "right",
          width: 100,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}</span>;
          },
        },
        {
          title: "Doanh Số",
          dataIndex: "actualTurnoverFormatted",
          key: "actualTurnoverFormatted",
          align: "right",
          width: 130
        }
      ],
    },
    {
      title: "% Đạt Được",
      children: [
        {
          title: "Số KH Mới",
          dataIndex: "percentNewCustomerCount",
          key: "percentNewCustomerCount",
          align: "right",
          width: 90,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}%</span>;
          },
        },
        {
          title: "Số KH VT",
          dataIndex: "percentVisitedCustomerCount",
          key: "percentVisitedCustomerCount",
          align: "right",
          width: 90,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}%</span>;
          },
        },
        {
          title: "SL Đơn Hàng",
          dataIndex: "percentPurchaseOrderCount",
          key: "percentPurchaseOrderCount",
          align: "right",
          width: 100,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}%</span>;
          },
        },
        {
          title: "Doanh Số",
          dataIndex: "percentTurnover",
          key: "percentTurnover",
          align: "right",
          width: 100,
          render: (value) => {
            return <span>{value ? value.toLocaleString() : 0}%</span>;
          },
        },
      ],
    },
  ];

  return (
    <div>
      <Table
        columns={kpiColumns}
        dataSource={data}
        bordered
        size="small"
        scroll={{ x: "calc(700px + 50%)", y: 400 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
      />
    </div>
  );
});

export default List;
