import React, { memo, useCallback, useState, useEffect } from 'react';
import { Row, Col, Select, DatePicker, Button } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select

const FilterStyled = memo(
  ({
    params,
    setParams,
    dataFilter,
    selectedRegionId,
    setSelectedRegionId,
    paging,
    setPaging,
    loading
  }) => {
    const [selectedProvinceId, setSelectedProvinceId] = useState()
    const [filteredProvinces, setFilteredProvinces] = useState([
      ..._.get(dataFilter, 'arrProvince'),
    ])

    useEffect(() => {
      var provinces = _.get(dataFilter, 'arrProvince');
      if(provinces && provinces.length > 0){
        filterProvinces();
      }
    }, [dataFilter]);

    const getQuery = useCallback(
      (value, name) => {
        setParams((preState) => {
          let nextState = { ...preState }
          nextState[name] = value
          return nextState
        })
      },
      [setParams],
    )

    const getFilter = () => {
      setPaging({ pageNumber: 1, pageSize: paging.pageSize });
    }

    const filterProvinces = (value) => {
      if (value) {
        let arrData = _.filter(
          _.get(dataFilter, 'arrProvince'),
          (item, index) => {
            return item?.businessRegionId === value
          },
        )
        setSelectedProvinceId(null)
        setFilteredProvinces(arrData)
      } else {
        setFilteredProvinces([..._.get(dataFilter, 'arrProvince')])
      }
      setSelectedRegionId(value);
    }

    const getProducts = useCallback(() => {
      setParams((preState) => {
        let nextState = { ...preState }
        nextState.regionId = selectedRegionId
        return nextState
      })
    },[selectedRegionId])

    useEffect(() => {
      getProducts()
    }, [getProducts])

    return (
      <div>
        <Row gutter={15}>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Khu vực</span>
            <Select
              value={selectedRegionId}
              style={{ width: '100%', marginTop: 10 }}
              placeholder="Khu vực"
              allowClear
              onChange={(e) => {
                getQuery(e, 'regionId');
                filterProvinces(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(_.get(dataFilter, 'arrBusinessRegions'), (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Tỉnh</span>
            <Select
              style={{ width: '100%', marginTop: 10 }}
              placeholder="Tỉnh"
              allowClear
              value={selectedProvinceId}
              onChange={(e) => {
                getQuery(e, 'provinceId');
                setSelectedProvinceId(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(filteredProvinces, (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Qúy</span>
            <DatePicker
              placeholder="Quý"
              style={{ width: '100%', marginTop: 10 }}
              value={params.quarter}
              onChange={(e) => {
                getQuery(e, 'quarter')
              }}
              picker="quarter"
            />
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 5 }}>
          <Col>
            <Button
              disabled={loading}
              type="primary"
              onClick={getFilter}
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </Col>
        </Row>
      </div>
    )
  },
)
export default styled(FilterStyled)`
  .ant-picker {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .clearParams {
    text-align: right;
  }
`
