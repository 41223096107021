import { Row, Col, Card, Divider } from "antd";
import moment from "moment";

const VisitDateCheckin = ({
  listDate,
  ChooseDate
}) => {
  const style = {
    card: { width: "100%", marginBottom: "1em" },
    cardImg__height: { height: "250px", with: "100%" },
    gridStyle: { width: '25%', textAlign: 'center', cursor: 'pointer' },
    label: { float: "right" },
    cardMeta: { textAlign: "center" }
  }

  return (
    <>
      <Divider orientation="left">Thông Tin CheckIn</Divider>

      <Card style={style.card}>
        <Row gutter={16} >
          {listDate?.visitInspectionList?.map((item, i) =>
          (
            <Col className="gutter-row" span={6} key={i}>
              <Card
                onClick={() => ChooseDate(item)}
                hoverable
                style={style.card}
                cover={
                  <img style={style.cardImg__height} alt="example" src="https://media.istockphoto.com/vectors/calendar-with-time-clock-icon-clock-time-calendar-date-of-schedule-vector-id1217166869?b=1&k=6&m=1217166869&s=170667a&w=0&h=6rZBg1i9V_41BikP2HsDnIIAz6zkweY3vaU29VFNbQo=" />}
              >
                <h6>In: {moment(item?.startInspectionTime).format('HH:mm:ss / DD-MM-YYYY')}</h6>
                <h6>Out: {moment(item?.endInspectionTime).format('HH:mm:ss / DD-MM-YYYY')}</h6>
                <h6>Total Time: {item?.inspectionTime}</h6>
              </Card>
            </Col>
          ))}

        </Row>

      </Card>
    </>
  );
};

export default VisitDateCheckin;
