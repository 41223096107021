import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  message,
  Divider,
  Col,
  Row,
  Form,
  Select,
  Upload,
  DatePicker
} from "antd";
import {
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteFilled,
  UnorderedListOutlined,
  LoadingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import KpiModal from "components/modals/KpiModal";
import services from "services";
import _ from "lodash";
import moment from 'moment';

let time;
const { Option } = Select;

export default function Kpi() {
  const [listKpi, setListKpi] = useState([]);
  const [showKpiModal, setShowKpiModal] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState({});
  const [filterCriteria, setFilterCriteria] = React.useState({month: null, year: null, staffId: null, provinceId: null});

  const [paging, setPaging] = useState({ pageNumber: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);

  const [isMode, setIsMode] = useState("Edit");

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [dataFilter, setDataFilter] = useState({
    arrStaffs: [],
    arrProvinces: [],
  });
  const [filteredStaffs, setFilteredStaffs] = useState([]);

  // Import
  const [importLoading, setImportLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  // end Import
 
  const handleGetListKpi = useCallback(() => {
    setIsLoading(true);
    services.shippingOrderService
      .kpi_FilterKpis({
        staffId: filterCriteria?.staffId,
        provinceId: filterCriteria?.provinceId,
        month: filterCriteria?.monthYear && moment(filterCriteria?.monthYear).format("MM"),
        year: filterCriteria?.monthYear && moment(filterCriteria?.monthYear).format("YYYY"),
        pageNumber: paging?.pageNumber,
        pageSize: paging?.pageSize
      })
      .then((res) => {
        setListKpi(res?.items);
        setIsLoading(false);
        setTotal(res?.totalCount ?? 0);
      })
      .catch((error) => {
        setTotal(0);
      });
  }, [paging, filterCriteria]);

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(() => {
      handleGetListKpi();
    }, 800);
  }, [handleGetListKpi]);

  useEffect(() => {
    // api nhân viên        
    services.userManagementService.resource_GetAllSalersWithPermission().then((res) => {
      let arrData = _.map(res, (item, index) => {
        item.index = index + 1;
        item.id = _.get(item, "userId");
        item.name = _.get(item, "fullName");
        return item;
      });
      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrStaffs = arrData;
        return nextState;
      });
    });

    // api tỉnh
    services.organization
    .province_GetAllProvincesWithPermission()
    .then(async (res) => {
      let array = _.map(res, (item, index) => {
        item.name = item.provinceName;
        return item;
      });

      setDataFilter((preState) => {
        let nextState = { ...preState };
        nextState.arrProvinces = array;
        return nextState;
      });
    })
    .catch((error) => {});

  }, [setDataFilter]);

  useEffect(() => {
    setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
  }, [dataFilter]);

  const filterStaffs = (value) => {
    if (value) {        
      let arrStaffs = _.filter(
        _.get(dataFilter, 'arrStaffs'),
        (item, index) => {
          return item?.provinceId === value;
        },
      )
      form.setFieldsValue({staffId: undefined});
      setFilteredStaffs(arrStaffs);
    } else {
      setFilteredStaffs([..._.get(dataFilter, 'arrStaffs')]);
    }
  }

  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa KPI
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.shippingOrderService
          .kpi_Delete({
            id: row.id,
            currentState: row.currentState,
          })
          .then((res) => {
            message.success("Xóa KPI thành công.");
            handleGetListKpi();
          })
          .catch((erro) => {
            message.error("Có lỗi xảy ra!");
          });
      },
      onCancel() {},
    });
  }, [handleGetListKpi]);

  const onSubmit = () => {
    let dataSubmit = {
      ...form.getFieldValue()
    };
    setFilterCriteria(dataSubmit);
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
  };

  const columns = [,
    {
      dataIndex: "staffCode",
      key: "staffCode",
      title: "Mã NV",
      width: 80,
    },
    {
      dataIndex: "staffName",
      key: "staffName",
      title: "Tên NV",
      width: 130
    },
    {
      dataIndex: "newCustomerCount",
      key: "newCustomerCount",
      title: "Khách hàng mới",
      width: 80,
    },
    {
      dataIndex: "visitedCustomerCount",
      key: "visitedCustomerCount",
      title: "Khách viếng thăm",
      width: 80,
    },
    {
      dataIndex: "purchaseOrderCount",
      key: "purchaseOrderCount",
      title: "Số lượng đơn",
      width: 80,
    },
    {
      dataIndex: "turnoverFormatted",
      key: "turnoverFormatted",
      title: "Doanh số",
      width: 120
    },
    {
      dataIndex: 'workdays',
      key: "workdays",
      title: 'Số ngày công',
      width: 90
    },
    {
      dataIndex: 'month',
      key: "month",
      title: 'Tháng',
      width: 50
    },
    {
      dataIndex: 'year',
      key: "year",
      title: 'Năm',
      width: 50
    },
    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      width: 150,
      fixed: "right",
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Button
            onClick={handleModalKpi(row, "Edit")}
            type="primary"
            size="small"
          >
            <span className="d-flex align-items-center">
              <EditOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            danger
            size="small"
            onClick={() => handleShowConfirmDelete(row)}
          >
            <span className="d-flex align-items-center">
              <DeleteFilled /> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            type="ghost"
            size="small"
            className="ml-1"
            onClick={handleModalKpi(row, "View")}
          >
            <span className="d-flex align-items-center">
              <UnorderedListOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const handleCreateKpi = useCallback(
    (mode) => (e) => {
      setShowKpiModal(true);
      setIsMode(mode);
    },
    []
  );

  const handleModalKpi = useCallback(
    (KpiInfor, mode) => (e) => {
      setShowKpiModal(true);
      setSelectedKpi(KpiInfor);
      setIsMode(mode);
    },
    []
  );

  const handleCancelModal = useCallback(() => {
    setSelectedKpi({});
    setShowKpiModal(false);
  }, []);

  const handleSaveModal = useCallback(() => {
    setShowKpiModal(false);
    setSelectedKpi({});
    setPaging({ pageNumber: 1, pageSize: paging?.pageSize });
  }, [paging]);

  const handleChangePaging = (pageNumber, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
      setPaging({ pageNumber: currentPage, pageSize });
    };

  //import
  function beforeUpload(file) {
    const isExcelFile = file.type === "application/vnd.ms-excel" ||
     file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcelFile) {
      message.error("You can only upload excel file!");
    }
    return isExcelFile ? true : Upload.LIST_IGNORE;;
  }

  const importKpis = (data) => {
    if (data.fileList.length > 0) {
      setImportLoading(true);
      const fileToUpload = {
        data: data.fileList[0].originFileObj,
        fileName: data.fileList[0].name
      };

      services.shippingOrderService
          .kpi_ImportKpisData(fileToUpload)
          .then((res) => {
            message.success('Import danh sách KPI thành công.');
            setPaging({ pageNumber: 1, pageSize: paging?.pageSize});
          })
          .catch((error) => {
            message.error('Có lỗi khi import KPI.');
          }).finally(() => {
            setImportLoading(false);
            setFileList([]);
          });
    }
  }
    //end import

  return (
    <>     
      <Card className="Kpi-card">
        <Divider style={{margin:10}}/>
        <Form form={form} onFinish={onSubmit} labelCol={{ span: 24 }}>
          <Row gutter={15}>
            <Col lg={6} xl={6}>
              <Form.Item name="provinceId" label="Tỉnh">
                <Select
                  style={{ width: "100%" }}
                  placeholder="Tỉnh"
                  allowClear
                  onChange={(e) => {
                    filterStaffs(e);
                  }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  showSearch
                >
                  {_.map(_.get(dataFilter, "arrProvinces"), (item, index) => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              <Form.Item name="staffId" label="Nhân viên">
                <Select
                  style={{ width: "100%" }}
                  placeholder="Nhân viên"
                  allowClear
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  showSearch
                >
                  {_.map(filteredStaffs, (item, index) => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              <Form.Item name="monthYear" label="Tháng xem">
                <DatePicker
                  placeholder="Tháng xem"
                  style={{ width: '100%' }}
                  //value={params.month}
                  format={'MM-YYYY'}
                  // onChange={(e) => {
                  //   getQuery(e, 'month')
                  // }}
                  picker="month"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={[8]}>
            <Col>
              <Button size="middle" onClick={handleCreateKpi("Create")}>
                <span className="d-flex align-items-center">
                  <PlusOutlined />
                  <span className="ml-1">Thêm mới</span>
                </span>
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => form.submit()}
                size="middle"
                disabled={isLoading}
              >
                <span className="d-flex align-items-center">
                  <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                </span>
              </Button>
            </Col>
            <Col>
              <Upload
                className='import-export ant-btn'
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                showUploadList={false}
                fileList={fileList}
                beforeUpload={beforeUpload}
                onChange={importKpis}
              >
                <span className='d-flex align-items-center text-white'>
                  {importLoading ? <LoadingOutlined /> : <DownloadOutlined />}
                  <span className='ml-1'>Import KPI</span> 
                </span>
              </Upload>
            </Col>
          </Row>
        </Form>

        <Divider style={{margin:10}}/>

        <Table
          rowKey="id"
          className="Kpi-card__table"
          columns={columns}
          dataSource={listKpi}
          scroll={{ x: 1200 }}
          bordered
          loading={isLoading}
          pagination={{
            total,
            pageSizeOptions: [10, 20, 50],
            current: paging?.pageNumber ?? 1,
            pageSize: paging?.pageSize ?? 10,
            onChange: handleChangePaging,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} trong ${total} dòng`,
          }}
          size="small"
          locale={{ emptyText: "Không có KPI nào" }}
        />

        <KpiModal
          visible={showKpiModal}
          onCancel={handleCancelModal}
          onSave={handleSaveModal}
          selectedKpi={selectedKpi}
          mode={isMode}
          filteredStaffs={filteredStaffs}
        />
      </Card>
    </>
  );
}
