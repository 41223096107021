import React, { memo, useCallback, useState, useEffect } from 'react';
import { Row, Col, Select, DatePicker, Button, message } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import services from "services";
import {saveFileAs} from 'utils/excel';

const { Option } = Select

const FilterStyled = memo(
  ({
    params,
    setParams,
    dataFilter,
    filterProvinces,
    filterStaffs,
    filteredProvinces,
    filteredStaffs,
    selectedRegionId,
    selectedProvinceId,
    selectedStaffId,
    setSelectedStaffId,
    paging,
    setPaging,
    loading,
    setLoading
  }) => {
    const getQuery = useCallback(
      (value, name) => {
        setParams((preState) => {
          let nextState = { ...preState }
          nextState[name] = value
          return nextState
        })
      },
      [setParams],
    )

    const getFilter = () => {
      setPaging({ pageNumber: 1, pageSize: paging.pageSize });
    }

    const exportKpiExcel = () => {
      setLoading(true);
      services.shippingOrderService
        .kpi_ExportKpiReport({
          regionId: params.regionId,
          provinceId: params.provinceId,
          staffId: params.staffId,
          month: params.month && moment(params.month).format("MM"),
          year: params.month && moment(params.month).format("YYYY"),
          pageNumber: paging.pageNumber,
          pageSize: paging.pageSize,
        })
        .then((response) => {
          saveFileAs(response.data, 'BC KPI theo tháng '+ moment().format("DD-MM-YYYY") + '.xlsx');
        })
        .catch((err) => {
          message.error('Có lỗi khi xuất excel');
        })
        .finally(() => {
          setLoading(false);
        });
    }
      
    const exportKpiProductExcel = () => {
      setLoading(true);
      services.shippingOrderService
        .kpi_ExportKpiProductReport({
          regionId: params.regionId,
          provinceId: params.provinceId,
          staffId: params.staffId,
          month: params.month && moment(params.month).format("MM"),
          year: params.month && moment(params.month).format("YYYY"),
          pageNumber: paging.pageNumber,
          pageSize: paging.pageSize,
        })
        .then((response) => {
          saveFileAs(response.data, 'BC KPI sản phẩm theo tháng '+ moment().format("DD-MM-YYYY") + '.xlsx');
        })
        .catch((err) => {
          message.error('Có lỗi khi xuất excel');
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return (
      <div>
        <Row gutter={15}>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
            <span>Khu vực</span>
            <Select
              value={selectedRegionId}
              style={{ width: '100%', marginTop: 10 }}
              placeholder="Khu vực"
              allowClear
              onChange={(e) => {
                getQuery(e, 'regionId');
                filterProvinces(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(_.get(dataFilter, 'arrBusinessRegions'), (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
            <span>Tỉnh</span>
            <Select
              style={{ width: '100%', marginTop: 10 }}
              placeholder="Tỉnh"
              allowClear
              value={selectedProvinceId}
              onChange={(e) => {
                getQuery(e, 'provinceId');
                filterStaffs(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(filteredProvinces, (item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
            <span>Nhân viên</span>
            <Select
              style={{ width: '100%', marginTop: 10 }}
              placeholder="Nhân viên"
              allowClear
              value={selectedStaffId}
              onChange={(e) => {
                getQuery(e, 'staffId');
                setSelectedStaffId(e);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            >
              {_.map(filteredStaffs, (item, index) => {
                return (
                  <Option value={item.userId} key={item.userId}>
                    {item.fullName}
                  </Option>
                )
              })}
            </Select>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <span>Tháng xem</span>
            <DatePicker
              placeholder="Tháng xem"
              style={{ width: '100%', marginTop: 10 }}
              value={params.month}
              format={'MM-YYYY'}
              onChange={(e) => {
                getQuery(e, 'month')
              }}
              picker="month"
            />
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 5 }}>
          <Col>
            <Button
              disabled={loading}
              type="primary"
              onClick={getFilter}
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </Col>
          <Col className='ml-2'>
            <Button
              disabled={loading}
              type='dashed'
              onClick={exportKpiExcel}
            >
              <span className='d-flex align-items-center'>
                <DownloadOutlined /> <span className='ml-1'>Xuất KPI theo tháng</span>
              </span>
            </Button>
          </Col>
          <Col className='ml-2'>
            <Button
              disabled={loading}
              type='dashed'
              onClick={exportKpiProductExcel}
            >
              <span className='d-flex align-items-center'>
                <DownloadOutlined /> <span className='ml-1'>Xuất KPI sản phẩm theo tháng</span>
              </span>
            </Button>
          </Col>
        </Row>
      </div>
    )
  },
)
export default styled(FilterStyled)`
  .ant-picker {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .clearParams {
    text-align: right;
  }
`
