/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 04/06/2021.
 * @description Modal Thêm/Sửa vùng miền
 * */

import React, { useCallback, useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, message } from "antd";
import PropTypes from "prop-types";
import { map } from "lodash";
import utils from "utils";
import services from "services";
import BaseSelect from "components/selects/baseSelect";

const SaleRouteModal = function ({ config, onCancel, onReloadPage }) {
  const { visible, mode, dataSource } = config;
  const [form] = Form.useForm();
  const [isContinue, setIsContinue] = useState(false);

  const isGeneral =
    window.location.pathname ===
    "/customer-management/sale-route-management-new";
  const handleConfirm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleConfirmAndContinue = useCallback(() => {
    setIsContinue(true);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleCancelModal = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  const handleSubmit = useCallback(
    (values) => {
      if (mode === utils.$const.COMMON.MODAL_MODE.ADD) {
        services.generalService
          .saleRoute_Create(values)
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onReloadPage();
            if (isContinue) {
              form.resetFields();
            } else {
              handleCancelModal();
              form.resetFields();
            }
          })
          .catch((error) => {
            message.error("Lưu không thành công, hãy thử lại.");
          });
      } else {
        if (isGeneral) {
          let payload = {
            id: values.id,
            saleRouteCode: values.saleRouteCode,
            saleRouteName: values.saleRouteName,
            notes: values.notes,
            currentState: values.currentState,
            status: values.status,
          };
          services.generalService
            .saleRoute_Update(payload)
            .then((res) => {
              setTimeout(() => {
                message.success("Lưu thành công.");
              }, 300);
              onReloadPage();
              handleCancelModal();
            })
            .catch((err) => {
              message.error("Lưu không thành công, hãy thử lại.");
            });
        } else {
          let payload = {
            id: values.id,
            currentState: values.currentState,
            saleId: values.saleIdObj?.value,
            notes: values.notes,
          };
          services.monitoringControlService
            .apiMonitorcontrolSaleroutemanagementUpdatesaleroutemanagement(
              payload
            )
            .then((res) => {
              setTimeout(() => {
                message.success("Lưu thành công.");
              }, 300);
              onReloadPage();
              handleCancelModal();
            })
            .catch((err) => {
              message.error("Lưu không thành công, hãy thử lại.");
            });
        }
      }
    },
    [mode, handleCancelModal, isGeneral]
  );

  const handleBrowseSale = useCallback((search) => {
    return services.organization.humanResource_GetAll(1, 1000);
  }, []);

  useEffect(() => {
    if (visible && mode === utils.$const.COMMON.MODAL_MODE.EDIT) {
      // set dữ liệu trong dataSource vào form
      form.setFieldsValue({
        id: dataSource.id,
        currentState: dataSource.currentState,
        saleRouteCode: dataSource.saleRouteCode,
        saleRouteName: dataSource.saleRouteName,
        saleIdObj: dataSource.salerName
          ? { label: dataSource.salerName, value: dataSource.salerId }
          : "",
        status: dataSource.status,
        notes: dataSource.notes,
      });
    }
  }, [dataSource, visible, mode, form]);

  const renderTitle = () =>
    mode === utils.$const.COMMON.MODAL_MODE.ADD
      ? `Thêm mới tuyến bán hàng`
      : "Chỉnh sửa tuyến bán hàng";
  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      centered
      onCancel={handleCancelModal}
      footer={[
        <Form.Item>
          <Button onClick={handleConfirm} type="primary">
            {dataSource?.id ? "Sửa" : "Thêm"}
          </Button>
          {mode === utils.$const.COMMON.MODAL_MODE.ADD ? (
            <Button
              title="Thêm và tiếp tục"
              key="submitAndContinute"
              type="primary"
              onClick={handleConfirmAndContinue}
            >
              Thêm và tiếp tục
            </Button>
          ) : (
            ""
          )}
          <Button type="default" onClick={handleCancelModal}>
            Quay lại
          </Button>
        </Form.Item>,
        // <Button key="submit" type="primary" onClick={handleConfirm}>
        //   Xác nhận
        // </Button>,
        // <Button key="back" onClick={handleCancelModal}>
        //   Quay lại
        // </Button>,
      ]}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        initialValues={{
          status: 1,
        }}
      >
        <Form.Item
          label="Mã tuyến"
          name="saleRouteCode"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Input disabled={!isGeneral} placeholder="Nhập mã tuyến" />
        </Form.Item>
        <Form.Item
          label="Tên tuyến"
          name="saleRouteName"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Input disabled={!isGeneral} placeholder="Nhập tên tuyến" />
        </Form.Item>
        {mode === utils.$const.COMMON.MODAL_MODE.EDIT && (
          <Form.Item
            label="Nhân viên sale"
            name="saleIdObj"
            rules={[{ required: true, message: "Vui lòng nhập" }]}
          >
            <BaseSelect
              disabled={isGeneral}
              searchName="saleIdObj"
              itemValue="userId"
              itemLabel="fullName"
              placeholder="Chọn nhân viên"
              searchOnMount
              labelInValue
              onSearch={handleBrowseSale}
            />
          </Form.Item>
        )}

        <Form.Item name="status" label="Trạng thái" labelCol={{ span: 6 }}>
          <Select disabled={!isGeneral} placeholder="Chọn trạng thái">
            {map(utils.$const.ROUTE_INFO.STATUS, (item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Mô tả" name="notes" labelCol={{ span: 6 }}>
          <Input.TextArea rows={2} placeholder="Nhập mô tả tuyến" />
        </Form.Item>

        <Form.Item hidden name="id">
          <Input disabled />
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};
SaleRouteModal.propTypes = {
  config: PropTypes.any,
  onCancel: PropTypes.func,
};
export default SaleRouteModal;
