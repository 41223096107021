import React, { useEffect, useState } from 'react';
import {
  Layout,
  message,
  Button,
  Breadcrumb,
} from 'antd';
// Services
import services from 'services';
import Logo from 'images/logo-dms.svg';
import {
  RightOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
// Redux
import AuthSlice from 'redux/auth/authSlice';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuSider from './MenuSider';
import QuickUser from 'components/user/QuickUser';
import {
  find,
  get,
  last,
  map,
  size,
  split,
} from 'lodash';

const { Header, Sider, Content } = Layout;

const PrivateLayout = function ({ children }) {
  const user = useSelector(AuthSlice.seletors.makeSelectUser);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isShowQuickUser, setIsShowQuickUser] = useState(false);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const location = useLocation();
  const [defaultOpenKeys] = useState(() => {
    if (location.pathname === '/') return ['/'];
    else {
      var paths = split(location.pathname, '/');
      let result = [];
      if (paths.length === 2) {
        result.push(`/${paths[1]}`);
      }
      if (paths[1] && paths[2]) {
        result = [`/${paths[1]}`, `/${paths[1]}/${paths[2]}`];
      }
      if (paths[3]) {
        result.push(`/${paths[1]}/${paths[2]}/${paths[3]}`);
      }
      return result;
    }
  });
  useEffect(() => {
    const handleBrowseAllMenu = () => {
      services.accessManagementService
        .mainMenu_GetBsWithPagination(1, 1000)
        .then((res) => {
          // const items = get(res, "items");
          const items = [
            {
              id: '4031235b-04bd-45c8-48f6-dashboard',
              menuName: 'Dashboard',
              menuPath: '/dashboard',
              subMenuDefinitions: [
                {
                  id: '4f070ab6-6159-4ae4-f969-08d96fbe0553',
                  subMenuName: 'Dashboard',
                  subMenuPath: '/dashboard',
                },
                {
                  id: '53cc6f97-760d-40d2-fc93-08d99adb2410',
                  subMenuName: 'Quyền giám sát khu vực',
                  subMenuPath: '/dashboard/area-monitoring',
                },
              ],
            },
            {
              id: '4031235b-04bd-45c8-48f6-08d9595c5bea',
              menuName: 'Quản lý bán hàng',
              menuPath: '/sale-management',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-08d959878c41',
                  subMenuName: 'Phiếu đặt hàng',
                  subMenuPath: '/sale-management/purchase-order',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-bán hàng',
                  subMenuName: 'Đơn bán hàng',
                  subMenuPath: '/sale-management/sell-order',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-bán hàng',
                  subMenuName: 'Danh sách đơn hàng',
                  subMenuPath: '/sale-management/list-order',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-purchase-order/new',
                  subMenuName: 'Tạo đơn hàng',
                  subMenuPath: '/sale-management/purchase-order/new',
                },
                {
                  id: 'd7838b77-14aa-4bd8-8b4d-ad719507c776',
                  subMenuName: 'Chương trình khuyến mãi',
                  subMenuPath: '/sale-management/promotion',
                },
                {
                  id: '1ca0bb65-2b1f-4959-acfe-b8bc6d5b6395',
                  subMenuName: 'Chỉ tiêu KPI',
                  subMenuPath: '/sale-management/kpi',
                },
              ],
            },
            {
              id: '4031235b-04bd-45c8-48f6-warehouse',
              menuName: 'Quản lý kho',
              menuPath: '/warehouse',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-warehouse-information',
                  subMenuName: 'Thông tin kho',
                  subMenuPath: '/warehouse/warehouse-information',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-product-inbound',
                  subMenuName: 'Quản lý nhập',
                  subMenuPath: '/warehouse/product-inbound',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-product-outbound',
                  subMenuName: 'Quản lý xuất',
                  subMenuPath: '/warehouse/product-outbound',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-productInventory',
                  subMenuName: 'Quản lý tồn',
                  subMenuPath: '/warehouse/productInventory',
                },
              ],
            },
            {
              id: '4031235b-04bd-45c8-48f6-general-management',
              menuName: 'Danh mục quản lý',
              menuPath: '/general-management',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-payment-method',
                  subMenuName: 'Hình thức thanh toán',
                  subMenuPath: '/general-management/payment-method',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-unit-type',
                  subMenuName: 'Đơn vị tính',
                  subMenuPath: '/general-management/unit-type',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-currency-unit',
                  subMenuName: 'Đơn vị tiền tệ',
                  subMenuPath: '/general-management/currency-unit',
                },
              ],
            },
            {
              id: 'a0747ea2-0e02-44f0-24d5-08d97b5397ea',
              menuName: 'Báo cáo tổng hợp',
              menuPath: '/report-management',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-attendance-summary',
                  subMenuName: 'Báo cáo viếng thăm khách hàng',
                  subMenuPath: '/report-management/attendance-summary',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-customer-turnoverbymonth',
                  subMenuName: 'Sản lượng của khách hàng theo tháng',
                  subMenuPath: '/report-management/customer-turnoverbymonth',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-kpi',
                  subMenuName: 'Báo cáo KPI theo tháng',
                  subMenuPath: '/report-management/kpi',
                },
                {
                  id: '012b46b8-ba5b-4c37-a0f7-9e5c16da8e47-kpi-daily',
                  subMenuName: 'Báo cáo KPI theo ngày',
                  subMenuPath: '/report-management/kpi-daily',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-visit-monitoring',
                  subMenuName: 'Báo cáo viếng thăm khách hàng theo nhân viên',
                  subMenuPath: '/report-management/visit-monitoring',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-report-management/timekeeping',
                  subMenuName: 'Báo cáo chấm công',
                  subMenuPath: '/report-management/timekeeping',
                },
                {
                  id: 'c5562524-809a-42f3-8baa-64a435f405fe',
                  subMenuName: 'Báo cáo khuyến mãi KH',
                  subMenuPath: '/report-management/promotion-report',
                },
              ],
            },
            {
              id: '4031235b-04bd-45c8-48f6-access-management',
              menuName: 'Tài khoản và phân quyền',
              menuPath: '/access-management',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-action-control',
                  subMenuName: 'Danh sách chức năng',
                  subMenuPath: '/access-management/action-control',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-permission',
                  subMenuName: 'Danh sách quyền truy cập',
                  subMenuPath: '/access-management/permission',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-role',
                  subMenuName: 'Quản lý nhóm tài khoản',
                  subMenuPath: '/access-management/role',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-menu-control',
                  subMenuName: 'Danh sách màn hình',
                  subMenuPath: '/access-management/menu-control',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-authorize-access',
                  subMenuName: 'Phân quyền',
                  subMenuPath: '/access-management/authorize-access',
                },
              ],
            },
            {
              id: '4031235b-04bd-45c8-48f6-statistic-management',
              menuName: 'Thống kê',
              menuPath: '/statistic-management',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-inventory-statistic',
                  subMenuName: 'Thống kê xuất nhập tồn',
                  subMenuPath: '/statistic-management/inventory-statistic',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-inventory-statistic',
                  subMenuName: 'Thống kê xuất hàng',
                  subMenuPath:
                    '/statistic-management/statistic-product-outbound',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-inventory-statistic',
                  subMenuName: 'Thống kê nhập hàng',
                  subMenuPath:
                    '/statistic-management/statistic-product-inbound',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-inventory-statistic',
                  subMenuName: 'Thống kê đơn hàng',
                  subMenuPath:
                    '/statistic-management/statistic-shipping-order',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-inventory-statistic',
                  subMenuName: 'Thống kê khách hàng',
                  subMenuPath: '/statistic-management/customer-summary',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-inventory-statistic',
                  subMenuName: 'Thống kê xuất nhập tồn',
                  subMenuPath: '/statistic-management/inventory-statistic',
                },
              ],
            },
            {
              id: '4031235b-04bd-45c8-48f6-statistic-management',
              menuName: 'Tổ chức',
              menuPath: '/organization',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-organization-organizeChart',
                  subMenuName: 'Sơ đồ tổ chức',
                  subMenuPath: '/organization/organizeChart',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-organization-roleManagement',
                  subMenuName: 'Quản lý chức danh',
                  subMenuPath: '/organization/title-management',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-organization-distributor-information',
                  subMenuName: 'Nhà phân phối',
                  subMenuPath: '/organization/distributor-information',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-organization-userAccount',
                  subMenuName: 'Quản lý nhân viên',
                  subMenuPath: '/organization/user-account',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-organization-organizeDefinition',
                  subMenuName: 'Quản lý tổ chức',
                  subMenuPath: '/organization/organize-definition',
                },
              ],
            },
            {
              id: '4031235b-04bd-45c8-48f6-statistic-management',
              menuName: 'Quản lý khách hàng',
              menuPath: '/customer-management',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-customer-management/sale-channel',
                  subMenuName: 'Kênh bán hàng',
                  subMenuPath: '/customer-management/sale-channel',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-organization-roleManagement',
                  subMenuName: 'Hình thức kinh doanh',
                  subMenuPath: '/customer-management/business-type',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-organization-distributor-information',
                  subMenuName: 'Nhóm khách hàng',
                  subMenuPath: '/customer-management/customer-groups',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-organization-userAccount',
                  subMenuName: 'Thông tin khách hàng',
                  subMenuPath: '/customer-management/customer-information',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-organization-organizeDefinition',
                  subMenuName: 'Danh sách tuyến bán hàng',
                  subMenuPath: '/customer-management/sale-route-management-new',
                },
              ],
            },

            {
              id: '4031235b-04bd-45c8-48f6-visit',
              menuName: 'Quản lý giám sát',
              menuPath: '/customer-management',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-visit-comments',
                  subMenuName: 'Xử lí vấn đề khách hàng',
                  subMenuPath: '/customer-management/visit-comments',
                },
              ],
            },
            {
              id: '4031235b-04bd-45c8-48f6-monitoring',
              menuName: 'Quản lý giám sát',
              menuPath: '/monitoring',
              subMenuDefinitions: [
                // {
                //   id: 'adc7cda4-a9c6-41db-2626-customer-location',
                //   subMenuName: 'Giám sát vị trí khách hàng',
                //   subMenuPath: '/monitoring/customer-location',
                // },
                // {
                //   id: 'adc7cda4-a9c6-41db-2626-location-checkin',
                //   subMenuName: 'Giám sát vị trí nhân viên',
                //   subMenuPath: '/monitoring/location-checkin',
                // },
                {
                  id: 'eacdc299-c770-4dae-026b-08d99502d11f',
                  subMenuName: 'Quản lý hình ảnh viếng thăm',
                  subMenuPath: '/monitoring/images-control',
                },
                {
                  id: 'f95a16fc-0104-4dc5-b6d7-c24492dbaa60',
                  subMenuName: 'Giám sát viếng thăm',
                  subMenuPath: '/monitoring/location-checkin-all',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-location-checkin-staff',
                  subMenuName: 'Giám sát vị trí viếng thăm',
                  subMenuPath: '/monitoring/location-checkin-staff',
                },
                {
                  id: 'ab0bc8fe-edc9-46b2-9878-b983f5e02d0d',
                  subMenuName: 'Bản đồ sản phẩm theo tỉnh',
                  subMenuPath: '/monitoring/product-map',
                },
                {
                  id: '76f01b79-943a-43c9-9b81-aa826a4c0591',
                  subMenuName: 'Bản đồ sản phẩm theo xã',
                  subMenuPath: '/monitoring/product-map-ward',
                }
              ],
            },
            {
              id: '4031235b-04bd-45c8-48f6-monitoring',
              menuName: 'Quản lý sản phẩm',
              menuPath: '/product-management',
              subMenuDefinitions: [
                {
                  id: 'adc7cda4-a9c6-41db-2626-product-industry',
                  subMenuName: 'Ngành hàng',
                  subMenuPath: '/product-management/product-industry',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-product-brand',
                  subMenuName: 'Nhãn hiệu',
                  subMenuPath: '/product-management/product-brand',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-provider-information',
                  subMenuName: 'Nhà cung cấp',
                  subMenuPath: '/product-management/provider-information',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-product-type',
                  subMenuName: 'Loại sản phẩm',
                  subMenuPath: '/product-management/product-type',
                },
                {
                  id: 'adc7cda4-a9c6-41db-2626-product-info',
                  subMenuName: 'Sản phẩm',
                  subMenuPath: '/product-management/product-info',
                },
              ],
            }
          ];
          var menuKey = get(defaultOpenKeys, 0, null);
          var subMenuKey = get(defaultOpenKeys, 1, null);

          if (menuKey) {
            var menu = find(items, (x) => x.menuPath === menuKey);
            if (menu) {
              var subMenu = find(
                menu.subMenuDefinitions,
                (x) => x.subMenuPath === subMenuKey
              );

              if (subMenu) {
                let result = [
                  {
                    key: '/',
                    name: 'Trang chủ',
                  },
                  {
                    key: menuKey,
                    name: menu.menuName,
                  },
                  {
                    key: subMenuKey,
                    name: subMenu.subMenuName,
                  },
                ];
                if (get(defaultOpenKeys, 2)) {
                  subMenuKey = get(defaultOpenKeys, 2);
                  subMenu = find(
                    menu.subMenuDefinitions,
                    (x) => x.subMenuPath === subMenuKey
                  );
                  if (subMenu) {
                    result.push({
                      key: subMenuKey,
                      name: subMenu.subMenuName,
                    });
                  }
                }
                setBreadCrumb(result);
              } else {
                setBreadCrumb([
                  {
                    key: '/',
                    name: 'Trang chủ',
                  },
                  {
                    key: menuKey,
                    name: menu.menuName,
                  },
                ]);
              }
            }
          }
          // // fake data
          // setListMenuControl([
          //   {
          //     id: "4031235b-04bd-45c8-48f6-08d9595c5bea",
          //     menuName: "Phiếu bán hàng",
          //     menuPath: "/sale-management",
          //     subMenuDefinitions: [
          //       {
          //         id: "adc7cda4-a9c6-41db-2626-08d959878c41",
          //         subMenuName: "Quản lý đặt hàng",
          //         subMenuPath: "/sale-management/order-management",
          //       },
          //     ],
          //   },
          // ]);
        })
        .catch((error) => {
          message.error('Có lỗi khi lấy danh sách menu.');
        });
    };
    if (size(defaultOpenKeys) > 0) {
      handleBrowseAllMenu();
    }
  }, [defaultOpenKeys]);

  return (
    <Layout id='privateLayout'>
      <Sider
        className='layout__sider'
        trigger={null}
        collapsible
        collapsed={isCollapse}
        width={300}
      >
        <div className='layout__sider-logo'>
          {!isCollapse && (
            <Link to='/'>
              <img src={Logo} alt='Danh Huy Soft' height='64' width='200' />
            </Link>
          )}
          <span
            className='collpase__button'
            onClick={() => setIsCollapse((prev) => !prev)}
          >
            {isCollapse ? <RightOutlined /> : <MenuFoldOutlined />}
          </span>
        </div>
        <div className='layout__sider-menu'>
          <MenuSider defaultOpenKeys={defaultOpenKeys} />
        </div>
      </Sider>
      <Layout className='layout__wrapper'>
        <Header className='layout__wrapper-header'>
          <div className='header__action'>
            <Button type='link' onClick={() => setIsShowQuickUser(true)}>
              <span>{user.userName}</span>
            </Button>
          </div>
        </Header>

        <Content className='layout__wrapper-content'>
          <div className='breadcrumb__wrapper'>
            <h4
              className='page__title mb-0'
              style={{
                color: '#023561',
              }}
            >
              {last(breadCrumb) ? last(breadCrumb).name : ''}
            </h4>
            <Breadcrumb className='pr-2'>
              {map(breadCrumb, (br) => {
                return (
                  <Breadcrumb.Item key={br.key}>
                    <Link to={br.key}>{br.name}</Link>
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </div>
          <div style={{ flex: 1 }}>{children}</div>
        </Content>
        <QuickUser
          visible={isShowQuickUser}
          onCancel={() => setIsShowQuickUser(false)}
        />
      </Layout>
    </Layout>
  );
};

export default PrivateLayout;
