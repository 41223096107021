/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author ductt.it.hust@gmail.com on 28/05/2021.
 * @description Modal Thêm/Sửa nhân viên
 * */

import React, { useCallback, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
} from "antd";
import PropTypes from "prop-types";
// Services
import services from "services";

const ForgetPasswordModal = function ({ visible, onCancel }) {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [fetching, setFetching] = useState(false);

  const handleConfirm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleSubmit = useCallback(
    (values) => {
      setFetching(true);
      setErrorMessage("");
      services.userManagementService
        .profile_ForgetPassword(values)
        .then((res) => {
          if (res.succeeded) {
            message.success("Gửi yêu cầu mật khẩu mới thành công. Vui lòng kiểm tra email.");
            form.resetFields();
            setTimeout(() => {
              onCancel();
            }, 1000);
            onCancel();
          } else {
            setErrorMessage("Có lỗi khi gửi yêu cầu mật khẩu mới. Vui lòng thử lại");
          }
          setFetching(false);
        })
        .catch((error) => {
          setErrorMessage("Có lỗi khi gửi yêu cầu mật khẩu mới. Vui lòng thử lại");
          setFetching(false);
        });
    },
    [form,onCancel]
  );

  const renderTitle = () => "Quên mật khẩu";
  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      width={450}
      onCancel={onCancel}
      footer={[
        <Button
          loading={fetching}
          key="submit"
          type="primary"
          onClick={handleConfirm}
        >
          Gửi yêu cầu
        </Button>,
        <Button key="back" onClick={onCancel}>
          Quay lại
        </Button>,
      ]}
    >
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "Sai định dạng",
            },
            {
              required: true,
              message: "Vui lòng nhập email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="text-danger text-center">{errorMessage}</div>
      </Form>
    </Modal>
  );
};
ForgetPasswordModal.propTypes = {
  visible: PropTypes.bool,
};
export default ForgetPasswordModal;
