import { useCallback, useState } from 'react';
import {
  Card,
  Row,
  Form,
  Input,
  Col,
  Divider,
  Select,
  DatePicker,
  Button,
  Tabs,
} from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import services from 'services';
import utils from 'utils';
import { map } from 'lodash';
import BaseSelect from 'components/selects/baseSelect';
import ProductOutboundManagement from 'components/statisticProductOutbound/ProductOutboundManagement';
import DocumentTypeManagement from 'components/statisticProductOutbound/DocumentTypeManagement';
import moment from 'moment';

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;

export default function StatisticProductOutbound() {
  const [form] = Form.useForm();

  const [params, setParams] = useState({});

  const handleSubmit = (values) => {
    setParams({ ...values, load: true });
  };

  const handleBrowseCustomerInformation = useCallback(() => {
    return services.generalService.customerInformation_GetListCustomer();
  }, []);

  return (
    <Card>
      <Form
        onFinish={handleSubmit}
        form={form}
        labelCol={{ span: 24 }}
        initialValues={{
          date: [
            moment(utils.$datetime.currentFirstDate()),
            moment(utils.$datetime.currentLastDate()),
          ],
        }}
      >
        <Row gutter={[8]}>
          <Col lg={8} xl={4}>
            <Form.Item name='DocumentCode' label='Mã phiếu xuất'>
              <Input placeholder='Nhập mã'></Input>
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='customerId' label='Mã khách hàng'>
              <BaseSelect
                searchName='Customer'
                itemValue='id'
                itemLabel='customerName'
                placeholder='Chọn khách hàng'
                searchOnMount
                labelInValue
                onSearch={handleBrowseCustomerInformation}
              />
            </Form.Item>
          </Col>
          <Col lg={8} xl={4}>
            <Form.Item name='documentType' label='Hình thức xuất'>
              <Select placeholder='Hình thức xuất'>
                {map(utils.$const.OUTBOUND.DOCUMENT_TYPE, (stat) => (
                  <Select.Option key={stat.id} value={stat.value}>
                    {stat.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={16} xl={8}>
            <Form.Item name='date' label='Từ ngày - đến ngày'>
              <RangePicker
                format={utils.$const.COMMON.DATE_TIME_FORMAT['YYYY-MM-DD']}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end' gutter={[8]}>
          <Col>
            <Button type='primary' htmlType='submit'>
              <span className='d-flex align-items-center'>
                <SearchOutlined /> <span className='ml-1'>Tìm kiếm</span>
              </span>
            </Button>
          </Col>
          <Col>
            <Button type='dashed'>
              <span className='d-flex align-items-center'>
                <DownloadOutlined />{' '}
                <span className='ml-1'>Xuất file Excel</span>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider />
      <Tabs defaultActiveKey='1' type='card'>
        <TabPane tab='Thống kê theo phiếu xuất' key='1'>
          <ProductOutboundManagement params={params} />
        </TabPane>
        <TabPane tab='Thống kê theo hình thức xuất' key='2'>
          <DocumentTypeManagement params={params} />
        </TabPane>
      </Tabs>
    </Card>
  );
}
