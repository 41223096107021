import { Button, Form, Input, Modal, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState, useCallback } from "react";
import services from "services";

const ProductIndustryModal = ({
  visible,
  onCancel,
  selectedProductIndustry,
  onReload,
  ...rest
}) => {
  const [form] = Form.useForm();
  const [saveCreate, setSave] = useState(false);

  const listStatus = [
    {
      id: 1,
      status: 1,
      statusName: "Hoạt động",
    },
    {
      id: 2,
      status: 0,
      statusName: "Không hoạt động",
    },
  ];
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  useEffect(() => {
    const initValues = {
      id: selectedProductIndustry.id,
      productIndustryCode: selectedProductIndustry.productIndustryCode,
      productIndustryName: selectedProductIndustry.productIndustryName,
      notes: selectedProductIndustry.notes,
      activeStatus: selectedProductIndustry.activeStatus,
      currentState: selectedProductIndustry.currentState,
    };
    form.setFieldsValue(initValues);
  }, [selectedProductIndustry, form]);

  const handleConfirm = useCallback((state) => {
    if(state){
      setSave(true)
    }else{
      setSave(false)
    }
    form.submit();
  }, [form]);

  const onSubmit = (values) => {
    if (selectedProductIndustry.id !== undefined) {
      if (!form.getFieldValue().activeStatus) {
        form.getFieldValue().activeStatus = 0;
      } else {
        form.getFieldValue().activeStatus = 1;
      }
      services.generalService
        .productIndustry_Update(form.getFieldValue())
        .then((res) => {
          if (res.succeeded) {
            message.success("chỉnh sửa thành công.");
            onReload();
            onCancel();
          } else {
            message.error("Có lỗi trong quá trình chỉnh sữa.");
          }
        })
        .catch((erro) => {
          message.error("Mã nghành hàng đã tồn tại");
        });
    } else {
      let activeStatus;
      if (
        form.getFieldValue().activeStatus === undefined ||
        !form.getFieldValue().activeStatus
      ) {
        activeStatus = 0;
      } else {
        activeStatus = 1;
      }
      let dataClone = {
        productIndustryCode: form.getFieldValue().productIndustryCode,
        productIndustryName: form.getFieldValue().productIndustryName,
        notes: form.getFieldValue().notes,
        activeStatus: activeStatus,
      };
      services.generalService
        .productIndustry_Create(dataClone)
        .then((res) => {
          if (res.succeeded) {
            message.success("Thêm ngành hàng thành công");
            if(saveCreate){
              form.resetFields();
            }else{
              onCancel();
            }
            onReload();
          } else {
            message.error("Có lỗi trong quá trình thêm mới");
          }
        })
        .catch((erro) => {
          message.error("Mã nghành hàng đã tồn tại");
        });
    }
  };

  // const [currentValue, setCurrentValue] = useState(
  //   selectedProductIndustry.activeStatus == 1 ? true : false
  // );

  // function onChange(checked) {
  //   setCurrentValue(checked);
  // }

  const title =
    selectedProductIndustry.id !== undefined
      ? "Chỉnh sữa ngành hàng"
      : "Thêm ngành hàng";
  //let status = selectedProductIndustry.activeStatus == 1 ? true : false;
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      {...rest}
      title={title}
      footer={[
        <Button type="primary" onClick={()=>handleConfirm(false)}>
          Lưu
        </Button>,
        // khi thêm mới nó nút này
        <Button
          style={{ display: selectedProductIndustry.id ? "none" : "" }}
          type="primary"
          onClick={() => handleConfirm(true)}
        >
          Lưu và tiếp tục
        </Button>,
        <Button key="back" onClick={onCancel}>
          Quay lại
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} {...layout} labelAlign="left">
        <Form.Item
          label="Mã ngành"
          required
          name="productIndustryCode"
          rules={[
            { required: true, message: "Mã ngành hàng không được trống" },
          ]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập mã ngành" />
        </Form.Item>
        <Form.Item
          label="Tên ngành"
          required
          name="productIndustryName"
          rules={[
            { required: true, message: "Tên ngành hàng không được trống" },
          ]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập tên nghành" />
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="activeStatus"
          labelCol={{ span: 7 }}
        >
          <Select placeholder="Chọn trạng thái">
            {listStatus.map((item) => (
              <Select.Option key={item?.id} value={item?.status}>
                {item?.statusName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Ghi chú" name="notes" labelCol={{ span: 7 }}>
          <TextArea placeholder="Nhập ghi chú" rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductIndustryModal;
