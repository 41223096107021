import { AccessManagementService } from './AccessManagementService';
import { AttendanceService } from './AttendanceService';
import { CommunicationService } from './CommunicationService';
import { CustomerService } from './CustomerService';
import { FileManagementService } from './FileManagementService';
import { GeneralService } from './GeneralService';
import { MonitorControlService } from './MonitorControlService';
import { OrganizationService } from './OrganizationService';
import { ShippingOrderService } from './ShippingOrderService';
import { StatisticReportService } from './StatisticReportService';
import { UserManagementService } from './UserManagementService';
import { WarehouseService } from './WarehouseService';

const attendanceService = new AttendanceService();
const organization = new OrganizationService();
const communication = new CommunicationService();
const fileManagement = new FileManagementService();
const userManagementService = new UserManagementService();
const generalService = new GeneralService();
const warehouseService = new WarehouseService();
const shippingOrderService = new ShippingOrderService();
const customerService = new CustomerService();
const monitoringControlService = new MonitorControlService();
const satatisticReportService = new StatisticReportService();
const accessManagementService = new AccessManagementService();

const services = {
  attendanceService,
  organization,
  communication,
  fileManagement,
  userManagementService,
  generalService,
  warehouseService,
  shippingOrderService,
  customerService,
  monitoringControlService,
  satatisticReportService,
  accessManagementService
};
export default services;
