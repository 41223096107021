/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 07/07/2021.
 * @description Modal Thêm/Sửa danh sách menu
 * */

import { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Modal, message, Select, InputNumber } from "antd";
import PropTypes from "prop-types";
import services from "services";
import utils from "utils";
import {map} from "lodash";
import BaseSelect from "components/selects/baseSelect";

const MenuControlModal = function ({ config, onCancel, onReloadPage}) {
  const { visible, dataSource, mode } = config;
  const [form] = Form.useForm();
  const [isContinue, setIsContinue] = useState(false);

  const handleBrowsePermission = useCallback((search) => {
    return services.accessManagementService.permissionDefinition_GetBsWithPagination(
      1,
      1000
    );
  }, []);

  const handleConfirm = useCallback(() => {
    setIsContinue(false);
    setTimeout(() => form.submit(), 400);
  },[form]);

  const handleConfirmAndContinue = useCallback(() => {
    setIsContinue(true);
    setTimeout(() => form.submit(), 400);
  }, [form]);

  const handleSubmit = useCallback(
    (values) => {
      if (mode === utils.$const.COMMON.MODAL_MODE.ADD) {
        services.accessManagementService
          .mainMenu_Create({
            ...values,
            permissionId: values.permissionIdObj?.value,
            // unused
            permissionIdObj: undefined,
          })
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            if(isContinue){
              form.resetFields();
            }else{
              onCancel();
              form.resetFields();
            }
          })
          .catch((error) =>{
            message.error("Lưu không thành công, hãy thử lại.");
          });
      } else {
        services.accessManagementService
          .mainMenu_Update({
            ...values,
            permissionId: values.permissionIdObj?.value,
            // unused
            permissionIdObj: undefined,
          })
          .then((res) => {
            setTimeout(() => {
              message.success("Lưu thành công.");
            }, 300);
            onReloadPage();
            onCancel();
            form.resetFields();
          })
          .catch((error) =>{
            message.error("Lưu không thành công, hãy thử lại.");
          });
      }
    },
    [mode, onReloadPage, isContinue, form, onCancel]
  );

  const renderTitle = () =>
    mode === utils.$const.COMMON.MODAL_MODE.ADD
      ? `Thêm mới menu`
      : `Chỉnh sửa menu`;

  const handleCloseModal = useCallback(() => {
    onCancel && onCancel();
    form.resetFields();
  }, [form, onCancel]);

  useEffect(() => {
    if (visible && mode === utils.$const.COMMON.MODAL_MODE.EDIT) {
      // set dữ liệu trong dataSource vào form
      form.setFieldsValue({
        id: dataSource.id,
        status: dataSource.status,
        currentState: dataSource.currentState,
        menuCode: dataSource.menuCode,
        menuName: dataSource.menuName,
        menuPath: dataSource.menuPath,
        index: dataSource.index,
        description: dataSource.description,
        permissionIdObj: {
          label: dataSource.permissionDefinition?.permissionName,
          value: dataSource.permissionDefinition?.id,
        },
      });
    }
  }, [dataSource, visible, mode, form]);

  return (
    <Modal
      visible={visible}
      title={renderTitle()}
      onOk={handleConfirm}
      onCancel={onCancel}
      footer={[
        <Form.Item>
          <Button onClick={handleConfirm} type="primary">
            {dataSource?.id ? "Sửa" : "Thêm"}
          </Button>
          {mode === utils.$const.COMMON.MODAL_MODE.ADD ?
            <Button
              title='Thêm và tiếp tục'
              key='submitAndContinute'
              type="primary"
              onClick={handleConfirmAndContinue}
            >
              Thêm và tiếp tục
            </Button> : ''
          }
          <Button type='default' onClick={handleCloseModal}>
            Quay lại
          </Button>
        </Form.Item>
      ]}
    >
      <Form onFinish={handleSubmit} form={form} initialValues={{index: 0}} labelAlign="left">
        <Form.Item
          label="Thứ tự"
          required
          name="index"
          rules={[{ required: true, message: "Mã sản phẩm không được trống" }]}
          labelCol={{ span: 6 }}
        >
          <InputNumber placeholder="Nhập thứ tự" />
        </Form.Item>
        <Form.Item
          label="Mã màn hình"
          required
          name="menuCode"
          rules={[{ required: true, message: "Mã màn hình không được trống" }]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Nhập mã" />
        </Form.Item>
        <Form.Item
          label="Tên màn hình"
          name="menuName"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Input placeholder="Nhập tên màn hình" />
        </Form.Item>
        <Form.Item
          label="Đường dẫn"
          name="menuPath"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Input
            placeholder="Nhập đường dẫn"
            addonBefore="http://*/"
            addonAfter=""
          />
        </Form.Item>
        <Form.Item
          label="Quyền truy cập"
          name="permissionIdObj"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <BaseSelect
            searchName="permissionIdObj"
            itemValue="id"
            itemLabel="permissionName"
            placeholder="Chọn quyền"
            searchOnMount
            labelInValue
            onSearch={handleBrowsePermission}
          />
        </Form.Item>
        <Form.Item
          label="Trạng thái"
          name="status"
          rules={[{ required: true, message: "Vui lòng nhập" }]}
          labelCol={{ span: 6 }}
        >
          <Select placeholder="Chọn trạng thái">
            {map(utils.$const.MENU_CONTROL.STATUS, (stat) => (
              <Select.Option key={stat.id} value={stat.id}>
                {stat.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item label="Mô tả" name="description" labelCol={{ span: 6 }}>
          <Input.TextArea placeholder="Nhập ghi chú" rows={5} />
        </Form.Item> */}
        {/* Hidden Form Item */}
        <Form.Item hidden name="id">
          <Input disabled></Input>
        </Form.Item>
        <Form.Item hidden name="currentState">
          <Input disabled></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
MenuControlModal.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.string,
};

export default MenuControlModal;
