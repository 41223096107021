import React, { memo } from "react";
import { Table } from "antd";
import utils from "utils";

const List = memo(({ data, setPaging, paging, total }) => {

  const handleChangePaging = (pageNumber, pageSize) => {
    const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
    setPaging({ pageNumber: currentPage, pageSize });
  };

  const columns = [
    {
      dataIndex: "no",
      key: "no",
      title: "STT",
      align: "center",
      width: 50,
      render: (value, row, index) => ((paging.pageNumber - 1) * paging.pageSize) + index + 1,
    },
    {
      title: "Mã NV",
      dataIndex: "staffCode",
      key: "staffCode",
      width: 120
    },
    {
      title: "Tên nhân viên",
      dataIndex: "staffName",
      key: "staffName",
      width: 160
    },
    {
      title: "Ngày",
      dataIndex: "visitDate",
      key: "visitDate",
      width: 100,
      render: (value) => utils.$datetime.formatDatetime(value),
    },
    {
      title: "KH trên tuyến",
      dataIndex: "customerCount",
      key: "customerCount",
      align: "right",
      width: 120
    },
    {
      title: "VT đúng tuyến",
      dataIndex: "countVisitedOnRoute",
      key: "countVisitedOnRoute",
      align: "right",
      width: 120
    },
    {
      title: "VT ngoại tuyến",
      dataIndex: "countVisitedNotOnRoute",
      key: "countVisitedNotOnRoute",
      align: "right",
      width: 120
    },
    {
      title: "KH chưa VT",
      dataIndex: "countNotVisited",
      key: "countNotVisited",
      align: "right",
      width: 120
    },
    {
      title: "VT có ảnh",
      dataIndex: "countWithPicture",
      key: "countWithPicture",
      align: "right",
      width: 120
    },
    {
      title: "VT không ảnh",
      dataIndex: "countWithNoPicture",
      key: "countWithNoPicture",
      align: "right",
      width: 120
    },
    {
      title: "VT có kiểm tồn",
      dataIndex: "countWithInventoryInspection",
      key: "countWithInventoryInspection",
      align: "right",
      width: 120
    },
    {
      title: "VT không kiểm tồn",
      dataIndex: "countWithNoInventoryInspection",
      key: "countWithNoInventoryInspection",
      align: "right",
      width: 120
    },
    {
      title: "VT có hoá đơn",
      dataIndex: "countWithPurchaseOrder",
      key: "countWithPurchaseOrder",
      align: "right",
      width: 120
    },
    {
      title: "VT không hoá đơn",
      dataIndex: "countWithNoPurchaseOrder",
      key: "countWithNoPurchaseOrder",
      align: "right",
      width: 120
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        size="small"
        scroll={{ x: "calc(700px + 50%)", y: 400 }}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.pageNumber ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
      />
    </>
  );
});

export default List;
