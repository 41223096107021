import { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Button,
  Table,
  message,
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import {
  DeleteOutlined,
  EditFilled,
  SearchOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import WarehouseModal from 'components/modals/WarehouseInfoModal';
import ConfirmModal from 'components/modals/confirm';
import services from 'services';
import APP_CONST from 'utils/constants';

export default function WarehouseInfomation() {
  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  const [listWarehouse, setListWarehouse] = useState([]);
  const [total, setTotal] = useState(0);
  const [listWarehouseType, setListWarehouseType] = useState([]);
  const [listProvider, setListProvider] = useState([]);
  const [showWarehouseModal, setShowWarehouseModal] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [isReloadPage, setIsReloadPage] = useState(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [params, setParams] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(false);

  useEffect(() => {
    services.generalService
      .warehouseType_GetAllWarehouseType()
      .then((res) => setListWarehouseType(res))
      .catch(() => setListWarehouseType([]));

    services.generalService
      .distributor_GetAllDistributorsWithPermission()
      .then((res) => setListProvider(res))
      .catch(() => setListProvider([]));
  }, []);

  useEffect(() => {
    if (Object.values(params).length === 0) {
      handleGetListWarehouseInfo();
    } else {
      handleFilterListWarehouseInfo();
    }
    handleGetListWarehouseInfo();
  }, [paging, isReloadPage, params]);

  const handleGetListWarehouseInfo = () => {
    setLoading(true);
    services.generalService
      .warehouses_Get(paging?.page, paging?.pageSize)
      .then((res) => {
        setTotal(res?.totalCount ?? 0);
        setListWarehouse(res?.items ?? []);
      })
      .catch(() => message.error('Lấy thông tin kho không thành công'))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterListWarehouseInfo = () => {
    setLoading(true);
    services.generalService
      .warehouses_GetByFilter({
        warehouseCode: params?.warehouseCode,
        warehouseName: params?.warehouseName,
        pageNumber: paging?.page,
        pageSize: paging?.pageSize,
      })
      .then((res) => {
        setTotal(res?.totalCount ?? 0);
        setListWarehouse(res?.items ?? []);
      })
      .catch(() => message.error('Lấy thông tin kho không thành công'))
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      dataIndex: 'no',
      key: 'no',
      title: 'No.',
      align: 'center',
      width: 75,
      render: (value, row, index) => index + 1,
    },
    {
      dataIndex: 'warehouseCode',
      key: 'warehouseCode',
      title: 'Mã kho',
      width: 150,
    },
    {
      dataIndex: 'warehouseName',
      key: 'warehouseName',
      title: 'Tên kho',
      width: 200,
    },
    {
      dataIndex: 'warehouseTypeId',
      key: 'warehouseTypeId',
      title: 'Loại kho',
      width: 200,
      ellipsis: true,
      render: (value) =>
        listWarehouseType?.find((type) => type?.id === value)
          ?.warehouseTypeName,
    },
    {
      dataIndex: 'representative',
      key: 'representative',
      title: 'Người đại diện',
      width: 200,
      ellipsis: true,
    },
    {
      dataIndex: 'accountancyName',
      key: 'accountancyName',
      title: 'Kế toán',
      width: 200,
      ellipsis: true,
    },
    {
      dataIndex: 'area',
      key: 'area',
      title: 'Khu vực',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: 'distributorDto.distributorName',
      key: 'distributorDto.distributorName',
      title: 'Nhà phân phối',
      width: 200,
      ellipsis: true,
      render: (value, row) => row?.distributorDto?.distributorName,
    },
    {
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      title: 'Số điện thoại',
      width: 150,
      ellipsis: true,
    },
    {
      dataIndex: 'activeStatus',
      key: 'activeStatus',
      title: 'Trạng thái',
      align: 'center',
      fixed: 'right',
      width: 150,
      render: (value) => (
        <div className={`status--${value ? 'active' : 'inactive'}`}>
          {
            APP_CONST?.WAREHOUSE_INFO?.STATUS?.find(
              (status) => status?.id === value
            )?.name
          }
        </div>
      ),
    },
    {
      dataIndex: 'id',
      key: 'id',
      title: '#',
      align: 'center',
      width: 150,
      fixed: 'right',
      render: (_, row) => (
        <div className='warehouseInfo-card__button'>
          <Button
            icon={<EditFilled className='warehouseInfo-card__icon' />}
            type='primary'
            onClick={handleEditWarehouse(row)}
          />
          <Button
            className='warehouseInfo-card__button--right'
            icon={<DeleteOutlined className='warehouseInfo-card__icon' />}
            type='danger'
            onClick={handleDeleteWarehouse(row)}
          />
          <Button
            icon={<UnorderedListOutlined />}
            onClick={handleViewWarehouse(row)}
          />
        </div>
      ),
    },
  ];
  const handleCreateWarehouse = () => setShowWarehouseModal(true);

  const handleEditWarehouse = (warehouse) => () => {
    setShowWarehouseModal(true);
    setSelectedWarehouse(warehouse);
  };

  const handleDeleteWarehouse = (warehouse) => () => {
    setSelectedWarehouse(warehouse);
    setIsShowConfirmModal(true);
  };

  const handleViewWarehouse = (warehouse) => () => {
    setSelectedWarehouse(warehouse);
    setShowWarehouseModal(true);
    setIsReadOnly(true);
  };

  const handleApproveToDeleteWarehouse = () => {
    services.generalService
      .warehouses_Delete({
        id: selectedWarehouse?.id,
        currentState: selectedWarehouse?.currentState,
      })
      .then(() => {
        message.success('Xóa kho thành công');
        setIsShowConfirmModal(false);
        setSelectedWarehouse({});
        handleReloadPage();
      })
      .catch((err) => {
        message.error('Xóa kho thất bại');
      });
  };

  const handleRejectToDeleteWarehouse = () => {
    setIsShowConfirmModal(false);
    setSelectedWarehouse({});
  };

  const handleCloseModal = () => {
    setShowWarehouseModal(false);
    setSelectedWarehouse({});
    setIsReadOnly(false);
  };

  const handleReloadPage = useCallback(() => {
    setIsReloadPage(!isReloadPage);
    setSelectedWarehouse({});
  }, [setSelectedWarehouse, setIsReloadPage, isReloadPage]);

  const handleChangePaging = (page, pageSize) => {
    const currentPage = pageSize !== paging?.pageSize ? 1 : page;
    setPaging({ page: currentPage, pageSize });
  };

  const handleSubmit = (values) => setParams(values);

  return (
    <Card className='warehouseInfo-card'>
      <Form
        initialValues={{}}
        form={form}
        labelCol={{ span: 24 }}
        onFinish={handleSubmit}
      >
        <Row gutter={[8]}>
          <Col lg={6} xl={6}>
            <Form.Item name='warehouseCode' label='Mã kho'>
              <Input placeholder='Nhập mã kho' />
            </Form.Item>
          </Col>
          <Col lg={6} xl={6}>
            <Form.Item name='warehouseName' label='Tên kho'>
              <Input placeholder='Nhập tên kho' />
            </Form.Item>
          </Col>
        </Row>
        <Row
          justify='end'
          style={{ marginBottom: '12px', display: 'flex', gap: '10px' }}
        >
          <Button
            type='primary'
            htmlType='submit'
            icon={<SearchOutlined />}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Tìm kiếm
          </Button>
          <Button className='import-export'>Import Excel</Button>
          <Button className='import-export'>Export Excel</Button>
          <Button type='primary' onClick={handleCreateWarehouse}>
            + Thêm kho
          </Button>
        </Row>
      </Form>
      <Table
        className='warehouseInfo-card__table'
        columns={columns}
        dataSource={listWarehouse}
        bordered
        scroll={{ x: 1200 }}
        rowKey={(row) => row?.id}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50],
          current: paging?.page ?? 1,
          pageSize: paging?.pageSize ?? 10,
          onChange: handleChangePaging,
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} trong ${total} dòng`,
        }}
        loading={loading}
        locale={{ emptyText: 'Không có kho nào' }}
      />

      <WarehouseModal
        visible={showWarehouseModal}
        onCancel={handleCloseModal}
        listWarehouseType={listWarehouseType}
        listProvider={listProvider}
        selectedWarehouse={selectedWarehouse}
        onReloadPage={handleReloadPage}
        isReadOnly={isReadOnly}
      />

      <ConfirmModal
        title='Xóa kho'
        visible={isShowConfirmModal}
        onCancel={handleRejectToDeleteWarehouse}
      >
        <div className='confirm-modal__content'>
          Bạn có chắc muốn xóa{' '}
          <strong>{selectedWarehouse?.warehouseName}</strong> không?
        </div>
        <div className='confirm-modal__footer'>
          <Button type='danger' onClick={handleRejectToDeleteWarehouse}>
            Không
          </Button>
          <Button type='primary' onClick={handleApproveToDeleteWarehouse}>
            Xác nhận
          </Button>
        </div>
      </ConfirmModal>
    </Card>
  );
}
