import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Card,Spin,message } from "antd";
import styled from "styled-components";
import _ from "lodash";
import Detail from "./detail";
import services from "services";
let time;
const ProductMap = ({ className, profile }) => {
  const ggMapKey = process?.env?.REACT_APP_GG_MAP_KEY;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const GetProductBusinessProvinces = useCallback(() => {
    setLoading(true);
    services.organization
      .businessProvince_GetProductBusinessProvinces()
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error('Không tìm thấy kết quả')
      });
  }, []);

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(GetProductBusinessProvinces, 800);
  }, [GetProductBusinessProvinces]);

  return (
    <Card>
      <Row className={className} gutter={16}>
        <Col xs={24}>
        <Spin spinning={loading} tip="Đang lấy dữ liệu...">
          <Detail
            data={data}
            ggMapKey={ggMapKey} />
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default styled(ProductMap)``;
