/**
 * Copyright 2021-present, TIENDUC.
 * All rights reserved.
 * @author nguyenminhthong1994@gmail.com on 01/06/2021.
 * @description Màn hình `Thông tin tuyến bán hàng`. Route `/generalManagement/SaleRoute`
 * */

import { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  message,
  Typography,
  Row,
  Col,
  Input,
  Form,
  Modal,
  Breadcrumb,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import services from "services";
import APP_CONST from "utils/constants";
import { get } from "lodash";
//import utils from "utils";

const SaleRoutePage = function () {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  //const [isReloadPage, setIsReloadPage] = useState(false);
  const [data, setData] = useState([]);
  const [paging, setPaging] = useState({
    total: 0,
    pageSize: 20,
  });
  // const [saleRouteModalConfig, setSaleRouteModalConfig] = useState({
  //   mode: utils.$const.COMMON.MODAL_MODE.ADD,
  //   visible: false,
  //   dataSource: {},
  // });

  const handleBrowseSaleRoute = useCallback((param) => {
    setIsLoading(true);
    const pageNumber = get(param, "pageNumber", 1);
    const pageSize = get(param, "pageSize", 20);
    services.generalService
      .saleRoute_FilterSaleRoute({
        ...param,
        pageNumber,
        pageSize,
      })
      .then((res) => {
        const items = get(res, "items");
        const total = get(res, "totalCount");
        setTotal(res?.totalCount ?? 0);
        setData(items);
        setPaging((prev) => ({
          ...prev,
          total,
          pageSize,
        }));
      })
      .catch((error) => {
        message.error("Có lỗi khi lấy danh sách tuyến.");
        setData([]);
      })
      .finally(() => setIsLoading(false));
  }, []);
  const handleSubmit = (values) => {
    let param = {
      pageNumber: 1,
      pageSize: 20,
      ...values,
    };
    handleBrowseSaleRoute(param);
  };
  // const handleReloadPage = useCallback(() => {
  //   setIsReloadPage(!isReloadPage);
  //   setSaleRouteModalConfig({
  //     visible: true,
  //     mode: utils.$const.COMMON.MODAL_MODE.ADD,
  //     dataSource: {},
  //   });
  // }, [setIsReloadPage, isReloadPage]);

  const handleChangePaging = useCallback(
    (page, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : page;
      setPaging({ pageNumber: currentPage, pageSize });
    },
    [paging]
  );
  // Modal Function
  const handleShowConfirmDelete = useCallback(
    (row) => {
      Modal.confirm({
        title: (
          <span>
            Xác nhận xóa tuyến
            <b className="text-danger">{row.purchaseId}</b>
          </span>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: "Xác nhận",
        okType: "danger",
        cancelText: "Hủy",
        content: "",
        onOk() {
          services.generalService
            .saleRoute_Delete([
              {
                id: row.id,
                currentState: row.currentState,
              },
            ])
            .then(() => {
              handleBrowseSaleRoute({});
              message.success("Xóa tuyến thành công.");
            })
            .catch(() =>
              message.error("Xóa tuyến không thành công.Vui lòng thử lại")
            );
        },
        onCancel() {},
      });
    },
    [handleBrowseSaleRoute]
  );

  // const handleCancelSaleRouteModal = useCallback(() => {
  //   setSaleRouteModalConfig({
  //     visible: false,
  //     mode: utils.$const.COMMON.MODAL_MODE.ADD,
  //     dataSource: {},
  //   });
  //   setTimeout(() => {
  //     handleBrowseSaleRoute({});
  //   }, 300);
  // }, [handleBrowseSaleRoute]);

  useEffect(() => {
    handleBrowseSaleRoute({});
  }, [handleBrowseSaleRoute]);

  // const renderTitle = () => {
  //   if (window.location.pathname === "/general-management/sale-route") {
  //     return <Typography.Text>TUYẾN BÁN HÀNG</Typography.Text>;
  //   }
  //   return null;
  // };

  const columns = [
    // {
    //   dataIndex: "no",
    //   key: "no",
    //   title: "No.",
    //   align: "center",
    //   width: 75,
    //   render: (value, row, index) => index + 1,
    //   fixed: "left",
    // },
    {
      title: "Mã tuyến bán hàng",
      dataIndex: "saleRouteCode",
      key: "saleRouteCode",
      width: 200,
      align: "center",
    },
    {
      title: "Tuyến bán hàng",
      dataIndex: "saleRouteName",
      key: "saleRouteName",
      width: 400,
      align: "center",
    },
    {
      title: "Mô tả",
      dataIndex: "notes",
      key: "notes",
      width: 500,
      align: "center",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 150,
      align: "center",
      fixed: "right",
      render: (value) => (
        <div className={`status--${value ? "active" : "inactive"}`}>
          {
            APP_CONST?.ROUTE_INFO?.STATUS?.find(
              (status) => status?.id === value
            )?.name
          }
        </div>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      title: "#",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Button
            size="small"
            type="primary"
            // onClick={() =>
            //   setSaleRouteModalConfig({
            //     visible: true,
            //     mode: utils.$const.COMMON.MODAL_MODE.EDIT,
            //     dataSource: row,
            //   })
            // }
          >
            <span className="d-flex align-items-center">
              <EditOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => handleShowConfirmDelete(row)}
          >
            <span className="d-flex align-items-center">
              <DeleteOutlined /> <span className="ml-1"></span>
            </span>
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 10px 0px 10px",
        }}
      >
        <Typography.Title level={4}>TUYẾN BÁN HÀNG</Typography.Title>
        <Breadcrumb>
          <Breadcrumb.Item>Trang chủ</Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="">Quản lý sản phẩm</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="">Tuyến bán hàng</a>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Card className="SaleRoutePage" size="small">
        {/* <Typography.Title level={5}>TUYẾN BÁN HÀNG</Typography.Title>
      <Divider /> */}
        <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }}>
          <Row gutter={[8]}>
            <Col lg={24} xl={6}>
              <Form.Item name="saleRouteCode" label="Mã tuyến">
                <Input placeholder="Nhập mã tuyến" />
              </Form.Item>
            </Col>
            <Col lg={24} xl={6} align="bottom">
              <Form.Item name="saleRouteName" label="Tuyến">
                <Input placeholder="Nhập tên tuyến" />
              </Form.Item>
            </Col>
            <Col lg={24} xl={6}>
              <Form.Item className="general-button--height" label=" ">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  <span className="d-flex align-items-center">
                    <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                  </span>
                </Button>
              </Form.Item>
            </Col>
            <Col lg={24} xl={2}>
              <Form.Item className="general-button--height" label=" ">
                <Button className="import-export">
                  <span className="d-flex align-items-center">
                    <PlusOutlined />
                    <span className="ml-1">Export excel</span>
                  </span>
                </Button>
              </Form.Item>
            </Col>
            <Col lg={24} xl={2}>
              <Form.Item className="general-button--height" label=" ">
                <Button className="import-export">
                  <span className="d-flex align-items-center">
                    <PlusOutlined />
                    <span className="ml-1">Import excel</span>
                  </span>
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Card>
          <Table
            rowKey="id"
            bordered
            columns={columns}
            dataSource={data}
            scroll={{ x: 1200 }}
            size="small"
            pagination={{
              total,
              pageSizeOptions: [10, 20, 50],
              current: paging?.pageNumber ?? 1,
              pageSize: paging?.pageSize ?? 10,
              onChange: handleChangePaging,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} trong ${total} dòng`,
            }}
            loading={isLoading}
            locale={{ emptyText: "Không có tuyến bán hàng" }}
            footer={() => (
              <span>
                {total > 0 ? ` ${total} / ${paging?.pageSize} dòng` : ""}
              </span>
            )}
          />
        </Card>
      </Card>
    </>
  );
};
export default SaleRoutePage;
