/**
 * Copyright 2021-present, Hoàng An.
 * All rights reserved.
 * @author tangan2215@gmail.com on 07/06/2021.
 * @description Màn hình Nhóm nghành hàng. Route `/productManagement/ProductIndustry`
 **/
import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  Table,
  Modal,
  message,
  Divider,
  Col,
  Row,
  Input,
  Form,
  Select
} from "antd";
import {
  DeleteOutlined,
  EditFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ProductIndustryModal from "components/modals/ProductIndustryModal";
import services from "services";
import Pagination from "components/Paginate/index";
export default function ProductIndustry() {
  let time;
  const [listProductIndustry, setlistProductIndustry] = useState([]);
  const [showProductIndustryModal, setShowProductIndustryModal] =
    useState(false);
  const [selectedProductIndustry, setSelectedProductIndustry] = useState({});

  const [paging, setPaging] = useState({ page: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  //const [isReloadPage, setIsReloadPage] = useState(false);

  const handleGetlistProductIndustry = useCallback(() => {
    setLoading(true);
    services.generalService
      .productIndustry_GetBsWithPagination(paging.page, paging.pageSize)
      .then((res) => {
        setlistProductIndustry(res.items);
        setLoading(false);
        setTotal(res?.totalCount ?? 0);
      })
      .catch((error) => {
        setTotal(0);
        setLoading(false);
      });
  }, [paging]);

  const [form] = Form.useForm();

  const listStatus = [
    {
      id: 1,
      status: 1,
      statusName: "Hoạt động",
    },
    {
      id: 2,
      status: 0,
      statusName: "Không hoạt động",
    },
  ];

  useEffect(() => {
    clearTimeout(time);
    time = setTimeout(() => {
      handleGetlistProductIndustry();
    }, 800);
  }, [handleGetlistProductIndustry]);

  // const handleChangePaging = useCallback(
  //   (page, pageSize) => {
  //     const currentPage = pageSize !== paging?.pageSize ? 1 : page;
  //     setPaging({ page: currentPage, pageSize });
  //   },
  //   [paging]
  // );

  const handleShowConfirmDelete = useCallback((row) => {
    Modal.confirm({
      title: (
        <span>
          Xác nhận xóa nghành hàng{" "}
          <b className="text-danger">{row.productIndustryCode}</b>{" "}
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Xác nhận",
      okType: "danger",
      cancelText: "Hủy",
      content: "",
      onOk() {
        services.generalService
          .productIndustry_Delete({
            id: row.id,
            currentState: row.currentState,
          })
          .then((res) => {
            message.success("Xóa nghành hàng thành công.");
            handleGetlistProductIndustry();
          })
          .catch((erro) => {
            message.error("Tồn tại sản phẩm có chứa nghành hàng.");
          });
      },
      onCancel() {},
    });
  }, []);

  const columns = [
    {
      dataIndex: "productIndustryCode",
      key: "productIndustryCode",
      title: "Mã ngành",
      // align: "center",
      width: 150,
    },
    {
      dataIndex: "productIndustryName",
      key: "productIndustryName",
      title: "Tên Ngành",
      // align: "center",
      width: 200,
    },
    {
      dataIndex: "notes",
      key: "",
      title: "Ghi Chú",
      // align: "center",
    },
    {
      dataIndex: "activeStatus",
      key: "",
      title: "Trạng Thái",
      align: "center",
      width: 180,
      // fixed: "right",
      render: (value) => (
        <div className={`status--${value ? "active" : "inactive"}`}>
          {value === 1 ? "Hoạt động" : "Không hoạt động"}
        </div>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      title: "#",
      width: 180,
      align: "center",
      fixed: "right",
      render: (_, row) => (
        <div className="warehouseInfo-card__button">
          <Button
            icon={<EditFilled className="warehouseInfo-card__icon" />}
            type="primary"
            onClick={handleEditProductIndustry(row)}
          ></Button>
          <Button
            className="warehouseInfo-card__button--right"
            icon={<DeleteOutlined className="warehouseInfo-card__icon" />}
            type="danger"
            onClick={() => handleShowConfirmDelete(row)}
          ></Button>
        </div>
      ),
    },
  ];

  const handleCreateProductIndustry = useCallback(() => {
    setShowProductIndustryModal(true);
  }, []);

  const handleEditProductIndustry = useCallback(
    (ProductIndustryInfor) => (e) => {
      setShowProductIndustryModal(true);
      setSelectedProductIndustry(ProductIndustryInfor);
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setShowProductIndustryModal(false);
    setSelectedProductIndustry({});
  }, []);

  const handleConfirmfilter = useCallback(() => {
    form.submit();
  }, [form]);

  const onSubmit = (values) => {
    setLoading(true);

    let dataSubmit = {
      ...form.getFieldValue(),
      pageNumber: 1,
      pageSize: 10,
    };

    services.generalService
      .productIndustry_FilterProductIndustry(dataSubmit)
      .then((res) => {
        setlistProductIndustry(res.items);
        setLoading(false);
        // if (res.items.length == 0) {
        //   message.error("Không tìm thấy nhãn hiệu");
        // } else {
        //   message.success("Tìm kiếm thành công");
        // }
      });
  };

  return (
    <>
      
      <Card className="CustomerInfor-card">
        <Divider style={{margin:10}}/>
        <Form form={form} onFinish={onSubmit} labelCol={{ span: 24 }}>
          <Row gutter={[15]}>
            <Col lg={6} xl={6}>
              <Form.Item name="productIndustryCode" label="Mã ngành">
                <Input placeholder="Nhập mã ngành" />
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              <Form.Item name="productIndustryName" label="Tên ngành">
                <Input placeholder="Nhập tên ngành" />
              </Form.Item>
            </Col>
            <Col lg={6} xl={6}>
              <Form.Item name="activeStatus" label="Trạng thái">
                <Select allowClear placeholder="Chọn trạng thái">
                  {listStatus.map((item, i) => (
                    <Select.Option key={i} value={item?.status}>
                      {item?.statusName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ display: "flex", alignItems: "end" }}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleConfirmfilter}
                  size="middle"
                  disabled={loading}
                >
                  <span className="d-flex align-items-center">
                    <SearchOutlined /> <span className="ml-1">Tìm kiếm</span>
                  </span>
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={[8]}>
            <Col>
              <Button size="middle" onClick={handleCreateProductIndustry}>
                <span className="d-flex align-items-center">
                  <PlusOutlined />
                  <span className="ml-1">Thêm mới</span>
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
        <Divider style={{margin:10}}/>
        {/* <div className="CustomerInfor-card__header">
        <Typography.Text>DANH SÁCH NGÀNH HÀNG</Typography.Text>
      </div>
       */}

        <Table
          rowKey="id"
          className="CustomerInfor-card__table"
          columns={columns}
          dataSource={listProductIndustry}
          bordered
          loading={loading}
          scroll={{ x: 1200 }}
          pagination={false}
          // pagination={{
          //   total,
          //   pageSizeOptions: [10, 20, 50],
          //   current: paging?.page ?? 1,
          //   pageSize: paging?.pageSize ?? 10,
          //   onChange: handleChangePaging,
          //   showSizeChanger: true,
          // }}
          locale={{ emptyText: "Không có nghành hàng nào" }}
          size="small"
        />
        <Pagination total={total} setPaging={setPaging} paging={paging} />
        <ProductIndustryModal
          visible={showProductIndustryModal}
          onCancel={handleCloseModal}
          selectedProductIndustry={selectedProductIndustry}
          onReload={handleGetlistProductIndustry}
        />
      </Card>
    </>
  );
}
