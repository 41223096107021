import { useEffect, useState, useCallback } from "react";
import { message, Table } from "antd";
import services from "services";
import moment from "moment";
import get from 'lodash';

function GeneralSummary({ params, paging, setPaging, loading, setLoading }) {
  const [listShippingOrder, setListShippingOrder] = useState([]);
  const [total, setTotal] = useState(0);

  const handleChangePaging = useCallback(
    (pageNumber, pageSize) => {
      const currentPage = pageSize !== paging?.pageSize ? 1 : pageNumber;
      setPaging({ pageNumber: currentPage, pageSize });
    }
  ); 
  
  const handleChangeTable = (pagination) => () => {
    const pageNumber = get(pagination, "pageNumber", 1);
    const pageSize = get(pagination, "pageSize", 10);
    setPaging({ pageNumber, pageSize });
  }

  useEffect(() => {
    setLoading(true);
    services.customerService
      .visitAttendance_ReportVisitAttendanceCheckin({
        customerName: params.customerName,
        staffId: params.staffId,
        provinceId: params.provinceId,
        pageNumber: paging.pageNumber,
        pageSize: paging.pageSize,
        fromDate: params.fromDate && params.fromDate.format(),
        toDate: params.toDate && params.toDate.format(),
      })
      .then((res) => {
        setListShippingOrder(res?.items || []);
        setTotal(res?.totalCount);
      })
      .catch((err) => {
        message.error("Lấy thông tin đơn viếng thăm thất bại");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [paging]);

  const summaryColumns = [
    {
      dataIndex: "no",
      key: "no",
      title: "STT",
      align: "center",
      fixed: "left",
      width: 18,
      render: (value, row, index) => ((paging.pageNumber - 1) * paging.pageSize) + index + 1,
    },
    {
      dataIndex: "staff",
      key: "StaffCode",
      title: "Mã NV",
      width: 30,
      render: (value) => (value ? value.staffCode : ""),
    },
    {
      dataIndex: "staff",
      key: "staffName",
      title: "Nhân viên",
      width: 60,
      render: (value) => (value ? value.staffName : ""),
    },
    {
      dataIndex: "startTime",
      key: "startTime",
      title: "Ngày",
      align: "right",
      width: 35,
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
    },
    {
      dataIndex: "startTime",
      key: "dayOfWeek",
      title: "Thứ",
      align: "right",
      width: 25,
      render: (value) => {
        var currentDate = value.getDay();
        switch (currentDate) {
          case 0:
            return "Chủ nhật";
          case 1:
            return "Thứ hai";
          case 2:
            return "Thứ ba";
          case 3:
            return "Thứ tư";
          case 4:
            return "Thứ năm";
          case 5:
            return "Thứ sáu";
          case 6:
            return "Thứ bảy";
          default:
            return "Chủ Nhật";
        }
      },
    },
    {
      dataIndex: "startTime",
      key: "CheckinTime",
      title: "Check in",
      align: "right",
      width: 30,
      render: (value) => (value ? moment(value).format("HH:mm:ss") : ""),
    },
    {
      dataIndex: "endTime",
      key: "CheckoutTime",
      title: "Check out",
      align: "right",
      width: 30,
      render:(value) => (value ? moment(value).format("HH:mm:ss") : ""),
    },
    {
      dataIndex: "customer",
      key: "customerCode",
      title: "Mã KH",
      width: 50,
      render: (value) => (value ? value.customerCode : ""),
    },
    {
      dataIndex: "customer",
      key: "customerName",
      title: "Tên KH",
      width: 60,
      render: (value) => (value ? value.customerName : ""),
    },
    // {
    //   dataIndex: "customerGroup",
    //   key: "customerGroup",
    //   title: "Nhóm KH",
    //   width: 60,
    //   render: (value) => (value ? value.customerGroupName : ""),
    // },
    {
      dataIndex: "phonenumber",
      key: "phonenumber",
      title: "SĐT",
      align: "right",
      width: 40,
    },
    {
      dataIndex: "address",
      key: "address",
      title: "Địa chỉ",
      width: 100,
    },
    {
      dataIndex: "checkinAddress",
      key: "checkinAddress",
      title: "Địa chỉ checkin",
      width: 130,
    },
    {
      dataIndex: "customer",
      key: "createdDate",
      title: "Ngày tạo KH",
      align: "right",
      width: 35,
      render: (value) => (value ? moment(value.createdDate).format("DD-MM-YYYY") : "")
    },
    {
      dataIndex: "countMinute",
      key: "countMinute",
      title: "Số phút",
      align: "right",
      width: 20,
    },
    {
      dataIndex: "isPhoto",
      key: "isPhoto",
      title: "Chụp ảnh",
      align: "center",
      width: 25,
      render: (value) => {
        if (value) {
          return <i className="fa fa-check" aria-hidden="true"></i>;
        } else {
          return <i className="fa fa-times" aria-hidden="true"></i>;
        }
      },
    },
    {
      dataIndex: "isPO",
      key: "isPO",
      title: "Đơn hàng",
      align: "center",
      width: 25,
      render: (value) => {
        if (value) {
          return <i className="fa fa-check" aria-hidden="true"></i>;
        } else {
          return <i className="fa fa-times" aria-hidden="true"></i>;
        }
      },
    },
    {
      dataIndex: "isInventory",
      key: "isInventory",
      title: "Ghi tồn",
      align: "center",
      width: 25,
      render: (value) => {
        if (value) {
          return <i className="fa fa-check" aria-hidden="true"></i>;
        } else {
          return <i className="fa fa-times" aria-hidden="true"></i>;
        }
      },
    },
    {
      dataIndex: "notes",
      key: "notes",
      title: "Ghi chú",
      width: 150,
    },
  ];

  return (
    <Table
      className="shippingOrderList"
      columns={summaryColumns}
      dataSource={listShippingOrder}
      onChange={handleChangeTable}
      loading={loading}
      pagination={{
        total: total ?? 0,
        pageSizeOptions: [10, 20, 50],
        current: paging?.pageNumber ?? 1,
        pageSize: paging?.pageSize ?? 10,
        onChange: handleChangePaging,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} trong ${total} dòng`,
      }}
      locale={{ emptyText: "Không có viếng thăm nào" }}
      bordered
      size="small"
      scroll={{ x: 3000, y: 680 }}
    />
  );
}

export default GeneralSummary;
