import React, { memo, useState } from "react";
import {
  Button,
  Input,
  Modal,
  message,
} from "antd";
import _ from "lodash";
import { CheckSquareOutlined, SendOutlined } from "@ant-design/icons";
import services from "services";
import moment from "moment";
import AuthSlice from "redux/auth/authSlice";
import { useSelector } from "react-redux";

const ModalCreate = memo(
  ({ visible, setVisible, setParams, setFilter, filter, dataFilter, data }) => {
    const [valueInput, setValueInput] = useState("");
    const user = useSelector(AuthSlice.seletors.makeSelectUser);
    const onSetValue = (value) => {
      setValueInput(value.target.value);
    };
    const onClose = () => {
      // setParams((preState) => {
      //   let nextState = { ...preState };
      //   nextState = nextState;
      //   return nextState;
      // });
      setVisible((preState) => {
        let nextState = { ...preState };
        nextState.isShow = false;
        return nextState;
      });
      setValueInput("");
    };
    const onSetData = () => {
      // khi đã xử lý thì không lưu
      let problemStatus = _.get(visible, "row.problemStatus");
      if (problemStatus != 4) {
        // khi nhập thì mới lưu
        if (valueInput) {
          let objNew = {
            comments: valueInput,
            staffName: _.get(user, "userName"),
            staffId: _.get(user, "userId") && _.get(user, "userId").toString(),
            createTime: moment(),
            status: 2,
            problemTypeId: _.get(visible, "row.problemTypeId"),
            customerId: _.get(visible, "row.customerId"),
            visitAttendanceId: _.get(visible, "row.visitAttendanceId"),
          };
          let arrData = _.get(visible, "data");
          arrData.push(objNew);
          let cloneData = _.cloneDeep(arrData);
          cloneData.splice(0, 1);
          // lấy currentState
          let ObjCurrentState = _.find(data, { id: _.get(visible, "row.id") });
          let paramNew = {
            id: _.get(visible, "row.id"),
            comments: valueInput,
            staffId: _.get(user, "userId"),
            audioUrl: "",
            currentState: _.get(ObjCurrentState, "currentState"),
            problemStatus: _.get(visible, "row.problemStatus"),
          };
          services.customerService
            .visitComment_Update(paramNew)
            .then((res) => {
              setVisible((preState) => {
                let nextState = { ...preState };
                nextState.data = arrData;
                return nextState;
              });
              setValueInput("");
              // setParams((preState) => {
              //   let nextState = { ...preState };
              //   nextState = nextState;
              //   return nextState;
              // });
            })
            .catch((error) => {
              message.error("Có lỗi trong quá trình xử lí !");
            });
        } 
      }
      else {
        message.error("Vấn đề này đã được xử lí");
      }
    };
    const onAction = () => {
      // lấy currentState
      let ObjCurrentState = _.find(data, { id: _.get(visible, "row.id") });
      let params = {
        id: _.get(visible, "row.id"),
        comments: "",
        staffId: _.get(user, "userId"),
        currentState: _.get(ObjCurrentState, "currentState"),
        audioUrl: "",
      };
      services.customerService
        .visitComment_ResolveComment(params)
        .then((res) => {
          message.success("Xác nhận thành công!");
          setVisible((preState) => {
            let nextState = { ...preState };
            nextState.isShow = false;
            return nextState;
          });
          setValueInput("");
          // setParams((preState) => {
          //   let nextState = { ...preState };
          //   nextState = nextState;
          //   return nextState;
          // });
        })
        .catch((error) => {
          message.error("Có lỗi trong quá trình xử lí !");
        });
    };

    // const onSetData = (value, row, index, name) => {
    //   clearTimeout(time);
    //   time = setTimeout(() => {
    //     let data = visible.data;
    //     Object.assign(row, {
    //       [`${name}`]: value,
    //     });
    //     // data.splice(index, 1, row)
    //     let dataNew = _.cloneDeep(data);
    //     setVisible((preState) => {
    //       let nextState = { ...preState };
    //       nextState.data = dataNew;
    //       return nextState;
    //     });
    //     // setData(dataNew)
    //   }, 0);
    // };

    // const boweload = useCallback(async () => {}, [isShow]);
    // useEffect(() => {
    //   setTimeout(boweload, 0);
    // }, [boweload]);
    return (
      <Modal
        closable={false}
        onCancel={onClose}
        destroyOnClose
        visible={visible.isShow}
        placement="right"
        width="40%"
        footer={null}
        title="Xử lí vấn đề khách hàng"
      >
        <div style={{ height: 500, overflow: "auto" }}>
          {_.map(_.get(visible, "data"), (item, index) => {
            // nếu là khách hàng (1) thì bên trái,nv (2) là bên phải
            let flex = _.get(item, "status") == 1 ? "flex-start" : "flex-end";
            let blackground =
              _.get(item, "status") == 1 ? "#e5e5e5" : "#aaffff";
            let text = _.get(item, "prolem", 0).length > 100 ? "90%" : "auto";
            let timer =
              item.createTime &&
              moment(item.createTime).format("DD-MM-YYYY HH:mm");
            return (
              <div
                key={index}
                style={{ display: "flex", justifyContent: flex }}
              >
                <div style={{ display: "grid", marginTop: 10 }}>
                  <span style={{ color: "#b2b2b2" }}>
                    {item.staffName}, {timer}
                  </span>
                  <span
                    style={{
                      border: `1px solid ${blackground}`,
                      background: blackground,
                      padding: 10,
                      borderRadius: 10,
                      whiteSpace: "normal",
                      width: text,
                    }}
                  >
                    {item.comments}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ display: "flex", marginTop: 10 }}>
          <Input
            value={valueInput}
            onChange={onSetValue}
            onPressEnter={onSetData}
            style={{ marginRight: 5 }}
          ></Input>
          <Button
            style={{ justifyContent: "flex-end" }}
            type="primary"
            icon={<SendOutlined />}
            onClick={onSetData}
          >
            Gửi
          </Button>
          <Button
            style={{
              justifyContent: "flex-end",
              display: "flex",
              color: "green",
              marginLeft: 5,
            }}
            type="link"
            icon={<CheckSquareOutlined style={{ fontSize: 35 }} />}
            onClick={onAction}
            title="Đã xử lý"
          ></Button>
        </div>
      </Modal>
    );
  }
);
export default ModalCreate;
