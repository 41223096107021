import { Button, Form, Input, Modal, Select, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useCallback, useEffect } from "react";
import services from "services";

const BrandGroupsModal = ({
  visible,
  onCancel,
  selectedBrandGroups = {},
  listBrandGroups = [],
  onReloadPage,
  ...props
}) => {
  const [form] = Form.useForm();
  //const [currentCusId, setcurrentCusId] = React.useState("");
  useEffect(() => {
    if (Object.keys(selectedBrandGroups).length > 0) {
      form.setFieldsValue(selectedBrandGroups);
    } else {
      form.resetFields();
    }
  }, [selectedBrandGroups, form]);

  const onCloseModal = () => {
    onCancel();
    onReloadPage();
  };

  const onSubmit = useCallback(
    (isContinue) => {
      const values = form.getFieldsValue();
      if (!selectedBrandGroups?.id) {
        services.generalService
          .customerGroup_Create({ ...values })
          .then((res) => {
            if (res?.succeeded) {
              message.success("Thêm nhóm khách hàng thành công");
              if (isContinue) {
                form.resetFields();
              } else {
                form.resetFields();
                onCloseModal();
              }
            }
          })
          .catch((err) => {
            message.error(
              err?.message ??
                "Thêm thất bại, có thể mã nhóm khách hàng đã tồn tại"
            );
          });
      } else {
        services.generalService
          .customerGroup_Update({
            id: selectedBrandGroups?.id,
            currentState: selectedBrandGroups?.currentState,
            ...values,
          })
          .then((res) => {
            if (res?.succeeded) {
              message.success("Sửa nhóm khách hàng thành công");
              onReloadPage();
              form.resetFields();
              onCancel();
            }
          })
          .catch((err) =>
            message.success(err?.message ?? "Sửa nhóm khách hàng thành công")
          );
      }
    },
    [form, onCancel, onReloadPage, selectedBrandGroups]
  );

  const handleConfirm = useCallback(
    (isContinue) => {
      form.submit();

      form.validateFields().then(() => {
        onSubmit(isContinue);
      });
    },
    [form, onSubmit]
  );
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      visible={visible}
      onCancel={onCloseModal}
      {...props}
      title={
        selectedBrandGroups?.id ? "Sửa nhóm khách hàng" : "Thêm nhóm khách hàng"
      }
      footer={[
        <Button type="primary" onClick={() => handleConfirm(false)}>
          Lưu
        </Button>,
        // khi thêm mới nó nút này
        <Button
          style={{ display: selectedBrandGroups?.id ? "none" : "" }}
          type="primary"
          onClick={() => handleConfirm(true)}
        >
          Lưu và tiếp tục
        </Button>,
        <Button key="back" onClick={onCloseModal}>
          Quay lại
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={() => {}}
        //onFieldsChange={(e) =>
          // e?.map((item, key) => {
          //   if (item.name[0] === "customerGroupCode") {
          //     setcurrentCusId(item?.value);
          //   }
          // })
        //}
        {...layout}
        labelAlign="left"
      >
        <Form.Item
          label="Mã nhóm"
          required
          name="customerGroupCode"
          rules={[{ required: true, message: "Mã nhóm KH không được trống" }]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập mã nhóm khách hàng" />
        </Form.Item>

        <Form.Item
          label="Tên nhóm KH"
          required
          name="customerGroupName"
          rules={[
            { required: true, message: "Tên nhóm khách hàng không được trống" },
          ]}
          labelCol={{ span: 7 }}
        >
          <Input placeholder="Nhập tên nhóm khách hàng" />
        </Form.Item>

        <Form.Item
          label="Trạng thái "
          required
          name="status"
          rules={[{ required: true, message: "Trạng thái không được trống" }]}
          labelCol={{ span: 7 }}
        >
          <Select placeholder="Chọn loại trạng thái">
            <Select.Option value={1}>Hoạt động</Select.Option>
            <Select.Option value={0}>Ngừng hoạt động</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Ghi chú" name="notes" labelCol={{ span: 7 }}>
          <TextArea placeholder="Nhập ghi chú" rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BrandGroupsModal;
